import React, { useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Images from 'assets/img';
import TextInput from 'components/TextInput';
import { Input } from 'components/TextInput/styles';
import { Row } from 'styles/components';
import {
  BoxSearch,
  Container,
  Error,
  FieldLabel,
  ImgFlag,
  providerSelectStyles,
  StyledMask,
  WrapperInput,
} from './styles';

function ProviderData({ formik, providerId, setProviderId, edit }) {
  const { providers } = useSelector((state) => state.providers);
  const animatedComponents = makeAnimated();
  const providersOptions = [];

  providers.map((item) =>
    providersOptions.push({
      value: item.id,
      label: item.social_reason,
    })
  );

  useEffect(() => {
    let provider = {};

    providers.map((prov) => {
      if (prov.id === providerId.value) {
        provider = {
          ...prov,
        };
      }
      return null;
    });

    formik.setFieldValue(`cnpj`, provider?.cnpj);
    formik.setFieldValue(`owner`, provider?.owner);
    formik.setFieldValue(`owner_phone`, provider?.phone);
    formik.setFieldValue(`owner_email`, provider?.email);

    formik.setFieldValue(`collect_zipcode`, provider?.zipcode);
    formik.setFieldValue(`collect_point`, provider?.address);
    formik.setFieldValue(`collect_complement`, provider?.complement);
    formik.setFieldValue(`collect_neighborhood`, provider?.neighborhood);
    formik.setFieldValue(`collect_city`, provider?.city);
    formik.setFieldValue(`collect_state`, provider?.state);
    formik.setFieldValue(`collect_corresponding_name`, provider?.owner);
    formik.setFieldValue(`collect_corresponding_email`, provider?.owner_email);
    formik.setFieldValue(`collect_corresponding_phone`, provider?.owner_phone);
  }, [providerId.value, providers]);

  const onChange = (selectedOptions) => {
    setProviderId(selectedOptions);
    formik.setFieldError('owner', '');
  };

  return (
    <Container>
      <Row wrap="wrap">
        <WrapperInput>
          <FieldLabel>Nome do Solicitante</FieldLabel>
          <Row>
            <BoxSearch>
              <FaSearch size={18} />
            </BoxSearch>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Informe o solicitante"
              components={animatedComponents}
              isSearchable
              name="provider_id"
              options={providersOptions}
              styles={providerSelectStyles}
              value={providerId}
              onChange={onChange}
              isDisabled={edit}
            />
          </Row>
          {formik.errors.owner && <Error>{formik.errors.owner}</Error>}
        </WrapperInput>
        <WrapperInput>
          <FieldLabel>CNPJ</FieldLabel>
          <StyledMask
            mask="99.999.999/9999-99"
            label="CNPJ"
            name="cnpj"
            value={formik.values.cnpj}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          >
            {(inputProps) => <Input {...inputProps} />}
          </StyledMask>
        </WrapperInput>
      </Row>

      <Row wrap="wrap">
        <WrapperInput>
          <TextInput
            label="Responsável"
            name="owner"
            placeholder=""
            type="text"
            value={formik.values.owner}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </WrapperInput>

        <WrapperInput>
          <TextInput
            label="E-mail"
            name="owner_email"
            placeholder=""
            value={formik.values.owner_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </WrapperInput>
        <WrapperInput>
          <FieldLabel>Telefone</FieldLabel>
          <Row width="93%">
            <BoxSearch>
              <ImgFlag src={Images.IconFlagBrasil} />
            </BoxSearch>
            <InputMask
              name="owner_phone"
              mask="(99) 99999-9999"
              value={formik.values.owner_phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Row>
        </WrapperInput>
      </Row>
    </Container>
  );
}

export default ProviderData;
