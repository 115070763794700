import { bool, element } from 'prop-types';
import React from 'react';
import { Container } from './styles';

export default function BooleanWrapper({ children, isShown }) {
  return <Container isShown={isShown}>{children}</Container>;
}

BooleanWrapper.propTypes = {
  children: element,
  isShown: bool,
};

BooleanWrapper.defaultProps = {
  children: null,
  isShown: true,
};
