import React from 'react';
import Images from 'assets/img';
import useWindowDimensions from 'hooks/useWindowDimensions';
import MarkerLetter from 'pages/Convocations/components/MarkerLetter';
import {
  capitalize,
  formatDate,
  getImgType,
  getWeight,
  zipcode,
} from 'pages/Convocations/helpers';
import { Row } from 'styles/components';
import { InfoBox } from '../../styles';
import { ImageWithLabelAndValue, TitleBox } from './styles';

export default function ChargeInfoOne({
  selectedInvoice,
  selectedConvocation,
}) {
  const { height, width } = useWindowDimensions();

  return (
    <InfoBox>
      <TitleBox>
        Informações da Carga - ID{' '}
        {selectedConvocation.id.substring(0, 6).toUpperCase()}
      </TitleBox>
      <Row>
        <ImageWithLabelAndValue>
          <img
            className="img-big"
            src={getImgType(selectedConvocation.convocation_type)}
            alt=""
          />
          <div className="label-and-value">
            <p className="label">Carga</p>
            <p className="value">
              {selectedConvocation.type.replace('Carga ', '')}
            </p>
          </div>
        </ImageWithLabelAndValue>
        <ImageWithLabelAndValue>
          <div className="label-and-value">
            <p className="label">Solicitante</p>
            <p className="value">
              {selectedInvoice
                ? capitalize(selectedInvoice.data.name)
                : capitalize(selectedConvocation.owner)}
            </p>
          </div>
        </ImageWithLabelAndValue>
      </Row>
      <Row>
        <ImageWithLabelAndValue>
          <div className="marker-small">
            <MarkerLetter
              width="16.8px"
              height="24px"
              number={0}
              fontSize="12px"
              margin="2px 0 0 0"
              markerBlue
            />
          </div>
          <div className="label-and-value">
            <p className="label">Local de Coleta</p>
            <p className="value">
              {`${capitalize(selectedConvocation.collect_point)} ${
                selectedConvocation.collect_number
              }, ${capitalize(
                selectedConvocation.collect_neighborhood
              )} - ${capitalize(
                selectedConvocation.collect_city
              )}/${selectedConvocation.collect_state.toUpperCase()} - ${zipcode(
                selectedConvocation.collect_zipcode
              )}`}
            </p>
          </div>
        </ImageWithLabelAndValue>
      </Row>
      <Row>
        <ImageWithLabelAndValue>
          <div className="marker-small">
            <MarkerLetter
              width="16.8px"
              height="24px"
              number={1}
              fontSize="12px"
              margin="2px 0 0 0"
              markerBlue
            />
          </div>
          <div className="label-and-value">
            <p className="label">Local de Entrega</p>
            {selectedInvoice ? (
              <p className="value">
                {`${capitalize(selectedInvoice.data.street)}, ${
                  selectedInvoice.data.number
                }, ${capitalize(selectedInvoice.data.district)} - ${capitalize(
                  selectedInvoice.data.city
                )}/${selectedInvoice.data.state} - ${zipcode(
                  selectedInvoice.data.zipcode
                )}`}
              </p>
            ) : (
              <p className="value">
                {`${capitalize(selectedConvocation.delivery_point)} ${
                  selectedConvocation.delivery_number
                }, ${capitalize(
                  selectedConvocation.delivery_neighborhood
                )} - ${capitalize(selectedConvocation.delivery_city)}/${
                  selectedConvocation.delivery_state
                } - ${zipcode(selectedConvocation.delivery_zipcode)}`}
              </p>
            )}
          </div>
        </ImageWithLabelAndValue>
      </Row>
      {width > 425 ? (
        <Row>
          <ImageWithLabelAndValue>
            <img className="img-small" src={Images.IconDate} alt="" />
            <div className="label-and-value">
              <p className="label">Data da coleta</p>
              <p className="value">
                {selectedConvocation.created_at.toDate().toLocaleDateString()}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Validade da carga</p>
              <p className="value">
                {selectedConvocation.validity.toDate().toLocaleDateString()}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <img className="img-small" src={Images.IconDriver} alt="" />
            <div className="label-and-value">
              <p className="label">Motorista</p>
              <p className="value">{selectedConvocation.driver_name}</p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <img className="img-small" src={Images.Kg} alt="" />
            <div className="label-and-value">
              <p className="label">
                {selectedConvocation.base_unit
                  ? selectedConvocation.base_unit
                  : 'Peso'}
              </p>
              <p className="value">{selectedConvocation.weight}</p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <img className="img-small" src={Images.Gift} alt="" />
            <div className="label-and-value">
              <p className="label">Volumes</p>
              <p className="value">{selectedConvocation.quantity_delivery}</p>
            </div>
          </ImageWithLabelAndValue>
        </Row>
      ) : (
        <>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.IconDate} alt="" />
              <div className="label-and-value">
                <p className="label">Data da coleta</p>
                <p className="value">
                  {selectedConvocation.created_at.toDate().toLocaleDateString()}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Validade da carga</p>
                <p className="value">
                  {selectedConvocation.validity.toDate().toLocaleDateString()}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.IconDriver} alt="" />
              <div className="label-and-value">
                <p className="label">Motorista</p>
                <p className="value">{selectedConvocation.driver_name}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.Kg} alt="" />
              <div className="label-and-value">
                <p className="label">
                  {selectedConvocation.base_unit
                    ? selectedConvocation.base_unit
                    : 'Peso'}
                </p>
                <p className="value">{selectedConvocation.weight}</p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.Gift} alt="" />
              <div className="label-and-value">
                <p className="label">Volumes</p>
                <p className="value">{selectedConvocation.quantity_delivery}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        </>
      )}
    </InfoBox>
  );
}
