import { call, put, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
// import { actions as toastrActions } from 'react-redux-toastr';

import api from 'services/api';

import ChatActions from '../ducks/chat';
import NotificationsActions from '../ducks/notifications';

function getChatChannel() {
  return eventChannel((emit) => {
    const unsubscribe = api
      .firestore()
      .collection('MESSAGE_THREADS')
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          return doc.data();
        });

        emit({ data });
      });
    return unsubscribe;
  });
}

export function* getChat() {
  try {
    const channel = yield call(getChatChannel);
    while (true) {
      const { data } = yield take(channel);

      yield put(ChatActions.getChatSuccess(data));
    }
  } catch (err) {
    yield put(ChatActions.getChatFailure(err));
  }
}

function getMessagesChannel(id) {
  return eventChannel((emit) => {
    const unsubscribe = api
      .firestore()
      .collection('MESSAGE_THREADS')
      .doc(id)
      .collection('MESSAGES')
      .orderBy('created_at', 'asc')
      .onSnapshot((querySnapshot) => {
        const messagesChat = querySnapshot.docs.map((doc) => {
          const firebaseData = doc.data();

          const data = {
            id: doc.id,
            created_at: new Date().getTime(),
            ...firebaseData,
          };

          if (!firebaseData.system) {
            data.user = {
              ...firebaseData.user,
              name: firebaseData.user.displayName,
            };
          }

          return data;
        });

        emit({ messagesChat });
      });
    return unsubscribe;
  });
}

export function* getMessages({ id }) {
  try {
    const channel = yield call(getMessagesChannel, id);

    while (true) {
      const { messagesChat } = yield take(channel);

      yield put(ChatActions.getMessagesSuccess(messagesChat));
    }
  } catch (err) {
    yield put(ChatActions.getMessagesFailure(err));
  }
}

export function* addMessage({ id, userId, message }) {
  try {
    const { userActive } = yield select((state) => state.auth);

    yield api
      .firestore()
      .collection('MESSAGE_THREADS')
      .doc(id)
      .set(
        {
          latestMessage: {
            textMessage: message,
            type: 'provider',
            created_at: new Date().getTime(),
          },
        },
        { merge: true }
      );

    yield api
      .firestore()
      .collection('MESSAGE_THREADS')
      .doc(id)
      .collection('MESSAGES')
      .add({
        textMessage: message,
        type: 'provider',
        created_at: new Date().getTime(),
        user: {
          id: userActive.id,
          displayName: userActive.name,
        },
      });

    const notificationData = JSON.parse(
      JSON.stringify({
        title: `Atendimento - MOL`,
        body: `${userActive.name}: ${message}`,
        direction: userId,
      })
    ); // This removes every undefined key from the object

    yield api
      .firestore()
      .collection('notifications')
      .add({
        ...notificationData,
        created_at: new Date(),
      })
      .then((ref) => ref.update({ id: ref.id }));

    yield put(ChatActions.addMessageSuccess());
  } catch (err) {
    yield put(ChatActions.addMessageFailure(err));
  }
}
