/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';

import Paper from 'components/Paper';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import Modal from './components/Modal';
import Images from 'assets/img';
import { Row } from 'styles/components';
import ReportsActions from 'store/ducks/reports';

import {
  Container,
  WrapperTable,
  // WrapperAdd,
  // AddIcon,
  // DeleteIcon,
  // EyeIcon,
  WrapperPopover,
  PopoverText,
  PopoverButton,
} from './styles';

function Reports() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const reports = useSelector((state) => state.reports);
  const [anchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [edit, setEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [report] = useState(null);
  const [search, setSearch] = useState('');

  const data = reports.reports.map((item) => ({
    ...item,
    actions: item.id,
    id: item.id,
    driver: item?.driver || '',
    problem: item.problem,
    created_at: item?.created_at?.seconds * 1000,
  }));

  const filteredData = data.filter(
    (item) =>
      item.driver.toUpperCase().includes(search.toUpperCase()) ||
      item.problem.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    dispatch(ReportsActions.getReportsRequest());
  }, [dispatch]);

  // function handleSelect(id) {
  //     reports.reports.map(rep => {
  //       if(rep.id === id){
  //         setReport(rep)
  //       }
  //     } )
  //     setOpenModal(true);

  // }

  return (
    <Container>
      <Paper
        title="REPORTES"
        icon={Images.IconReports}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              // { label: 'Ações', id: 'actions' },
              { label: 'ID', id: 'id' },
              { label: 'Condutor', id: 'driver' },
              { label: 'Reporte', id: 'problem' },
              { label: 'Data de cadastro', id: 'created_at' },
            ]}
            activePage={0}
            totalPages={2}
            data={filteredData}
            customComponent={{
              actions: (id) => (
                <Row>
                  {/* <EyeIcon
                    onClick={() => handleSelect(id)}
                  /> */}
                  {/* <DeleteIcon
                    onClick={(e) => {
                      setSelectedId(id);
                      setAnchorEl(e.currentTarget);
                      setOpen(true);
                    }}
                  /> */}
                </Row>
              ),
              created_at: (e) => format(new Date(e), 'dd/MM/yyyy'),
            }}
          />
        </WrapperTable>
      </Paper>

      {openModal && report && (
        <Modal
          data={report}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          setSelectedId(null);
        }}
        placement="top"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <WrapperPopover>
          <PopoverText>Tem certeza?</PopoverText>
          <PopoverButton
            onClick={() => {
              dispatch(ReportsActions.removeReportRequest(selectedId));
              setSelectedId(null);
              setOpen(false);
            }}
          >
            Sim
          </PopoverButton>
        </WrapperPopover>
      </Popover>
    </Container>
  );
}

export default Reports;
