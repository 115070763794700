import styled from 'styled-components';
import { MdAttachMoney, MdRemoveRedEye } from 'react-icons/md';
import { IoIosDocument } from 'react-icons/io';

export const Container = styled.div``;

export const WrapperSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 10%;
  margin-left: auto;
  margin-bottom: 30px;
`;

export const Paper = styled.div`
  background-color: #ffffff;
  box-shadow: -3px 3px 6px #0000000f;
  border-radius: 5px;
  padding: 42px 67px 30px;
  @media (min-width: 1280px) {
    height: 624px;
  }
`;

export const PaperTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #7b7b7b;
  margin-bottom: 78px;
`;

export const WrapperChart = styled.div`
  width: 100%;
  /* height: 100%; */
`;

export const IconMoney = styled(MdAttachMoney).attrs({ size: '30px' })`
  color: #fff;
`;

export const IconPaper = styled(IoIosDocument).attrs({ size: '30px' })`
  color: #122d58;
`;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;
