/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import Paper from 'components/Paper';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import Modal from './components/Modal';
import NotificationActions from 'store/ducks/notifications';

import {
  Container,
  WrapperTable,
  WrapperAdd,
  AddIcon,
  ChatIcon,
} from './styles';

function Notifications() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');

  const data = notifications.map((item) => ({
    ...item,
    id: item.id,
    title: item.title,
    description: item.body,
    date: item.created_at.seconds * 1000,
  }));

  const filteredData = data.filter(
    (item) =>
      item.title.toUpperCase().includes(search.toUpperCase()) ||
      item.description.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    dispatch(NotificationActions.getNotificationsRequest());
  }, [dispatch]);

  return (
    <Container>
      <Paper
        title="Central de notificações"
        icon={<ChatIcon />}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              { label: 'ID', id: 'id' },
              { label: 'Título', id: 'title' },
              { label: 'Descrição', id: 'description' },
              { label: 'Data de cadastro', id: 'date' },
            ]}
            activePage={0}
            totalPages={2}
            data={filteredData}
            customComponent={{
              date: (e) => format(new Date(e), 'dd/MM/yyyy'),
            }}
          />
        </WrapperTable>
      </Paper>
      <WrapperAdd>
        <AddIcon onClick={() => setOpenModal(true)} />
      </WrapperAdd>
      <Modal open={openModal} onClose={() => setOpenModal(false)} />
    </Container>
  );
}

export default Notifications;
