import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import Antecipations from 'pages/Antecipations';
import InfoAntecipations from 'pages/Antecipations/components';
import Chat from 'pages/Chat';
import Collaborators from 'pages/Collaborators';
import ConvocationsDetails from 'pages/ConvocationDetails';
import Convocations from 'pages/Convocations';
import CreateConvocation from 'pages/CreateConvocation';

import Dashboard from 'pages/Dashboard';
import Forgot from 'pages/Forgot';
import Notifications from 'pages/Notifications';
import Providers from 'pages/Providers';
import Reports from 'pages/Reports';
import SignIn from 'pages/SignIn';
import TempAccompaniment from 'pages/TempAccompaniment';
import EvaluationForm from 'pages/EvaluationForm';
import Trip from 'pages/Trip';
import Trucker from 'pages/Trucker';
import TruckerData from 'pages/Trucker/TruckerData';
import history from 'services/history';
import Route from './Route';

// import Register from 'pages/Register';

function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        Página não encontrada - No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default function Routes() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect exact path="/" to="/signin" />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/forgot" exact component={Forgot} />
        <Route path="/temp/:id" component={TempAccompaniment} />
        <Route path="/rate/:id" component={EvaluationForm} />
        {/* <Route path="/register" component={Register} isPrivate /> */}
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/trip" component={Trip} isPrivate />
        <Route path="/trucker" component={Trucker} isPrivate />
        <Route path="/trucker_data" component={TruckerData} isPrivate />
        <Route path="/notifications" component={Notifications} isPrivate />
        <Route path="/collaborators" component={Collaborators} isPrivate />
        <Route path="/convocations" component={Convocations} isPrivate />
        <Route path="/antecipations" component={Antecipations} isPrivate />
        <Route
          path="/InfoAntecipations"
          component={InfoAntecipations}
          isPrivate
        />
        <Route
          path="/convocationDetails"
          component={ConvocationsDetails}
          isPrivate
        />

        <Route
          path="/createConvocation"
          component={CreateConvocation}
          isPrivate
        />
        <Route path="/providers" component={Providers} isPrivate />
        <Route path="/reports" component={Reports} isPrivate />
        <Route path="/chats" component={Chat} isPrivate />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
}
