import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getInsurancesRequest: null,
  getInsurancesSuccess: ['insurances'],
  getInsurancesFailure: ['error'],
});

export const InsurancesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  insurances: [],
  isLoading: false,
  error: null,
});

/* Reducers */

const getInsurancesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getInsurancesSuccess = (state, { insurances }) =>
  state.merge({
    insurances,
    isLoading: false,
  });

const getInsurancesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INSURANCES_REQUEST]: getInsurancesRequest,
  [Types.GET_INSURANCES_SUCCESS]: getInsurancesSuccess,
  [Types.GET_INSURANCES_FAILURE]: getInsurancesFailure,
});
