import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;

  background-color: ${(props) => (props.color ? props.color : '#CACACA')};
  width: 38px;
  height: 38px;
  border-radius: 50%;

  margin-bottom: 10px;

  img {
    width: 19.5px;
  }
`;
