import { Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import Scrollbars from 'react-custom-scrollbars';
import { TabContext, TabList } from '@material-ui/lab';
import Images from 'assets/img';
import BadgeStatus from './components/BadgeStatus';
import TruckerActions from 'store/ducks/trucker';
import {
  Card,
  Close,
  Content,
  Header,
  ProfileImg,
  Row,
  Column,
  Text,
  MarkerMol,
  PositionBox,
  ShareIcon,
  TabListMol,
  TabPanelMol,
} from './styles';
import { capitalize } from '../../helpers';
import Layout from './layout';
import PercurseItem from '../ChargeInfos/components/PercurseItem';
import useSnapshot from 'hooks/useGetUserLocation';
import { useDispatch } from 'react-redux';

export default function TruckerPopover({
  selectedConvocation,
  popoverPosition,
  handleClose,
  open,
  cte,
}) {
  const [selectedCte, setSelectedCte] = useState(cte);
  const [value, setValue] = useState('1');
  const dispatch = useDispatch();

  const truckerLocation = useSnapshot(
    selectedConvocation ? selectedConvocation.id : null
  );
  const { latitude, longitude } = truckerLocation;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToActualPosition = () => {
    handleClose();
    dispatch(TruckerActions.setActualMarkerTrucker(truckerLocation));
  };

  useEffect(() => {
    setSelectedCte(cte);
  }, [cte]);

  return (
    <Layout open={open} popoverPosition={popoverPosition}>
      {selectedConvocation && selectedCte && (
        <Card>
          <Header>
            ID: {selectedConvocation.id}
            <Close onClick={handleClose} />
          </Header>
          <Content>
            <Row align="flex-end" justify="flex-start" margin="0 0 16px 0">
              <ProfileImg>
                <FiUser />
              </ProfileImg>
              <Column align="flex-start" justify="flex-end" margin="0 0 0 8px">
                <Text align="start">Motorista</Text>
                <Text
                  align="start"
                  weight="600"
                  color="#122D58"
                  size="12px"
                  lineHeight="14.63px"
                >
                  {capitalize(selectedConvocation.driver_name)}
                </Text>
              </Column>
            </Row>
            <Row align="flex-start" margin="0 0 8px 0">
              <MarkerMol src={Images.MarkerMol} alt="Marcador MOL" />
              <PositionBox>
                <Text
                  weight="400"
                  size="10px"
                  lineHeight="12.19px"
                  color="#C4C4C4"
                  align="start"
                >
                  Posição Atual
                </Text>
                {latitude && longitude ? (
                  <Row align="center">
                    <Text
                      weight="400"
                      size="12px"
                      lineHeight="14.63px"
                      color="#7B7B7B"
                      width="fit-content"
                      margin="0 10px 0 0"
                      align="start"
                    >
                      {latitude}, {longitude}
                    </Text>
                    <ShareIcon onClick={goToActualPosition} />
                  </Row>
                ) : (
                  <Text
                    weight="400"
                    size="12px"
                    lineHeight="14.63px"
                    color="#7B7B7B"
                    width="fit-content"
                    margin="0 10px 0 0"
                    align="start"
                  >
                    Localização Indefinida
                  </Text>
                )}
              </PositionBox>
            </Row>
            <TabContext value={value}>
              <TabListMol>
                <TabList onChange={handleChange}>
                  <Tab label="Realizada" value="1" />
                  <Tab label="Em andamento" value="2" />
                </TabList>
              </TabListMol>
              <TabPanelMol value="1">
                <Scrollbars style={{ width: '330px', height: '204px' }}>
                  {selectedCte.invoices.map((invoice) => {
                    if (invoice.signature && invoice.status === 'completed') {
                      return (
                        <PercurseItem
                          invoice={invoice}
                          invoiceTimestamp={cte.created_at}
                        />
                      );
                    }
                  })}
                </Scrollbars>
              </TabPanelMol>
              <TabPanelMol value="2">
                <Scrollbars style={{ width: '330px', height: '204px' }}>
                  {selectedCte.invoices.map((invoice) => {
                    if (!invoice.signature && invoice.status !== 'completed') {
                      return (
                        <PercurseItem
                          invoice={invoice}
                          invoiceTimestamp={cte.created_at}
                        />
                      );
                    }
                  })}
                </Scrollbars>
              </TabPanelMol>
            </TabContext>
          </Content>
        </Card>
      )}
    </Layout>
  );
}
