import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  height: 110px;
  width: 96%;
  overflow: hidden;

  margin-right: 0;

  font-weight: 400;
  color: #7b7b7b;
  font-size: 13px;
  line-height: 14.63px;

  padding: 10px 14px;

  .imgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-right: 10px;

    img {
      width: 19.5px;
    }
  }

  .informations {
    padding: 4px 0 0 0;
    height: 100%;
    width: 87%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .type-name {
      p {
        font-size: 10px;
        line-height: 9.75px;
      }

      h4 {
        font-weight: 600;
        font-size: 15px;
        line-height: 17.07px;

        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        line-clamp: 1;

        margin-bottom: 7px;
      }

      h5 {
        font-weight: 400;
        font-size: 11px;
        line-height: 12.19px;
        color: #7b7b7b;

        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        line-clamp: 1;

        margin-bottom: 13px;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .info-charge {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;
    }

    .info-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .type {
        width: max-content;
        padding-left: 20px;
      }
    }

    .info-bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .driver {
        width: max-content;
        padding-left: 20px;
      }
    }

    p.date {
      font-size: 10px;
      line-height: 12px;
      color: #c8c8c8;
    }
  }

  .menu {
    position: absolute;
    right: 0;
    top: 0;

    z-index: 10;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
  }
`;

export const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
