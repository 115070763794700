import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '100px',
    },
  })
);

export const Container = styled.div`
  height: 100vh;
`;
