import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  height: 120px;
  width: 100%;

  &:last-child {
    .line {
      display: none !important;
    }
  }

  .marker-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    position: relative;

    margin-right: 15px;

    img {
      height: 25px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`;

export const ShareButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15); */
  border-radius: 50%;

  position: absolute;
  bottom: 5px;
  right: 5px;

  cursor: pointer;

  & .icon {
    width: 18px;
    color: #122d58;
  }
`;

export const Line = styled.div`
  width: 2px;
  flex: 1;
  background-color: #a7b4ca;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.color ?? 'rgba(240, 151, 2, 0.1)'};

  position: relative;

  width: 100%;

  padding: 8px;

  margin-bottom: 16px;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.63px;
    color: #7b7b7b;
  }

  p.date {
    font-size: 10px;
    line-height: 12.19px;
  }
`;

export const Text = styled.span`
  color: ${(props) => props.color ?? '#A7B4CA'};
  font-size: ${(props) => props.size ?? '10px'};
  font-weight: ${(props) => props.weight ?? '400'};
  line-height: ${(props) => props.lineHeight ?? '12.19px'};
  margin: ${(props) => props.margin ?? '0'};
  width: 100%;
  text-align: ${(props) => props.align ?? 'center'};
`;
