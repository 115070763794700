/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/styles';
import Stepper from '@material-ui/core/Stepper';
import InputCurrency from 'react-currency-input';

export const Container = styled.div`
  width: 90vw;
  max-width: 518px;
`;

export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;
`;

export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const Line = styled.div`
  background-color: #e5e5e5;
  height: 1px;
  width: 100%;
`;

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  color: red;
  font-weight: 600;
  align-self: center;
`;

export const useStyles = makeStyles(() => ({
  root: {},
  circle: {
    backgroundColor: '#FFF28D',
  },
}));

export const StepperMUI = styled(Stepper)`
  & .MuiStepIcon-root.MuiStepIcon-active {
    color: #fff28d;
  }
  & .MuiStepIcon-text {
    fill: #122d58;
    font-weight: bold;
  }
  & .MuiStepLabel-label.MuiStepLabel-active {
    color: #a5a5a5;
  }

  /* & .MuiStepIcon-root.MuiStepIcon-completed {
    color: #fff28d;
  } */

  & .MuiStepIcon-completed {
    fill: #122d58;
  }
`;

export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxMap = styled.div`
  width: 100%;
  height: 250px;
`;

export const BoxSearch = styled.div`
  height: 32px;
  padding: 0 12px;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgFlag = styled.img`
  height: 18px;
  object-fit: contain;
`;

export const selectStyles = {
  control: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'white',
    display: 'flex',
    width: 350,
    borderColor: '#ebebeb',
    minHeight: 32,
    height: 32,

    // ':hover': {
    //   ...styles[':hover'],
    //   borderColor: !isDisabled && (isSelected ? '#122d58' : '#ebebeb'),
    // },
    ':active': {
      ...styles[':active'],
      borderColor: !isDisabled && (isSelected ? '#122d58' : '#ebebeb'),
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#122d58'
        : isFocused
        ? '#ebebeb'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : 'gray',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#122d58' : 'gray'),
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
};

// width: '100%',
// height: 32,
// borderColor: '#ebebeb',

export const Currency = styled(InputCurrency)`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;
