import React from 'react';
import { element, func, bool, string } from 'prop-types';
import { Modal } from '@material-ui/core';

import { Container, Card, Header, Close } from './styles';

function ModalComp({ children, open, onClose, title }) {
  return (
    <Modal className="modal-comp" open={open}>
      <Container>
        <Card>
          <Header>
            {title}
            <Close onClick={onClose} />
          </Header>
          {children}
        </Card>
      </Container>
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  children: element.isRequired,
  onClose: func,
  open: bool,
  title: string,
};

ModalComp.defaultProps = {
  onClose: () => {},
  open: false,
  title: '',
};
