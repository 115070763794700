import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  border-radius: 15px;

  background-color: ${(props) => (props.color ? props.color : '#7B7B7B')};

  img {
    max-width: 12px;
    height: 12px;
    filter: brightness(100);
  }

  p {
    color: #fff;
    margin-left: 5.75px;

    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    line-clamp: 1;

    max-width: 200px;
  }
`;
