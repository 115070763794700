/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, arrayOf, object } from 'prop-types';
import Modal from 'components/Modal';

import {
  useStyles,
  Container,
  WrapperBody,
  WrapperBottom,
  BottomText,
  FieldText,
  FieldValue,
  Row,
  Text,
} from './styles';

function ModalComp({ data, open, onClose, onClick, setFreight }) {
  const classes = useStyles();

  const risk_rate = 36.25;
  const freight_value =
    data?.freight_value.replace('R$', '').replace('.', '').replace(',', '.') ||
    0;
  const value =
    freight_value -
    (parseFloat(
      data?.value.replace('R$', '').replace('.', '').replace(',', '.')
    ) +
      freight_value * 0.085 +
      risk_rate +
      (data?.insurance
        ? parseFloat(data?.insurance.replace('.', '').replace(',', '.'))
        : 0) +
      (data?.toll_value
        ? parseFloat(data?.toll_value.replace('.', '').replace(',', '.'))
        : 0) +
      (data?.unload_value
        ? parseFloat(data?.unload_value.replace('.', '').replace(',', '.'))
        : 0) +
      freight_value * 0.0175);

  setFreight(value);

  return (
    <Modal open={open} onClose={onClose} title="RESUMO DA CARGA">
      <Container>
        <>
          <WrapperBody>
            <Row>
              <FieldText>Frete Embarcador</FieldText>
              <Text>R$</Text>
              <FieldValue>{freight_value}</FieldValue>
            </Row>

            <Row>
              <FieldText>Imposto</FieldText>
              <Text>R$</Text>
              <FieldValue>{(freight_value * 0.085).toFixed(2)}</FieldValue>
            </Row>

            <Row>
              <FieldText>Tx Gerenciamento Risco</FieldText>
              <Text>R$</Text>
              <FieldValue>{risk_rate}</FieldValue>
            </Row>

            <Row>
              <FieldText>Seguro</FieldText>
              <Text>R$</Text>
              <FieldValue>
                {(data?.insurance
                  ? parseFloat(
                      data?.insurance.replace('.', '').replace(',', '.') || 0.0
                    )
                  : 0
                ).toFixed(2)}
              </FieldValue>
            </Row>

            <Row>
              <FieldText>Pedágio</FieldText>
              <Text>R$</Text>
              <FieldValue>
                {(data?.toll_value
                  ? parseFloat(
                      data?.toll_value.replace('.', '').replace(',', '.') || 0.0
                    )
                  : 0
                ).toFixed(2)}
              </FieldValue>
            </Row>

            <Row>
              <FieldText>Descarga</FieldText>
              <Text>R$</Text>
              <FieldValue>
                {(data?.unload_value
                  ? parseFloat(
                      data?.unload_value.replace('.', '').replace(',', '.') ||
                        0.0
                    )
                  : 0
                ).toFixed(2)}
              </FieldValue>
            </Row>

            <Row>
              <FieldText>Taxa Emissão</FieldText>
              <Text>R$</Text>
              <FieldValue>{(freight_value * 0.0175).toFixed(2)}</FieldValue>
            </Row>

            <Row>
              <FieldText>Frete Motorista</FieldText>
              <Text>R$</Text>
              <FieldValue>
                {data?.value
                  .replace('R$', '')
                  .replace('.', '')
                  .replace(',', '.')}{' '}
              </FieldValue>
            </Row>

            {/* <Line /> */}

            <Row>
              <FieldText className={classes.text}> Frete Líquido</FieldText>
              <FieldValue className={classes.value} negative={value < 0}>
                {value.toFixed(2)}
              </FieldValue>
            </Row>
          </WrapperBody>

          <WrapperBottom>
            <BottomText onClick={onClose}>Cancelar</BottomText>
            <BottomText onClick={onClick}>Confirmar</BottomText>
          </WrapperBottom>
        </>
      </Container>
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onClick: func.isRequired,
  data: arrayOf(object).isRequired,
};
