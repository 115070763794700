import React from 'react';
import Images from 'assets/img';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Loading } from 'pages/Chat/styles';
import MarkerLetter from 'pages/Convocations/components/MarkerLetter';
import {
  capitalize,
  getImgType,
  getWeight,
  zipcode,
} from 'pages/Convocations/helpers';
import { Row } from 'styles/components';
import { InfoBox } from '../../styles';
import { ImageWithLabelAndValue, TitleBox } from './styles';

export default function ChargeInfoFour({ selectedConvocation, cte, user }) {
  const { height, width } = useWindowDimensions();

  return user != null ? (
    <InfoBox>
      <TitleBox>Informações do Veículo</TitleBox>
      {width > 425 ? (
        <Row>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Placa do veículo</p>
              <p className="value">
                {selectedConvocation.plate
                  ? selectedConvocation.plate.toString().toUpperCase()
                  : user.plate
                  ? user.plate.toString().toUpperCase()
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">UF da Placa</p>
              <p className="value">
                {selectedConvocation.uf_plate
                  ? selectedConvocation.uf_plate.toString().toUpperCase()
                  : user._uf_plate
                  ? user._uf_plate.toString().toUpperCase()
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Tipo de rodado</p>
              <p className="value">
                {user.type_rotated
                  ? capitalize(user.type_rotated)
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Carroceria</p>
              <p className="value">
                {user.type_bodywork
                  ? capitalize(user.type_bodywork)
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Eixos</p>
              <p className="value">{user.qtd_axes ?? 'Não informado'}</p>
            </div>
          </ImageWithLabelAndValue>
        </Row>
      ) : (
        <>
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Placa do veículo</p>
                <p className="value">
                  {user.plate
                    ? user.plate.toString().toUpperCase()
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">UF da Placa</p>
                <p className="value">{user.uf_plate ?? 'Não informado'}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Tipo de rodado</p>
                <p className="value">
                  {user.type_rotated
                    ? capitalize(user.type_rotated)
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Carroceria</p>
                <p className="value">
                  {user.type_bodywork
                    ? capitalize(user.type_bodywork)
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Eixos</p>
                <p className="value">{user.qtd_axes ?? 'Não informado'}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        </>
      )}
      <Row>
        <ImageWithLabelAndValue>
          <div className="label-and-value">
            <p className="label">Fabricante</p>
            <p className="value">
              {user.brand_vehicle
                ? user.brand_vehicle.toString().toUpperCase()
                : 'Não informado'}
            </p>
          </div>
        </ImageWithLabelAndValue>
        <ImageWithLabelAndValue>
          <div className="label-and-value">
            <p className="label">Modelo</p>
            <p className="value">
              {user.model_vehicle
                ? capitalize(user.model_vehicle)
                : 'Não informado'}
            </p>
          </div>
        </ImageWithLabelAndValue>
      </Row>
    </InfoBox>
  ) : (
    <InfoBox>
      <Loading />
    </InfoBox>
  );
}
