import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  max-width: 1000px;
`;

export const Body = styled.div`
  padding: 30px 20px;
  border-bottom: 1px solid #e5e5e5;
`;

export const WrapperBottom = styled.div`
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
`;

export const BackButton = styled.a`
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
`;
