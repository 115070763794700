import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth || '100%'};
  min-width: ${(props) => props.minWidth || '100%'};
  margin: ${(props) => props.margin};
`;

export const Label = styled.span`
  color: #7b7b7b;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 2px;
  white-space: nowrap;
`;

export const Content = styled.select`
  height: 48px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxNDIxIDBDMC41MjMzMDggMCAwLjA3NzE0MTggMS4wNzcxNCAwLjcwNzEwNiAxLjcwNzExTDQuMjkyODkgNS4yOTI4OUM0LjY4MzQyIDUuNjgzNDIgNS4zMTY1OCA1LjY4MzQyIDUuNzA3MTEgNS4yOTI4OUw5LjI5Mjg5IDEuNzA3MTFDOS45MjI4NiAxLjA3NzE0IDkuNDc2NjkgMCA4LjU4NTc4IDBIMS40MTQyMVoiIGZpbGw9IiM3QjdCN0IiLz4KPC9zdmc+Cg==)
    no-repeat 96% 50%;
  width: 100%;

  option {
    transition: ease-in 1100ms;
    background: #fff;
  }

  padding: 5px 16px;
  background-color: #fafafa;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  ${(props) => (props.error ? 'box-shadow: 0 3px 6px #f2716044;' : '')}
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;
