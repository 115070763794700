import styled from 'styled-components';

export const Button = styled.div`
  height: ${(props) => props.height || '32px'};
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) =>
    props.type === 'primary' ? '#FFF28D' : '#DEDEDE'};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || '0'};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: ${(props) => props.align || 'stretch'};
  font-size: 11px;
  font-weight: 700;
  color: #122d58;

  :first-child {
    margin-right: 30px;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  width: 15px;
  height: 15px;
`;
