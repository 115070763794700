import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from 'services/api';

import NotificationsActions from '../ducks/notifications';

export function* getNotifications() {
  try {
    const res = yield api.firestore().collection('notifications').get();

    const notifications = res.docs.map((item) => item.data());

    yield put(NotificationsActions.getNotificationsSuccess(notifications));
  } catch (err) {
    yield put(NotificationsActions.getNotificationsFailure(err));
  }
}

export function* addNotification({ notification, closeModal }) {
  try {
    const { users } = yield select((state) => state.notifications);
    const { text: destiny } = users.find(
      (item) => item.value === notification.direction
    );

    const notificationData = JSON.parse(JSON.stringify(notification)); // This removes every undefined key from the object
    yield api
      .firestore()
      .collection('notifications')
      .add({
        ...notificationData,
        created_at: new Date(),
      })
      .then((ref) => ref.update({ id: ref.id }));

    yield put(NotificationsActions.addNotificationSuccess());
    yield put(NotificationsActions.getNotificationsRequest());
    closeModal();

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Notificação enviada',
        message: `Audiência: ${destiny}`,
      })
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao enviar notificação',
      })
    );
    yield put(NotificationsActions.addNotificationFailure(err));
  }
}

export function* getUsers() {
  try {
    const res = yield api.firestore().collection('users').get();

    const users = res.docs
      .map((item) => item.data())
      .map((item) => ({ text: item.email, value: item.id }));

    yield put(NotificationsActions.getUsersSuccess(users));
  } catch (err) {
    yield put(NotificationsActions.getUsersFailure(err));
  }
}
