import React, { useEffect } from 'react';
import { element } from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { MdDashboard, MdChat } from 'react-icons/md';
import { IoIosDocument } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/Modal';
import ReportsList from '../components/List';

import { Icon } from 'styles/components';
import Images from 'assets/img';
import AuthActions from 'store/ducks/auth';
import ReportsActions from 'store/ducks/reports';

import {
  useStyles,
  Logo,
  ContainerProfile,
  WrapperProfile,
  ProfileImg,
  PopoverRow,
  ProfileIcon,
  ExitIcon,
  PopoverText,
} from './styles';

export default function DefaultLayout({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reportsEl, setReportsEl] = React.useState(null);
  const userActive = useSelector((state) => state.auth.userActive);
  const { reports } = useSelector((state) => state.reports);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch(ReportsActions.getReportsRequest());
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function openProfileMenu() {
    handlePopoverClose();
    setOpenProfile(true);
  }

  const handleReports = (event) => {
    setReportsEl(event.currentTarget);
  };

  const handleCloseReports = () => {
    dispatch(ReportsActions.setReportOldAllRequest());
    setReportsEl(null);
  };

  function handleSetReportOld(reportId) {
    dispatch(ReportsActions.setReportOldRequest(reportId));
    setReportsEl(null);
  }

  const reportsRender = reports.filter(
    (report) => !report.read || report.read === undefined
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Logo src={Images.Logo} />
          <ContainerProfile>
            <Tooltip title="Reportes">
              <IconButton color="inherit" onClick={handleReports}>
                <Badge badgeContent={reportsRender.length} color="secondary">
                  <AnnouncementIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <WrapperProfile onClick={handlePopoverOpen}>
              <ProfileImg
                src={userActive.profileImg || Images.IconProfileImg}
              />
            </WrapperProfile>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <PopoverRow onClick={openProfileMenu}>
                <ProfileIcon />
                <PopoverText>Perfil</PopoverText>
              </PopoverRow>
              <PopoverRow onClick={() => dispatch(AuthActions.signOut())}>
                <ExitIcon />
                <PopoverText>Sair</PopoverText>
              </PopoverRow>
            </Popover>

            <Popover
              anchorEl={reportsEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              onClose={handleCloseReports}
              open={Boolean(reportsEl)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ReportsList
                reports={reportsRender}
                onSelect={handleSetReportOld}
              />
            </Popover>
          </ContainerProfile>
        </Toolbar>
      </AppBar>
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => history.push('/profile')}>Perfil</MenuItem>
        <MenuItem onClick={() => dispatch(AuthActions.signOut())}>
          Sair
        </MenuItem>
      </Menu> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button component={NavLink} to="/dashboard">
            <ListItemIcon>
              <MdDashboard size={20} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem button component={NavLink} to="/trip">
            <ListItemIcon>
              <IoIosDocument size={20} />
            </ListItemIcon>
            <ListItemText primary="Viagem" />
          </ListItem>

          <ListItem button component={NavLink} to="/trucker">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconGrayUsers} />
            </ListItemIcon>
            <ListItemText primary="Caminhoneiros" />
          </ListItem>

          <ListItem button component={NavLink} to="/providers">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconProviders} />
            </ListItemIcon>
            <ListItemText primary="Empresas" />
          </ListItem>

          <ListItem button component={NavLink} to="/notifications">
            <ListItemIcon>
              <MdChat size={20} />
            </ListItemIcon>
            <ListItemText primary="Notificações" />
          </ListItem>

          {/* <ListItem button component={NavLink} to="/notifications">
            <ListItemIcon>
              <FaUsers size={20} />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem> */}
          <ListItem button component={NavLink} to="/convocations">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconGrayTruck} />
            </ListItemIcon>
            <ListItemText primary="Carga" />
          </ListItem>

          <ListItem button component={NavLink} to="/collaborators">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconUserGear} />
            </ListItemIcon>
            <ListItemText primary="Colaboradores" />
          </ListItem>

          <ListItem button component={NavLink} to="/chats">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconChat} />
            </ListItemIcon>
            <ListItemText primary="Chat" />
          </ListItem>

          <ListItem button component={NavLink} to="/reports">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconReports} />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
          </ListItem>

          <ListItem button component={NavLink} to="/antecipations">
            <ListItemIcon>
              <Icon pointer width="20px" src={Images.IconHandHolding} />
            </ListItemIcon>
            <ListItemText primary="Antecipações" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Modal
        data={userActive}
        open={openProfile}
        onClose={() => setOpenProfile(false)}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: element.isRequired,
};
