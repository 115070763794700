import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/styles';

export const Container = styled.div`
  width: 90vw;
  max-width: 518px;
`;

export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;
`;

export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const Line = styled.div`
  background-color: #e5e5e5;
  height: 1px;
  width: 100%;
`;

export const useStyles = makeStyles(() => ({
  root: {},
  circle: {
    backgroundColor: '#FFF28D',
  },
}));

export const ImgFlag = styled.img`
  height: 18px;
  object-fit: contain;
`;

// width: '100%',
// height: 32,
// borderColor: '#ebebeb',
