import styled from 'styled-components';

export const Button = styled.div`
  width: ${(props) => props.width || 'fit-content'};
  height: ${(props) => props.height || 'fit-content'};
  padding: ${(props) => props.padding || '13.5px 15px'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.type === 'primary' || props.type === 'submit'
      ? '#122D58'
      : props.type === 'text-button'
      ? 'transparent'
      : 'transparent'};
  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.type === 'primary' || props.type === 'submit'
      ? '#FFFFFF'
      : props.type === 'text-button'
      ? '#122D58'
      : '#122D58'};
  border: ${(props) =>
    props.type === 'primary' ||
    props.type === 'submit' ||
    props.type === 'text-button'
      ? 'none'
      : '1px solid #122D58'};
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: ${(props) => props.marginTop || '0'};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: ${(props) => props.align || 'stretch'};
  font-size: 14px;
  font-weight: 800;
  transition: 400ms;
  position: relative;

  &:hover {
    background-color: ${(props) =>
      props.type === 'text-button' ? 'rgba(0, 0, 0, 0.1)' : ''};
  }

  & .disabled-modal {
    background-color: #ababab;
    border-radius: 5px;
    opacity: 0.85;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  filter: ${(props) => (props.iconLight ? 'brightness(100)' : null)};
`;
