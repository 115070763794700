import * as yup from 'yup';

export const schema = (verifyEmail) => {
  let name = yup.string();
  let password = yup.string();
  let profile = yup.string().required('Informe o Perfil');

  if (!verifyEmail) {
    name = name.required('Nome é obrigatório');
    password = password.required('Senha é obrigatória');
  }

  return yup.object().shape({
    name,
    email: yup
      .string()
      .email('E-mail inválido')
      .test(
        'is-valid',
        'E-mail já cadastrado',
        () => verifyEmail === 0 || verifyEmail === 'inactive'
      )
      .required('E-mail é obrigatório'),
    password,
    profile,
  });
};
