import React from 'react';
import { bool, func } from 'prop-types';

import Modal from 'components/Modal';

import {
  Container,
  WrapperBody,
  Text,
  WrapperBottom,
  BottomText,
} from './styles';

function ApproveModal({ open, onClose, onConfirm }) {
  return (
    <Modal open={open} onClose={onClose} title="APROVAÇÃO">
      <Container>
        <WrapperBody>
          <Text>Você deseja realizar a aprovação deste condutor ?</Text>
        </WrapperBody>
        <WrapperBottom>
          <BottomText onClick={onClose}>Voltar</BottomText>
          <BottomText onClick={onConfirm}>Aprovar</BottomText>
        </WrapperBottom>
      </Container>
    </Modal>
  );
}

export default ApproveModal;

ApproveModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
};
