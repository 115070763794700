/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { bool, func, shape } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';
import { Row, Column, Icon } from 'styles/components';
import Images from 'assets/img';
import CollabsActions from 'store/ducks/collaborators';

import { schema } from './schemaValidation';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  WrapperImage,
  Avatar,
  ImageText,
  WrapperInput,
  InputFile,
} from './styles';

function ModalComp({ data, open, onClose }) {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(null);
  // const [email, setEmail] = useState('');
  const inputFile = useRef(null);

  function handleSubmitted(values) {
    dispatch(
      CollabsActions.updateCollabRequest(
        { ...values, avatar: avatar || data.profileImg },
        onClose
      )
    );
  }

  return (
    <Modal open={open} onClose={onClose} title="PERFIL DO USUÁRIO">
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            name: data.name,
            email: data.email,
            password: '********',
          }}
          onSubmit={(values) => handleSubmitted(values)}
          validationSchema={schema()}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <WrapperBody>
                <WrapperContent>
                  <>
                    <Row margin="0 0 20px 0">
                      <WrapperImage>
                        {avatar ? (
                          <Avatar src={URL.createObjectURL(avatar)} />
                        ) : data.profileImg ? (
                          <Avatar src={data.profileImg} />
                        ) : (
                          <Icon height="35px" src={Images.IconCamera} />
                        )}
                      </WrapperImage>
                      <Column>
                        <ImageText>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore
                        </ImageText>
                        <Button
                          label="ENVIAR FOTO"
                          onClick={() => inputFile.current.click()}
                        />
                      </Column>
                    </Row>
                    <WrapperInput>
                      <TextField
                        color="secondary"
                        placeholder="Nome"
                        name="name"
                        helperText={touched.name ? errors.name : ''}
                        error={touched.name && Boolean(errors.name)}
                        value={values.name}
                        onChange={handleChange}
                      />
                    </WrapperInput>
                  </>

                  <WrapperInput>
                    <TextField
                      color="secondary"
                      placeholder="E-mail"
                      name="email"
                      helperText={touched.email ? errors.email : ''}
                      error={touched.email && Boolean(errors.email)}
                      value={values.email}
                      disabled
                    />
                  </WrapperInput>

                  <WrapperInput>
                    <TextField
                      color="secondary"
                      placeholder="Senha"
                      type="password"
                      name="password"
                      helperText={touched.password ? errors.password : ''}
                      error={touched.password && Boolean(errors.password)}
                      value={values.password}
                      onChange={handleChange}
                    />
                  </WrapperInput>
                </WrapperContent>
              </WrapperBody>
              <WrapperBottom>
                <BottomText onClick={onClose}>Cancelar</BottomText>

                <BottomText onClick={handleSubmit}>Salvar</BottomText>
              </WrapperBottom>
            </>
          )}
        </Formik>
      </Container>
      <InputFile
        ref={inputFile}
        onChange={(res) => setAvatar(res.target.files[0])}
      />
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  data: shape().isRequired,
};
