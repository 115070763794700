import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 255px;
`;

export const Label = styled.span`
  color: #7b7b7b;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const WrapperDatePicker = styled.div`
  height: 32px;
  padding-left: 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}
`;

export const DateInput = withStyles({
  root: () => ({
    width: '100%',
    '&:hover': {
      borderWidth: '0px',
    },
    '& .MuiInputBase-input': {
      color: '#7b7b7b',
      fontSize: 12,
      fontWeight: 600,
    },
    '& .MuiFormControl': {
      width: '100%',
      border: '1px solid red',
    },
    '& .MuiInputBase-root': {
      marginTop: '-16px',
    },
    '& .MuiInput-underline:before': {
      borderWidth: '0px',
    },
    '& .MuiInput-underline:after': {
      borderWidth: '0px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
  }),
})(KeyboardDatePicker);
