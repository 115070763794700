import api from 'services/api';
import IssuersActions from '../ducks/issuers';
import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

export function* getIssuers() {
  const userActive = yield select((state) => state.auth.userActive);
  const administrationGroup =
    userActive.role === 'admin'
      ? userActive.id
      : userActive?.administration_group;

  try {
    let data = null;

    //Se nao pertence ao grupo de admin
    if (
      userActive.role.includes('admin') &&
      userActive?.profile !== 'administrative'
    ) {
      data = yield api
        .firestore()
        .collection('issuers')
        .where('administration_group', '==', administrationGroup)
        //.orderBy('created_at', 'desc')
        .get();
    } else {
      data = yield api
        .firestore()
        .collection('issuers')
        .orderBy('created_at', 'desc')
        .get();
    }

    const issuers = data.docs.map((item) => item.data());

    //Ordena resultado de forma descrescente
    issuers.sort(function (a, b) {
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.created_at < b.created_at) {
        return 1;
      }
      return 0;
    });

    yield put(IssuersActions.getIssuersSuccess(issuers));
  } catch (err) {
    yield put(IssuersActions.getIssuersFailure(err));
  }
}

export function* addIssuers({ issuer, closeModal }) {
  const userActive = yield select((state) => state.auth.userActive);
  const administrationGroup =
    userActive.role === 'admin'
      ? userActive.id
      : userActive.administration_group;

  try {
    //Inclui o certificado digital primeiro
    yield api
      .storage()
      .ref(
        `${`certificate/${issuer.cnpj.replace(/\D/g, '')}`}/${
          issuer.certificate.name
        }`
      )
      .put(issuer.certificate);

    const url_certificate = yield api
      .storage()
      .ref(
        `${`certificate/${issuer.cnpj.replace(/\D/g, '')}`}/${
          issuer.certificate.name
        }`
      )
      .getDownloadURL();

    delete issuer.certificate;

    const data = {
      ...issuer,
      url_certificate,
      administration_group: administrationGroup,
      created_at: new Date(),
      updated_at: new Date(),
    };

    yield api
      .firestore()
      .collection('issuers')
      .add(data)
      .then((ref) => {
        data.id = ref.id;
        ref.update({ id: ref.id });
      });

    data.created_at = {
      seconds: Math.round(data.created_at.getTime() / 1000),
    };

    yield put(IssuersActions.addIssuerSuccess(data));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'EMITENTE',
        message: 'Cadastro realizado com sucesso!',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar o Emitente',
        message: 'Tente novamente',
      })
    );

    yield put(IssuersActions.addIssuerFailure(err));
  }
}

export function* updateIssuer({ issuer, closeModal }) {
  try {
    let url_certificate = null;

    //Inclui o certificado digital primeiro
    if (issuer.hasOwnProperty('certificate')) {
      yield api
        .storage()
        .ref(
          `${`certificate/${issuer.cnpj.replace(/\D/g, '')}`}/${
            issuer.certificate.name
          }`
        )
        .put(issuer.certificate);

      url_certificate = yield api
        .storage()
        .ref(
          `${`certificate/${issuer.cnpj.replace(/\D/g, '')}`}/${
            issuer.certificate.name
          }`
        )
        .getDownloadURL();

      delete issuer.certificate;
    }

    const data = {
      ...issuer,
      updated_at: new Date(),
    };

    if (url_certificate) {
      data.url_certificate = url_certificate;
    }

    delete data.created_at;

    yield api.firestore().collection('issuers').doc(issuer.id).update(data);

    yield put(IssuersActions.updateIssuerSuccess(data));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'EMITENTE',
        message: 'Atualizada com sucesso!',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar o Emiente',
        message: 'Tente novamente',
      })
    );

    yield put(IssuersActions.updateIssuerFailure(err));
  }
}
