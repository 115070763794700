import styled from 'styled-components';
import { MdRemoveRedEye, MdCloudUpload } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 22px;
`;

export const UploadFile = styled(MdCloudUpload).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 22px;
`;

export const WrapperPopover = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const PopoverText = styled.p`
  color: #888;
  margin-right: 10px;
`;

export const PopoverButton = styled.div`
  padding: 3px 5px;
  background-color: #f27160;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
`;

export const DeleteIcon = styled(MdDelete).attrs({ size: '16px' })`
  color: #f27160;
  cursor: pointer;
  margin-right: 22px;
`;

export const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;
