import React from 'react';
import { Container } from './styles';

export default function DrawerItem({ icon, onClick, description }) {
  return (
    <Container onClick={onClick}>
      <div className="box-icon">{icon}</div>
      <p>{description}</p>
    </Container>
  );
}
