import { GetApp, WarningRounded } from '@material-ui/icons';
import React from 'react';
import Images from 'assets/img';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Loading } from 'pages/Chat/styles';
import MarkerLetter from 'pages/Convocations/components/MarkerLetter';
import {
  capitalize,
  formatDate,
  getImgType,
  getWeight,
  zipcode,
} from 'pages/Convocations/helpers';
import { Column } from 'styles/components';
import {
  BoxPhoto,
  Documento,
  GridFiles,
  Label,
  Pagina,
} from '../ChargeInfoTwo/styles';
import { Box, ImageWithLabelAndValue, InfoBox, Row, TitleBox } from './styles';
import { saveAs } from 'file-saver';
import Scrollbars from 'react-custom-scrollbars';

export default function BigChargeInfo({
  specificInvoice,
  selectedConvocation,
  cte,
  user,
}) {
  const { height, width } = useWindowDimensions();

  const download = (image) => {
    saveAs(image, image.split('?')[0].split('%').pop());
  };

  return user != null && selectedConvocation != null && cte != null ? (
    <InfoBox>
      {width > 425 ? (
        <Box>
          <TitleBox>
            Informações da Carga - ID{' '}
            {selectedConvocation.id.substring(0, 6).toUpperCase()}
          </TitleBox>
          <Row align="flex-start">
            <ImageWithLabelAndValue>
              <img
                className="img-big"
                src={getImgType(selectedConvocation.convocation_type)}
                alt=""
              />
              <div className="label-and-value">
                <p className="label">Carga</p>
                <p className="value">
                  {selectedConvocation.type.replace('Carga ', '')}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Solicitante</p>
                <p className="value">
                  {specificInvoice
                    ? capitalize(specificInvoice.data.name)
                    : capitalize(selectedConvocation.owner)}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="marker-small">
                <MarkerLetter
                  width="16.8px"
                  height="24px"
                  number={0}
                  fontSize="12px"
                  margin="2px 0 0 0"
                  markerBlue
                />
              </div>
              <div className="label-and-value">
                <p className="label">Local de Coleta</p>
                <p className="value">
                  {`${capitalize(selectedConvocation.collect_point)} ${
                    selectedConvocation.collect_number
                  }, ${capitalize(
                    selectedConvocation.collect_neighborhood
                  )} - ${capitalize(
                    selectedConvocation.collect_city
                  )}/${selectedConvocation.collect_state.toUpperCase()} - ${zipcode(
                    selectedConvocation.collect_zipcode
                  )}`}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="marker-small">
                <MarkerLetter
                  width="16.8px"
                  height="24px"
                  number={1}
                  fontSize="12px"
                  margin="2px 0 0 0"
                  markerBlue
                />
              </div>
              <div className="label-and-value">
                <p className="label">Local de Entrega</p>
                {specificInvoice ? (
                  <p className="value">
                    {`${capitalize(specificInvoice.data.street)}, ${
                      specificInvoice.data.number
                    }, ${capitalize(
                      specificInvoice.data.district
                    )} - ${capitalize(specificInvoice.data.city)}/${
                      specificInvoice.data.state
                    } - ${zipcode(specificInvoice.data.zipcode)}`}
                  </p>
                ) : (
                  <p className="value">
                    {`${capitalize(selectedConvocation.delivery_point)} ${
                      selectedConvocation.delivery_number
                    }, ${capitalize(
                      selectedConvocation.delivery_neighborhood
                    )} - ${capitalize(selectedConvocation.delivery_city)}/${
                      selectedConvocation.delivery_state
                    } - ${zipcode(selectedConvocation.delivery_zipcode)}`}
                  </p>
                )}
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.IconDate} alt="" />
              <div className="label-and-value">
                <p className="label">Data da coleta</p>
                <p className="value">
                  {selectedConvocation.created_at.toDate().toLocaleDateString()}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Validade da carga</p>
                <p className="value">
                  {selectedConvocation.validity.toDate().toLocaleDateString()}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.IconDriver} alt="" />
              <div className="label-and-value">
                <p className="label">Motorista</p>
                <p className="value">{selectedConvocation.driver_name}</p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.Kg} alt="" />
              <div className="label-and-value">
                <p className="label">
                  {selectedConvocation.base_unit
                    ? selectedConvocation.base_unit
                    : 'Peso'}
                </p>
                <p className="value">{selectedConvocation.weight}</p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.Gift} alt="" />
              <div className="label-and-value">
                <p className="label">Volumes</p>
                <p className="value">{selectedConvocation.quantity_delivery}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        </Box>
      ) : (
        <Box>
          <TitleBox>
            Informações da Carga - ID{' '}
            {selectedConvocation.id.substring(0, 6).toUpperCase()}
          </TitleBox>
          <Row>
            <ImageWithLabelAndValue>
              <img
                className="img-big"
                src={getImgType(selectedConvocation.convocation_type)}
                alt=""
              />
              <div className="label-and-value">
                <p className="label">Carga</p>
                <p className="value">
                  {selectedConvocation.type.replace('Carga ', '')}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Solicitante</p>
              <p className="value">
                {specificInvoice
                  ? capitalize(specificInvoice.data.name)
                  : capitalize(selectedConvocation.owner)}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="marker-small">
              <MarkerLetter
                width="16.8px"
                height="24px"
                number={0}
                fontSize="12px"
                margin="2px 0 0 0"
                markerBlue
              />
            </div>
            <div className="label-and-value">
              <p className="label">Local de Coleta</p>
              <p className="value">
                {`${capitalize(selectedConvocation.collect_point)} ${
                  selectedConvocation.collect_number
                }, ${capitalize(
                  selectedConvocation.collect_neighborhood
                )} - ${capitalize(
                  selectedConvocation.collect_city
                )}/${selectedConvocation.collect_state.toUpperCase()} - ${zipcode(
                  selectedConvocation.collect_zipcode
                )}`}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="marker-small">
              <MarkerLetter
                width="16.8px"
                height="24px"
                number={1}
                fontSize="12px"
                margin="2px 0 0 0"
                markerBlue
              />
            </div>
            <div className="label-and-value">
              <p className="label">Local de Entrega</p>
              <p className="value">
                {`${capitalize(selectedConvocation.delivery_point)} ${
                  selectedConvocation.delivery_number
                }, ${capitalize(
                  selectedConvocation.delivery_neighborhood
                )} - ${capitalize(selectedConvocation.delivery_city)}/${
                  selectedConvocation.delivery_state
                } - ${zipcode(selectedConvocation.delivery_zipcode)}`}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.IconDate} alt="" />
              <div className="label-and-value">
                <p className="label">Data da coleta</p>
                <p className="value">
                  {selectedConvocation.created_at.toDate().toLocaleDateString()}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Validade da carga</p>
                <p className="value">
                  {selectedConvocation.validity.toDate().toLocaleDateString()}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.IconDriver} alt="" />
              <div className="label-and-value">
                <p className="label">Motorista</p>
                <p className="value">{selectedConvocation.driver_name}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.Kg} alt="" />
              <div className="label-and-value">
                <p className="label">
                  {selectedConvocation.base_unit
                    ? selectedConvocation.base_unit
                    : 'Peso'}
                </p>
                <p className="value">{selectedConvocation.weight}</p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <img className="img-small" src={Images.Gift} alt="" />
              <div className="label-and-value">
                <p className="label">Volumes</p>
                <p className="value">{selectedConvocation.quantity_delivery}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        </Box>
      )}
      <Box>
        <TitleBox>Informações da Carga</TitleBox>
        {width > 425 ? (
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Número do manifesto</p>
                <p className="value">
                  {specificInvoice
                    ? specificInvoice.client_id_invoice
                      ? specificInvoice.client_id_invoice
                      : 'Não informado'
                    : cte.id}
                </p>
              </div>
            </ImageWithLabelAndValue>
            {!specificInvoice && (
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Notas Fiscais</p>
                  <p className="value">{cte.invoices.length}</p>
                </div>
              </ImageWithLabelAndValue>
            )}
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Impostos</p>
                <p className="value">
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(cte.tax)}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        ) : (
          <>
            <Row>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Número do manifesto</p>
                  <p className="value">
                    {specificInvoice
                      ? specificInvoice.client_id_invoice
                        ? specificInvoice.client_id_invoice
                        : 'Não informado'
                      : cte.id}
                  </p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
            <Row>
              {!specificInvoice && (
                <ImageWithLabelAndValue>
                  <div className="label-and-value">
                    <p className="label">Notas Fiscais</p>
                    <p className="value">{cte.invoices.length}</p>
                  </div>
                </ImageWithLabelAndValue>
              )}
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Impostos</p>
                  <p className="value">
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(cte.tax)}
                  </p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
          </>
        )}
        <Row>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Frete</p>
              <p className="value">
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(cte.transportTotal)}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Total</p>
              <p className="value">
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(cte.totalInvoices)}
              </p>
            </div>
          </ImageWithLabelAndValue>
        </Row>
        <Column width="100%">
          <Label>Arquivos anexados</Label>
          <Scrollbars style={{ maxWidth: '100%', height: '110px' }}>
            {!specificInvoice ? (
              <GridFiles>
                <BoxPhoto
                  key={cte.url_mdfe}
                  onClick={() => {
                    if (cte.url_mdfe) {
                      window.open(cte.url_mdfe);
                    }
                  }}
                >
                  {cte.url_mdfe ? (
                    <Documento file={cte.url_mdfe}>
                      <Pagina width="100" pageNumber={1} />
                    </Documento>
                  ) : (
                    <>
                      <div className="not-found">
                        <WarningRounded />
                        <p>Não encontrado</p>
                      </div>
                    </>
                  )}
                  <div className="download-box">
                    <GetApp className="icon" />
                  </div>
                </BoxPhoto>
                {cte.invoices.map((invoice) => {
                  if (invoice.dacte) {
                    return (
                      <BoxPhoto
                        key={invoice.dacte}
                        onClick={() => {
                          window.open(invoice.dacte);
                        }}
                      >
                        <Documento file={invoice.dacte}>
                          <Pagina width="100" pageNumber={1} />
                        </Documento>
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    );
                  }
                })}
                {cte.invoices.map((invoice) => {
                  if (invoice.images != null && invoice.images.length > 0) {
                    return invoice.images.map((image) => (
                      <BoxPhoto key={image} onClick={() => download(image)}>
                        <img src={image} alt="Imagem" />
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    ));
                  }
                  return null;
                })}
              </GridFiles>
            ) : (
              <GridFiles>
                {specificInvoice.documents &&
                  specificInvoice.documents.map((doc) => {
                    return doc.split('.').pop().toLowerCase() !== '.pdf' ? (
                      <BoxPhoto key={doc} onClick={() => download(doc)}>
                        <img src={doc} alt="Imagem" />
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    ) : (
                      <BoxPhoto
                        key={doc}
                        onClick={() => {
                          window.open(doc);
                        }}
                      >
                        <Documento file={doc}>
                          <Pagina width="100" pageNumber={1} />
                        </Documento>
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    );
                  })}
              </GridFiles>
            )}
          </Scrollbars>
        </Column>
      </Box>
      <Box>
        <TitleBox>Informações do Motorista</TitleBox>
        {width > 425 ? (
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Motorista</p>
                <p className="value">
                  {user.name ? capitalize(user.name) : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">CNH</p>
                <p className="value">{user.cnh ?? 'Não informado'}</p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Categoria</p>
                <p className="value">
                  {user.driverCategory ?? 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Tipo de transportador</p>
                <p className="value">
                  {user.transport_type
                    ? user.transport_type.toString().toUpperCase()
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        ) : (
          <>
            <Row>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Motorista</p>
                  <p className="value">
                    {user.name ? capitalize(user.name) : 'Não informado'}
                  </p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
            <Row>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">CNH</p>
                  <p className="value">{user.cnh ?? 'Não informado'}</p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
            <Row>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Categoria</p>
                  <p className="value">
                    {user.driverCategory ?? 'Não informado'}
                  </p>
                </div>
              </ImageWithLabelAndValue>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Tipo de transportador</p>
                  <p className="value">
                    {user.transport_type
                      ? user.transport_type.toString().toUpperCase()
                      : 'Não informado'}
                  </p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
          </>
        )}
      </Box>
      <Box>
        <TitleBox>Informações do Veículo</TitleBox>
        {width > 425 ? (
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Placa do veículo</p>
                <p className="value">
                  {selectedConvocation.plate
                    ? selectedConvocation.plate.toString().toUpperCase()
                    : user.plate
                    ? user.plate.toString().toUpperCase()
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">UF da Placa</p>
                <p className="value">
                  {selectedConvocation.uf_plate
                    ? selectedConvocation.uf_plate.toString().toUpperCase()
                    : user._uf_plate
                    ? user._uf_plate.toString().toUpperCase()
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Tipo de rodado</p>
                <p className="value">
                  {user.type_rotated
                    ? capitalize(user.type_rotated)
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Carroceria</p>
                <p className="value">
                  {user.type_bodywork
                    ? capitalize(user.type_bodywork)
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Eixos</p>
                <p className="value">{user.qtd_axes ?? 'Não informado'}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        ) : (
          <>
            <Row>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Placa do veículo</p>
                  <p className="value">
                    {user.plate
                      ? user.plate.toString().toUpperCase()
                      : 'Não informado'}
                  </p>
                </div>
              </ImageWithLabelAndValue>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">UF da Placa</p>
                  <p className="value">{user.uf_plate ?? 'Não informado'}</p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
            <Row>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Tipo de rodado</p>
                  <p className="value">
                    {user.type_rotated
                      ? capitalize(user.type_rotated)
                      : 'Não informado'}
                  </p>
                </div>
              </ImageWithLabelAndValue>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Carroceria</p>
                  <p className="value">
                    {user.type_bodywork
                      ? capitalize(user.type_bodywork)
                      : 'Não informado'}
                  </p>
                </div>
              </ImageWithLabelAndValue>
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Eixos</p>
                  <p className="value">{user.qtd_axes ?? 'Não informado'}</p>
                </div>
              </ImageWithLabelAndValue>
            </Row>
          </>
        )}
        <Row>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Fabricante</p>
              <p className="value">
                {user.brand_vehicle
                  ? user.brand_vehicle.toString().toUpperCase()
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Modelo</p>
              <p className="value">
                {user.model_vehicle
                  ? capitalize(user.model_vehicle)
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
        </Row>
      </Box>
    </InfoBox>
  ) : (
    <InfoBox>
      <Loading />
    </InfoBox>
  );
}
