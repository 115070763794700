/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { MdRemoveRedEye } from 'react-icons/md';

export const Container = styled.div`
  width: 100%;
  padding-top: 50px;
`;

export const Header = styled.div`
  height: 51px;
  border-bottom: 3px solid #f4f4f4;
  display: flex;
`;

export const HeaderOptions = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  position: relative;

  font-size: 14px;
  font-weight: 700;
  color: ${(props) =>
    props.error ? '#F27160' : props.active ? '#122D58' : '#AFB1B4'};

  p {
    text-shadow: 0 3px 6px
      ${(props) => (props.error ? '#00000019' : 'transparent')};
  }
  transition: 200ms;
`;

export const HeaderIndicator = styled.div`
  width: 100%;
  background-color: ${(props) => (props.error ? '#F27160' : '#122d58')};
  height: 3px;
  position: absolute;
  bottom: -3px;
  transform: ${(props) =>
    props.right === 2
      ? 'translateX(190%)'
      : props.right === 1
      ? 'translateX(95%)'
      : 'translateX(0)'};
  transition: 200ms;
`;

export const Body = styled.div`
  padding: 25px 15px;
`;

export const Title = styled.p`
  font-size: 11px;
  font-weight: 700;
  color: #7b7b7b;
  margin-bottom: 10px;
`;

export const AddDocText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #afb1b4;
  margin-top: 35px;
`;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const WrapperSmallImage = styled.div`
  overflow: hidden;
  height: 30px;
  width: 30px;
  border-radius: 5px;
`;

export const SmallImage = styled.img`
  width: 100%;
`;

export const WrapperContent = styled.div`
  max-width: 1500px;
  margin-top: 10px;
`;

export const WrapperInput = styled.div`
  max-width: 255px;
`;
