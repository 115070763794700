import * as yup from 'yup';

export const schema = () => {
  return yup.object().shape({
    social_reason: yup.string().min(3).required('Razão Social é obrigatório'),
    cnpj: yup.string().min(14).required('CNPJ é obrigatório'),
    owner: yup.string().min(3).required('O responsável é obrigatório'),
    phone: yup.string().min(11).required('Telefone é obrigatório'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    zipcode: yup.string().min(8).required('CEP é obrigatório'),
    address: yup.string().required('Endereço é obrigatório'),
    number: yup.string().required('Número é obrigatório'),
    neighborhood: yup.string().required('Bairro é obrigatório'),
    city: yup.string().required('Cidade é obrigatório'),
    state: yup.string().required('Estado é obrigatório'),
  });
};
