import { put, select, call, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';

import api, { apiLogin } from 'services/api';

import ConvocationsActions from '../ducks/convocations';
import ShortUniqueId from 'short-unique-id';
import { useSelector } from 'react-redux';
// import AuthActions from '../ducks/auth';

function getConvocationChannel() {
  return eventChannel((emit) => {
    const unsubscribe = api
      .firestore()
      .collection('convocations')
      .orderBy('created_at', 'desc')
      .onSnapshot((querySnap) => emit({ querySnap }));

    return unsubscribe;
  });
}

export function* convocationsWatcher() {
  const channel = yield call(getConvocationChannel);

  while (true) {
    const { querySnap } = yield take(channel);

    if (querySnap) {
      let convocations = [];
      querySnap.docs.forEach((doc) => convocations.push(doc.data()));
      const convocationsFiltered = convocations.filter(
        (item) => item.status != 'canceled'
      );

      yield put(
        ConvocationsActions.getConvocationsSuccess(convocationsFiltered)
      );
    } else {
      // yield put(ConvocationsActions.getConvocationsFailure(err));
    }
  }
}

export function* getConvocations() {
  try {
    const data = yield api
      .firestore()
      .collection('convocations')
      .orderBy('created_at', 'desc')
      .get();

    const filtered = data.docs.map((item) => item.data());

    const convocations = filtered.filter((item) => item.status != 'canceled');

    yield put(ConvocationsActions.getConvocationsSuccess(convocations));
  } catch (err) {
    yield put(ConvocationsActions.getConvocationsFailure(err));
  }
}

export function* addConvocation({ convocation, closeModal }) {
  try {
    const userActive = yield select((state) => state.auth.userActive);

    let data = {
      ...convocation,
      status_mdfe: false,
      status: 'initial',
      created_at: new Date(),
      updated_at: new Date(),
    };

    if (userActive.labelStatus) {
      data = {
        ...convocation,
        status_mdfe: false,
        status: 'initial',
        labelStatus: userActive.labelStatus,
        created_at: new Date(),
        updated_at: new Date(),
      };
    }

    yield apiLogin
      .firestore()
      .collection('convocations')
      .add(data)
      .then((ref) => {
        data.id = ref.id;
        ref.update({ id: ref.id });
      });

    data.created_at = {
      seconds: Math.round(data.created_at.getTime() / 1000),
    };

    data.validity = {
      seconds: Math.round(data.validity.getTime() / 1000),
    };

    yield put(ConvocationsActions.addConvocationSuccess(data));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'CARGA',
        message: 'Registrada com sucesso!',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar carga',
        message: 'Tente novamente',
      })
    );

    yield put(ConvocationsActions.addConvocationFailure(err));
  }
}

export function* updateConvocation({ convocation, closeModal }) {
  try {
    const userActive = yield select((state) => state.auth.userActive);

    let data = {
      ...convocation,
      validity: new Date(convocation.validity),
      updated_at: new Date(),
    };

    if (userActive.labelStatus && !convocation.labelStatus) {
      data = {
        ...convocation,
        labelStatus: userActive.labelStatus,
        validity: new Date(convocation.validity),
        updated_at: new Date(),
      };
    }

    delete data.created_at;

    yield api
      .firestore()
      .collection('convocations')
      .doc(convocation.id)
      .update(data);

    data.created_at = {
      seconds: convocation.created_at.seconds,
    };

    data.validity = {
      seconds: Math.round(data.validity / 1000),
    };

    yield put(ConvocationsActions.updateConvocationSuccess(data));
    yield put(ConvocationsActions.getConvocationsRequest());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'CARGA',
        message: 'Atualizada com sucesso!',
      })
    );
    if (closeModal) closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar a carga',
        message: 'Tente novamente',
      })
    );

    yield put(ConvocationsActions.updateConvocationFailure(err));
  }
}

export function* getTemporaryLink({ convocationId, invoiceId }) {
  try {
    let resp = yield api
      .firestore()
      .collection('temporary')
      .where('convocation_id', '==', convocationId)
      .get();

    const todayDate = new Date();
    let respFiltered;

    if (invoiceId) {
      respFiltered = resp.docs
        .filter(
          (obj) =>
            obj.data().due_date > todayDate.getTime() &&
            obj.data().client_id_invoice === invoiceId
        )
        .map((obj) => (obj = obj.data()));
    } else {
      respFiltered = resp.docs
        .filter((obj) => obj.data().due_date > todayDate.getTime())
        .map((obj) => (obj = obj.data()));
    }

    if (
      resp.empty ||
      respFiltered.length == 0 ||
      (!invoiceId && respFiltered.pop().client_id_invoice)
    ) {
      console.log('EMPTY');
      const userActive = yield select((state) => state.auth.userActive);
      const dueDate = new Date();
      dueDate.setDate(dueDate.getDate() + 30);
      const uniqueId = new ShortUniqueId({ length: 6 });
      const uid = uniqueId();

      yield api.firestore().collection('temporary').doc(uid).set({
        id: uid,
        user_id: userActive.id,
        convocation_id: convocationId,
        due_date: dueDate.getTime(),
      });

      yield put(
        ConvocationsActions.setTemporaryLink({
          id: uid,
          user_id: userActive.id,
          convocation_id: convocationId,
          due_date: dueDate.getTime(),
        })
      );
    } else {
      console.log('NOT_EMPTY');
      yield put(ConvocationsActions.setTemporaryLink(respFiltered.pop()));
    }
  } catch (err) {
    console.log(err);

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao criar link de compartilhamento',
        message: 'Tente novamente',
      })
    );
  }
}

export function* updateLocation({
  fixedLocations,
  locations,
  selectedConvocation,
  closeModal,
}) {
  try {
    yield api
      .firestore()
      .collection('location')
      .doc(selectedConvocation.id)
      .set(
        {
          user_location: JSON.parse(JSON.stringify(fixedLocations)),
          bkup_user_location: JSON.parse(JSON.stringify(locations)),
        },
        { merge: true }
      );

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Location',
        message: 'Atualizada com sucesso!',
      })
    );
    if (closeModal) closeModal();
  } catch (err) {
    console.log(err);

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar a location',
        message: 'Tente novamente',
      })
    );
  }
}

export function* removeConvocation({ id }) {
  try {
    yield api.firestore().collection('convocations').doc(id).delete();

    yield put(ConvocationsActions.removeConvocationSuccess());
    yield put(ConvocationsActions.getConvocationsRequest());
  } catch (err) {
    yield put(ConvocationsActions.removeConvocationFailure(err));
  }
}

export function* sendMail({ file }) {
  try {
    const { convocationActive } = yield select((state) => state.convocations);

    let link;
    if (!convocationActive?.qrcode_url) {
      link = yield uploadQrcode(convocationActive.id, file);
    }

    const sendMailCollect = yield api.functions().httpsCallable('sendMail');
    sendMailCollect({
      dest: convocationActive.collect_corresponding_email,
      link: link || convocationActive?.qrcode_url,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        console.log('sanitizedMessageCollect', result);
      })
      .catch((error) => {
        // Getting the Error details.
        if (error) {
          put(
            toastrActions.add({
              type: 'error',
              title: 'Erro ao enviar o e-mail',
              message: 'Tente novamente mais tarde.',
            })
          );
        }
        // ...
      });

    const sendMailDelivery = yield api.functions().httpsCallable('sendMail');
    sendMailDelivery({
      dest: convocationActive.delivery_corresponding_email,
      link: link || convocationActive?.qrcode_url,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        console.log('sanitizedMessageDelivery', result);
      })
      .catch((error) => {
        // Getting the Error details.
        if (error) {
          put(
            toastrActions.add({
              type: 'error',
              title: 'Erro ao enviar o e-mail',
              message: 'Tente novamente mais tarde.',
            })
          );
        }
        // ...
      });

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'E-mail',
        message: 'Enviado com sucesso!',
      })
    );

    yield put(ConvocationsActions.putSendMailSuccess());
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao enviar o e-mail',
        message: 'Tente novamente mais tarde.',
      })
    );
    yield put(ConvocationsActions.putSendMailFailure(err));
  }
}

export function* uploadQrcode(uid, file) {
  // Extract image extension
  const filename = `qrcode.png`;

  yield apiLogin
    .storage()
    .ref(`qrcodes/${uid}/${filename}`)
    .putString(file, 'data_url');

  const uri = yield apiLogin
    .storage()
    .ref(`qrcodes/${uid}/${filename}`)
    .getDownloadURL();

  yield api
    .firestore()
    .collection('convocations')
    .doc(uid)
    .update({ qrcode_url: uri });

  return uri;
}
