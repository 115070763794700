/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { bool, func, string, arrayOf, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputMask from 'react-input-mask';
import cep from 'cep-promise';

import Map from 'components/Map';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
// import Button from 'components/Button';
import { Row } from 'styles/components';
import Images from 'assets/img';
import ProvidersActions from 'store/ducks/providers';
import { Input, Label } from 'components/TextInput/styles';
import { locationByIP, locationByAddress } from 'services/getLocations';

import { schema } from './schemaValidation';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  Line,
  WrapperInput,
  Loading,
  Center,
  // useStyles,
  StepperMUI,
  BoxMap,
  BoxSearch,
  // selectStyles,
  ImgFlag,
} from './styles';

const key = process.env.REACT_APP_MAPS_KEY;

function getSteps() {
  return ['DADOS DA EMPRESA/RESPONSÁVEL', 'ENDEREÇO DA EMPRESA'];
}

function ModalProviders({ data, open, onClose, edit, selectedId }) {
  const dispatch = useDispatch();
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [formErrors, setFormErrors] = useState({});
  const steps = getSteps();

  const [fullAddress, setFullAddress] = useState({
    cep: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const formik = useFormik({
    initialValues: {
      social_reason: '',
      cnpj: '',
      state_subscribe: '',
      taxpayer: 'Não',
      owner: '',
      phone: '',
      email: '',
      zipcode: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      lat: '',
      lng: '',
    },
    onSubmit: (values) => handleSubmitted(values),
    validationSchema: schema(),
    validateOnChange: true,
    isValidating: true,
  });

  const validate = (values) => {
    const errors = {};
    if (!values.social_reason) {
      errors.social_reason = 'Obrigatório';
    } else if (values.social_reason.length < 3) {
      errors.social_reason = 'Deve ser maior que 3 caracteres.';
    }

    if (!values.cnpj) {
      errors.cnpj = 'Obrigatório';
    } else if (values.cnpj.replace(/([^\d])+/gim, '').length < 14) {
      errors.cnpj = 'CNPJ incompleto.';
    }

    if (!values.state_subscribe) {
      errors.state_subscribe = 'Obrigatório';
    } else if (values.state_subscribe.replace(/([^\d])+/gim, '').length < 9) {
      errors.state_subscribe = 'Inscrição estadual incompleto.';
    }

    if (!values.owner) {
      errors.owner = 'Obrigatório';
    } else if (values.owner.length < 3) {
      errors.owner = 'Deve ser maior que 3 caracteres';
    }

    if (!values.phone) {
      errors.phone = 'Obrigatório';
    } else if (values.phone.replace(/([^\d])+/gim, '').length < 11) {
      errors.phone = 'Número de telefone incompleto.';
    }

    if (!values.email) {
      errors.email = 'Obrigatório';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'E-mail inválido.';
    }

    return errors;
  };

  const handleClose = () => {
    setActiveStep(0);
    formik.resetForm();
    onClose();
  };

  function handleSubmitted(values) {
    if (selectedId) {
      dispatch(
        ProvidersActions.updateProviderRequest(
          { ...values, id: selectedId },
          onClose
        )
      );
    } else {
      dispatch(ProvidersActions.addProviderRequest({ ...values }, onClose));
    }
    formik.resetForm();
  }

  const handleNext = () => {
    const errors = validate(formik.values);
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setFormErrors(errors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getPosition = (address) => {
    locationByAddress(address).then((pos) => {
      if (pos.lat !== 0) {
        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
        formik.setFieldValue('lat', pos.lat);
        formik.setFieldValue('lng', pos.lng);
      }
    });
  };

  const getInitialPosition = (address) => {
    locationByIP()
      .then((pos) => {
        if (pos.lat !== 0) {
          setPosition({
            latitude: pos.lat,
            longitude: pos.lng,
          });
        }
      })
      .catch(() => {
        locationByAddress(address).then((pos) => {
          if (pos.lat !== 0) {
            setPosition({
              latitude: pos.lat,
              longitude: pos.lng,
            });
          }
        });
      });
  };

  useEffect(() => {
    getInitialPosition('Brasil');
  }, []);

  useEffect(() => {
    if (edit) {
      data.map((item) => {
        if (item.id === selectedId) {
          formik.setValues(item);
          if (item.lat) {
            setPosition({
              latitude: item.lat,
              longitude: item.lng,
            });
          }
        }
        return null;
      });
    }
  }, [data, edit, selectedId]);

  const getFormattedAddress = (address, number) => {
    let result = address.street;
    if (number && number.length) {
      result += `, ${number}`;
    }
    if (address.neighborhood.length) {
      result += `, ${address.neighborhood}`;
    }
    if (address.city.length) {
      result += `, ${address.city}`;
    }
    if (address.city.length) {
      result += ` - ${address.state}`;
    }
    return `${result}, Brasil`;
  };

  const handleCepChange = (cepRequested) => {
    cep(cepRequested)
      .then((cepResponse) => {
        const address = {
          cep: cepRequested,
          street: cepResponse.street,
          neighborhood: cepResponse.neighborhood,
          city: cepResponse.city,
          state: cepResponse.state,
        };
        setFullAddress(address);
        formik.setFieldValue(`address`, cepResponse.street);
        formik.setFieldValue(`neighborhood`, cepResponse.neighborhood);
        formik.setFieldValue(`city`, cepResponse.city);
        formik.setFieldValue(`state`, cepResponse.state);

        getPosition(getFormattedAddress(address, formik.values.number));
      })
      .catch(() => {
        setFullAddress({
          cep: cepRequested,
          street: formik.values.address,
          neighborhood: formik.values.neighborhood,
          city: '',
          state: '',
        });
        formik.setFieldError(`zipcode`, 'CEP não encontrado');
      });
  };

  const handleAddressNumberChange = (addressNumber) => {
    if (addressNumber) {
      console.error(getFormattedAddress(fullAddress, addressNumber));
      getPosition(getFormattedAddress(fullAddress, addressNumber));
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={selectedId ? 'ATUALIZAR UMA EMPRESA' : 'CRIAR NOVA EMPRESA'}
    >
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <>
            <WrapperBody>
              <WrapperContent>
                <StepperMUI activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {
                      color: '#FFF28D',
                    };
                    const labelProps = {};

                    return activeStep !== index ? (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps} />
                      </Step>
                    ) : (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </StepperMUI>

                <Line />

                {activeStep === 0 && (
                  <>
                    <WrapperInput>
                      <TextInput
                        id="social_reason"
                        label="Razão Social"
                        placeholder="Digite aqui..."
                        error={
                          // !!formik.touched.social_reason &&
                          // !!formik.errors.social_reason &&
                          !!formErrors.social_reason
                        }
                        value={formik.values.social_reason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <Label>CNPJ</Label>
                      <InputMask
                        id="cnpj"
                        mask="99.999.999/9999-99"
                        label="CNPJ"
                        placeholder="Digite aqui..."
                        error={
                          // !!formik.touched.cnpj &&
                          // !!formik.errors.cnpj &&
                          !!formErrors.cnpj
                        }
                        value={formik.values.cnpj}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </WrapperInput>

                    <WrapperInput>
                      <Label>Inscrição Estadual</Label>
                      <InputMask
                        id="state_subscribe"
                        mask="999999999"
                        label="Inscrição Estadual"
                        placeholder="Digite aqui..."
                        error={
                          // !!formik.touched.cnpj &&
                          // !!formik.errors.cnpj &&
                          !!formErrors.state_subscribe
                        }
                        value={formik.values.state_subscribe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </WrapperInput>

                    <WrapperInput>
                      <Label>Contribuinte</Label>
                      <RadioGroup
                        aria-label="taxpayer"
                        name="taxpayer"
                        value={formik.values.taxpayer}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="owner"
                        label="Responsável"
                        placeholder="Digite aqui..."
                        error={
                          // !!formik.touched.owner &&
                          // !!formik.errors.owner &&
                          !!formErrors.owner
                        }
                        value={formik.values.owner}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <Label>Telefone</Label>
                      <Row width="100%">
                        <BoxSearch>
                          <ImgFlag src={Images.IconFlagBrasil} />
                        </BoxSearch>
                        <InputMask
                          id="phone"
                          mask="(99) 99999-9999"
                          placeholder="Digite aqui..."
                          error={
                            // formik.touched.phone && Boolean(formik.errors.phone)
                            !!formErrors.phone
                          }
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </Row>
                      {/* <TextInput
                          type="tel"
                          pattern="[0-9]{10}"
                          label="Telefone"
                          placeholder="Digite aqui..."
                          id="phone"
                          error={touched.phone && Boolean(errors.phone)}
                          value={values.phone}
                          onChange={handleChange}
                        /> */}
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="email"
                        type="email"
                        label="E-mail"
                        placeholder="Digite aqui..."
                        error={
                          // formik.touched.email &&
                          // Boolean(formik.errors.email) &&
                          !!formErrors.email
                        }
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>
                  </>
                )}

                {activeStep === 1 && (
                  <>
                    <WrapperInput>
                      <TextInput
                        id="zipcode"
                        label="CEP"
                        error={
                          formik.touched.zipcode &&
                          Boolean(formik.errors.zipcode)
                        }
                        value={formik.values.zipcode}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleCepChange(e.target.value)}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="address"
                        label="Endereço"
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="number"
                        label="Nº"
                        error={
                          !!formik.touched.number && !!formik.errors.number
                        }
                        value={formik.values.number}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          handleAddressNumberChange(e.target.value);
                        }}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="complement"
                        label="Complemento"
                        error={
                          formik.touched.complement &&
                          Boolean(formik.errors.complement)
                        }
                        value={formik.values.complement}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>
                    <WrapperInput>
                      <TextInput
                        id="neighborhood"
                        label="Bairro"
                        error={
                          formik.touched.neighborhood &&
                          Boolean(formik.errors.neighborhood)
                        }
                        value={formik.values.neighborhood}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="city"
                        label="Cidade"
                        disabled
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="state"
                        label="Estado"
                        disabled
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>

                    <BoxMap>
                      {position.latitude !== 0 && (
                        <Map
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                          loadingElement={
                            <Center>
                              <Loading />
                            </Center>
                          }
                          containerElement={
                            <div
                              style={{
                                height: `250px`,
                                // borderRadius: '11px',
                                overflow: 'hidden',
                                // boxShadow:
                                //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                              }}
                            />
                          }
                          mapElement={<div style={{ height: `100%` }} />}
                          position={position}
                        />
                      )}
                    </BoxMap>
                  </>
                )}
              </WrapperContent>
            </WrapperBody>
            <WrapperBottom>
              {activeStep !== 0 && (
                <BottomText onClick={handleBack}>Voltar</BottomText>
              )}
              {activeStep === 1 ? (
                <BottomText onClick={formik.handleSubmit}>Concluir</BottomText>
              ) : (
                <BottomText onClick={handleNext}>Avançar</BottomText>
              )}
            </WrapperBottom>
          </>
        </form>
      </Container>
    </Modal>
  );
}

export default ModalProviders;

ModalProviders.propTypes = {
  open: bool.isRequired,
  edit: bool.isRequired,
  onClose: func.isRequired,
  selectedId: string,
  data: arrayOf(object).isRequired,
};

ModalProviders.defaultProps = {
  selectedId: null,
};
