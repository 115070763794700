import React, { useEffect } from 'react';
import {
  CirclePhoto,
  Container,
  GridRow,
  Header,
  LabelAndValue,
  ListFiles,
  SubTitleGrey,
  WrapperCenter,
  GridFiles,
} from './styles';
import Close from '@material-ui/icons/Close';
import { FiUser } from 'react-icons/fi';
import TruckerActions from 'store/ducks/trucker';
import { useDispatch, useSelector } from 'react-redux';
import { GetApp } from '@material-ui/icons';
import { BoxPhoto } from 'pages/Convocations/components/MarkerPopover/styles';
import { saveAs } from 'file-saver';
import { Loading } from 'pages/ConvocationDetails/styles';
import { CircularProgress } from '@material-ui/core';

function UserDrawer() {
  const dispatch = useDispatch();
  const { selectedTrucker, truckerDrawer } = useSelector(
    (state) => state.trucker
  );

  const download = (image) => {
    saveAs(image, image.split('?')[0].split('%').pop());
  };

  // useEffect(() => {
  //   console.log(selectedTrucker)
  // }, [selectedTrucker])

  return (
    <Container className={truckerDrawer ? 'opened' : ''}>
      <Header>
        <Close
          onClick={() => dispatch(TruckerActions.closeTruckerDrawer())}
          className="icon"
        />
        Dados do condutor
      </Header>
      {selectedTrucker ? (
        <>
          <WrapperCenter>
            <CirclePhoto>
              {/* <img src="" alt="" /> */}
              <div className="img">
                <FiUser />
              </div>
            </CirclePhoto>
            {selectedTrucker.name}
          </WrapperCenter>
          <SubTitleGrey>Documentos do condutor</SubTitleGrey>
          <GridFiles>
            <BoxPhoto onClick={() => download(selectedTrucker.url_cnh)}>
              <img src={selectedTrucker.url_cnh} alt="Imagem" />
              <div className="download-box">
                <GetApp className="icon" />
              </div>
            </BoxPhoto>
            <BoxPhoto onClick={() => download(selectedTrucker.url_document)}>
              <img src={selectedTrucker.url_document} alt="Imagem" />
              <div className="download-box">
                <GetApp className="icon" />
              </div>
            </BoxPhoto>
            <BoxPhoto onClick={() => download(selectedTrucker.url_house)}>
              <img src={selectedTrucker.url_house} alt="Imagem" />
              <div className="download-box">
                <GetApp className="icon" />
              </div>
            </BoxPhoto>
          </GridFiles>
          <GridRow>
            <LabelAndValue>
              <div className="label">CPF</div>
              <div className="value">{selectedTrucker.cpf}</div>
            </LabelAndValue>
            <LabelAndValue>
              <div className="label">CNH</div>
              <div className="value">0000000000000</div>
            </LabelAndValue>
          </GridRow>
          <GridRow>
            <LabelAndValue>
              <div className="label">Categoria da CNH</div>
              <div className="value">D</div>
            </LabelAndValue>
            <LabelAndValue>
              <div className="label">Validade</div>
              <div className="value">00/00/0000</div>
            </LabelAndValue>
          </GridRow>
          <GridRow>
            <LabelAndValue>
              <div className="label">Tipo de transporte</div>
              <div className="value">{selectedTrucker.transport_type}</div>
            </LabelAndValue>
          </GridRow>
          <GridRow>
            <LabelAndValue>
              <div className="label">Telefone</div>
              <div className="value">{selectedTrucker.phone}</div>
            </LabelAndValue>
          </GridRow>
          <GridRow>
            <LabelAndValue>
              <div className="label">E-mail</div>
              <div className="value">{selectedTrucker.email}</div>
            </LabelAndValue>
          </GridRow>
          <SubTitleGrey>Documentos do veículo</SubTitleGrey>
          <GridFiles>
            <BoxPhoto onClick={() => download(selectedTrucker.url_holding_cnh)}>
              <img src={selectedTrucker.url_holding_cnh} alt="Imagem" />
              <div className="download-box">
                <GetApp className="icon" />
              </div>
            </BoxPhoto>
            <BoxPhoto
              onClick={() => download(selectedTrucker.url_holding_document)}
            >
              <img src={selectedTrucker.url_holding_document} alt="Imagem" />
              <div className="download-box">
                <GetApp className="icon" />
              </div>
            </BoxPhoto>
            <BoxPhoto
              onClick={() => download(selectedTrucker.url_holding_house)}
            >
              <img src={selectedTrucker.url_holding_house} alt="Imagem" />
              <div className="download-box">
                <GetApp className="icon" />
              </div>
            </BoxPhoto>
          </GridFiles>
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}

export default UserDrawer;
