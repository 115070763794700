import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  border-right: ${(props) => (props.mapActived ? '1px solid #F1F2F5' : 'none')};
  padding-right: 14px;
  width: 350px;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  width: 100%;

  padding: 20px;

  .status {
    position: absolute;
    top: 0;
    right: 0;
  }

  .exit-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 25px;
    cursor: pointer;
  }

  .profile-img {
    width: 80px;
    height: 80px;
    overflow: hidden;

    border-radius: 6px;
    font-size: 50px;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
`;

export const Text = styled.p`
  color: ${(props) => props.color ?? '#A7B4CA'};
  font-size: ${(props) => props.size ?? '10px'};
  font-weight: ${(props) => props.weight ?? '400'};
  line-height: ${(props) => props.lineHeight ?? '12.19px'};
  margin: ${(props) => props.margin ?? '0'};
  width: 100%;
  text-align: ${(props) => props.align ?? 'center'};
`;

export const LabelAndValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0 24px;

  .label {
    color: #a7b4ca;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.19px;
    text-align: center;
  }

  .label {
    color: #7b7b7b;
    font-size: 10px;
    font-weight: 400;
    line-height: 14.63px;
    text-align: center;
  }

  & + & {
    margin-top: 10px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  margin: ${(props) => props.margin ?? '0'};

  background-color: #a7b4ca;
`;

export const ProfileImgDefault = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #c4c4c4;
`;
