/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, arrayOf, object, string } from 'prop-types';
import { FiRotateCw } from 'react-icons/fi';
import ConvocationActions from 'store/ducks/convocations';

import Modal from 'components/Modal';

import { Container, ContainerModal, Card, WrapperCenter } from './styles';
import ButtonDrawer from 'components/ButtonDrawer';
import { useDispatch, useSelector } from 'react-redux';
import Images from 'assets/img';
import { Close } from '@material-ui/icons';
import api from 'services/api';
import ButtonModal from 'components/ButtonModal';

export default function DeleteModal({ open, reload, onClose }) {
  const dispatch = useDispatch();
  const { convocationActive, acceptDelete } = useSelector(
    (state) => state.convocations
  );

  const deleteConvocation = (convocation) => {
    api
      .firestore()
      .collection('location')
      .doc(`${convocation.id}`)
      .delete()
      .then(() => {
        dispatch(ConvocationActions.setAcceptedDelete());
      });
  };

  return (
    <Container>
      <Card>
        {!acceptDelete ? (
          <ContainerModal>
            <h3>Excluir carga</h3>
            <p>Você gostaria de excluir esta carga?</p>
            <WrapperCenter>
              <ButtonModal
                onClick={() => dispatch(ConvocationActions.closeDeleteModal())}
                type="secondary"
                width="80px"
                label="Não"
              />
              <ButtonModal
                onClick={deleteConvocation(convocationActive)}
                label="Sim"
                width="80px"
                margin="0 0 0 24px"
              />
            </WrapperCenter>
          </ContainerModal>
        ) : (
          <ContainerModal accepted={true}>
            <Close
              onClick={() => dispatch(ConvocationActions.closeDeleteModal())}
              className="close-icon"
            />
            <img src={Images.DoneIcon} alt="Icone de Certo" />
            <h3>Carga excluída com sucesso!</h3>
          </ContainerModal>
        )}
      </Card>
    </Container>
  );
}

DeleteModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
