import React from 'react';
import { string } from 'prop-types';
import { Grid } from '@material-ui/core';
import TextInput from 'components/TextInput';

import { Container, Header, Body } from './styles';

function BasicInfoCard({ trucker }) {
  return (
    <Container>
      <Header>Informações básicas</Header>
      <Body>
        <Grid container spacing={3}>
          <Grid item lg={3} xs>
            <TextInput label="Nome" value={trucker?.name} disabled />
          </Grid>
          <Grid item lg={3} xs>
            <TextInput label="Telefone" value={trucker?.phone} disabled />
          </Grid>
          <Grid item lg={3} xs>
            <TextInput label="E-mail" value={trucker?.email} disabled />
          </Grid>
          <Grid item lg={3} xs>
            <TextInput label="CPF" value={trucker?.cpf} disabled />
          </Grid>
        </Grid>
      </Body>
    </Container>
  );
}

export default BasicInfoCard;

BasicInfoCard.propTypes = {
  name: string.isRequired,
  phone: string.isRequired,
  email: string.isRequired,
  cpf: string.isRequired,
};
