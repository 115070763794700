import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { Radio, withStyles } from '@material-ui/core';

export const Container = styled.div`
  width: 90vw;
  max-width: 518px;
`;

export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 400px;
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;
`;

export const WrapperImage = styled.div`
  width: 109px;
  min-width: 109px;
  height: 109px;
  background: none;
  border: 1px solid #122d58;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;

export const Avatar = styled.img`
  width: 109px;
  height: 109px;
  object-fit: cover;
`;

export const ImageText = styled.div`
  font-size: 12px;
  color: #cccccc;
  font-weight: 500;
  margin-bottom: 15px;
`;

export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
`;

export const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const ReactiveText = styled.p`
  color: #122d58;
  font-size: 16px;
  font-weight: 600;
`;

export const RadioControl = withStyles({
  root: () => ({
    '& .MuiSvgIcon-root': {
      color: '#122d58',
    },
  }),
})(Radio);

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  text-align: center;
  color: red;
  font-weight: 600;
  width: 100%;
`;
