import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled(PerfectScrollbar)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0a1d3b66;
`;

export const WrapperMenu = styled.div``;

export const Icon = styled.div`
  width: 35px;
  padding-top: 4px;
`;

export const TextIcon = styled.p`
  font-size: 14px;
  text-align: left;
  vertical-align: center;
`;

export const PopupButton = styled.button`
  width: 100%;
  min-height: 25px;

  border-radius: 5px;
  background-color: transparent;
  outline: none;
  border: none;

  padding: 3px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  transition: 400ms;
  cursor: pointer;

  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 12px;
    color: #7b7b7b;
    text-align: left;
  }

  & .icon {
    width: 15px;
    margin-right: 8px;
  }

  & + & {
    margin-top: 5px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;
