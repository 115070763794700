import * as yup from 'yup';

export const schema = () => {
  return yup.object().shape({
    delivery_zipcode: yup.string().min(8).required('CEP é obrigatório'),
    delivery_point: yup.string().required('Endereço é obrigatório'),
    // delivery_number: yup
    //   .string()
    //   .required('E-mail é obrigatório'),
    delivery_complement: yup.string(),
    delivery_neighborhood: yup.string().required('Bairro é obrigatório'),
    delivery_city: yup.string().required('Cidade é obrigatório'),
    delivery_state: yup.string().max(2).required('Estado é obrigatório'),
    delivery_corresponding: yup
      .string()
      .required('Correspondente é obrigatório'),
    delivery_corresponding_phone: yup
      .string()
      .required('Telefone do correspondente é obrigatório'),
    delivery_corresponding_email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail do correspondente é obrigatório'),
  });
};
