import styled from 'styled-components';
import { MdAddCircle, MdDelete, MdRemoveRedEye } from 'react-icons/md';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const WrapperAdd = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`;

export const AddIcon = styled(MdAddCircle).attrs({ size: '44px' })`
  color: #122d58;
  cursor: pointer;
`;

export const DeleteIcon = styled(MdDelete).attrs({ size: '16px' })`
  color: #f27160;
  cursor: pointer;
  margin-right: 24px;
`;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const WrapperPopover = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const PopoverText = styled.p`
  color: #888;
  margin-right: 10px;
`;

export const PopoverButton = styled.div`
  padding: 3px 5px;
  background-color: #f27160;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
`;
