import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Label = styled.span`
  color: #7b7b7b;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 2px;
`;

export const DatePicker = styled(ReactDatePicker).attrs((props) => ({
  ref: props.ref,
  type: props.type,
  pattern: props.pattern,
}))`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 5px 16px;
  background-color: #fafafa;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: ${(props) => (props.width ? props.width : '100%')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '100%')};

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;
