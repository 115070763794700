import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getCteRequest: ['convocation_id'],
  getCteSuccess: ['cte'],
  getCteFailure: ['error'],
  addCteRequest: ['cte', 'closeModal'],
  addCteSuccess: ['cte'],
  addCteFailure: ['error'],
  updateCteRequest: ['cte', 'closeModal'],
  updateCteSuccess: ['cte'],
  updateCteFailure: ['error'],
  removeCteRequest: ['id'],
  removeCteSuccess: null,
  removeCteFailure: ['error'],
  getIdCteRequest: ['id'],
  getIdCteSuccess: ['cte'],
  getIdCteFailure: ['error'],
});

export const CteTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  cte: {},
  isLoading: false,
  error: null,
});

/* Reducers */

const getCteRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getCteSuccess = (state, { cte }) =>
  state.merge({
    cte,
    isLoading: false,
  });

const getCteFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addCteRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addCteSuccess = (state, { cte }) =>
  state.merge({
    cte: cte,
    isLoading: false,
  });

const addCteFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const updateCteRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updateCteSuccess = (state, { cte }) =>
  state.merge({
    cte: cte,
    isLoading: false,
  });

const updateCteFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const removeCteRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const removeCteSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const removeCteFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getIdCteRequest = (state, { id }) =>
  state.merge({
    id,
    isLoading: true,
  });

const getIdCteSuccess = (state, { cte }) =>
  state.merge({
    cte,
    isLoading: false,
  });

const getIdCteFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CTE_REQUEST]: getCteRequest,
  [Types.GET_CTE_SUCCESS]: getCteSuccess,
  [Types.GET_CTE_FAILURE]: getCteFailure,
  [Types.ADD_CTE_REQUEST]: addCteRequest,
  [Types.ADD_CTE_SUCCESS]: addCteSuccess,
  [Types.ADD_CTE_FAILURE]: addCteFailure,
  [Types.UPDATE_CTE_REQUEST]: updateCteRequest,
  [Types.UPDATE_CTE_SUCCESS]: updateCteSuccess,
  [Types.UPDATE_CTE_FAILURE]: updateCteFailure,
  [Types.REMOVE_CTE_REQUEST]: removeCteRequest,
  [Types.REMOVE_CTE_SUCCESS]: removeCteSuccess,
  [Types.REMOVE_CTE_FAILURE]: removeCteFailure,
  [Types.GET_ID_CTE_REQUEST]: getIdCteRequest,
  [Types.GET_ID_CTE_SUCCESS]: getIdCteSuccess,
  [Types.GET_ID_CTE_FAILURE]: getIdCteFailure,
});
