/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { bool, func, arrayOf, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Modal from 'components/Modal';
import Table from 'components/Table';
import TripActions from 'store/ducks/trip';

import {
  Container,
  WrapperTable,
  WrapperMiddle,
  Row,
  LeftText,
  RightText,
  WrapperBottom,
  BottomText,
  WrapperCollapse,
  WhiteRow,
  GrayRow,
  FlexCell,
  Arrow,
} from './styles';

function ModalTrip({ open, onClose, data }) {
  const dispatch = useDispatch();
  // const trip = useSelector((state) => state.trip);
  const [opened, setOpened] = useState(false);

  function confirmPayment() {
    dispatch(TripActions.confirmPaymentRequest(data.actions, onClose));
  }

  return (
    <Modal open={open} onClose={onClose} title="CTE GERADA">
      <Container>
        <WrapperTable>
          <WhiteRow>
            <FlexCell flex={0.7} />
            <FlexCell flex={3} />
            <FlexCell bold>Nº de NF</FlexCell>
            <FlexCell bold>Imposto</FlexCell>
            <FlexCell bold>Total das NF</FlexCell>
          </WhiteRow>
          <GrayRow>
            <FlexCell
              flex={0.7}
              center
              pointer
              onClick={() => setOpened(!opened)}
            >
              <Arrow open={opened} />
            </FlexCell>
            <FlexCell flex={3} bold>
              Notas Fiscais
            </FlexCell>
            <FlexCell>{data?.invoices.length}</FlexCell>
            <FlexCell>
              {data?.taxes.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </FlexCell>
            <FlexCell>
              {data?.nfe_value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </FlexCell>
          </GrayRow>
          <WrapperCollapse open={opened}>
            <Table
              columns={[
                { label: 'Nº. da NF', id: 'barcode' },
                { label: 'Imposto', id: 'taxes' },
                { label: 'Frete', id: 'transportValue' },
                { label: 'Valor', id: 'value' },
              ]}
              customComponent={{
                taxes: (e) =>
                  e
                    ? e.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : '-',
                transportValue: (e) =>
                  e?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
                value: (e) =>
                  e
                    ? e?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : '-',
              }}
              data={data?.invoices}
              noNavigator
            />
          </WrapperCollapse>
        </WrapperTable>
        <WrapperMiddle>
          <Row>
            <LeftText>Taxa de Emissão</LeftText>
            <RightText>
              {data?.emisson_tax.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </RightText>
          </Row>
          <Row>
            <LeftText>Valor total</LeftText>
            <RightText>
              {data?.total_value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </RightText>
          </Row>
          <Row>
            <LeftText>Emitido em</LeftText>
            <RightText>
              {data?.emission_date
                ? format(
                    new Date(data.emission_date),
                    "dd 'de' MMMM 'de' yyyy",
                    {
                      locale: ptBR,
                    }
                  )
                : '-'}
            </RightText>
          </Row>
          <Row>
            <LeftText>Status</LeftText>
            <RightText>
              {data?.status === 0
                ? 'Pendente'
                : data?.status === 1
                ? 'Emitido'
                : 'Cancelado'}
            </RightText>
          </Row>
          <Row>
            <LeftText>Número do manifesto</LeftText>
            <RightText>{data?.manifest_number}</RightText>
          </Row>
        </WrapperMiddle>
        <WrapperBottom>
          <BottomText onClick={onClose}>Voltar</BottomText>
          {data?.status !== 1 && (
            <BottomText onClick={confirmPayment}>
              Confirmar Pagamento
            </BottomText>
          )}
        </WrapperBottom>
      </Container>
    </Modal>
  );
}

export default ModalTrip;

ModalTrip.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  data: arrayOf(object).isRequired,
};
