import React from 'react';
import { string, arrayOf, object, func, bool } from 'prop-types';

import { Container, Label, Content } from './styles';

export default function Selector({
  name,
  options,
  onChange,
  value,
  type,
  label,
  width,
  maxWidth,
  minWidth,
  margin,
  defaulOption,
  error,
  disabled,
}) {
  return (
    <Container width={width} margin={margin} maxWidth={maxWidth}>
      {label && <Label>{label}</Label>}
      <Content
        name={name}
        onChange={onChange}
        value={value}
        type={type}
        error={error}
        disabled={disabled}
      >
        {defaulOption && (
          <option disabled selected value>
            {defaulOption}
          </option>
        )}
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </Content>
    </Container>
  );
}

Selector.propTypes = {
  name: string,
  options: arrayOf(object).isRequired,
  onChange: func.isRequired,
  value: string,
  type: string,
  label: string,
  width: string,
  maxWidth: string,
  minWidth: string,
  margin: string,
  defaulOption: string,
  error: bool,
  disabled: bool,
};

Selector.defaultProps = {
  name: null,
  value: null,
  type: 'primary',
  label: null,
  width: null,
  maxWidth: null,
  minWidth: null,
  margin: null,
  defaulOption: null,
  error: false,
};
