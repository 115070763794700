/* eslint-disable no-nested-ternary */
import {
  CircularProgress,
  Drawer,
  Icon,
  Popover,
  Tooltip,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import fileDownload from 'js-file-download';
import axios from 'axios';
import Images from 'assets/img';
import Paper from 'components/Paper';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import Modal from './components/Modal';
import ConvocationsActions from 'store/ducks/convocations';
import CteActions from 'store/ducks/cte';
import ProvidersActions from 'store/ducks/providers';
import TruckerActions from 'store/ducks/trucker';
import { Column, Row } from 'styles/components';
import QRCode from './components/QRCode';
import CustomScrollbar from 'react-custom-scrollbars';
import { Send, LocationOn, Close } from '@material-ui/icons';
import mapStyle from '../../components/MapConvocationDetails/mapStyle.json';
import { GoogleMap } from 'react-google-maps';

import {
  AddIcon,
  Container,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PopoverButton,
  PopoverText,
  WrapperAdd,
  WrapperPopover,
  WrapperTable,
  IconPaper,
  Actions,
  Action,
  BoxMap,
  BoxDrawerRight,
  ButtonDrawer,
  Card,
  CloseButton,
  ShareButton,
} from './styles';
import StaticDrawer from 'components/StaticDrawer';
import Button from 'components/ButtonDrawer';
import ChargeItem from './components/ChargeItem';
import AnimatedSearchInput from 'components/AnimatedSearchInput';
import ModalFilter from './components/ModalFilter';
import { Center, Loading } from '../ConvocationDetails/styles';
import { MapWithAMarker } from 'components/MapConvocationDetails';
import useSnapshot from 'hooks/useGetUserLocation';
import api from 'services/api';
import TruckerPopover from './components/TruckerPopover';
import MarkerPopover from './components/MarkerPopover';
import MoreVert from '@material-ui/icons/MoreVert';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DrawerItem from 'components/DrawerItem';
import {
  Header,
  Subtitle,
  Title,
  WrapperLeft,
} from 'components/StaticDrawer/styles';
import UserDrawer from 'components/UserDrawer';
import CircleButton from 'components/CircleButton';
import ChargeInfos from './components/ChargeInfos';
import PercurseLogs from './components/PercurseLogs';
import DeleteModal from './components/DeleteModal';
import MessageTruckerModal from './components/MessageTruckerModal';
import { after } from 'lodash';
import { LoadingPage } from 'components/Loading';
import ErrorModal from './components/ErrorModal';
import { MapConvocation } from './components/MapConvocation';
import ModalShare from './components/ModalShare';
import { FiShare } from 'react-icons/fi';

function Convocations() {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const {
    convocations,
    centerMap,
    convocationActive,
    openModalEdit,
    openModalQrCode,
    deleteModal,
  } = useSelector((state) => state.convocations);

  const [popoverPosition, setPopoverPosition] = useState(null);
  const [markerPopoverPosition, setMarkerPopoverPosition] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [selectedConvocation, setSelectedConvocation] = useState(null);
  const [search, setSearch] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [disabledDownload, setDisabledDownload] = useState(true);
  const [filtered, setFiltered] = useState(false);
  const [posCollect, setPosCollect] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [positionDest, setPositionDest] = useState({
    latitude: 0,
    longitude: 0,
  });
  const mapRef = useRef(null);
  const [provider, setProvider] = React.useState({});
  const [paths, setPaths] = useState(null);

  const [filterOptions, setFilterOptions] = useState({
    status: [],
    drivers: [],
    types: [],
    deliveryCities: [],
    deliveryStates: [],
  });

  const { providers } = useSelector((state) => state.providers);
  const truckerLocation = useSnapshot(selectedConvocation);
  const [filteredData, setFilteredData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cte, setCte] = useState(null);
  const [error, setError] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [userPopover, setUserPopover] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [percurseLogs, setPercurseLogs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  let delayFactor = 0;

  const { latitude, longitude } = truckerLocation;

  const { oneTrucker } = useSelector((state) => state.trucker);

  const key = process.env.REACT_APP_MAPS_KEY;

  const [formFilter, setFormFilter] = useState({
    id: '',
    startDate: new Date('01/01/2000'),
    endDate: new Date(),
    status: [],
    driver_name: [],
    type: [],
    vehicle: '',
    nfe: '',
    delivery_city: [],
    delivery_state: [],
  });

  const data = convocations.map((item) => ({
    ...item,
    actions: item.id,
    id: item.id,
    requester: item.owner,
    type: item.type,
    created_at: item.created_at.seconds * 1000,
    validity: item.validity.seconds * 1000,
  }));

  const handleResetFilter = () => {
    setFormFilter({
      id: '',
      startDate: new Date().setFullYear(2000),
      endDate: new Date(),
      status: [],
      driver_name: [],
      type: [],
      vehicle: '',
      nfe: '',
      delivery_city: [],
      delivery_state: [],
    });

    setFilteredData(
      data.filter(
        (item) =>
          item.requester.toUpperCase().includes(search.toUpperCase()) ||
          item.type.toUpperCase().includes(search.toUpperCase())
      )
    );
  };

  const handleFilter = () => {
    setFiltered(true);
    var dataFiltered = data.filter(
      (item) =>
        item.requester.toUpperCase().includes(search.toUpperCase()) ||
        item.type.toUpperCase().includes(search.toUpperCase())
    );

    if (formFilter.id != '') {
      dataFiltered = dataFiltered.filter(
        (item) =>
          item.id != null &&
          item.id.toUpperCase().includes(formFilter.id.toUpperCase())
      );
    }
    if (formFilter.nfe != '') {
      dataFiltered = dataFiltered.filter(
        (item) =>
          item.nfe != null &&
          item.nfe.toUpperCase().includes(formFilter.nfe.toUpperCase())
      );
    }
    if (formFilter.vehicle != '') {
      dataFiltered = dataFiltered.filter(
        (item) =>
          item.vehicle != null &&
          item.vehicle.toUpperCase().includes(formFilter.vehicle.toUpperCase())
      );
    }
    if (formFilter.type.length > 0) {
      var filteredData = [];

      formFilter.type.map((typ) => {
        filteredData.push(
          ...dataFiltered.filter(
            (item) =>
              item.type != null &&
              item.type.toUpperCase().includes(typ.value.toUpperCase())
          )
        );
      });

      dataFiltered = filteredData;
    }
    if (formFilter.status.length > 0) {
      var filteredData = [];

      formFilter.status.map((stats) => {
        filteredData.push(
          ...dataFiltered.filter(
            (item) =>
              item.status != null &&
              item.status.toUpperCase() == stats.value.toUpperCase()
          )
        );
      });

      dataFiltered = filteredData;
    }
    if (formFilter.driver_name != '') {
      var filteredData = [];

      formFilter.driver_name.map((driver) => {
        filteredData.push(
          ...dataFiltered.filter(
            (item) =>
              item.driver_name != null &&
              item.driver_name.toUpperCase() == driver.value.toUpperCase()
          )
        );
      });

      dataFiltered = filteredData;
    }
    if (formFilter.delivery_state.length > 0) {
      var filteredData = [];

      formFilter.delivery_state.map((state) => {
        filteredData.push(
          ...dataFiltered.filter(
            (item) =>
              item.delivery_state != null &&
              item.delivery_state.toUpperCase().includes(state.toUpperCase())
          )
        );
      });

      dataFiltered = filteredData;
    }
    if (formFilter.delivery_city.length) {
      var filteredData = [];

      formFilter.delivery_city.map((city) => {
        filteredData.push(
          ...dataFiltered.filter(
            (item) =>
              item.delivery_city != null &&
              item.delivery_city.toUpperCase().includes(city.toUpperCase())
          )
        );
      });

      dataFiltered = filteredData;
    }
    if (formFilter.created_at != '') {
      var selectedDateOne = new Date(formFilter.startDate);
      var selectedDateTwo = new Date(formFilter.endDate);

      selectedDateOne = selectedDateOne.setHours(0, 0, 0);

      selectedDateTwo = selectedDateTwo.setHours(23, 59, 59);

      dataFiltered = dataFiltered.filter((item) => {
        var itemDate = new Date(item.created_at);

        return (
          item.created_at != null &&
          itemDate >= selectedDateOne &&
          itemDate <= selectedDateTwo
        );
      });
    }
    // console.log('formFilter', formFilter);
    // console.log('dataFiltered', dataFiltered);

    setFilteredData(dataFiltered);
    handleCloseFilter();
  };

  const updateSelectorsOptions = () => {
    var options = {
      status: [],
      drivers: [],
      types: [],
      deliveryCities: [],
      deliveryStates: [],
    };

    data.map((item) => {
      if (!alreadyExists(item.status, options.status)) {
        options.status.push(item.status);
      }
      if (!alreadyExists(item.driver_name, options.drivers)) {
        options.drivers.push(item.driver_name);
      }
      if (!alreadyExists(item.type, options.types)) {
        options.types.push(item.type);
      }
      if (
        !alreadyExists(
          item.delivery_state.substring(0, 2),
          options.deliveryStates
        )
      ) {
        options.deliveryStates.push(item.delivery_state.substring(0, 2));
      }
    });

    setFilterOptions(options);
  };

  const alreadyExists = (value, list) => {
    const index = list.indexOf(value);

    return index != -1 ? true : false;
  };

  function getCtes(convocation) {
    setCte(null);
    setInvoices(null);

    if (convocation) {
      api
        .firestore()
        .collection('ctes')
        .where('convocation_id', '==', convocation.id)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              const selectedCte = snapshot.docs[0].data();
              const filteredInvoices = selectedCte.invoices.filter(
                (item) =>
                  (item.data != null &&
                    item.data.lat != null &&
                    item.data.lng != null) ||
                  (item.receiver != null &&
                    item.receiver.lat != null &&
                    item.receiver.lng != null)
              );

              setCte(selectedCte);
              setInvoices(filteredInvoices);
            } else {
              console.log('No such document!');
              setCte(null);
              setInvoices([]);
              if (error == null) {
                setError({ message: 'Erro ao procurar CTE!' });
              }
            }
          },
          (err) => {
            console.log('error:', err);
            setError(err);
          }
        );
    } else if (selectedConvocation) {
      api
        .firestore()
        .collection('ctes')
        .where('convocation_id', '==', selectedConvocation.id)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs.length > 0) {
              const selectedCte = snapshot.docs[0].data();
              const filteredInvoices = selectedCte.invoices.filter(
                (item) =>
                  (item.data != null &&
                    item.data.lat != null &&
                    item.data.lng != null) ||
                  (item.receiver != null &&
                    item.receiver.lat != null &&
                    item.receiver.lng != null)
              );

              setCte(selectedCte);
              setInvoices(filteredInvoices);
            } else {
              console.log('No such document!');
              setCte(null);
              setInvoices([]);
              if (error == null) {
                setError({ message: 'Erro ao procurar CTE!' });
              }
            }
          },
          (err) => {
            console.log('error:', err);
            setError(err);
          }
        );
    }
  }

  const getUser = async () => {
    setUserPopover(null);

    if (selectedConvocation && selectedConvocation.driver) {
      api
        .firestore()
        .collection('users')
        .where('cpf', '==', selectedConvocation.driver)
        .get()
        .then((doc) => {
          if (doc.docs.length > 0) {
            const user = doc.docs[0].data();
            dispatch(TruckerActions.setSelectedTrucker(user));
          } else {
            console.log('No such document!');
            setUserPopover(null);
            dispatch(TruckerActions.setSelectedTrucker(null));
            if (error == null) {
              setError({ message: 'Erro ao procurar motorista!' });
            }
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setUserPopover(null);
          dispatch(TruckerActions.setSelectedTrucker(null));
          console.log('error:', error);
          setError({ message: error });
        });
    }

    return null;
  };

  function calcDist(location1, location2) {
    let R = 6371;
    let dLat = (location2.lat - location1.lat) * (Math.PI / 180);
    let dLon = (location2.lng - location1.lng) * (Math.PI / 180);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(location1.lat * (Math.PI / 180)) *
        Math.cos(location2.lat * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;

    return d;
  }

  const getPaths = async () => {
    try {
      setOpenErrorModal(false);
      setPaths(null);

      if (selectedConvocation && selectedConvocation.id) {
        api
          .firestore()
          .collection('location')
          .doc(`${selectedConvocation.id}`)
          .onSnapshot(
            async (snapshot) => {
              if (snapshot.exists) {
                const loc = snapshot
                  .data()
                  .user_location.filter(
                    (item) =>
                      item.latitude !== null &&
                      item.longitude !== null &&
                      item.createdAt.toDate() > new Date('2022-09-19')
                  );

                // const fixedPath = await fixPath(loc);
                // if (fixedPath.length > loc.length) {
                //   dispatch(
                //     ConvocationsActions.updateLocationRequest(
                //       fixedPath,
                //       loc,
                //       selectedConvocation
                //     )
                //   );
                // }

                const dirs = loc.map(
                  (item) =>
                    (item = {
                      lat: item.latitude ? item.latitude : item.lat,
                      lng: item.longitude ? item.longitude : item.lng,
                    })
                );

                console.log('dirs', dirs);

                setPaths(dirs);
                setOpenErrorModal(false);
              } else {
                console.log('No such document!');
                setPaths([]);
                setOpenErrorModal(true);
              }
            },
            (err) => {
              console.log('error:', err);
              if (error == null) {
                setError(err);
              }
            }
          );
      }
    } catch (error) {
      console.log('error:', error);
      setError({ message: error });
      return null;
    }
  };

  // const fixPath = async (filteredPath) => {
  //   let fixedPath = [];
  //   setLoading(true);

  //   console.log('filteredPath', filteredPath);

  //   for (let index = 0; index < filteredPath.length; index++) {
  //     const element = filteredPath[index];
  //     const afterElement =
  //       element != filteredPath[filteredPath.length - 1]
  //         ? filteredPath[index + 1]
  //         : null;

  //     fixedPath.push(element);

  //     if (
  //       afterElement != null &&
  //       calcDist(
  //         { lat: element.latitude, lng: element.longitude },
  //         {
  //           lat: afterElement.latitude,
  //           lng: afterElement.longitude,
  //         }
  //       ) > 0.1
  //     ) {
  //       const directionService = new window.google.maps.DirectionsService();

  //       directionService.route(
  //         {
  //           origin: new window.google.maps.LatLng(
  //             element.latitude,
  //             element.longitude
  //           ),
  //           destination: new window.google.maps.LatLng(
  //             afterElement.latitude,
  //             afterElement.longitude
  //           ),
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         },
  //         (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //             fixedPath = [...fixedPath, ...result.routes[0].overview_path];
  //             // console.log(index, result);
  //           } else {
  //             console.error(`error fetching directions ${result}`);
  //           }
  //         }
  //       );

  //       await sleep(1000);
  //     }
  //   }

  //   // console.log('fixedPath', fixedPath);

  //   setLoading(false);
  //   return fixedPath;
  // };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleDelete = () => {
    const convocationId = convocations.filter(
      (item) => item.id === selectedConvocation.id
    );

    const [convocation] = convocationId.map((item) => ({
      ...item,
    }));

    if (convocation.status !== 'initial') {
      alert.info(
        'Esta carga não pode ser excluida porque já esta em andamento!'
      );
    } else {
      dispatch(
        ConvocationsActions.updateConvocationRequest({
          ...convocation,
          status: 'canceled',
          validity: convocation.validity.seconds * 1000,
        })
      );
    }
  };

  const viewConvocation = (convocation) => {
    if (selectedConvocation === convocation) {
      setSelectedConvocation(null);
    }
    setSelectedConvocation(convocation);
  };

  const handleOpenFilter = () => {
    updateSelectorsOptions();
    setFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setFiltered(false);
    setFilterOpen(false);
  };

  const handleOpenTruckerPopover = (event) => {
    setPopoverPosition(event.domEvent.currentTarget);
  };

  const handleCloseTruckerPopover = () => {
    setPopoverPosition(null);
  };

  const handleOpenMarkerPopover = (event, invoice) => {
    setMarkerPopoverPosition(event.domEvent.currentTarget);
    setSelectedInvoice(invoice);
  };

  const handleCloseMarkerPopover = () => {
    setMarkerPopoverPosition(null);
    setSelectedInvoice(null);
  };

  const toogleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const tooglePercurseLogs = (convocation) => {
    getCtes(convocation);
    setPercurseLogs(convocation);
  };
  const { modalDelete, messageModal, acceptDelete, modalShare } = useSelector(
    (state) => state.convocations
  );

  const openTruckerPopover = Boolean(popoverPosition);
  const openMarkerPopover = Boolean(markerPopoverPosition);

  useEffect(() => {
    setFilteredData(
      data.filter(
        (item) =>
          item.requester.toUpperCase().includes(search.toUpperCase()) ||
          item.type.toUpperCase().includes(search.toUpperCase())
      )
    );
  }, []);

  useEffect(() => {
    if (filteredData) {
      setPageLoading(false);
    }
  }, [filteredData]);

  useEffect(() => {
    if (selectedConvocation) {
      getPaths();
      getCtes();
      getUser();
    }
  }, [selectedConvocation]);

  useEffect(() => {
    setFilteredData(
      data.filter(
        (item) =>
          item.requester.toUpperCase().includes(search.toUpperCase()) ||
          item.type.toUpperCase().includes(search.toUpperCase())
      )
    );
  }, [search]);

  useEffect(() => {
    if (convocations && convocations.length > 0) {
      setFilteredData(convocations);
      handleResetFilter();
    }
  }, [convocations]);

  return pageLoading ? (
    <LoadingPage />
  ) : (
    <Container>
      <Card>
        {selectedConvocation && cte ? (
          <ChargeInfos
            convocation={selectedConvocation}
            cte={cte}
            onClose={() => {
              setSelectedConvocation(null);
            }}
          />
        ) : (
          <StaticDrawer
            title="CARGAS"
            icon={Images.IconGrayTruck}
            width="350px"
            mapActived={selectedConvocation != null}
          >
            <Actions>
              <Button
                type="primary"
                icon={Images.Add}
                label="Novo"
                onClick={() => {
                  history.push({
                    pathname: '/createConvocation',
                    state: {
                      selectedId: null,
                      edit: false,
                      convocationId: null,
                    },
                  });
                }}
              />
              <CircleButton
                type="secondary"
                icon={Images.Filter}
                label="Filtrar"
                margin="0 80px 0 0"
                onClick={filterOpen ? handleCloseFilter : handleOpenFilter}
              />
              <AnimatedSearchInput
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => setSearch('')}
                value={search}
              />
            </Actions>
            <CustomScrollbar style={{ height: '73%' }}>
              {filteredData ? (
                filteredData.map((data) => (
                  <ChargeItem
                    key={data.id}
                    selected={selectedConvocation === data}
                    tooglePercurseLogs={tooglePercurseLogs}
                    viewConvocation={viewConvocation}
                    data={data}
                  />
                ))
              ) : (
                <CircularProgress />
              )}
            </CustomScrollbar>
          </StaticDrawer>
        )}
        {modalDelete && (
          <>
            <DeleteModal />
            <BoxMap>
              <MapConvocation
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                containerElement={
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
                loadingElement={
                  <Center>
                    <Loading />
                  </Center>
                }
              />
            </BoxMap>
          </>
        )}
        {messageModal && (
          <>
            <MessageTruckerModal />
            <BoxMap>
              <MapConvocation
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                containerElement={
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
                loadingElement={
                  <Center>
                    <Loading />
                  </Center>
                }
              />
            </BoxMap>
          </>
        )}
        {percurseLogs && (
          <>
            <PercurseLogs
              invoices={invoices}
              onClose={() => setPercurseLogs(null)}
            />
            <BoxMap>
              <MapConvocation
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                containerElement={
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
                loadingElement={
                  <Center>
                    <Loading />
                  </Center>
                }
              />
            </BoxMap>
          </>
        )}
        {error && (
          <BoxMap>
            <ErrorModal
              reload={() => {
                getPaths();
                getCtes();
                getUser();
              }}
              message={error.message}
              onClose={() => {
                setError(null);
              }}
            />
            <MapConvocation
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
              containerElement={
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              loadingElement={
                <Center>
                  <Loading />
                </Center>
              }
            />
          </BoxMap>
        )}
        {selectedConvocation && (
          <BoxMap>
            <MapWithAMarker
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
              containerElement={
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              loadingElement={
                <Center>
                  <Loading />
                </Center>
              }
              truckerLocation={truckerLocation}
              handleOpenPopover={handleOpenTruckerPopover}
              handleOpenMarker={handleOpenMarkerPopover}
              closeModalError={() => setPaths(null)}
              cte={cte}
              loading={loading}
              convocation={selectedConvocation}
              getPaths={getPaths}
              paths={paths}
              invoices={invoices}
            />
            <CloseButton onClick={() => setSelectedConvocation(null)}>
              <Close className="icon" />
            </CloseButton>
            <ShareButton
              onClick={() => {
                dispatch(
                  ConvocationsActions.openShareModal(selectedConvocation.id)
                );
              }}
            >
              <img
                className="icon"
                src={Images.IconShare}
                alt="Ícone de Download"
              />
            </ShareButton>
            <Tooltip title="Opções">
              <ButtonDrawer onClick={toogleDrawer}>
                <ChevronLeft className="icon-1" />
                <MoreVert className="icon-2" />
              </ButtonDrawer>
            </Tooltip>
          </BoxMap>
        )}

        <Drawer anchor="right" open={openDrawer} onClose={toogleDrawer}>
          <Header>
            <WrapperLeft>
              <Column>
                <Title color="#122D58">Ações</Title>
              </Column>
            </WrapperLeft>
            <Close className="icon" onClick={toogleDrawer} />
          </Header>
          <BoxDrawerRight>
            <DrawerItem
              onClick={() => {
                toogleDrawer();
                dispatch(
                  ConvocationsActions.setConvocationRequest(selectedConvocation)
                );
                dispatch(ConvocationsActions.openMessageModal());
              }}
              icon={<Send className="icon" />}
              description="Enviar Mensagem ao motorista"
            />
            <DrawerItem
              onClick={() => {
                toogleDrawer();
                tooglePercurseLogs(selectedConvocation);
              }}
              icon={<LocationOn className="icon" />}
              description="Log de percurso da carga"
            />
          </BoxDrawerRight>
        </Drawer>

        {openTruckerPopover && (
          <TruckerPopover
            popoverPosition={popoverPosition}
            open={openTruckerPopover}
            handleOpenPopover={handleOpenTruckerPopover}
            selectedConvocation={selectedConvocation}
            handleClose={handleCloseTruckerPopover}
            cte={cte}
          />
        )}

        {openMarkerPopover && (
          <MarkerPopover
            popoverPosition={markerPopoverPosition}
            open={openMarkerPopover}
            handleOpenMarker={handleOpenMarkerPopover}
            selectedConvocation={selectedConvocation}
            handleClose={handleCloseMarkerPopover}
            selectedInvoice={selectedInvoice}
            cte={cte}
          />
        )}

        {openModalEdit && convocationActive && (
          <Modal
            data={convocations}
            open={openModalEdit}
            onClose={() => dispatch(ConvocationsActions.closeModalEdit())}
            selectedId={convocationActive.id}
            edit={false}
            providers={providers}
          />
        )}

        {openModalQrCode && convocationActive && (
          <QRCode
            convocationKey={convocationActive.id}
            open={openModalQrCode}
            name={convocationActive?.owner}
            phone={convocationActive?.owner_phone}
          />
        )}

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setOpen(false);
          }}
          placement="top"
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <WrapperPopover>
            <PopoverText>Tem certeza?</PopoverText>
            <PopoverButton
              onClick={() => {
                handleDelete();
                setOpen(false);
              }}
            >
              Sim
            </PopoverButton>
          </WrapperPopover>
        </Popover>
      </Card>

      <ModalFilter
        data={data}
        form={formFilter}
        resetFilter={handleResetFilter}
        setForm={setFormFilter}
        handleFilter={handleFilter}
        options={filterOptions}
        setOptions={setFilterOptions}
        open={filterOpen}
        handleClose={handleCloseFilter}
      />

      <ModalShare />

      <UserDrawer />
    </Container>
  );
}

export default Convocations;
