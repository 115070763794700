import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getNotificationsRequest: null,
  getNotificationsSuccess: ['notifications'],
  getNotificationsFailure: ['error'],
  addNotificationRequest: ['notification', 'closeModal'],
  addNotificationSuccess: null,
  addNotificationFailure: ['error'],
  getUsersRequest: null,
  getUsersSuccess: ['users'],
  getUsersFailure: ['error'],
});

export const NotificationsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  notifications: [],
  users: [{ text: 'Todos', value: undefined }],
  isLoading: false,
  error: null,
  openModal: false,
});

/* Reducers */

const getNotificationsRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getNotificationsSuccess = (state, { notifications }) =>
  state.merge({
    notifications,
    isLoading: false,
  });

const getNotificationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addNotificationRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addNotificationSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const addNotificationFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getUsersRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getUsersSuccess = (state, { users }) =>
  state.merge({
    users: [...state.users, ...users],
    isLoading: false,
  });

const getUsersFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
  [Types.ADD_NOTIFICATION_REQUEST]: addNotificationRequest,
  [Types.ADD_NOTIFICATION_SUCCESS]: addNotificationSuccess,
  [Types.ADD_NOTIFICATION_FAILURE]: addNotificationFailure,
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,
});
