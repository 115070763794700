import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'styles/components';
import WrapperInput from 'components/WrapperInput';
import InsurancesActions from 'store/ducks/insurances';

import { Container, FieldLabel, Error } from './styles';
import { Currency } from 'pages/Convocations/components/Modal/styles';
import Selector from 'components/Selector';
import getInsurance from 'services/getInsurance';

function Insurance({ formik, edit }) {
  const dispatch = useDispatch();
  const { insurances } = useSelector((state) => state.insurances);

  useEffect(() => {
    dispatch(InsurancesActions.getInsurancesRequest());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <WrapperInput>
          <Selector
            label="Tipo do Seguro"
            options={[
              { text: 'Sem seguro', value: '' },
              { text: 'Carga', value: 'carga' },
              { text: 'Carga e Roubo', value: 'cargaroubo' },
            ]}
            type="secondary"
            name="type_insurance"
            error={
              formik.touched.type_insurance &&
              Boolean(formik.errors.type_insurance)
            }
            value={formik.values.type_insurance}
            onChange={(e) => {
              formik.handleChange(e);
              getInsurance(e.target.value, formik, insurances);
              formik.setFieldError('type_insurance', '');
            }}
            disabled={edit}
          />
        </WrapperInput>

        <WrapperInput>
          <FieldLabel>Valor Seguro</FieldLabel>

          <Currency
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            name="insurance_value"
            error={
              formik.touched.insurance_value &&
              Boolean(formik.errors.insurance_value)
            }
            value={formik.values.insurance_value}
            onChangeEvent={(e) => {
              formik.handleChange(e);
              formik.setFieldError('insurance_value', '');
            }}
            onBlur=""
            disabled
          />
          <Error>
            {formik.errors.insurance_value
              ? formik.errors.insurance_value
              : null}
          </Error>
        </WrapperInput>
      </Row>
    </Container>
  );
}

export default Insurance;
