import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import api from 'services/api';

import ReportsActions from '../ducks/reports';

export function* getReports() {
  try {
    const data = yield api.firestore().collection('reportsConvocations').get();

    const reports = data.docs.map((item) => item.data());

    yield put(ReportsActions.getReportsSuccess(reports));
  } catch (err) {
    yield put(ReportsActions.getReportsFailure(err));
  }
}

export function* removeReport({ id }) {
  try {
    yield api.firestore().collection('reportsConvocations').doc(id).delete();

    yield put(ReportsActions.removeReportSuccess());
    yield put(ReportsActions.getReportsRequest());
  } catch (err) {
    yield put(ReportsActions.removeReportFailure(err));
  }
}

export function* setReportOld({ reportId }) {
  try {
    const reportsStore = yield select((state) => state.reports.reports);
    const reportsDelete = reportsStore.filter((rp) => reportId !== rp.id);

    yield api
      .firestore()
      .collection('reportsConvocations')
      .doc(reportId)
      .update({ read: true });

    yield put(ReportsActions.setReportOldSuccess(reportsDelete));
  } catch (error) {
    yield put(ReportsActions.setReportOldFailure(error));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao setar o report',
        message:
          'Ops...Tivemos um problema. Por favor tente novamente mais tarde.',
      })
    );
  }
}

export function* setReportOldAll() {
  try {
    yield api
      .firestore()
      .collection('reportsConvocations')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({ read: true });
        });
      });

    yield getReports();

    yield put(ReportsActions.setReportOldAllSuccess());
  } catch (error) {
    yield put(ReportsActions.setReportOldAllFailure(error));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao setar todos os reportes',
        message:
          'Ops...Tivemos um problema. Por favor tente novamente mais tarde.',
      })
    );
  }
}
