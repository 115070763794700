import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from 'services/api';

import AntecipationsActions from '../ducks/antecipations';

export function* getAntecipations() {
  try {
    const data = yield api
      .firestore()
      .collection('anticipations')
      .orderBy('created_at', 'desc')
      .get();

    const antecipations = data.docs.map((item) => item.data());

    yield put(AntecipationsActions.getAntecipationsSuccess(antecipations));
  } catch (err) {
    yield put(AntecipationsActions.getAntecipationsFailure(err));
  }
}

export function* updateAntecipations({ id, status, closeModal }) {
  const userActive = yield select((state) => state.auth.userActive);

  try {
    yield api
      .firestore()
      .collection('anticipations')
      .doc(id)
      .update({
        payment: status === 'completed' ? 'pending' : 'completed',
        date_write_off_at: status === 'completed' ? '' : new Date(),
        user_writeoff: status === 'completed' ? '' : userActive.id,
      });
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Pagamento confirmado',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao confirmar pagamento',
      })
    );
    yield put(AntecipationsActions.updateAntecipationsFailure(err));
  }
}

export function* removeAntecipations({ id }) {
  try {
    yield api.firestore().collection('anticipations').doc(id).delete();

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Pagamento excluido com sucesso',
      })
    );

    yield put(AntecipationsActions.removeAntecipationsSuccess());

    yield put(AntecipationsActions.getAntecipationsRequest());
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao excluir pagamento',
      })
    );
    yield put(AntecipationsActions.removeAntecipationsFailure(err));
  }
}

export function* fileAntecipations({ file, id }) {
  try {
    yield api
      .storage()
      .ref(`${`antecipation/${id}`}/${file.name}`)
      .put(file);

    const uri = yield api
      .storage()
      .ref(`antecipation/${id}/${file.name}`)
      .getDownloadURL();

    if (uri) {
      const ref = yield api
        .firestore()
        .collection('anticipations')
        .doc(id)
        .get();

      const data = ref.data()?.images || [];
      data.push(uri);

      yield api.firestore().collection('anticipations').doc(id).update({
        images: data,
      });
    }

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Arquivo incluído com sucesso',
      })
    );
  } catch (error) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao incluir arquivo',
      })
    );

    yield put(AntecipationsActions.fileAntecipationsFailure(error));
  }
}

export function* deleteFileAntecipations({ file, id, setRefresh }) {
  const text = file.substr(file.indexOf('%2', 10) + 3);
  const fileName = text
    .substr(text.indexOf('%2', 10) + 3)
    .substr(
      text.substr(text.indexOf('%2', 10)).indexOf('%2'),
      text.indexOf('?alt', 10) - 3 - text.indexOf('%2', 10)
    );

  try {
    yield api
      .storage()
      .ref(`${`antecipation/${id}`}/${fileName}`)
      .delete();

    const ref = yield api.firestore().collection('anticipations').doc(id).get();

    const data = ref.data().images.filter((item) => item !== file);

    yield api.firestore().collection('anticipations').doc(id).update({
      images: data,
    });

    setRefresh(true);
  } catch (error) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao deletar arquivo',
      })
    );
  }
}

export function* getIdAntecipations({ id }) {
  try {
    const data = yield api
      .firestore()
      .collection('anticipations')
      .doc(id)
      .get();

    const antecipation = data.data();

    yield put(AntecipationsActions.getIdAntecipationsSuccess(antecipation));
  } catch (err) {
    yield put(AntecipationsActions.getIdAntecipationsFailure(err));
  }
}
