import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetApp } from '@material-ui/icons';
import { saveAs } from 'file-saver';

import Images from 'assets/img';
import ButtonModal from 'components/ButtonModal';
import api from 'services/api';
import TruckerActions from 'store/ducks/trucker';

import BadgeStatus from '../ChargeInfos/components/BadgeStatus';
import MarkerLetter from '../MarkerLetter';
import { SubTitleGrey } from '../TruckerPopover/styles';
import Layout from './layout';
import {
  BoxPhoto,
  Card,
  Close,
  ContainerStatus,
  Content,
  GridFiles,
  GridRow,
  Header,
  ImageWithLabelAndValue,
} from './styles';
import { formatDateTime } from 'utils/formatDateTime';

export default function MarkerPopover({
  selectedConvocation,
  popoverPosition,
  handleClose,
  open,
  selectedInvoice,
  cte,
}) {
  const dispatch = useDispatch();
  const { truckerDrawer } = useSelector((state) => state.trucker);

  function getStatus() {
    if (selectedInvoice) {
      if (selectedInvoice.signature && selectedInvoice.status === 'completed') {
        return (
          <BadgeStatus
            icon={Images.ChargeStatusTwo}
            text="Carga Entregue"
            color="#6ABF62"
          />
        );
      }
      if (selectedInvoice.images && selectedInvoice.images.length > 0) {
        return (
          <BadgeStatus
            icon={Images.ChargeStatusTwo}
            text="Carga Coletada"
            color="#F09702"
          />
        );
      }
      return (
        <BadgeStatus
          icon={Images.ChargeStatusOne}
          text="Motorista Encontrado"
          color="#8946A0"
        />
      );
    }
  }

  const download = (image) => {
    saveAs(image, image.split('?')[0].split('%').pop());
  };

  function capitalize(string) {
    if (string !== null && string !== undefined) {
      const strings = string.toString().toLowerCase().split(' ');
      let formattedString = '';
      strings.forEach((item) => {
        item = item.trim();
        if (item !== strings[strings.length - 1]) {
          formattedString += `${item.charAt(0).toUpperCase() + item.slice(1)} `;
        } else {
          formattedString += item.charAt(0).toUpperCase() + item.slice(1);
        }
      });

      return formattedString;
    }
    return '';
  }

  function zipcode(string) {
    return `${string
      .replace('.', '')
      .replace('-', '')
      .substring(0, 5)}-${string.substring(5)}`;
  }

  function getImgType(type) {
    switch (type) {
      case 'Carga Granel':
        return Images.AgriculturalFlag;
      case 'Carga Perigosa':
        return Images.DangerousFlag;
      case 'Carga Frigorificada':
        return Images.ColdFlag;
      case 'Carga Viva':
        return Images.AnimalFlag;
      case 'Carga Solta':
        return Images.WoodFlag;
      case 'Carga Unitizada':
        return Images.BoxFlag;
      case 'Carga Animal':
        return Images.PetFlag;
      case 'Carga Inflamável':
        return Images.FlammableFlag;
      case 'Carga Química':
        return Images.ChemicalFlag;
      default:
        return Images.GeneralFlag;
    }
  }

  const setActiveInvoice = () => {
    const data = {
      ...cte,
      activeInvoice: selectedInvoice,
      updated_at: new Date(),
    };

    api
      .firestore()
      .collection('ctes')
      .doc(cte.id)
      .update(data)
      .then((resp) => console.log('respFirebase', resp))
      .catch((err) => console.log('errFirebase', err));

    handleClose();
  };

  return (
    <Layout popoverPosition={popoverPosition} open={open}>
      {selectedConvocation && (
        <Card>
          <Header>
            ID: {selectedConvocation.id}
            <Close onClick={handleClose} />
          </Header>
          {selectedInvoice && (
            <Content>
              <GridRow>
                <ImageWithLabelAndValue>
                  <img
                    className="img-big"
                    src={getImgType(selectedConvocation.convocation_type)}
                    alt=""
                  />
                  <div className="label-and-value">
                    <p className="label">Carga</p>
                    <p className="value">
                      {selectedConvocation.type.replace('Carga ', '')}
                    </p>
                  </div>
                </ImageWithLabelAndValue>
                <ImageWithLabelAndValue
                  className="pointer"
                  onClick={() => {
                    if (truckerDrawer) {
                      dispatch(TruckerActions.closeTruckerDrawer());
                    } else {
                      dispatch(TruckerActions.openTruckerDrawer());
                    }
                  }}
                >
                  <img className="img-big" src={Images.IconGrayUser} alt="" />
                  <div className="label-and-value">
                    <p className="label">Motorista</p>
                    <p className="value">
                      {capitalize(selectedConvocation.driver_name)}
                    </p>
                  </div>
                </ImageWithLabelAndValue>
              </GridRow>
              <ImageWithLabelAndValue>
                <div className="marker-big">
                  <MarkerLetter
                    width="16.8px"
                    height="24px"
                    number={0}
                    fontSize="12px"
                    margin="2px 0 0 0"
                  />
                </div>
                <div className="label-and-value">
                  <p className="label">Coleta</p>
                  <p className="value">
                    {selectedInvoice.data
                      ? `${capitalize(selectedInvoice.data.street)} ${
                          selectedInvoice.data.number
                        } - ${capitalize(
                          selectedInvoice.data.city
                        )}/${selectedInvoice.data.state.toUpperCase()} ${capitalize(
                          selectedInvoice.data.country
                        )} - ${zipcode(selectedInvoice.data.zipcode)}${
                          selectedInvoice.data.complement !== ''
                            ? ` (${capitalize(
                                selectedInvoice.data.complsement
                              )})`
                            : ''
                        }`
                      : `${capitalize(selectedInvoice.receiver.street)} ${
                          selectedInvoice.receiver.number
                        } - ${capitalize(
                          selectedInvoice.receiver.city
                        )}/${selectedInvoice.receiver.state.toUpperCase()} ${capitalize(
                          selectedInvoice.receiver.country
                        )} - ${zipcode(selectedInvoice.receiver.zipcode)}${
                          selectedInvoice.receiver.complement !== ''
                            ? ` (${capitalize(
                                selectedInvoice.receiver.complsement
                              )})`
                            : ''
                        }`}
                  </p>
                </div>
              </ImageWithLabelAndValue>
              <ContainerStatus>{getStatus('in_progress_res')}</ContainerStatus>
              <ImageWithLabelAndValue>
                <img className="img-small" src={Images.IconDate} alt="" />
                <div className="label-and-value">
                  <p className="label">Data da coleta</p>
                  <p className="value">
                    {formatDateTime(selectedConvocation.created_at)}
                  </p>
                </div>
              </ImageWithLabelAndValue>
              <SubTitleGrey>Arquivos anexados</SubTitleGrey>
              <GridFiles>
                {selectedInvoice.images &&
                  selectedInvoice.images.map((image) => (
                    <BoxPhoto key={image} onClick={() => download(image)}>
                      <img src={image} alt="Imagem" />
                      <div className="download-box">
                        <GetApp className="icon" />
                      </div>
                    </BoxPhoto>
                  ))}
              </GridFiles>
              {cte.activeInvoice != null &&
              cte.activeInvoice.barcode !== selectedInvoice.barcode ? (
                <ButtonModal
                  onClick={setActiveInvoice}
                  label="Selecionar como Ativo"
                  width="100%"
                />
              ) : null}
            </Content>
          )}
        </Card>
      )}
    </Layout>
  );
}
