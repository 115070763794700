import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getReportsRequest: null,
  getReportsSuccess: ['reports'],
  getReportsFailure: ['error'],
  removeReportRequest: ['id'],
  removeReportSuccess: null,
  removeReportFailure: ['error'],
  setReportOldRequest: ['reportId'],
  setReportOldSuccess: ['reports'],
  setReportOldFailure: ['error'],
  setReportOldAllRequest: null,
  setReportOldAllSuccess: null,
  setReportOldAllFailure: ['error'],
});

export const ReportsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  reports: [],
  isLoading: false,
  error: null,
  openModal: false,
});

/* Reducers */

const getReportsRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getReportsSuccess = (state, { reports }) =>
  state.merge({
    reports,
    isLoading: false,
  });

const getReportsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const removeReportRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const removeReportSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const removeReportFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

export const setOldRequest = (state) => state.merge({ isLoading: true });
export const setOldSuccess = (state, { reports }) =>
  state.merge({ isLoading: false, reports });
export const setOldFailure = (state, { error }) =>
  state.merge({ error, isLoading: false });

export const setOldAllRequest = (state) => state.merge({ isLoading: true });
export const setOldAllSuccess = (state) => state.merge({ isLoading: false });
export const setOldAllFailure = (state, { error }) =>
  state.merge({ error, isLoading: false });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORTS_REQUEST]: getReportsRequest,
  [Types.GET_REPORTS_SUCCESS]: getReportsSuccess,
  [Types.GET_REPORTS_FAILURE]: getReportsFailure,
  [Types.REMOVE_REPORT_REQUEST]: removeReportRequest,
  [Types.REMOVE_REPORT_SUCCESS]: removeReportSuccess,
  [Types.REMOVE_REPORT_FAILURE]: removeReportFailure,
  [Types.SET_REPORT_OLD_REQUEST]: setOldRequest,
  [Types.SET_REPORT_OLD_SUCCESS]: setOldSuccess,
  [Types.SET_REPORT_OLD_FAILURE]: setOldFailure,
  [Types.SET_REPORT_OLD_ALL_REQUEST]: setOldAllRequest,
  [Types.SET_REPORT_OLD_ALL_SUCCESS]: setOldAllSuccess,
  [Types.SET_REPORT_OLD_ALL_FAILURE]: setOldAllFailure,
});
