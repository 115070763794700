import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import Images from 'assets/img';
import Button from 'components/Button';
import Paper from 'components/Paper';
import { Input } from 'components/TextInput/styles';
import ConvocationsActions from 'store/ducks/convocations';
import CtesActions from 'store/ducks/cte';
import ProvidersActions from 'store/ducks/providers';
import CteActions from 'store/ducks/cte';
import TruckerActions from 'store/ducks/trucker';
import TextInput from 'components/TextInput';
import { schema } from './schemaValidation';
import CollectPoints from './Sections/CollectPoints';
import Cte from './Sections/Cte';
import DeliveryPoint from './Sections/DeliveryPoint';
import LoadInfo from './Sections/LoadInfo';
import ProviderData from './Sections/ProviderData';
import WrapperInput from 'components/WrapperInput';
import { useAlert } from 'react-alert';

import {
  Container,
  FieldLabel,
  Header,
  WrapperButtons,
  TabNav,
  TabItem,
  WrapperNav,
  WrapperSection,
} from './styles';
import Section from './components/Section';
import { addDays } from 'date-fns';
import Insurance from './Sections/Insurance';
import ModalComp from './components/Modal';

function PublicConvocation({ history, location }) {
  const dispatch = useDispatch();
  const alert = useAlert();

  const convocationId = location.state.convocationId;
  const provider = location.state.provider;
  const edit = location.state.edit;

  const [convocationType, setConvocationType] = useState('public');
  const { truckers } = useSelector((state) => state.trucker);
  const [headerId, setHeaderId] = React.useState(0);
  const [confirm, setConfirm] = useState(false);
  const [summary, setSummary] = useState(false);
  const [data, setData] = useState(null);
  const [freight, setFreight] = useState(0);
  const [providerId, setProviderId] = useState(
    provider
      ? {
          label: provider.social_reason,
          value: provider.id,
        }
      : []
  );
  const { oneTrucker } = useSelector((state) => state.trucker);
  const { convocationActive } = useSelector((state) => state.convocations);
  const { cte } = useSelector((state) => state.cte);

  useEffect(() => {
    dispatch(ConvocationsActions.getConvocationsRequest());
    dispatch(ProvidersActions.getProvidersRequest());

    if (provider) {
      dispatch(CteActions.getCteRequest(convocationId.id));
    }
  }, [dispatch]);

  const handleChange = (_, value) => {
    setHeaderId(value);
  };

  useEffect(() => {
    if (
      convocationActive &&
      formik.values.cte_type === 'embarcador' &&
      confirm
    ) {
      cteConvocation(formik.values.invoices);
    }
  }, [convocationActive, confirm]);

  useEffect(() => {
    if (cte && edit) {
      formik.setFieldValue('cte_type', 'embarcador');

      if (cte.invoices) {
        let invoices = [];
        cte.invoices.forEach((item) => {
          invoices.push({
            ...item,
            cnpj: item?.cpf || item.cnpj,
            volume: parseFloat(item?.volume),
            weight: parseFloat(item?.weight),
          });
        });

        formik.setFieldValue('invoices', invoices);
      }

      setConvocationType(convocationId?.type_convocation);
    } else {
      formik.setFieldValue('cte_type', 'motorista');
      formik.setFieldValue('invoices', []);
    }
  }, [cte]);

  function handleSubmitted(values) {
    const errors = validateRequester(formik.values.owner);

    if (
      formik.values.cte_type === 'embarcador' &&
      formik.values.invoices.length === 0
    ) {
      errors.invoices = 'Nenhuma nota fiscal foi informada';
      formik.setFieldError('invoices', errors.invoices);
    }

    if (Object.keys(errors).length === 0) {
      const data_values = {
        cnpj: values.cnpj,
        collect_city: values.collect_city,
        collect_complement: values.collect_complement,
        collect_corresponding: values.collect_corresponding_name,
        collect_corresponding_email: values.collect_corresponding_email,
        collect_corresponding_phone: values.collect_corresponding_phone,
        collect_lat: values.collect_lat,
        collect_lng: values.collect_lng,
        collect_neighborhood: values.collect_neighborhood,
        collect_number: values.collect_number,
        collect_point: values.collect_point,
        collect_state: values.collect_state,
        collect_zipcode: values.collect_zipcode,
        delivery_city: values.delivery_city,
        delivery_complement: values.delivery_complement,
        delivery_corresponding: values.delivery_corresponding_name,
        delivery_corresponding_email: values.delivery_corresponding_email,
        delivery_corresponding_phone: values.delivery_corresponding_phone,
        delivery_lat: values.delivery_lat,
        delivery_lng: values.delivery_lng,
        delivery_neighborhood: values.delivery_neighborhood,
        delivery_number: values.delivery_number,
        delivery_point: values.delivery_point,
        delivery_state: values.delivery_state,
        delivery_zipcode: values.delivery_zipcode,
        owner: values.owner,
        owner_email: values.owner_email,
        provider_id: providerId.value,
        type: values.cargo_type,
        validity: values.validity,
        value: values.value,
        freight_value: values.freight_value,
        weight: values.weight,
        quantity_delivery: values.invoices.length,
        type_convocation: values.type_convocation,
        type_insurance: values.type_insurance,
        insurance_value: values.insurance_value,
        toll_value: values.toll_value,
        note: values.note,
      };

      if (values.delivery_cpf) {
        data_values.delivery_cpf = values.delivery_cpf;
      }

      if (values.private_trucker_cpf) {
        data_values.driver = values.private_trucker_cpf;
        data_values.driver_name = values.private_trucker;
      }

      if (edit) {
        data_values.id = convocationId.id;
        data_values.created_at = convocationId.created_at;
      }

      setData(data_values);
      setSummary(true);
    }
  }

  const formik = useFormik({
    initialValues: {
      cnpj: convocationId?.cnpj || '',
      owner: convocationId?.owner || '',
      owner_phone: convocationId?.owner_phone || '',
      owner_email: convocationId?.owner_email || '',
      note: convocationId?.note || '',

      type_convocation: convocationId?.type_convocation || 'public',
      type_insurance: convocationId?.type_insurance || '',
      insurance_value: convocationId?.insurance_value || '0',
      payment_responser: convocationId?.payment_responser || '',
      private_trucker: convocationId?.driver_name || '',
      private_trucker_cpf: convocationId?.driver || '',
      collect_point: convocationId?.collect_point || '',
      collect_zipcode: convocationId?.collect_zipcode || '',
      collect_number: convocationId?.collect_number || '',
      collect_neighborhood: convocationId?.collect_neighborhood || '',
      collect_complement: convocationId?.collect_complement || '',
      collect_state: convocationId?.collect_state || '',
      collect_city: convocationId?.collect_city || '',
      collect_lat: convocationId?.collect_lat || '',
      collect_lng: convocationId?.collect_lng || '',
      collect_corresponding_name: convocationId?.collect_corresponding || '',
      collect_corresponding_phone:
        convocationId?.collect_corresponding_phone || '',
      collect_corresponding_email:
        convocationId?.collect_corresponding_email || '',
      toll_value: convocationId?.toll_value || '',
      cte_type: cte?.id && edit ? 'embarcador' : 'motorista',
      cte_key: '',
      delivery_cnpj: '',
      delivery_cpf: '',
      delivery_point: convocationId?.delivery_point || '',
      delivery_zipcode: convocationId?.delivery_zipcode || '',
      delivery_number: convocationId?.delivery_number || '',
      delivery_neighborhood: convocationId?.delivery_neighborhood || '',
      delivery_complement: convocationId?.delivery_complement || '',
      delivery_city: convocationId?.delivery_city || '',
      delivery_state: convocationId?.delivery_state || '',
      delivery_lat: convocationId?.delivery_lat || '',
      delivery_lng: convocationId?.delivery_lng || '',
      delivery_corresponding_name: convocationId?.delivery_corresponding || '',
      delivery_corresponding_phone:
        convocationId?.delivery_corresponding_phone || '',
      delivery_corresponding_email:
        convocationId?.delivery_corresponding_email || '',
      invoices: cte?.id && edit ? cte?.invoices : [],
      romaneio: {
        delivery_cpf: '',
        delivery_point: '',
        delivery_zipcode: '',
        delivery_number: '',
        delivery_neighborhood: '',
        delivery_complement: '',
        delivery_city: '',
        delivery_state: '',
        delivery_corresponding_name: '',
        delivery_corresponding_phone: '',
        delivery_corresponding_email: '',
        weight: '',
        driver_value: '',
        delivery_value: '',
        delivery_size: '',
        payment_responser: '',
        receiver: {
          city: '',
          complement: '',
          email: '',
          name: '',
          neighborhood: '',
          number: '',
          phone: '',
          state: '',
          street: '',
          zipcode: '',
        },
      },
      weight: convocationId?.weight || '',
      cargo_type: convocationId?.type || '',
      validity: convocationId
        ? new Date(convocationId?.validity.seconds * 1000)
        : addDays(new Date(), 1),
      value: convocationId?.value || '',
      freight_value: convocationId?.freight_value || '',
      unload_value: convocationId?.unload_value || '',
    },
    validationSchema: schema,
    validateOnChange: false,
    isValidating: true,
    onSubmit: (values) => handleSubmitted(values),
  });

  const validateRequester = (value) => {
    const errors = {};
    if (!value || value.length === 0) {
      errors.providerId = 'Obrigatório, escolha um solicitante.';
    }

    return errors;
  };

  const cteConvocation = (invoices) => {
    let totalInvoices = 0;
    let transportTotal = parseFloat(
      convocationActive.freight_value
        .substr(3, convocationActive.freight_value.length)
        .replace('.', '')
        .replace(',', '.')
    );
    let balance = transportTotal;
    let weightTotal = 0;

    //Faz o calculo para rateio do frete
    invoices.forEach((item) => {
      totalInvoices += item.nfeValue;
      weightTotal += parseFloat(item.weight);
    });

    const data_cte = {
      convocation_id: convocationActive.id,
      totalInvoices: totalInvoices,
      transportTotal: transportTotal,
      typeCte: 'convocation',
      weightTotal: weightTotal,
      send_tax: parseFloat((transportTotal * 0.0175).toFixed(2)),
      tax: parseFloat((transportTotal * 0.085).toFixed(2)),
      invoices: [],
    };

    invoices.forEach((item, index) => {
      let participation = item.nfeValue / totalInvoices;
      let transportValue = 0;

      if (index === invoices.length - 1) {
        transportValue = Number(balance.toFixed(2));
      } else {
        transportValue = Number((transportTotal * participation).toFixed(2));

        if (balance < transportValue) {
          transportValue = Number(balance.toFixed(2));
        }
      }

      balance -= transportValue;

      data_cte.invoices.push({
        barcode: item.barcode,
        nfeValue: item.nfeValue,
        volume: item.volume,
        weight: item.weight,
        transportValue: parseFloat(transportValue),
        payment_responser: item.payment_responser,
      });

      //valido se é destinatario é pessoa fisica ou juridica
      if (item.hasOwnProperty('receiver')) {
        data_cte.invoices[index].cpf = item.cnpj;
        data_cte.invoices[index].receiver = item.receiver;
      } else {
        data_cte.invoices[index].cnpj = item.cnpj;
      }
    });

    if (data_cte && !edit) {
      dispatch(CtesActions.addCteRequest(data_cte, closeModal));
    } else if (data_cte && edit) {
      dispatch(
        CtesActions.updateCteRequest({ ...data_cte, id: cte.id }, closeModal)
      );
    }
  };

  const closeModal = () => {
    formik.resetForm();
    setProviderId([]);
    history.push({
      pathname: '/convocations',
    });
  };

  const addConvocation = (value) => {
    if (freight < 0) {
      setSummary(false);

      alert.info('O valor final do Frete não pode ser negativo !');

      return;
    }

    if (data?.owner) {
      if (edit) {
        dispatch(
          ConvocationsActions.updateConvocationRequest(data, closeModal)
        );
        setConfirm(true);
      } else {
        dispatch(ConvocationsActions.addConvocationRequest(data, closeModal));
        setConfirm(true);
      }
    }
  };

  const getTruckerId = (cpf) => {
    if (cpf.length === 14) dispatch(TruckerActions.getCpfTruckerRequest(cpf));
  };

  useEffect(() => {
    if (oneTrucker?.id) {
      formik.setFieldValue(`private_trucker_cpf`, oneTrucker.cpf);
      formik.setFieldValue(`private_trucker`, oneTrucker.name);
    }
  }, [oneTrucker]);

  const truckersOptions = [];

  truckers.map((item) =>
    truckersOptions.push({
      value: item.id,
      label: item.name,
      name: item.name,
      cpf: item.cpf,
    })
  );

  return (
    <>
      <Paper
        title={provider ? 'ALTERAR CARGA' : 'INCLUIR CARGA'}
        icon={Images.IconGrayUser}
      />
      <Header>Nova carga</Header>
      <Container>
        <WrapperButtons>
          <Button
            type="secondary"
            label="Cancelar"
            width="130px"
            height="33px"
            onClick={() => history.goBack()}
          />
          <Button
            type="primary"
            label="Salvar"
            width="130px"
            height="33px"
            margin="20px"
            onClick={(values) => formik.handleSubmit(values)}
          />
        </WrapperButtons>

        <WrapperNav>
          <TabNav value={headerId} onChange={handleChange}>
            <TabItem headerId="0" label="DADOS DA CARGA" />
            <TabItem headerId="1" label="DADOS DO SOLICITANTE" />
            <TabItem headerId="2" label="ENDEREÇO COLETA" />
            <TabItem headerId="3" label="ENDEREÇO ENTREGA" />
            <TabItem headerId="4" label="DADOS SEGURO" />
          </TabNav>
        </WrapperNav>
        <form onSubmit={formik.handleSubmit}>
          {headerId === 0 ? (
            <>
              <WrapperSection>
                <LoadInfo
                  formik={formik}
                  setConvocationType={setConvocationType}
                  edit={edit}
                />
              </WrapperSection>

              {convocationType === 'private' ? (
                <Section title="MOTORISTA">
                  <WrapperSection>
                    <WrapperInput>
                      <FieldLabel>CPF</FieldLabel>
                      <InputMask
                        mask="999.999.999-99"
                        label="CPF"
                        name="private_trucker_cpf"
                        placeholder="Ainda não definido"
                        value={formik.values.private_trucker_cpf}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.private_trucker_cpf &&
                          Boolean(formik.errors.private_trucker_cpf)
                        }
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          getTruckerId(e.target.value);
                        }}
                        disabled={edit}
                      >
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </WrapperInput>
                    <WrapperInput>
                      <TextInput
                        id="private_trucker"
                        label="Nome"
                        name="private_trucker"
                        placeholder=""
                        disabled
                        value={formik.values.private_trucker}
                        onChange={formik.handleChange}
                        onBlur={''}
                      />
                    </WrapperInput>
                  </WrapperSection>
                </Section>
              ) : null}
            </>
          ) : null}
          {headerId === 1 ? (
            <ProviderData
              formik={formik}
              providerId={providerId}
              setProviderId={setProviderId}
              edit={edit}
            />
          ) : null}
          {headerId === 2 ? (
            <CollectPoints formik={formik} edit={edit} />
          ) : null}
          {headerId === 3 ? (
            <>
              <DeliveryPoint formik={formik} edit={edit} />
              <Cte formik={formik} edit={edit} />
            </>
          ) : null}
          {headerId === 4 ? (
            <>
              <Insurance formik={formik} edit={edit} />
            </>
          ) : null}
        </form>

        <ModalComp
          data={data}
          open={summary}
          onClose={() => setSummary(false)}
          onClick={() => addConvocation()}
          setFreight={setFreight}
        />
      </Container>
    </>
  );
}

export default PublicConvocation;
