import React from 'react';

import { Container, Box, Description, Img } from './styles';

export default function Status({ status, image, description }) {
  return (
    <Container status={status}>
      <Box>
        <Img src={image} />
      </Box>
      <Description>{description}</Description>
    </Container>
  );
}
