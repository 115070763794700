import { FiCopy } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = styled.form`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;

  position: absolute;
  left: 45%;

  background: #ffffff;

  z-index: 7;
  transform: translateX(-120%);

  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  transition: 500ms ease-in-out;

  h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    margin-left: 4px;
    margin-right: 34px;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 10px;

    img {
      height: 18px;
    }
  }

  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  &.visible {
    display: flex;
    transform: translateX(0);
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .icon-close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    z-index: 99999;

    &.visible {
      display: flex;
      transform: translateX(-50%);
    }
  }
`;

export const WrapperCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const BoxCopy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px 14px;
  margin-bottom: 10px;

  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  h3 {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;

    margin-right: 10px;
  }
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999;

  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    &.backdrop-visible {
      background-color: #ffffff66;
      backdrop-filter: blur(5px);
    }
  }
`;

export const CopyIcon = styled(FiCopy)`
  width: 26px;
  transition: 400ms;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    /* transform: scale(0.95); */
  }
`;
