import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  authCheckedSuccess: null,
  signInRequest: ['email', 'password'],
  signInSuccess: ['userActive'],
  signInFailure: ['error'],
  signOut: null,
  forgotRequest: ['email'],
  forgotSuccess: null,
  forgotFailure: ['error'],
});

export const AuthTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  authChecked: false,
  signedIn: false,
  isLoading: false,
  error: null,
  userActive: null,
});

/* Reducers */

export const checkedSuccess = (state) =>
  state.merge({
    authChecked: true,
    isLoading: false,
  });

export const signInReq = (state) => state.merge({ isLoading: true });
export const signInSuc = (state, { userActive }) =>
  state.merge({ signedIn: true, isLoading: false, userActive });

export const signInFail = (state, { error }) =>
  state.merge({
    isLoading: false,
    error: !!error,
    signedIn: false,
    userActive: null,
  });

export const logout = (state) =>
  state.merge({
    signedIn: false,
    error: null,
  });

export const forgotReq = (state) => state.merge({ isLoading: true });
export const forgotSuc = (state) => state.merge({ isLoading: false });
export const forgotFail = (state, { error }) =>
  state.merge({
    isLoading: false,
    error: !!error,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTH_CHECKED_SUCCESS]: checkedSuccess,
  [Types.SIGN_IN_REQUEST]: signInReq,
  [Types.SIGN_IN_SUCCESS]: signInSuc,
  [Types.SIGN_IN_FAILURE]: signInFail,
  [Types.SIGN_OUT]: logout,
  [Types.FORGOT_REQUEST]: forgotReq,
  [Types.FORGOT_SUCCESS]: forgotSuc,
  [Types.FORGOT_FAILURE]: forgotFail,
});
