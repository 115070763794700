/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, arrayOf, object, string } from 'prop-types';
import { FiRotateCw } from 'react-icons/fi';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import { GetApp } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  ContentText,
  ContainerModal,
  Card,
  Header,
  Close,
  BoxPhoto,
} from './styles';
import Images from 'assets/img';
import { zipcode } from '../../helpers';
import BadgeStatus from './components/BadgeStatus';
import { Row } from 'styles/components';
import ConvocationActions from 'store/ducks/convocations';
import useWindowDimensions from 'hooks/useWindowDimensions';

export default function PercurseLogs({ invoices, reload, onClose }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  function getStatus(status) {
    switch (status) {
      case 'completed':
        return (
          <BadgeStatus
            icon={Images.ChargeStatusFour}
            text="Carga Entregue"
            color="#6ABF62"
          />
        );
      default:
        return (
          <BadgeStatus icon={Images.ChargeStatusZero} text="Não iniciada" />
        );
    }
  }

  const download = (image) => {
    saveAs(image, image.split('?')[0].split('%').pop());
  };

  return (
    <Container>
      <Card>
        <Header>
          Logs de Percurso
          <Close onClick={onClose} />
        </Header>
        <ContainerModal>
          <ContentText>
            <Table
              stickyHeader={width <= 768}
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Data/Hora</TableCell>
                  <TableCell align="left">Lat, Long</TableCell>
                  <TableCell align="left">Local</TableCell>
                  <TableCell align="left">Mapa</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Documentos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices
                  ? invoices.map((invoice) => (
                      <TableRow
                        key={invoice.barcode}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell align="left" />
                        <TableCell align="left">
                          {invoice.data
                            ? `${invoice.data.lat}, ${invoice.data.lng}`
                            : 'Não informado'}
                        </TableCell>
                        <TableCell align="left">
                          {invoice.data
                            ? `${capitalize(invoice.data.street)} ${
                                invoice.data.number
                              } - ${capitalize(
                                invoice.data.city
                              )}/${invoice.data.state.toUpperCase()} ${capitalize(
                                invoice.data.country
                              )} - ${zipcode(invoice.data.zipcode)}${
                                invoice.data.complement !== ''
                                  ? ` (${capitalize(invoice.data.complement)})`
                                  : ''
                              }`
                            : 'Não informado'}
                        </TableCell>
                        <TableCell align="left">
                          <img
                            className="icon-map"
                            onClick={() => {
                              dispatch(
                                ConvocationActions.setCenterMap({
                                  lat: invoice.data.lat,
                                  lng: invoice.data.lng,
                                })
                              );
                              onClose();
                            }}
                            src={Images.LogoMap}
                            alt="Mapa"
                          />
                        </TableCell>
                        <TableCell align="left">
                          {getStatus(invoice.status)}
                        </TableCell>
                        <TableCell align="left">
                          <Row>
                            {invoice.images &&
                              invoice.images.map((image) => (
                                <BoxPhoto
                                  key={image}
                                  onClick={() => download(image)}
                                >
                                  <img src={image} alt="Imagem" />
                                  <div className="download-box">
                                    <GetApp className="icon" />
                                  </div>
                                </BoxPhoto>
                              ))}
                          </Row>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </ContentText>
        </ContainerModal>
      </Card>
    </Container>
  );
}

PercurseLogs.propTypes = {
  onClose: func.isRequired,
};
