import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from 'react-google-maps';

const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControl: false,
  streetViewControl: false,
  scrollwheel: false,
  navigationControl: false,
  scaleControl: false,
  draggable: false,
  gestureHandling: 'greedy',
  disableDoubleClickZoom: true,
  draggableCursor: 'default',
  styles: [
    {
      stylers: [
        { saturation: -100 },
        { gamma: 0.8 },
        { lightness: 4 },
        { visibility: 'on' },
      ],
    },
  ],
};

const Map = withScriptjs(
  withGoogleMap(({ position, positionDest }) => {
    // const [directions, setDirections] = React.useState(null);
    // let delayFactor = 0;

    // React.useEffect(() => {
    //   if (positionDest && positionDest.latitude !== 0)
    //     getDirections(position, positionDest);
    // }, []);

    // async function getDirections(startLoc, destinationLoc) {
    //   const directionService = new window.google.maps.DirectionsService();
    //   directionService.route(
    //     {
    //       origin: new window.google.maps.LatLng(
    //         startLoc.latitude,
    //         startLoc.longitude
    //       ),
    //       destination: new window.google.maps.LatLng(
    //         destinationLoc.latitude,
    //         destinationLoc.longitude
    //       ),
    //       travelMode: window.google.maps.TravelMode.DRIVING,
    //     },
    //     (result, status) => {
    //       // console.log("status", status);
    //       if (status === window.google.maps.DirectionsStatus.OK) {
    //         setDirections(result);
    //       } else if (
    //         status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT
    //       ) {
    //         delayFactor += 0.2;
    //         // if (this.delayFactor <= 10) this.delayFactor = 0.2;
    //         setTimeout(() => {
    //           getDirections(startLoc, destinationLoc);
    //         }, delayFactor * 200);
    //       } else {
    //         console.error(`error fetching directions ${result}`);
    //       }
    //     }
    //   );
    // }

    return positionDest && positionDest.latitude !== 0 ? (
      <GoogleMap
        defaultZoom={7}
        defaultCenter={{ lat: position.latitude, lng: position.longitude }}
      >
        {/* {directions && <DirectionsRenderer directions={directions} />} */}
      </GoogleMap>
    ) : (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: position.latitude, lng: position.longitude }}
        center={{ lat: position.latitude, lng: position.longitude }}
        defaultOptions={defaultMapOptions}
        clickableIcons={false}
      >
        <Marker
          position={{ lat: position.latitude, lng: position.longitude }}
        />
      </GoogleMap>
    );
  })
);

export default Map;
