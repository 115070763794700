import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getProvidersRequest: null,
  getProvidersSuccess: ['providers'],
  getProvidersFailure: ['error'],
  addProviderRequest: ['provider', 'closeModal'],
  addProviderSuccess: ['provider'],
  addProviderFailure: ['error'],
  updateProviderRequest: ['provider', 'closeModal'],
  updateProviderSuccess: ['provider'],
  updateProviderFailure: ['error'],
  removeProviderRequest: ['id'],
  removeProviderSuccess: null,
  removeProviderFailure: ['error'],
});

export const ProvidersTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  providers: [],
  isLoading: false,
  error: null,
  openModal: false,
});

/* Reducers */

const getProvidersRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getProvidersSuccess = (state, { providers }) =>
  state.merge({
    providers,
    isLoading: false,
  });

const getProvidersFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addProviderRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addProviderSuccess = (state, { provider }) =>
  state.merge({
    providers: [...state.providers, provider],
    isLoading: false,
    openModal: false,
  });

const addProviderFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const updateProviderRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updateProviderSuccess = (state, { provider }) =>
  state.merge({
    providers: state.providers.map((item) => {
      if (item.id === provider.id) {
        return provider;
      }
      return item;
    }),
    isLoading: false,
    openModal: false,
  });

const updateProviderFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const removeProviderRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const removeProviderSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const removeProviderFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROVIDERS_REQUEST]: getProvidersRequest,
  [Types.GET_PROVIDERS_SUCCESS]: getProvidersSuccess,
  [Types.GET_PROVIDERS_FAILURE]: getProvidersFailure,
  [Types.ADD_PROVIDER_REQUEST]: addProviderRequest,
  [Types.ADD_PROVIDER_SUCCESS]: addProviderSuccess,
  [Types.ADD_PROVIDER_FAILURE]: addProviderFailure,
  [Types.UPDATE_PROVIDER_REQUEST]: updateProviderRequest,
  [Types.UPDATE_PROVIDER_SUCCESS]: updateProviderSuccess,
  [Types.UPDATE_PROVIDER_FAILURE]: updateProviderFailure,
  [Types.REMOVE_PROVIDER_REQUEST]: removeProviderRequest,
  [Types.REMOVE_PROVIDER_SUCCESS]: removeProviderSuccess,
  [Types.REMOVE_PROVIDER_FAILURE]: removeProviderFailure,
});
