import React from 'react';

import { Container, Header, Description, Title } from './styles';

export default function Box({ title, children }) {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Description>{children}</Description>
    </Container>
  );
}
