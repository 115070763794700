import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import { WrapperMenu, TextIcon, Icon, PopupButton } from './styles';

const ITEM_HEIGHT = 48;

export default function LongMenu({
  handleClick,
  handleClose,
  anchorMenu,
  menuOptions,
}) {
  const open = Boolean(anchorMenu);

  return (
    <WrapperMenu>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorMenu}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '185px',
            borderRadius: '0 5px 5px 5px',
            border: '1px solid #C4C4C4',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
            padding: '5px',
          },
        }}
        transformOrigin={{
          vertical: -40,
          horizontal: -20,
        }}
      >
        {menuOptions.map((option) => (
          <PopupButton
            key={option}
            selected={option === 'Pyxis'}
            onClick={option.onClick}
            disabled={option.disabled}
          >
            <Icon>{option.icon}</Icon>
            <TextIcon>{option.textButton}</TextIcon>
          </PopupButton>
        ))}
      </Menu>
    </WrapperMenu>
  );
}
