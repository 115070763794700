import styled from 'styled-components';
import { MdSearch, MdClear } from 'react-icons/md';

export const Container = styled.div`
  height: 50px;
  background: #fafafa;
  border: 2px solid #efefef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 18px;
  width: 50px;
  padding: 9px 11px;
  cursor: pointer;

  z-index: 0;

  &.active {
    z-index: 9999;
    border: 2px solid #c4c4c4;

    animation-name: active;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  &.closed {
    z-index: 9999;
    border: 2px solid #c4c4c4;

    animation-name: disabled;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  @keyframes active {
    0% {
      border-radius: 50%;
      justify-content: space-between;
      width: 50px;
    }
    50% {
      border-radius: 5px;
      justify-content: space-between;
      width: 50px;
    }
    100% {
      border-radius: 5px;
      justify-content: space-between;
      width: 92%;
    }
  }

  @keyframes disabled {
    0% {
      border-radius: 5px;
      justify-content: space-between;
      width: 88%;
    }
    50% {
      border-radius: 5px;
      justify-content: space-between;
      width: 50px;
    }
    100% {
      border-radius: 50%;
      justify-content: space-between;
      width: 50px;
    }
  }
`;

export const Input = styled.input.attrs({ type: 'text' })`
  background: none;
  border: none;
  color: #7b7b7b;
  font-weight: 600;
  font-size: 14px;
  ::placeholder {
    color: #7f7f7f;
    font-weight: 400;
  }
  flex: 1;
  width: 70%;
  min-width: 60px;

  padding: 0 13px;

  display: none;
  transition: 500ms;
  transition-delay: 500ms;

  &.visible {
    display: inherit;
  }
`;

export const SearchIcon = styled(MdSearch).attrs({ size: '24px' })`
  color: #7b7b7b;
  margin-right: 0;
`;

export const ClearIcon = styled(MdClear).attrs({ size: '24px' })`
  color: #7b7b7b;
  /* margin-left: 13px; */
  cursor: pointer;

  display: none;
  transition: 500ms;
  transition-delay: 500ms;

  &.visible {
    display: inherit;
  }
`;
