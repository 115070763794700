import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getCollabsRequest: null,
  getCollabsSuccess: ['collabs'],
  getCollabsFailure: ['error'],
  addCollabRequest: ['collab', 'closeModal'],
  addCollabSuccess: ['collab'],
  addCollabFailure: ['error'],
  updateCollabRequest: ['collab', 'closeModal'],
  updateCollabSuccess: ['collab'],
  updateCollabFailure: ['error'],
  reactivateCollabRequest: ['email', 'closeModal'],
  reactivateCollabSuccess: null,
  reactivateCollabFailure: ['error'],
  removeCollabRequest: ['id'],
  removeCollabSuccess: ['collabs'],
  removeCollabFailure: ['error'],
});

export const CollabsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  collabs: [],
  isLoading: false,
  error: null,
  openModal: false,
});

/* Reducers */

const getCollabsRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getCollabsSuccess = (state, { collabs }) =>
  state.merge({
    collabs,
    isLoading: false,
  });

const getCollabsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addCollabRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addCollabSuccess = (state, { collab }) =>
  state.merge({
    collabs: [...state.collabs, collab],
    isLoading: false,
    openModal: false,
  });

const addCollabFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const updateCollabRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updateCollabSuccess = (state, { collab }) =>
  state.merge({
    collabs: state.collabs.map((item) => {
      if (item.id === collab.id) {
        return collab;
      }
      return item;
    }),
    isLoading: false,
    openModal: false,
  });

const updateCollabFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const reactivateCollabRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const reactivateCollabSuccess = (state) =>
  state.merge({
    isLoading: false,
    openModal: false,
  });

const reactivateCollabFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const removeCollabRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const removeCollabSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const removeCollabFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COLLABS_REQUEST]: getCollabsRequest,
  [Types.GET_COLLABS_SUCCESS]: getCollabsSuccess,
  [Types.GET_COLLABS_FAILURE]: getCollabsFailure,
  [Types.ADD_COLLAB_REQUEST]: addCollabRequest,
  [Types.ADD_COLLAB_SUCCESS]: addCollabSuccess,
  [Types.ADD_COLLAB_FAILURE]: addCollabFailure,
  [Types.UPDATE_COLLAB_REQUEST]: updateCollabRequest,
  [Types.UPDATE_COLLAB_SUCCESS]: updateCollabSuccess,
  [Types.UPDATE_COLLAB_FAILURE]: updateCollabFailure,
  [Types.REACTIVATE_COLLAB_REQUEST]: reactivateCollabRequest,
  [Types.REACTIVATE_COLLAB_SUCCESS]: reactivateCollabSuccess,
  [Types.REACTIVATE_COLLAB_FAILURE]: reactivateCollabFailure,
  [Types.REMOVE_COLLAB_REQUEST]: removeCollabRequest,
  [Types.REMOVE_COLLAB_SUCCESS]: removeCollabSuccess,
  [Types.REMOVE_COLLAB_FAILURE]: removeCollabFailure,
});
