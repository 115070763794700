import styled from 'styled-components';
import ReactLoading from 'react-loading';

export const Container = styled.div`
  width: 90vw;
  max-width: 518px;
`;

export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;
`;

export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const Line = styled.div`
  background-color: #e5e5e5;
  height: 1px;
  width: 100%;
`;

export const ButtonDownload = styled.a`
  cursor: pointer;
`;

export const BoxQrCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 223px;
  height: 407px;
  background: #122d58;
  border-radius: 8px;
  opacity: 1;
`;

export const ImgLogo = styled.img`
  width: 84px;
  height: 34px;
  margin: 20px 0 11px;
`;

export const BoxInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Label = styled.label`
  color: white;
  font-size: 10px;
`;

export const Strong = styled.strong`
  color: #fff28d;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat';
`;
