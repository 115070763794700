/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import mapStyle from '../../../../components/MapConvocationDetails/mapStyle.json';

export const MapConvocation = withScriptjs(
  withGoogleMap(() => {
    const mapRef = useRef(null);
    return (
      <>
        <GoogleMap
          zoom={4}
          ref={mapRef}
          center={{
            lat: -22.818791,
            lng: -43.06855,
          }}
          options={{ styles: mapStyle }}
        />
      </>
    );
  })
);
