import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { useDispatch, useSelector } from 'react-redux';

import ErrorModal from 'pages/Convocations/components/ErrorModal';
import LoadingModal from 'pages/Convocations/components/LoadingModal';
import api from 'services/api';
import ConvocationActions from 'store/ducks/convocations';

import mapStyle from './mapStyle.json';

export const MapWithAMarker = withScriptjs(
  withGoogleMap(
    ({
      paths,
      cte,
      invoices,
      convocation,
      specificInvoice,
      loading,
      getPaths,
      handleOpenPopover,
      handleOpenMarker,
      truckerLocation,
      closeModalError,
    }) => {
      const dispatch = useDispatch();
      const { modalDelete, centerMap, messageModal, acceptDelete } =
        useSelector((state) => state.convocations);
      const { cte: cteRedux } = useSelector((state) => state.cte);
      const { actualMarkerTrucker } = useSelector((state) => state.trucker);

      const prevMarkersRef = useRef([]);
      const mapRef = useRef(null);
      const noTruckerMapRef = useRef(null);
      const polylineRef = React.useRef(null);

      const [firstLocation, setFirstLocation] = useState(null);
      const [locationTrucker, setLocationTrucker] = useState(null);

      // const [directions, setDirections] = useState(null);
      const [locations, setLocations] = useState(paths);
      const [markers, setMarkers] = useState(invoices);

      const [activeInvoice, setActiveInvoice] = useState(null);

      function calcDist(location1, location2) {
        let R = 6371;
        let dLat = (location2.lat - location1.lat) * (Math.PI / 180);
        let dLon = (location2.lng - location1.lng) * (Math.PI / 180);

        let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(location1.lat * (Math.PI / 180)) *
            Math.cos(location2.lat * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;

        return d;
      }

      const checkIfHaveActiveInvoice = () => {
        if (invoices) {
          const orderedInvoices = invoices.sort(
            (inv1, inv2) =>
              calcDist(
                {
                  lat: inv1?.data?.lat || inv1?.receiver?.lat,
                  lng: inv1?.data?.lng || inv1?.receiver?.lng,
                },
                {
                  lat: truckerLocation.latitude,
                  lng: truckerLocation.longitude,
                }
              ) -
              calcDist(
                {
                  lat: inv2?.data?.lat || inv2?.receiver?.lat,
                  lng: inv2?.data?.lng || inv2?.receiver?.lat,
                },
                {
                  lat: truckerLocation.latitude,
                  lng: truckerLocation.longitude,
                }
              )
          );

          if (cte.activeInvoice == null) {
            const data = {
              ...cte,
              activeInvoice:
                orderedInvoices[0] != null ? orderedInvoices[0] : null,
              updated_at: new Date(),
            };

            api
              .firestore()
              .collection('ctes')
              .doc(cte.id)
              .update(data)
              .then((resp) => console.log('respFirebase', resp))
              .catch((err) => console.log('errFirebase', err));
          }
        }
      };

      const getLastLocation = () => {
        if (locationTrucker == null) {
          setFirstLocation({
            lat: truckerLocation.latitude,
            lng: truckerLocation.longitude,
          });
        }
        if (truckerLocation) {
          setLocationTrucker({
            lat: truckerLocation.latitude,
            lng: truckerLocation.longitude,
          });
        }
      };

      function invoiceMarker(invoice) {
        if (invoice) {
          if (invoice.signature && invoice.status === 'completed') {
            return {
              url: 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green-checked.png?alt=media&token=29585f05-c839-45a5-a084-f825ac6061aa',
              scaledSize: {
                width: 28,
                height: 32,
              },
            };
          } else {
            return {
              url: 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green.png?alt=media&token=d3ce9acc-edff-473b-900c-43ab09540daf',
              scaledSize: {
                width: 22,
                height: 32,
              },
            };
          }
        } else {
          return {
            url: 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green.png?alt=media&token=d3ce9acc-edff-473b-900c-43ab09540daf',
            scaledSize: {
              width: 22,
              height: 32,
            },
          };
        }
      }

      function convocationMarker(type) {
        if (convocation) {
          if (
            convocation.status === 'arrived_requester' ||
            convocation.status === 'in_progress_res' ||
            convocation.status === 'completed'
          ) {
            return {
              url:
                convocation.status === 'completed' && type === 'delivery'
                  ? 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green-checked.png?alt=media&token=29585f05-c839-45a5-a084-f825ac6061aa'
                  : type === 'delivery'
                  ? 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green.png?alt=media&token=d3ce9acc-edff-473b-900c-43ab09540daf'
                  : 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-blue-checked.png?alt=media&token=01b411bd-5429-42ca-a71b-bc0b38834223',
              scaledSize: {
                width: 28,
                height: 32,
              },
            };
          } else {
            return {
              url:
                type === 'collect'
                  ? 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-blue.png?alt=media&token=509e1c65-3633-42ee-87b3-4bbf89098219'
                  : 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green.png?alt=media&token=d3ce9acc-edff-473b-900c-43ab09540daf',
              scaledSize: {
                width: 22,
                height: 32,
              },
            };
          }
        } else {
          return {
            url:
              type === 'collect'
                ? 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-blue.png?alt=media&token=509e1c65-3633-42ee-87b3-4bbf89098219'
                : 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin-green.png?alt=media&token=d3ce9acc-edff-473b-900c-43ab09540daf',
            scaledSize: {
              width: 22,
              height: 32,
            },
          };
        }
      }

      useEffect(() => {
        if (invoices != null && cte != null && cte.activeInvoice == null) {
          checkIfHaveActiveInvoice();
        }
      }, []);

      useEffect(() => {
        getLastLocation();
      }, [truckerLocation]);

      useEffect(() => {
        if (cte != null && cte.activeInvoice != null) {
          setActiveInvoice(cte.activeInvoice);
        }
      }, [cte]);

      useEffect(() => {
        dispatch(ConvocationActions.setCenterMap(null));
        if (paths && paths.length > 0) {
          setMarkers(invoices);
          setLocations(paths);
          if (invoices != null) {
            setActiveInvoice(invoices[0]);
          }
        } else {
          setLocationTrucker(null);
          setLocations([]);
          setMarkers([]);
        }
      }, [paths, invoices]);

      return (
        <>
          {truckerLocation ? (
            !loading ? (
              locations != null && truckerLocation != null ? (
                <>
                  <GoogleMap
                    zoom={6}
                    ref={mapRef}
                    center={
                      centerMap ??
                      (truckerLocation &&
                        truckerLocation.latitude &&
                        truckerLocation.longitude)
                        ? {
                            lat: truckerLocation.latitude,
                            lng: truckerLocation.longitude,
                          }
                        : {
                            lat: convocation.collect_lat,
                            lng: convocation.collect_lng,
                          }
                    }
                    options={{ styles: mapStyle }}
                  >
                    <>
                      {locationTrucker && (
                        <Marker
                          position={locationTrucker}
                          onClick={(e) => {
                            handleOpenPopover(e);
                          }}
                          animation={window.google.maps.Animation.DROP}
                          icon={{
                            url: 'https://firebasestorage.googleapis.com/v0/b/manifestoonline-dd357.appspot.com/o/makers%2Fpin_mol.png?alt=media&token=a3562480-ac22-4879-bef1-a92c90975b57',
                            scaledSize: {
                              width: 21.82,
                              height: 42,
                            },
                          }}
                        />
                      )}
                      {convocation && (
                        <Marker
                          position={{
                            lat: convocation.collect_lat,
                            lng: convocation.collect_lng,
                          }}
                          styles={{ zIndex: '99999999' }}
                          animation={window.google.maps.Animation.DROP}
                          icon={convocationMarker('collect')}
                        />
                      )}
                      {!specificInvoice &&
                        convocation &&
                        convocation.collect_lat !== convocation.delivery_lat &&
                        convocation.collect_lng !==
                          convocation.delivery_lng && (
                          <Marker
                            position={{
                              lat: convocation.delivery_lat,
                              lng: convocation.delivery_lng,
                            }}
                            styles={{ zIndex: '99999999' }}
                            animation={window.google.maps.Animation.DROP}
                            icon={convocationMarker('delivery')}
                          />
                        )}
                      {specificInvoice ? (
                        <Marker
                          position={{
                            lat:
                              specificInvoice?.data?.lat ||
                              specificInvoice?.receiver?.lat,
                            lng:
                              specificInvoice?.data?.lng ||
                              specificInvoice?.receiver?.lng,
                          }}
                          styles={{ zIndex: '99999999' }}
                          onClick={(e) => {
                            handleOpenMarker(e, specificInvoice);
                          }}
                          animation={window.google.maps.Animation.DROP}
                          icon={invoiceMarker(specificInvoice)}
                        />
                      ) : (
                        invoices &&
                        invoices.map((invoice) => (
                          <Marker
                            position={{
                              lat: invoice?.data?.lat || invoice?.receiver?.lat,
                              lng: invoice?.data?.lng || invoice?.receiver?.lng,
                            }}
                            styles={{ zIndex: '99999999' }}
                            onClick={(e) => {
                              handleOpenMarker(e, invoice);
                            }}
                            animation={window.google.maps.Animation.DROP}
                            icon={invoiceMarker(invoice)}
                          />
                        ))
                      )}
                    </>
                  </GoogleMap>
                </>
              ) : (
                <>
                  <ErrorModal
                    reload={() => getPaths()}
                    onClose={closeModalError}
                  />
                </>
              )
            ) : (
              <>
                <LoadingModal reload={() => {}} onClose={closeModalError} />
              </>
            )
          ) : (
            <>
              <GoogleMap
                ref={noTruckerMapRef}
                options={{ styles: mapStyle }}
                defaultZoom={4}
                defaultCenter={{
                  lat: -22.818791,
                  lng: -43.06855,
                }}
              />
            </>
          )}
        </>
      );
    }
  )
);
