import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { useDispatch, useSelector } from 'react-redux';
import { BoxCopy, Container, CopyIcon, WrapperCenter } from './styles';
import ConvocationActions from 'store/ducks/convocations';
import Images from 'assets/img';
import { CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { FiCopy } from 'react-icons/fi';
import { useAlert } from 'react-alert';

export default function ModalShare() {
  const dispatch = useDispatch();
  const { modalShare, temporaryLink } = useSelector(
    (state) => state.convocations
  );
  const alert = useAlert();
  const url = 'https://admin.mol.log.br/temp/';

  useEffect(() => {
    console.log(temporaryLink);
  }, [temporaryLink]);

  return (
    <Container className={modalShare ? 'visible' : ''}>
      <div className="header">
        <img src={Images.IconShare} alt="" />
        <h3>Compartilhar Carga</h3>
        <CloseIcon
          className="icon-close"
          onClick={() => {
            dispatch(ConvocationActions.closeShareModal());
          }}
        />
      </div>

      {temporaryLink ? (
        <>
          <BoxCopy>
            <h3>{url + temporaryLink.id}</h3>
            <CopyIcon
              onClick={() => {
                navigator.clipboard.writeText(url + temporaryLink.id);
                alert.show('Link copiado com sucesso!', {
                  position: 'bottom center',
                });
              }}
            />
          </BoxCopy>
          <div className="actions">
            <WhatsappShareButton
              style={{
                borderRadius: '1000px',
                width: '54px',
                height: '54px',
                overflow: 'hidden',
                marginRight: '10px',
              }}
              url={url + temporaryLink.id}
            >
              <WhatsappIcon style={{ width: '54px', height: '54px' }} />
            </WhatsappShareButton>
            <EmailShareButton
              style={{
                borderRadius: '1000px',
                width: '54px',
                height: '54px',
                overflow: 'hidden',
                marginRight: '10px',
              }}
              url={url + temporaryLink.id}
            >
              <EmailIcon style={{ width: '54px', height: '54px' }} />
            </EmailShareButton>
            <FacebookMessengerShareButton
              style={{
                borderRadius: '1000px',
                width: '54px',
                height: '54px',
                overflow: 'hidden',
                marginRight: '10px',
              }}
              url={url + temporaryLink.id}
            >
              <FacebookMessengerIcon
                style={{ width: '54px', height: '54px' }}
              />
            </FacebookMessengerShareButton>
            <TelegramShareButton
              style={{
                borderRadius: '1000px',
                width: '54px',
                height: '54px',
                overflow: 'hidden',
              }}
              url={url + temporaryLink.id}
            >
              <TelegramIcon style={{ width: '54px', height: '54px' }} />
            </TelegramShareButton>
          </div>
        </>
      ) : (
        <WrapperCenter>
          <CircularProgress style={{ width: '32px' }} />
        </WrapperCenter>
      )}
    </Container>
  );
}
