import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';

export const Container = styled.div`
  background-color: #fff;
  height: calc(100vh - 64px);
  width: 307px;
  transition: 200ms;
  padding: 24px 8px 0 16px;
  overflow-x: hidden;

  position: absolute;
  bottom: -24px;
  right: 0;

  z-index: 9999;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.1), inset -1px 0px 0px #f1f2f5;

  transition: 600ms;
  transform: translateX(500px);

  &.opened {
    transform: translateX(0);
  }
`;

export const CirclePhoto = styled.div`
  background-color: #efefef;
  width: 140px;
  height: 140px;
  padding: 7.37px;

  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7b7b7b;

  margin-bottom: 8px;

  .img {
    width: 100%;
    height: 100%;
    font-size: 50px;
    border-radius: 50%;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .status-trucker {
    width: 30px;
    height: 30px;
    background-color: #6abf62;
    border-radius: 50%;
    position: absolute;
    right: 7px;
    bottom: 7px;
    z-index: 99;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 56px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #7b7b7b;

  & .icon {
    cursor: pointer;
    color: #7b7b7b;
    margin-right: 8px;
  }
`;

export const WrapperCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
`;

export const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 24px;

  & + & {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 24px;
  }
`;

export const ListFiles = styled(Scrollbars)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const GridFiles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
`;

export const SubTitleGrey = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #7b7b7b;

  margin-bottom: 8px;
  margin-top: 24px;
`;

export const LabelAndValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7b7b7b;

    margin-bottom: 4px;
  }

  .value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7b7b7b;
  }
`;
