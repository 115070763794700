import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
`;

export const TableWrapper = styled(PerfectScrollbar)`
  max-height: ${(props) => props.maxheight};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 10px 750px;
`;

export const Body = styled.tbody``;

export const Row = styled.tr`
  height: 46px;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;

export const Cell = styled.td`
  padding: 5px 5px 5px 5px;
  color: #7b7b7b;
  /* color: ${(props) => props.color || '#7b7b7b'}; */

  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;

  :first-child {
    /* padding-left: 30px; */
  }
  :last-child {
    /* padding-left: 10px; */
  }
`;

export const HeadCell = styled.th`
  padding: 5px 5px;
  color: #8091ab;

  font-size: 12px;
  font-weight: 700;
  text-align: left;

  :first-child {
    /* padding-left: 10px; */
    /* margin-right: auto; */
  }
  :nth-child(3) {
    /* padding-right: 30px; */
  }
  :last-child {
    margin-right: 35px;
  }
`;

export const WrapperNavigator = styled.div`
  display: flex;
  width: 85%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  padding-right: 30px;
`;

export const WrapperEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const EmptyText = styled.span`
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 600;
`;
