import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth || '100%'};
  min-width: ${(props) => props.minWidth || '100%'};
  margin: ${(props) => props.margin};
`;

export const Label = styled.span`
  color: #7b7b7b;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 6px;
  white-space: nowrap;
`;

export const Content = styled.select`
  height: 32px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
  background: #cecece;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 98% 50%;
  width: 100%;
  option {
    transition: ease-in 1100ms;
    background: #fff;
  }

  ${(props) =>
    props.type === 'primary'
      ? `
      border: 1px solid #122d58;
      padding: 5px;`
      : `

      padding: 0 12px;
      background-color: #fff;
      border: 1px solid ${props.error ? '#F27160' : '#ebebeb'};
      ${props.error ? 'box-shadow: 0 3px 6px #f2716044;' : ''}
      color: #7b7b7b;
      font-size: 12px;
      font-weight: 600;

      ::placeholder {
        font-weight: 400;
      }

      transition: 200ms
      `}
`;
