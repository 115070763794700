import { IconButton, Menu } from '@material-ui/core';
import React, { forwardRef } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { blue, red } from '@material-ui/core/colors';
import Images from 'assets/img';
import TextIcon from './components/TextIcon';
import { Container, MenuButtons } from './styles';
import BadgeStatus from './components/BadgeStatus';
import {
  capitalize,
  getWeight,
  getImgType,
  formatDate,
  getValue,
} from '../../helpers';
import PopupMenu from './components/PopupMenu';
import { Tooltip } from '@mui/material';

export default function ChargeItem({
  selected,
  selectedUser,
  viewConvocation,
  data,
  tooglePercurseLogs,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getStatus(status) {
    switch (status) {
      case 'in_progress_req':
        return (
          <BadgeStatus
            icon={Images.ChargeStatusOne}
            color="rgba(137, 70, 160, 0.4)"
            title="Motorista encontrado"
          />
        );
      case 'arrived_requester':
        return (
          <BadgeStatus
            icon={Images.ChargeStatusTwo}
            color="rgba(240, 151, 2, 0.4)"
            title="Carga encontrada"
          />
        );
      case 'in_progress_res':
        return (
          <BadgeStatus
            icon={Images.ChargeStatusThree}
            color="rgba(112, 180, 242, 0.4)"
            title="A caminho"
          />
        );
      case 'completed':
        return (
          <BadgeStatus
            icon={Images.ChargeStatusFive}
            color="#2A5BA8"
            title="Carga finalizada"
          />
        );
      default:
        return (
          <BadgeStatus icon={Images.ChargeStatusZero} title="Não iniciada" />
        );
    }
  }

  return (
    <Container>
      <div className="imgs">
        {getStatus(data.status)}
        <Tooltip title={data.type}>
          <img src={getImgType(data.type)} alt="" />
        </Tooltip>
      </div>
      <div className="informations">
        <div className="type-name">
          <h4>{data.requester}</h4>
          <h5>{`${capitalize(data.collect_city)}, ${data.collect_state}`}</h5>
        </div>
        <div className="info">
          <TextIcon
            icon={Images.IconDate}
            text={formatDate(data.created_at) + ' -'}
          />
          {data.status !== 'initial' ? (
            <div className="driver">
              <TextIcon icon={Images.IconDriver} text={data.driver_name} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="menu">
        <PopupMenu
          tooglePercurseLogs={tooglePercurseLogs}
          viewConvocation={viewConvocation}
          data={data}
          selected={selected}
          anchorEl={anchorEl}
          open={open}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      </div>
    </Container>
  );
}
