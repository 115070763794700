import React, { useRef, useState, useEffect } from 'react';
import { arrayOf, object } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Images from 'assets/img';
import Paper from 'components/Paper';
import Button from 'components/Button';
import TruckerActions from 'store/ducks/trucker';

import BasicInfoCard from './components/BasicInfoCard';
import DocumentSession from './components/DocumentSession';
import ReportModal from './components/ReportModal';

import { Container, Body, Padding } from './styles';

function TruckerData({ location }) {
  const dispatch = useDispatch();
  const { oneTrucker: data } = useSelector((state) => state.trucker);
  const { id } = location.state;
  const formRef = useRef(null);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [trucker, setTrucker] = useState(null);

  useEffect(() => {
    if (data.id === id) {
      setTrucker(data);
    }
  }, [data]);

  useEffect(() => {
    dispatch(TruckerActions.getOneTruckerRequest(id));
  }, [dispatch, id]);

  return (
    <Container>
      <Paper
        title="DADOS DO CAMINHONEIRO"
        subtitle="CAMINHONEIROS/"
        icon={Images.IconGrayUser}
        rightContent={
          <Grid container spacing={1} style={{ maxWidth: 360 }}>
            <Grid item sm={6} xs={12}>
              <Button
                label="REALIZAR APROVAÇÃO"
                onClick={() => formRef.current.handleSubmit()}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                label="REPORTAR PENDÊNCIA"
                type="secondary"
                onClick={() => setOpenReportModal(true)}
              />
            </Grid>
          </Grid>
        }
      >
        <Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BasicInfoCard trucker={trucker} />
            </Grid>
            <Grid item xs={12}>
              <Padding>
                <DocumentSession id={id} formRef={formRef} />
              </Padding>
            </Grid>
          </Grid>
        </Body>
      </Paper>
      <ReportModal
        id={trucker?.id}
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
      />
    </Container>
  );
}

export default TruckerData;

TruckerData.propTypes = {
  location: arrayOf(object).isRequired,
};
