/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { bool, func, arrayOf, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Formik } from 'formik';
import { FormControlLabel } from '@material-ui/core';
import { Label } from 'components/TextInput/styles';
import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';
import { Row, Column, Icon } from 'styles/components';
import Images from 'assets/img';
import CollabsActions from 'store/ducks/collaborators';
import { schema } from './schemaValidation';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  WrapperImage,
  Avatar,
  ImageText,
  WrapperInput,
  InputFile,
  Loading,
  ReactiveText,
  RadioControl,
  Error,
} from './styles';

function ModalComp({ data, open, onClose }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.collabs);
  const [avatar, setAvatar] = useState(null);
  const [email, setEmail] = useState('');
  const [profile, setProfile] = useState(null);

  const inputFile = useRef(null);
  const type_profile = [
    {
      value: 'administrative',
      label: 'Administrativo',
    },
    {
      value: 'agent',
      label: 'Agente',
    },

    {
      value: 'commercial',
      label: 'Comercial',
    },
    {
      value: 'provider',
      label: 'Embarcador',
    },
  ];

  function verifyEmail() {
    const user = data.find((item) => item.email === email);
    return user ? (user.status === 0 ? 'inactive' : 'active') : 0;
  }

  function handleSubmitted(values) {
    if (verifyEmail() === 'inactive') {
      dispatch(CollabsActions.reactivateCollabRequest(values.email, onClose));
    } else {
      dispatch(
        CollabsActions.addCollabRequest(
          {
            ...values,
            profile,
            avatar,
          },
          onClose
        )
      );
    }
  }

  return (
    <Modal open={open} onClose={onClose} title="ADICIONAR COLABORADOR">
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            email: '',
            password: '',
            profile: '',
          }}
          onSubmit={(values) => handleSubmitted(values)}
          validationSchema={schema(verifyEmail())}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <WrapperBody>
                <WrapperContent>
                  {verifyEmail() !== 'inactive' && (
                    <>
                      <Row margin="0 0 20px 0">
                        <WrapperImage>
                          {avatar ? (
                            <Avatar src={URL.createObjectURL(avatar)} />
                          ) : (
                            <Icon height="35px" src={Images.IconCamera} />
                          )}
                        </WrapperImage>
                        <Column>
                          <ImageText>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore
                          </ImageText>
                          <Button
                            label="ENVIAR FOTO"
                            onClick={() => inputFile.current.click()}
                          />
                        </Column>
                      </Row>
                      <WrapperInput>
                        <TextField
                          color="secondary"
                          placeholder="Nome"
                          name="name"
                          helperText={touched.name ? errors.name : ''}
                          error={touched.name && Boolean(errors.name)}
                          value={values.name}
                          onChange={handleChange}
                        />
                      </WrapperInput>
                    </>
                  )}
                  <WrapperInput>
                    <TextField
                      color="secondary"
                      placeholder="E-mail"
                      name="email"
                      helperText={touched.email ? errors.email : ''}
                      error={touched.email && Boolean(errors.email)}
                      value={values.email}
                      onChange={(e) => {
                        handleChange(e);
                        setEmail(e.target.value);
                      }}
                    />
                  </WrapperInput>
                  {verifyEmail() === 'inactive' && (
                    <WrapperInput>
                      <ReactiveText>
                        Esse e-mail já consta na nossa base de dados, mas está
                        desativado. Deseja reativá-lo?
                      </ReactiveText>
                    </WrapperInput>
                  )}
                  {verifyEmail() !== 'inactive' && (
                    <WrapperInput>
                      <TextField
                        color="secondary"
                        placeholder="Senha"
                        type="password"
                        name="password"
                        helperText={touched.password ? errors.password : ''}
                        error={touched.password && Boolean(errors.password)}
                        value={values.password}
                        onChange={handleChange}
                      />
                    </WrapperInput>
                  )}

                  <WrapperInput>
                    <Label>Perfil</Label>
                    <RadioGroup
                      aria-label="profile"
                      name="profile"
                      value={values.profile}
                      helperText={touched.profile ? errors.profile : ''}
                      error={touched.profile && Boolean(errors.profile)}
                      onChange={(e) => {
                        handleChange(e);
                        setProfile(e.target.value);
                      }}
                    >
                      {type_profile.map((item) => (
                        <FormControlLabel
                          value={item.value}
                          control={<RadioControl />}
                          label={item.label}
                        />
                      ))}

                      <Error>
                        {touched.profile && Boolean(errors.profile)
                          ? errors.profile
                          : null}
                      </Error>
                    </RadioGroup>
                  </WrapperInput>
                </WrapperContent>
              </WrapperBody>
              <WrapperBottom>
                <BottomText onClick={onClose}>Cancelar</BottomText>
                {isLoading ? (
                  <Loading />
                ) : (
                  <BottomText onClick={handleSubmit}>
                    {verifyEmail() === 'inactive' ? 'Reativar' : 'Salvar'}
                  </BottomText>
                )}
              </WrapperBottom>
            </>
          )}
        </Formik>
      </Container>
      <InputFile
        ref={inputFile}
        onChange={(res) => setAvatar(res.target.files[0])}
      />
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  data: arrayOf(object).isRequired,
};
