import styled from 'styled-components';
import { MdAddCircle, MdChat } from 'react-icons/md';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const WrapperAdd = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`;

export const AddIcon = styled(MdAddCircle).attrs({ size: '44px' })`
  color: #122d58;
  cursor: pointer;
`;

export const ChatIcon = styled(MdChat).attrs({ size: '20px' })`
  color: #7b7b7b;
  margin-right: 24px;
`;
