import cep from 'cep-promise';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { arrayOf, object, element } from 'prop-types';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Selector from 'components/Selector';
import TextInput from 'components/TextInput';
import DatePicker from 'components/DatePicker';
import Table from 'components/Table';
import TruckerActions from 'store/ducks/trucker';
import IssuersActions from 'store/ducks/issuers';

import { schema } from './schemaValidation';
import Modal from '../AdditionalDocModal';
import SeeImage from '../SeeImage';
import ApproveModal from '../ApproveModal';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Header,
  HeaderOptions,
  HeaderIndicator,
  Body,
  Title,
  AddDocText,
  EyeIcon,
  WrapperSmallImage,
  SmallImage,
  WrapperContent,
  WrapperInput,
} from './styles';

function DocumentSession({ id, formRef }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [approveModalData, setApproveModalData] = useState({
    open: false,
    values: {},
  });
  const [modalData, setModalData] = useState(null);
  const { oneTrucker: data } = useSelector((state) => state.trucker);
  const [trucker, setTrucker] = useState(null);

  const { issuers } = useSelector((state) => state.issuers);
  const data_issuer = !issuers
    ? []
    : issuers?.map((item) => ({
        ...item,
      }));

  const options = [];
  const options_name = [];

  for (const item of data_issuer) {
    if (options_name.length == 0) {
      options.push({ text: 'Selecione', value: '' });
      options_name.push({ text: 'Selecione', value: '' });
    }

    options.push({
      text: item.cnpj,
      value: item.cnpj,
    });

    options_name.push({
      text: item.social_reason,
      value: item.social_reason,
    });
  }
  const tableData = data.documents
    ? data.documents.map((item) => ({
        actions: '',
        id: data.id,
        document: item.url_document,
        holding_document: item.url_holding_document,
        plate: item.plate,
        chassi: item.chassi,
        renavam: item.renavam,
        type: item.type,
        cpfCnpj: item.cpfCnpj,
        rntc: item.rntc,
        uf_plate: item.uf_plate || '',
        capacity_kg: item.capacity_kg || '',
        capacity_m3: item.capacity_m3 || '',
        tara_kg: item.tara_kg || '',
        type_rotated: item.type_rotated || '',
        type_bodywork: item.type_bodywork || '',
        qtd_axes: item.qtd_axes || '',
      }))
    : null;

  function handleSubmitted(values) {
    setApproveModalData({ open: true, values });
  }

  function driverTabError(touched, errors) {
    const keys = ['cnh', 'driverCategory', 'licenseDue', 'transport_type'];

    for (const item of keys) {
      if (touched[item] && Boolean(errors[item])) {
        return true;
      }
    }
    return false;
  }

  function vehicleTabError(touched, errors) {
    const keys = ['plate', 'chassi', 'renavam', 'cpfCpnj', 'rntc'];

    for (const item of keys) {
      if (touched[item] && Boolean(errors[item])) {
        return true;
      }
    }
    return false;
  }

  function insuranceTabError(touched, errors) {
    const keys = [
      'firstlicense',
      'birth_date',
      'mother_name',
      'sex_trucker',
      'zipcode',
      'point',
      'neighborhood',
      'city',
      'state',
    ];

    for (const item of keys) {
      if (touched[item] && Boolean(errors[item])) {
        return true;
      }
    }
    return false;
  }

  const handleCepChange = (cepRequested, setFieldValue, setFieldError) => {
    cep(cepRequested)
      .then((cepResponse) => {
        setFieldValue(`point`, cepResponse.street);
        setFieldValue(`neighborhood`, cepResponse.neighborhood);
        setFieldValue(`city`, cepResponse.city);
        setFieldValue(`state`, cepResponse.state);
      })
      .catch(() => {
        setFieldError(`zipcode`, 'CEP não encontrado');
      });
  };

  useEffect(() => {
    if (data?.id === id) {
      setTrucker(data);
    }
  }, [data]);

  useEffect(() => {
    dispatch(TruckerActions.getOneTruckerRequest(id));
    dispatch(IssuersActions.getIssuersRequest());
  }, [dispatch]);

  return (
    <Container>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          cnh: trucker ? trucker.cnh : '',
          driverCategory: trucker ? trucker.driverCategory : null,
          licenseDue: trucker?.licenseDue
            ? trucker.licenseDue?.seconds * 1000
            : null,
          plate: trucker ? trucker.plate : '',
          chassi: trucker ? trucker.chassi : '',
          renavam: trucker ? trucker.renavam : '',
          cpfCpnj: trucker ? trucker.cpfCpnj : '',
          rntc: trucker ? trucker.rntc : '',
          transport_type: trucker?.transport_type
            ? trucker.transport_type
            : 'etc',
          uf_plate: trucker ? trucker.uf_plate : '',
          capacity_kg: trucker?.capacity_kg ? trucker.capacity_kg : '',
          capacity_m3: trucker?.capacity_m3 ? trucker.capacity_m3 : '',
          tara_kg: trucker?.tara_kg ? trucker.tara_kg : '',
          type_rotated: trucker?.type_rotated ? trucker.type_rotated : '',
          type_bodywork: trucker?.type_bodywork ? trucker.type_bodywork : '',
          qtd_axes: trucker ? trucker.qtd_axes : '',
          rg: trucker ? trucker?.rg : '',
          security_number: trucker ? trucker.security_number : '',
          firstlicense: trucker?.firstlicense
            ? trucker.firstlicense?.seconds * 1000
            : null,
          birth_date: trucker?.birth_date
            ? trucker.birth_date?.seconds * 1000
            : null,
          mother_name: trucker?.mother_name ? trucker.mother_name : '',
          sex_trucker: trucker?.sex_trucker ? trucker.sex_trucker : '',
          zipcode: trucker ? trucker.zipcode : '',
          point: trucker ? trucker.point : '',
          neighborhood: trucker ? trucker.neighborhood : '',
          complement: trucker?.complement ? trucker.complement : '',
          city: trucker ? trucker.city : '',
          state: trucker ? trucker.state : '',
          date_risky: trucker?.date_risky
            ? trucker.date_risky?.seconds * 1000
            : null,
          trucker_status_riscky: trucker?.trucker_status_riscky
            ? trucker.trucker_status_riscky
            : '',
          description_analysis: trucker?.description_analysis
            ? trucker.description_analysis
            : '',
          brand_vehicle: trucker?.brand_vehicle ? trucker.brand_vehicle : '',
          model_vehicle: trucker?.model_vehicle ? trucker.model_vehicle : '',
          issuer: trucker?.issuer ? trucker.issuer : '',
          social_reason: trucker?.social_reason ? trucker.social_reason : '',
          limit_anticipate: trucker?.limit_anticipate
            ? trucker.limit_anticipate
            : null,
        }}
        onSubmit={(values) => handleSubmitted(values)}
        validationSchema={schema}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          setFieldError,
        }) => (
          <>
            <Header>
              <HeaderOptions
                active={selectedTab === 0}
                onClick={() => {
                  setSelectedTab(0);
                }}
                error={driverTabError(touched, errors)}
              >
                <p>DOCUMENTOS DO CONDUTOR</p>
                <HeaderIndicator
                  right={selectedTab}
                  error={
                    (selectedTab === 0 && driverTabError(touched, errors)) ||
                    (selectedTab === 1 && vehicleTabError(touched, errors)) ||
                    (selectedTab === 2 && insuranceTabError(touched, errors))
                  }
                />
              </HeaderOptions>

              <HeaderOptions
                active={selectedTab === 1}
                onClick={() => {
                  setSelectedTab(1);
                }}
                error={vehicleTabError(touched, errors)}
              >
                <p>DOCUMENTOS DO VEÍCULO</p>
              </HeaderOptions>

              <HeaderOptions
                active={selectedTab === 2}
                onClick={() => {
                  setSelectedTab(2);
                }}
                error={insuranceTabError(touched, errors)}
              >
                <p>GERENCIADORA DE RISCO</p>
              </HeaderOptions>
            </Header>
            <Body>
              {selectedTab !== 2 ? (
                <Title>
                  Fotos do documento do {selectedTab ? 'veículo' : 'motorista'}
                </Title>
              ) : null}

              {selectedTab === 0 ? (
                <>
                  <WrapperContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item lg={3} xs>
                            <SeeImage
                              image={trucker?.url_cnh}
                              text="Foto da CNH do condutor"
                            />
                          </Grid>
                          <Grid item lg={3} xs>
                            <SeeImage
                              image={trucker?.url_holding_cnh}
                              text="Foto do motorista com a CNH"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={8} md={12}>
                        <Grid container spacing={4}>
                          <Grid item md={3}>
                            <TextInput
                              label="CNH do motorista"
                              name="cnh"
                              error={touched.cnh && Boolean(errors.cnh)}
                              value={values.cnh}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <WrapperInput>
                              <Selector
                                label="Categoria da habilitação"
                                defaulOption="Selecione a categoria"
                                options={[
                                  { text: 'A', value: 'A' },
                                  { text: 'B', value: 'B' },
                                  { text: 'AB', value: 'AB' },
                                  { text: 'C', value: 'C' },
                                  { text: 'D', value: 'D' },
                                  { text: 'E', value: 'E' },
                                ]}
                                type="secondary"
                                name="driverCategory"
                                error={
                                  touched.driverCategory &&
                                  Boolean(errors.driverCategory)
                                }
                                value={values.driverCategory}
                                onChange={handleChange}
                              />
                            </WrapperInput>
                          </Grid>
                          <Grid item md={3}>
                            <DatePicker
                              name="licenseDue"
                              error={
                                touched.licenseDue && Boolean(errors.licenseDue)
                              }
                              label="Validade da habilitação"
                              selectedDate={values.licenseDue}
                              handleDateChange={(e) => {
                                setFieldValue('licenseDue', e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={8} md={12}>
                        <Grid container spacing={4}>
                          <Grid item lg={3} xs>
                            <WrapperInput>
                              <Selector
                                label="Tipo Transportador"
                                defaulOption="Selecione o Tipo"
                                options={[
                                  { text: 'TAC', value: 'tac' },
                                  { text: 'ETC', value: 'etc' },
                                  { text: 'CTC', value: 'ctc' },
                                ]}
                                type="secondary"
                                name="transport_type"
                                error={
                                  touched.transport_type &&
                                  Boolean(errors.transport_type)
                                }
                                value={values.transport_type}
                                onChange={handleChange}
                              />
                            </WrapperInput>
                          </Grid>
                          <Grid item md={3}>
                            <WrapperInput>
                              <Selector
                                label="Emitente"
                                options={options}
                                type="secondary"
                                name="issuer"
                                value={values.issuer}
                                onChange={(e) => {
                                  handleChange(e);
                                  const [issuer] = data_issuer.filter(
                                    (item) =>
                                      item.cnpj === e.currentTarget.value
                                  );

                                  if (issuer) {
                                    setFieldValue(
                                      'social_reason',
                                      issuer.social_reason
                                    );
                                  } else {
                                    setFieldValue('social_reason', '');
                                  }
                                }}
                              />
                            </WrapperInput>
                          </Grid>

                          <Grid item md={3}>
                            <WrapperInput>
                              <Selector
                                label="Nome Emitente"
                                options={options_name}
                                type="secondary"
                                name="social_reason"
                                value={values.social_reason}
                                onChange={(e) => {
                                  handleChange(e);
                                  const [issuer] = data_issuer.filter(
                                    (item) =>
                                      item.social_reason ===
                                      e.currentTarget.value
                                  );

                                  if (issuer) {
                                    setFieldValue('issuer', issuer.cnpj);
                                  } else {
                                    setFieldValue('issuer', '');
                                  }
                                }}
                              />
                            </WrapperInput>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </WrapperContent>

                  <WrapperContent>
                    <Grid item xs={8} md={12}>
                      <Grid container spacing={4}>
                        <Grid item md={3}>
                          <TextInput
                            label="Limite Antecipação"
                            name="limit_anticipate"
                            type="number"
                            value={values.limit_anticipate}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </WrapperContent>
                </>
              ) : selectedTab === 1 ? (
                <>
                  <WrapperContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Grid container spacing={4}>
                            <Grid item lg={3} xs>
                              <SeeImage
                                image={trucker.url_document}
                                text="Foto do Renavan do veículo"
                              />
                            </Grid>
                            <Grid item lg={3} xs>
                              <SeeImage
                                image={trucker.url_holding_document}
                                text="Foto do motorista com o Renavan"
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <WrapperContent>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Placa do veículo"
                                  name="plate"
                                  error={touched.plate && Boolean(errors.plate)}
                                  value={values.plate}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Chassi"
                                  name="chassi"
                                  error={
                                    touched.chassi && Boolean(errors.chassi)
                                  }
                                  value={values.chassi}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Renavam"
                                  name="renavam"
                                  type="number"
                                  error={
                                    touched.renavam && Boolean(errors.renavam)
                                  }
                                  value={values.renavam}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </WrapperContent>
                        <WrapperContent>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="CNPJ/CPF do proprietário"
                                  name="cpfCpnj"
                                  error={
                                    touched.cpfCpnj && Boolean(errors.cpfCpnj)
                                  }
                                  value={values.cpfCpnj}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="RNTC"
                                  name="rntc"
                                  type="number"
                                  error={touched.rntc && Boolean(errors.rntc)}
                                  value={values.rntc}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="UF da Placa"
                                  name="uf_plate"
                                  helperText={
                                    touched.uf_plate ? errors.uf_plate : ''
                                  }
                                  error={Boolean(errors.uf_plate)}
                                  value={values.uf_plate}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </WrapperContent>

                        <WrapperContent>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Capacidade (KG)"
                                  name="capacity_kg"
                                  type="number"
                                  helperText={
                                    touched.capacity_kg
                                      ? errors.capacity_kg
                                      : ''
                                  }
                                  error={Boolean(errors.capacity_kg)}
                                  value={values.capacity_kg}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Capacidade (M3)"
                                  name="capacity_m3"
                                  type="number"
                                  helperText={
                                    touched.capacity_m3
                                      ? errors.capacity_m3
                                      : ''
                                  }
                                  error={Boolean(errors.capacity_m3)}
                                  value={values.capacity_m3}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Tara (KG)"
                                  name="tara_kg"
                                  type="number"
                                  helperText={
                                    touched.tara_kg ? errors.tara_kg : ''
                                  }
                                  error={Boolean(errors.tara_kg)}
                                  value={values.tara_kg}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </WrapperContent>

                        <WrapperContent>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item md={4} xs>
                                <WrapperInput>
                                  <Selector
                                    label="Tipo de Rodado"
                                    defaulOption="Selecione o rodado"
                                    options={[
                                      { text: 'Truck', value: 'Truck' },
                                      { text: 'Toco', value: 'Toco' },
                                      {
                                        text: 'Cavalo Mecânico',
                                        value: 'Cavalo Mecânico',
                                      },
                                      { text: 'VAN', value: 'VAN' },
                                      {
                                        text: 'Utilitário',
                                        value: 'Utilitário',
                                      },
                                      { text: 'Outros', value: 'Outros' },
                                    ]}
                                    type="secondary"
                                    name="type_rotated"
                                    error={
                                      touched.type_rotated &&
                                      Boolean(errors.type_rotated)
                                    }
                                    value={values.type_rotated}
                                    onChange={handleChange}
                                  />
                                </WrapperInput>
                              </Grid>
                              <Grid item md={4} xs>
                                <WrapperInput>
                                  <Selector
                                    label="Tipo de Carroceria"
                                    defaulOption="Selecione a carroceria"
                                    options={[
                                      {
                                        text: 'Não aplicável',
                                        value: 'Não aplicável',
                                      },
                                      { text: 'Aberta', value: 'Aberta' },
                                      {
                                        text: 'Fechada/Baú',
                                        value: 'Fechada/Baú',
                                      },
                                      { text: 'Granelera', value: 'Granelera' },
                                      {
                                        text: 'Porta Container',
                                        value: 'Porta Container',
                                      },
                                      { text: 'Sider', value: 'Sider' },
                                    ]}
                                    type="secondary"
                                    name="type_bodywork"
                                    error={
                                      touched.type_bodywork &&
                                      Boolean(errors.type_bodywork)
                                    }
                                    value={values.type_bodywork}
                                    onChange={handleChange}
                                  />
                                </WrapperInput>
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Quantidade de Eixos"
                                  name="qtd_axes"
                                  type="number"
                                  helperText={
                                    touched.qtd_axes ? errors.qtd_axes : ''
                                  }
                                  error={Boolean(errors.qtd_axes)}
                                  value={values.qtd_axes}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </WrapperContent>

                        <WrapperContent>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Marca"
                                  name="brand_vehicle"
                                  helperText={
                                    touched.brand_vehicle
                                      ? errors.brand_vehicle
                                      : ''
                                  }
                                  error={Boolean(errors.brand_vehicle)}
                                  value={values.brand_vehicle}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Modelo"
                                  name="model_vehicle"
                                  helperText={
                                    touched.model_vehicle
                                      ? errors.model_vehicle
                                      : ''
                                  }
                                  error={Boolean(errors.model_vehicle)}
                                  value={values.model_vehicle}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item md={4} xs>
                                <TextInput
                                  label="Ano"
                                  name="year_vehicle"
                                  type="number"
                                  helperText={
                                    touched.year_vehicle
                                      ? errors.year_vehicle
                                      : ''
                                  }
                                  error={Boolean(errors.year_vehicle)}
                                  value={values.year_vehicle}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </WrapperContent>
                      </Grid>
                    </Grid>
                  </WrapperContent>
                  {tableData && (
                    <>
                      <AddDocText>DOCUMENTOS ADICIONAIS</AddDocText>
                      <Table
                        maxHeight="200px"
                        noNavigator
                        columns={[
                          { label: 'Ações', id: 'actions' },
                          { label: 'Documento', id: 'document' },
                          { label: 'Placa do Veículo', id: 'plate' },
                          { label: 'Chassi', id: 'chassi' },
                          { label: 'Renavam', id: 'renavam' },
                          { label: 'Tipo', id: 'type' },
                          { label: 'CNPJ/CPF do proprietário', id: 'cpfCnpj' },
                          { label: 'RNTC', id: 'rntc' },
                        ]}
                        activePage={0}
                        totalPages={2}
                        data={tableData}
                        customComponent={{
                          actions: (e, item) => (
                            <EyeIcon
                              onClick={() => {
                                setOpenModal(true);
                                setModalData(item);
                              }}
                            />
                          ),
                          document: (e) => (
                            <WrapperSmallImage>
                              <Zoom>
                                <SmallImage src={e} />
                              </Zoom>
                            </WrapperSmallImage>
                          ),
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <WrapperContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item lg={3} xs>
                            <DatePicker
                              name="firstlicense"
                              error={
                                touched.firstlicense &&
                                Boolean(errors.firstlicense)
                              }
                              label="Data 1º Habilitacao"
                              selectedDate={values.firstlicense}
                              handleDateChange={(e) => {
                                setFieldValue('firstlicense', e);
                              }}
                            />
                          </Grid>

                          <Grid item lg={3} xs>
                            <TextInput
                              label="Codigo segurança da CNH"
                              name="security_number"
                              type="number"
                              error={
                                touched.security_number &&
                                Boolean(errors.security_number)
                              }
                              value={values.security_number}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item lg={3} xs>
                            <TextInput
                              label="RG"
                              name="rg"
                              type="number"
                              error={touched.rg && Boolean(errors.rg)}
                              value={values.rg}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </WrapperContent>

                  <WrapperContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item lg={3} xs>
                            <TextInput
                              label="Nome da Mae"
                              name="mother_name"
                              error={
                                touched.mother_name &&
                                Boolean(errors.mother_name)
                              }
                              value={values.mother_name}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item lg={3} xs>
                            <DatePicker
                              name="birth_date"
                              error={
                                touched.birth_date && Boolean(errors.birth_date)
                              }
                              label="Data Nascimento"
                              selectedDate={values.birth_date}
                              handleDateChange={(e) => {
                                setFieldValue('birth_date', e);
                              }}
                            />
                          </Grid>

                          <Grid item md={3} xs>
                            <WrapperInput>
                              <Selector
                                label="Sexo"
                                defaulOption="Selecione o Sexo"
                                options={[
                                  { text: 'Selecione', value: '' },
                                  { text: 'Masculino', value: 'masculine' },
                                  { text: 'Feminino', value: 'feminine' },
                                ]}
                                type="secondary"
                                name="sex_trucker"
                                error={
                                  touched.sex_trucker &&
                                  Boolean(errors.sex_trucker)
                                }
                                value={values.sex_trucker}
                                onChange={handleChange}
                              />
                            </WrapperInput>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </WrapperContent>

                  <WrapperContent>
                    <Grid container spacing={4}>
                      <Grid item lg={3} xs>
                        <TextInput
                          label="CEP"
                          type="number"
                          name="zipcode"
                          error={touched.zipcode && Boolean(errors.zipcode)}
                          value={values.zipcode}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldError('zipcode', '');
                            setFieldError('point', '');
                            setFieldError('neighborhood', '');
                            setFieldError('city', '');
                            setFieldError('state', '');
                          }}
                          onBlur={(e) =>
                            handleCepChange(
                              e.target.value,
                              setFieldValue,
                              setFieldError
                            )
                          }
                        />
                      </Grid>
                      <Grid item lg={3} xs>
                        <TextInput
                          label="Endereço"
                          name="point"
                          placeholder=""
                          error={touched.point && Boolean(errors.point)}
                          value={values.point}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldError('point', '');
                          }}
                          onBlur={''}
                        />
                      </Grid>
                      <Grid item lg={3} xs>
                        <TextInput
                          label="Complemento"
                          name="complement"
                          placeholder=""
                          value={values.complement}
                          onChange={handleChange}
                          onBlur={''}
                        />
                      </Grid>
                    </Grid>
                  </WrapperContent>

                  <WrapperContent>
                    <Grid container spacing={4}>
                      <Grid item lg={3} xs>
                        <TextInput
                          label="Bairro"
                          name="neighborhood"
                          placeholder=""
                          error={
                            touched.neighborhood && Boolean(errors.neighborhood)
                          }
                          value={values.neighborhood}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldError('neighborhood', '');
                          }}
                          onBlur={''}
                        />
                      </Grid>

                      <Grid item lg={3} xs>
                        <TextInput
                          label="Cidade"
                          name="city"
                          placeholder=""
                          error={touched.city && Boolean(errors.city)}
                          value={values.city}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldError('city', '');
                          }}
                          onBlur={''}
                        />
                      </Grid>
                      <Grid item lg={3} xs>
                        <TextInput
                          label="Estado"
                          name="state"
                          placeholder=""
                          error={touched.state && Boolean(errors.state)}
                          value={values.state}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldError('state', '');
                          }}
                          onBlur={''}
                        />
                      </Grid>
                    </Grid>
                  </WrapperContent>
                  <WrapperContent>
                    <Grid item xs={12}>
                      <Grid container spacing={4}>
                        <Grid item lg={3}>
                          <DatePicker
                            name="date_risky"
                            label="Data da Consulta"
                            selectedDate={values.date_risky}
                            handleDateChange={(e) => {
                              setFieldValue('date_risky', e);
                            }}
                            disabled
                          />
                        </Grid>

                        <Grid item md={3}>
                          <WrapperInput>
                            <Selector
                              label="Risco Condutor"
                              defaulOption="Sem avaliação"
                              options={[
                                { text: 'Aprovado', value: 'aproved' },
                                { text: 'Reprovado', value: 'reproved' },
                              ]}
                              type="secondary"
                              name="trucker_status_riscky"
                              value={values.trucker_status_riscky}
                              onChange={handleChange}
                              disabled
                            />
                          </WrapperInput>
                        </Grid>

                        <Grid item lg={3} xs>
                          <TextInput
                            label="Retorno Analise"
                            name="description_analysis"
                            placeholder=""
                            value={values.description_analysis}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldError('state', '');
                            }}
                            onBlur={''}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </WrapperContent>
                </>
              )}
            </Body>
          </>
        )}
      </Formik>
      {modalData && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={modalData}
        />
      )}
      <ApproveModal
        open={approveModalData.open}
        onConfirm={async () => {
          await dispatch(
            TruckerActions.setDocumentDataRequest(
              { ...approveModalData.values, status: 1 },
              () => {
                setApproveModalData({ open: false, values: {} });
                history.push('./trucker');
              }
            )
          );
        }}
        onClose={() => setApproveModalData({ open: false, values: {} })}
      />
    </Container>
  );
}

export default DocumentSession;

DocumentSession.propTypes = {
  data: arrayOf(object).isRequired,
  formRef: element.isRequired,
};
