import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import api, { apiLogin } from 'services/api';

import CollabsActions from '../ducks/collaborators';
import AuthActions from '../ducks/auth';

export function* getCollabs() {
  try {
    const data = yield api
      .firestore()
      .collection('users')
      .where('role', '==', 'admin')
      .get();

    const users = data.docs.map((item) => item.data());

    yield put(CollabsActions.getCollabsSuccess(users));
  } catch (err) {
    yield put(CollabsActions.getCollabsFailure(err));
  }
}

export function* addCollab({ collab, closeModal }) {
  try {
    const { name, email, password, profile, avatar } = collab;

    let uri;

    const user = yield apiLogin
      .auth()
      .createUserWithEmailAndPassword(email, password);

    if (avatar !== null) {
      const ext = avatar.name.split('.').pop(); // Extract image extension
      const filename = `profileImg.${ext}`;

      yield apiLogin.storage().ref(`${user.user.uid}/${filename}`).put(avatar);

      uri = yield apiLogin
        .storage()
        .ref(`${user.user.uid}/${filename}`)
        .getDownloadURL();
    }

    const uploadData = {
      id: user.user.uid,
      name,
      email,
      profile,
      role: 'admin',
      status: 1,
      created_at: new Date(),
      updated_at: new Date(),
    };

    if (avatar !== null) {
      uploadData.profileImg = uri;
    }

    yield apiLogin
      .firestore()
      .collection('users')
      .doc(user.user.uid)
      .set(uploadData);

    uploadData.created_at = {
      seconds: Math.round(uploadData.created_at.getTime() / 1000),
    };

    yield put(CollabsActions.addCollabSuccess(uploadData));
    closeModal();
  } catch (err) {
    if (err.code === 'auth/email-already-in-use') {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro ao cadastrar',
          message: 'E-mail já cadastrado',
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro ao cadastrar',
          message: 'Tente novamente',
        })
      );
    }

    yield put(CollabsActions.addCollabFailure(err));
  }
}
export function* updateCollab({ collab, closeModal }) {
  try {
    const { name, email, password, avatar } = collab;

    const userActive = yield select((state) => state.auth.userActive);

    let uri;
    const user = api.auth().currentUser;

    user.updatePassword(password);

    if (avatar !== null && typeof avatar !== 'string') {
      const ext = avatar.name.split('.').pop(); // Extract image extension
      const filename = `profileImg.${ext}`;

      yield api.storage().ref(`${user.uid}/${filename}`).put(avatar);

      uri = yield api.storage().ref(`${user.uid}/${filename}`).getDownloadURL();
    }

    const uploadData = {
      id: user.uid,
      name,
      email,
      role: 'admin',
      status: 1,
      profileImg: userActive.profileImg,
      created_at: userActive.created_at,
      updated_at: new Date(),
    };

    if (avatar !== null && typeof avatar !== 'string') {
      uploadData.profileImg = uri;
    }

    yield api.firestore().collection('users').doc(user.uid).set(uploadData);

    yield put(AuthActions.signInSuccess(uploadData));
    yield put(CollabsActions.updateCollabSuccess(uploadData));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'PERFIL',
        message: 'Atualizado com sucesso!',
      })
    );
    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar o perfil',
        message: 'Tente novamente',
      })
    );

    yield put(CollabsActions.updateCollabFailure(err));
  }
}

export function* reactivateCollab({ email, closeModal }) {
  try {
    const users = yield api
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get();

    const collab = users.docs[0].data();

    yield api
      .firestore()
      .collection('users')
      .doc(collab.id)
      .update({ status: 1 });

    yield put(CollabsActions.reactivateCollabSuccess());
    yield put(CollabsActions.getCollabsRequest());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Usuário reativado',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao reativar usuário',
      })
    );
    yield put(CollabsActions.reactivateCollabFailure(err));
  }
}

export function* removeCollab({ id }) {
  try {
    yield api.firestore().collection('users').doc(id).update({ status: 0 });

    yield put(CollabsActions.removeCollabSuccess());
    yield put(CollabsActions.getCollabsRequest());
  } catch (err) {
    yield put(CollabsActions.removeCollabFailure(err));
  }
}
