import ReactLoading from 'react-loading';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
export const Icon = styled.image``;
export const CustomText = styled.text`
  font-size: 12px;
  color: #7b7b7b;
  margin-left: 15px;
`;
export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  margin-bottom: 7px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BooleanWrapper = styled.div`
  display: ${(props) => (props.isShown ? 'flex' : 'none')};
  padding: 15px 0;
  width: 100%;
  max-width: 400px;

  flex-direction: column;
  margin-right: 20px;
  align-items: flex-start;
`;

export const BoxMap = styled.div`
  width: 100%;
  min-width: 260px;
  max-width: 480px;
  height: 260px;
  margin: 27px 30px 0 20px;
`;
export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const BoxSearch = styled.div`
  height: 32px;
  padding: 0 12px;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ImgFlag = styled.img`
  height: 18px;
  object-fit: contain;
`;
