import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import store from 'store';

import Routes from './routes';
import GlobalStyle from './styles/global';
import { Alert, Button, Container } from './styles';
import { Row } from './styles/components';

import './config/ReactrotronConfig';
import 'normalize.css';

const theme = createTheme(
  {
    typography: {
      fontFamily: ['Montserrat'].join(','),
    },
  },
  ptBR
);

const alertOptions = {
  position: positions.TOP_LEFT,
  timeout: 5000,
  offset: '90px',
  transition: transitions.SCALE,
  onClose: () => {},
};

const AlertTemplate = ({ style, options, message, close }) => (
  <Container>
    <Alert style={style}>
      <Row>
        {options.type === 'info'}
        {options.type === 'success' && ':)'}
        {options.type === 'error' && ':('}
        {message}
      </Row>
      <Row>
        <Button onClick={close}>Fechar </Button>
      </Row>
    </Alert>
  </Container>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Toaster />
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Routes />
          <GlobalStyle />
          <ReduxToastr
            closeOnToastrClick
            position="top-right"
            preventDuplicates
            progressBar
            timeOut={4000}
          />
        </AlertProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
