import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth};
`;

export const Label = styled.span`
  color: #7b7b7b;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#122d58')};
  border-radius: 5px;
  resize: vertical;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  font-family: 'Montserrat';
  min-height: 120px;
  max-height: 600px;

  ::placeholder {
    color: #7b7b7b;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat';
  }
`;
