import styled from 'styled-components';
import ReactLoading from 'react-loading';

export const Content = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 425px) {
    padding: 0 5vw;
  }
`;

export const TitleBox = styled.h3`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #122d58;
  margin-bottom: 20px;
`;

export const InfoBox = styled.div`
  width: 50vw;
  padding: 72px;
  background-color: #ffffff;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 75vw;
    padding: 42px;
  }

  @media (max-width: 425px) {
    width: 95vw;
    padding: 22px;
  }
`;

export const Txt = styled.p`
  font-family: 'Montserrat';
  font-weight: ${(props) => props.fontWeight ?? '400'};
  font-size: ${(props) => props.fontSize ?? '16px'};
  line-height: ${(props) => props.lineHeight ?? '22px'};

  margin: ${(props) => props.margin ?? '0'};

  text-align: ${(props) => props.textAlign ?? 'center'};

  strong {
    font-weight: 600;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  }

  color: #000000;
`;

export const WrapperBottom = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;
`;

export const WrapperLogo = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 40px;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
  width: 80,
  type: 'spinningBubbles',
})``;
