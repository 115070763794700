/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import store from 'store';
import { useDispatch } from 'react-redux';

import TempLayout from 'pages/_layouts/temporary';
import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';
import AuthActions from 'store/ducks/auth';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const { signedIn, userActive } = store.getState().auth;

  if (isPrivate && (!signedIn || userActive?.profile !== 'administrative')) {
    dispatch(AuthActions.signOut())
    return <Redirect to="/" />;
  }

  if (signedIn && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  // eslint-disable-next-line no-nested-ternary
  const Layout = signedIn
    ? DefaultLayout
    : pathname.split('/')[1] === 'temp' || pathname.split('/')[1] === 'rate'
      ? TempLayout
      : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout rate={pathname.split('/')[1] === 'rate'}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
