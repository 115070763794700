import ReactLoading from 'react-loading';
import styled from 'styled-components';
import InputCurrency from 'react-currency-input';

export const ImgFlag = styled.img`
  height: 18px;
  object-fit: contain;
`;

export const Container = styled.form`
  display: flex;

  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
export const FixedAdressDiv = styled.div`
  display: flex;
  align-items: center;
  max-width: 700px;
  width: 100%;
  height: 50px;
  padding: 10px 0 15px 15px;
  border-radius: 5px;
  color: #7b7b7b;
  font-size: 12px;
  line-height: 18px;
  background: #efefef;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Icon = styled.image``;
export const CustomText = styled.text`
  font-size: 12px;
  color: #7b7b7b;
  margin-left: 15px;
`;
export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  margin-bottom: 7px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxMap = styled.div`
  width: 100%;
  min-width: 260px;
  margin: 100px 30px 0 20px;
  max-width: 480px;
  height: 260px;
`;
export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const BoxSearch = styled.div`
  height: 32px;
  padding: 0 12px;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Currency = styled(InputCurrency)`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;
