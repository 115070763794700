/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import Paper from 'components/Paper';
import Images from 'assets/img';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import { Row } from 'styles/components';
import Modal from './components/Modal';
import TripActions from 'store/ducks/trip';

import {
  Container,
  WrapperTable,
  EyeIcon,
  WhatsappIcon,
  BlockIcon,
} from './styles';

function Trip() {
  const dispatch = useDispatch();
  const { ctes } = useSelector((state) => state.trip);
  const [search, setSearch] = useState('');
  const [opened, setOpened] = useState(false);
  const [item, setItem] = useState(null);

  const resolved = ctes.map((item, index) => ({
    actions: item.id,
    id: index,
    status: item.status,
    number: item.invoices.length,
    nfe_value: Number(item.transportTotal),
    taxes: Number(item.tax),
    emisson_tax: Number(item.tax),
    transport_value: Number(item.send_tax),
    due_date: item.created_at ? item.created_at?.seconds * 1000 : '',
    total_value: Number(item.transportTotal),
    manifest_number: item.id,
    emission_date: item.emiterDate?.seconds * 1000,
    invoices: item.invoices,
  }));

  const filteredData = resolved.filter((item) =>
    String(item.manifest_number).toUpperCase().includes(search.toUpperCase())
  );

  function cancelPayment(id) {
    dispatch(TripActions.cancelPaymentRequest(id));
  }

  useEffect(() => {
    dispatch(TripActions.getCtesRequest());
  }, [dispatch]);

  return (
    <Container>
      <Paper
        title="Viagem"
        icon={Images.IconPaper}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              { label: 'Ações', id: 'actions' },
              { label: 'ID', id: 'id' },
              { label: 'Status', id: 'status' },
              { label: 'Nº de Notas', id: 'number' },
              { label: 'Valor das NFe’s', id: 'nfe_value' },
              { label: 'Impostos', id: 'taxes' },
              { label: 'Tx de Emissão', id: 'emisson_tax' },
              { label: 'Valor do Frete', id: 'transport_value' },
              { label: 'Vencimento', id: 'due_date' },
              { label: 'Valor total', id: 'total_value' },
              { label: 'Número do manifesto', id: 'manifest_number' },
              { label: 'Data de emissão', id: 'emission_date' },
            ]}
            data={filteredData}
            customComponent={{
              actions: (e, item) => (
                <Row>
                  <EyeIcon
                    onClick={() => {
                      setOpened(true);
                      setItem(item);
                    }}
                  />
                  <WhatsappIcon />
                  <BlockIcon onClick={() => cancelPayment(e)} />
                </Row>
              ),

              status: (e) =>
                e === 0
                  ? 'Pendente'
                  : e === 1
                  ? 'Emitido'
                  : e === 2
                  ? 'Processando'
                  : 'Cancelado',
              nfe_value: (e) =>
                e.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
              taxes: (e) =>
                e.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
              emisson_tax: (e) =>
                e.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
              transport_value: (e) =>
                e.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
              due_date: (e) => (e ? format(new Date(e), 'dd/MM/yyyy') : '-'),
              total_value: (e) =>
                e.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
              emission_date: (e) =>
                e ? format(new Date(e), 'dd/MM/yyyy') : '-',
            }}
          />
        </WrapperTable>
      </Paper>
      <Modal open={opened} onClose={() => setOpened(false)} data={item} />
    </Container>
  );
}

export default Trip;
