import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getTruckersRequest: null,
  getTruckersSuccess: ['truckers'],
  getTruckersFailure: ['error'],
  getOneTruckerRequest: ['id'],
  getOneTruckerSuccess: ['oneTrucker'],
  getOneTruckerFailure: ['error'],
  getCpfTruckerRequest: ['cpf'],
  getCpfTruckerSuccess: ['oneTrucker'],
  getCpfTruckerFailure: ['error'],
  reportPendingRequest: ['id', 'body', 'closeModal'],
  reportPendingSuccess: null,
  reportPendingFailure: ['error'],
  updateAditionalDocumentRequest: [
    'values',
    'url_document',
    'id',
    'modalClose',
  ],
  updateAditionalDocumentSuccess: null,
  updateAditionalDocumentFailure: ['error'],
  setDocumentDataRequest: ['values', 'closeModal'],
  setDocumentDataSuccess: null,
  setDocumentDataFailure: ['error'],
  setSelectedTrucker: ['selectedTrucker'],
  setActualMarkerTrucker: ['truckerLocation'],
  openTruckerDrawer: null,
  closeTruckerDrawer: null,
});

export const TruckerTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  truckers: [],
  oneTrucker: {},
  selectedTrucker: {},
  truckerDrawer: false,
  actualMarkerTrucker: null,
  isLoading: false,
  error: null,
});

/* Reducers */

const getTruckersRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getTruckersSuccess = (state, { truckers }) =>
  state.merge({
    truckers,
    isLoading: false,
  });

const getTruckersFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getOneTruckerRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getOneTruckerSuccess = (state, { oneTrucker }) =>
  state.merge({
    oneTrucker,
    isLoading: false,
  });

const getOneTruckerFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getCpfTruckerRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getCpfTruckerSuccess = (state, { oneTrucker }) =>
  state.merge({
    oneTrucker,
    isLoading: false,
  });

const getCpfTruckerFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const reportPendingRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const reportPendingSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const reportPendingFailure = (state) =>
  state.merge({
    isLoading: false,
  });

const updateAditionalDocumentRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updateAditionalDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const updateAditionalDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
  });

const setDocumentDataRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const setDocumentDataSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const setDocumentDataFailure = (state) =>
  state.merge({
    isLoading: false,
  });

const setSelectedTrucker = (state, { selectedTrucker }) =>
  state.merge({
    selectedTrucker,
  });

const setActualMarkerTrucker = (state, { truckerLocation }) =>
  state.merge({
    setActualMarkerTrucker: truckerLocation,
  });

const openTruckerDrawer = (state, { selectedTrucker }) =>
  state.merge({
    truckerDrawer: true,
  });

const closeTruckerDrawer = (state) =>
  state.merge({
    truckerDrawer: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TRUCKERS_REQUEST]: getTruckersRequest,
  [Types.GET_TRUCKERS_SUCCESS]: getTruckersSuccess,
  [Types.GET_TRUCKERS_FAILURE]: getTruckersFailure,
  [Types.GET_ONE_TRUCKER_REQUEST]: getOneTruckerRequest,
  [Types.GET_ONE_TRUCKER_SUCCESS]: getOneTruckerSuccess,
  [Types.GET_ONE_TRUCKER_FAILURE]: getOneTruckerFailure,
  [Types.GET_ONE_TRUCKER_REQUEST]: getCpfTruckerRequest,
  [Types.GET_ONE_TRUCKER_SUCCESS]: getCpfTruckerSuccess,
  [Types.GET_ONE_TRUCKER_FAILURE]: getCpfTruckerFailure,
  [Types.REPORT_PENDING_REQUEST]: reportPendingRequest,
  [Types.REPORT_PENDING_SUCCESS]: reportPendingSuccess,
  [Types.REPORT_PENDING_FAILURE]: reportPendingFailure,
  [Types.UPDATE_ADITIONAL_DOCUMENT_REQUEST]: updateAditionalDocumentRequest,
  [Types.UPDATE_ADITIONAL_DOCUMENT_SUCCESS]: updateAditionalDocumentSuccess,
  [Types.UPDATE_ADITIONAL_DOCUMENT_FAILURE]: updateAditionalDocumentFailure,
  [Types.SET_DOCUMENT_DATA_REQUEST]: setDocumentDataRequest,
  [Types.SET_DOCUMENT_DATA_SUCCESS]: setDocumentDataSuccess,
  [Types.SET_DOCUMENT_DATA_FAILURE]: setDocumentDataFailure,
  [Types.SET_SELECTED_TRUCKER]: setSelectedTrucker,
  [Types.OPEN_TRUCKER_DRAWER]: openTruckerDrawer,
  [Types.CLOSE_TRUCKER_DRAWER]: closeTruckerDrawer,
  [Types.SET_ACTUAL_MARKER_TRUCKER]: setActualMarkerTrucker,
});
