/* eslint-disable no-console */
/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
import { Check } from '@material-ui/icons';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Step, StepLabel, Stepper } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import Images from 'assets/img';
import ButtonDrawer from 'components/ButtonDrawer';
import { MapWithAMarker } from 'components/MapConvocationDetails';
import api from 'services/api';
import { Center, Loading } from '../Chat/styles';
import MarkerPopover from '../Convocations/components/MarkerPopover';
import TruckerPopover from '../Convocations/components/TruckerPopover';
import { capitalize } from '../Convocations/helpers';
import { CloseButton } from '../Convocations/styles';
import BigChargeInfo from './boxs/BigChargeInfo';
import ChargeInfoFour from './boxs/ChargeInfoFour';
import ChargeInfoOne from './boxs/ChargeInfoOne';
import ChargeInfoThree from './boxs/ChargeInfoThree';
import ChargeInfoTwo from './boxs/ChargeInfoTwo';

import {
  BoxMap,
  ColorlibConnector,
  ColorlibStepIconRoot,
  Content,
  GridInfoBoxs,
  Txt,
} from './styles';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useSnapshot from 'hooks/useGetUserLocation';

export default function TempAccompaniment() {
  const { id } = useParams();
  const [cte, setCte] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [idParam, setIdParam] = useState('');
  const [temporaryData, setTemporaryData] = useState(null);
  const [selectedConvocation, setSelectedConvocation] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [specificInvoice, setSpecificInvoice] = useState(null);
  const [filteredInvoices, setFilteredInvoices] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(null);
  const [paths, setPaths] = useState(null);
  const [activeStep, setActiveStep] = useState(-1);
  const [user, setUser] = useState(null);
  const [provider, setProvider] = useState({});
  const [invalidDueDate, setInvalidDueDate] = useState(false);
  const [accompaniment, setAccompaniment] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState(null);
  const [markerPopoverPosition, setMarkerPopoverPosition] = useState(null);
  const { pathname } = useLocation();
  const key = process.env.REACT_APP_MAPS_KEY;
  const payment = JSON.parse(localStorage.getItem('payment'));
  const { height, width } = useWindowDimensions();
  const truckerLocation = useSnapshot(selectedConvocation);

  const steps = [
    {
      label: 'Carga Iniciada',
    },
    {
      label: 'Motorista encontrado',
    },
    {
      label: 'Em carregamento',
    },
    {
      label: 'Emitindo documentos',
    },
    {
      label: 'Carga a caminho',
    },
    {
      label: 'Carga entregue',
    },
  ];

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed || active ? (
          <Check className="TempStepIcon-completed" />
        ) : (
          <div />
        )}
      </ColorlibStepIconRoot>
    );
  }

  async function goToCheckout() {
    if (payment !== null && payment.cte_id === cte.id) {
      window.open(payment.payment_url);
    } else {
      try {
        const { data } = await axios.post(
          'https://api.pagar.me/1/payment_links',
          {
            api_key: 'ak_live_Fo05kAJWGWAAuGYvGctLAep3EdJHSb',
            amount: cte.transportTotal.toFixed(2).toString().replace('.', ''),
            items: [
              {
                id: cte.id,
                title: 'Serviço de Frete',
                unit_price: cte.transportTotal
                  .toFixed(2)
                  .toString()
                  .replace('.', ''),
                quantity: 1,
                tangible: false,
              },
            ],
            payment_config: {
              credit_card: {
                enabled: true,
                free_installments: 3,
                interest_rate: 25,
                max_installments: 12,
              },
              default_payment_method: 'credit_card',
            },
            customer_config: {
              customer: {
                external_id: cte.id,
                name: selectedConvocation.collect_corresponding,
                type: 'individual',
                country: 'br',
                email: selectedConvocation.collect_corresponding_email,
                documents: [
                  selectedConvocation.cnpj != null
                    ? {
                        type: 'cnpj',
                        number: selectedConvocation.cnpj
                          .replace('.', '')
                          .replace('-', '')
                          .replace('.', ''),
                      }
                    : {
                        type: 'cpf',
                        number: selectedConvocation.cpf
                          .replace('.', '')
                          .replace('-', '')
                          .replace('.', ''),
                      },
                ],
                phone_numbers: [
                  selectedConvocation.collect_corresponding_phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace(' ', '')
                    .replace('-', ''),
                ],
              },
              billing: {
                name: selectedConvocation.collect_corresponding,
                address: {
                  country: 'br',
                  state: 'SP',
                  city: 'São Paulo',
                  neighborhood: 'Fulanos bairro',
                  street: 'Rua dos fulanos',
                  street_number: '123',
                  zipcode: '05170060',
                },
              },
            },
          }
        );

        localStorage.setItem(
          'payment',
          JSON.stringify({ cte_id: cte.id, payment_url: data.url })
        );
        setPaymentUrl(data.url);
        window.open(data.url);
      } catch (error) {
        console.log('ERROR', error);
      }
    }
  }

  function getConvocation(convocationId) {
    setSelectedConvocation(null);

    if (convocationId) {
      api
        .firestore()
        .collection('convocations')
        .doc(convocationId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const convocation = doc.data();
            setSelectedConvocation(convocation);
            getUser();
          } else {
            console.log('No such document!');
            setSelectedConvocation(null);
          }
        });
    }
  }

  function getUser() {
    setUser(null);

    if (selectedConvocation != null) {
      api
        .firestore()
        .collection('users')
        .where('cpf', '==', selectedConvocation.driver)
        .get()
        .then((doc) => {
          if (doc.docs.length > 0) {
            const respUser = doc.docs[0].data();
            setUser(respUser);
          } else {
            console.log('No such document!');
            setUser(null);
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setUser(null);
        });
    }
  }

  const getProvider = () => {
    const hasSelectedConvocation = Boolean(selectedConvocation);

    if (hasSelectedConvocation) {
      const db = api.firestore();

      const docUserRef = db
        .collection('providers')
        .where(
          'administration_group',
          '==',
          selectedConvocation.administration_group ??
            selectedConvocation.user_id
        );

      docUserRef
        .get()
        .then((doc) => {
          if (doc.docs.length > 0) {
            const docData = doc.docs[0].data();
            setProvider(docData);
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    }
  };

  function getCte(convocationId, tempData) {
    setCte(null);
    setSelectedInvoice(null);

    if (convocationId) {
      api
        .firestore()
        .collection('ctes')
        .where('convocation_id', '==', convocationId)
        .onSnapshot((snapshot) => {
          if (snapshot.docs.length > 0) {
            const selectedCte = snapshot.docs[0].data();

            const filtered = selectedCte.invoices.filter((item) => item.data);
            console.log('filtered', filtered);
            setCte(selectedCte);
            if (tempData && tempData.client_id_invoice) {
              setSpecificInvoice(
                filtered.find(
                  (obj) =>
                    obj.client_id_invoice &&
                    obj.client_id_invoice === tempData.client_id_invoice
                )
              );
            }
            setFilteredInvoices(filtered);
          } else {
            console.log('No such document!');
            setCte(null);
          }
        });
    }
  }

  function getTemporary() {
    setIdParam(null);

    api
      .firestore()
      .collection('temporary')
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const dateNow = new Date();
          const tempData = doc.data();
          setTemporaryData(tempData);
          if (tempData.due_date >= dateNow) {
            setIdParam(tempData.convocation_id);
            getCte(tempData.convocation_id, tempData);
            getConvocation(tempData.convocation_id);
            setInvalidDueDate(false);
          } else {
            localStorage.removeItem('temporary');
            setInvalidDueDate(true);
          }
        } else {
          console.log('No such document!');
          setIdParam('');
          setInvalidDueDate(false);
        }
      });
  }

  const getPaths = async () => {
    setOpenErrorModal(false);
    setPaths(null);

    if (selectedConvocation && selectedConvocation.id) {
      api
        .firestore()
        .collection('location')
        .doc(`${selectedConvocation.id}`)
        .onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            const loc = snapshot
              .data()
              .user_location.filter(
                (item) => item.latitude !== null && item.longitude !== null
              );

            // const fixedPath = await fixPath(loc);
            // if (fixedPath.length > loc.length) {
            //   dispatch(
            //     ConvocationsActions.updateLocationRequest(
            //       fixedPath,
            //       loc,
            //       selectedConvocation
            //     )
            //   );
            // }

            const dirs = loc.map(
              // eslint-disable-next-line no-return-assign
              (item) =>
                (item = {
                  lat: item.latitude ? item.latitude : item.lat,
                  lng: item.longitude ? item.longitude : item.lng,
                })
            );

            setPaths(dirs);
            setOpenErrorModal(false);
          } else {
            console.log('location', 'No such document!');
            setPaths([]);
            setOpenErrorModal(true);
          }
        });
    }

    return null;
  };

  const handleOpenTruckerPopover = (event) => {
    setPopoverPosition(event.domEvent.currentTarget);
  };

  const handleCloseTruckerPopover = () => {
    setPopoverPosition(null);
  };

  const handleOpenMarkerPopover = (event, invoice) => {
    setMarkerPopoverPosition(event.domEvent.currentTarget);
    setSelectedInvoice(invoice);
  };

  const handleCloseMarkerPopover = () => {
    setMarkerPopoverPosition(null);
    setSelectedInvoice(null);
  };

  const getStatus = () => {
    if (
      selectedConvocation.status === 'completed' &&
      cte.status === 2 &&
      cte.status_cte >= 4
    ) {
      return 5;
    }
    if (cte.status === 2 && cte.status_cte >= 4) {
      return 4;
    }
    if (cte.status === 1 || (cte.status === 2 && cte.status_cte < 4)) {
      return 3;
    }
    if (cte.status === 0) {
      return 2;
    }
    if (cte.trucker) {
      return 1;
    }
    return 0;
  };

  const openTruckerPopover = Boolean(popoverPosition);
  const openMarkerPopover = Boolean(markerPopoverPosition);

  useEffect(() => {
    if (id.length > 6) {
      getCte(id);
      getConvocation(id);
    } else {
      getTemporary();
    }
  }, [id]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled && selectedConvocation) {
      getPaths();
      getUser();
      getProvider();
      if (cte) {
        setActiveStep(getStatus());
      }

      return () => {
        isCancelled = false;
      };
    }
  }, [selectedConvocation, cte]);

  return selectedConvocation && cte ? (
    !accompaniment ? (
      <Content>
        <Txt margin="79px 0 42px 0" fontSize="18px">
          Olá,{' '}
          <strong>
            {specificInvoice
              ? capitalize(specificInvoice.data.name)
              : capitalize(selectedConvocation.owner)}
          </strong>
          , acompanhe o andamento de sua carga.
        </Txt>
        {width > 425 ? (
          <Stepper
            activeStep={getStatus()}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {provider.labelStatus
              ? Object.values(provider.labelStatus).map((step) => (
                  <Step key={step}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {step}
                    </StepLabel>
                  </Step>
                ))
              : steps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
          </Stepper>
        ) : (
          <Scrollbars style={{ width: '100%', height: 100 }}>
            <Stepper
              activeStep={getStatus()}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {selectedConvocation.labelStatus
                ? Object.values(selectedConvocation.labelStatus).map((step) => (
                    <Step key={step}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {step}
                      </StepLabel>
                    </Step>
                  ))
                : steps.map((step) => (
                    <Step key={step.label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
            </Stepper>
          </Scrollbars>
        )}
        {activeStep <= 1 ? (
          <>
            <Txt margin="26px 0 36px 0">
              Sua carga já está preparada para iniciar o trajeto. Falta apenas o
              pagamento.
              <br />
              Para realizar o pagamento, clique no botão abaixo.
              <br />
              {payment !== null && (
                <>
                  <br />
                  <strong>Se já realizou o pagamento aguarde aprovação</strong>
                </>
              )}
            </Txt>
            <ButtonDrawer
              iconLight
              align="center"
              padding="24px 32px"
              icon={Images.Monetization}
              label="REALIZAR PAGAMENTO"
              onClick={goToCheckout}
            />
          </>
        ) : (
          <></>
          // <Txt margin="26px 0 36px 0">
          //   Perfeito! O pagamento da foi realizado. Em breve sua carga
          //   <br />
          //   estará a caminho da entrega.
          // </Txt>
        )}
        <Txt margin="66px 0 26px 0" fontSize="16px">
          <strong>Você pode acompanhar a localização da sua carga.</strong>
          <br />
          <br />
          Clique no botão abaixo para ver a localização da sua carga
        </Txt>
        <ButtonDrawer
          iconLight
          align="center"
          padding="24px 32px"
          disabled={activeStep <= 1}
          icon={Images.IconPath}
          label="ACOMPANHAR MINHA CARGA"
          onClick={() => {
            setAccompaniment(true);
          }}
        />
        {payment === null && activeStep <= 1 && (
          <>
            <Txt margin="26px 0 0 0" fontSize="14px">
              <strong>Ops!</strong> A opção de acompanhamento de carga só estará
              disponível
              <br />
              após o pagamento da carga.
            </Txt>
            <Txt margin="0 0 64px 0" fontSize="14px" onClick={() => {}}>
              <strong>Clique aqui</strong> para realizar o pagamento
            </Txt>
          </>
        )}
        <GridInfoBoxs>
          <ChargeInfoOne
            selectedInvoice={specificInvoice}
            selectedConvocation={selectedConvocation}
          />
          <ChargeInfoTwo
            selectedInvoice={specificInvoice}
            selectedConvocation={selectedConvocation}
            cte={cte}
          />
          <ChargeInfoThree
            selectedConvocation={selectedConvocation}
            cte={cte}
            user={user}
          />
          <ChargeInfoFour
            selectedConvocation={selectedConvocation}
            cte={cte}
            user={user}
          />
        </GridInfoBoxs>
      </Content>
    ) : (
      <Content>
        <BoxMap>
          <MapWithAMarker
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
            containerElement={
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
            loadingElement={
              <Center>
                <Loading />
              </Center>
            }
            truckerLocation={truckerLocation}
            cte={cte}
            handleOpenPopover={handleOpenTruckerPopover}
            handleOpenMarker={handleOpenMarkerPopover}
            specificInvoice={specificInvoice}
            convocation={selectedConvocation}
            getPaths={getPaths}
            paths={paths}
            invoices={filteredInvoices}
          />
          <CloseButton onClick={() => setAccompaniment(false)}>
            <ArrowBack className="icon" />
          </CloseButton>
        </BoxMap>
        <BigChargeInfo
          selectedConvocation={selectedConvocation}
          specificInvoice={specificInvoice}
          selectedInvoice={selectedInvoice}
          cte={cte}
          user={user}
        />
        {openTruckerPopover && (
          <TruckerPopover
            popoverPosition={popoverPosition}
            open={openTruckerPopover}
            handleOpenPopover={handleOpenTruckerPopover}
            selectedConvocation={selectedConvocation}
            handleClose={handleCloseTruckerPopover}
            cte={cte}
          />
        )}
        {openMarkerPopover && (
          <MarkerPopover
            popoverPosition={markerPopoverPosition}
            open={openMarkerPopover}
            handleOpenMarker={handleOpenMarkerPopover}
            selectedConvocation={selectedConvocation}
            handleClose={handleCloseMarkerPopover}
            selectedInvoice={selectedInvoice}
            cte={cte}
          />
        )}
      </Content>
    )
  ) : invalidDueDate && temporaryData ? (
    <Content>
      <Txt margin="64px 0 42px 0" fontSize="18px">
        Esse link venceu em{' '}
        <strong>{new Date(temporaryData.due_date).toLocaleDateString()}</strong>
        .
      </Txt>
      <Txt margin="0 0 36px 0">
        Para ter acesso novamente será necessário gerar um novo link.
      </Txt>
    </Content>
  ) : (
    <Content>
      <Loading />
    </Content>
  );
}
