/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, arrayOf, object, string } from 'prop-types';
import { FiRotateCw } from 'react-icons/fi';

import Modal from 'components/Modal';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  ContentText,
  ContainerModal,
  Card,
  Header,
  Close,
} from './styles';

export default function ErrorModal({ open, message, reload, onClose }) {
  return (
    <Container>
      <Card>
        <Header>
          Erro :(
          <Close onClick={onClose} />
        </Header>
        <ContainerModal>
          <ContentText>
            <strong>Ops!</strong>
            <br />
            {message ? (
              <p>{message}</p>
            ) : (
              <p>Ocorreu um erro no carregamento do mapa.</p>
            )}
          </ContentText>
          <BottomText onClick={reload}>
            <FiRotateCw className="icon" />
            Recarregar
          </BottomText>
        </ContainerModal>
      </Card>
    </Container>
  );
}

ErrorModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
