import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'components/DatePicker';
import Selector from 'components/Selector';
import TextInput from 'components/TextInput';
import WrapperInput from 'components/WrapperInput';
import { customSelectStyles } from '../../components/SelectStyle';
import { Container, Currency, FieldLabel, Error } from './styles';
import { Row } from 'styles/components';

function LoadInfo({ formik, setConvocationType, edit }) {
  const animatedComponents = makeAnimated();

  const convocationOptions = [
    { value: 'public', label: 'Pública' },
    { value: 'private', label: 'Privada' },
  ];

  const onChangeConvocationType = (option) => {
    setConvocationType(option.value);
    formik.setFieldValue('type_convocation', option.value);
  };

  const getValue = (options) => {
    if (options) {
      return options.find(
        (option) => option.value === formik.values.type_convocation
      );
    } else {
      return '';
    }
  };

  return (
    <Container>
      <WrapperInput>
        <FieldLabel style={{ marginTop: 17 }}>Carga</FieldLabel>
        <Select
          className="basic-single"
          classNamePrefix="select"
          components={animatedComponents}
          name="type_convocation"
          options={convocationOptions}
          styles={customSelectStyles}
          value={getValue(convocationOptions)}
          onChange={onChangeConvocationType}
          isDisabled={edit}
        />
      </WrapperInput>

      <WrapperInput>
        <Selector
          label="Tipo de carga"
          options={[
            { text: 'Selecione', value: '' },
            { text: 'Carga Geral', value: 'Carga Geral' },
            { text: 'Solta', value: 'Solta' },
            { text: 'Unitizada', value: 'Unitizada' },
            { text: 'Carga a Granel', value: 'Carga a Granel' },
            { text: 'Carga Neogranel', value: 'Carga Neogranel' },
            {
              text: 'Carga Frigorificada',
              value: 'Carga Frigorificada',
            },
            { text: 'Carga Perigosa', value: 'Carga Perigosa' },
          ]}
          type="secondary"
          name="cargo_type"
          error={formik.touched.cargo_type && Boolean(formik.errors.cargo_type)}
          value={formik.values.cargo_type}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldError('cargo_type', '');
          }}
          disabled={edit}
        />
      </WrapperInput>

      <WrapperInput>
        <DatePicker
          name="validity"
          error={formik.touched.validity && Boolean(formik.errors.validity)}
          label="Validade da Carga"
          helperText={formik.errors.validity}
          selectedDate={formik.values.validity}
          handleDateChange={(e) => {
            formik.setFieldValue('validity', e);
          }}
          disabled={edit}
        />
        {formik.errors.validity ? (
          <Error>{formik.errors.validity}</Error>
        ) : null}
      </WrapperInput>

      <WrapperInput>
        <FieldLabel>Peso total da carga(em Kg)</FieldLabel>

        <Currency
          prefix=""
          decimalSeparator=","
          thousandSeparator="."
          name="weight"
          error={formik.touched.weight && Boolean(formik.errors.weight)}
          value={formik.values.weight}
          onChangeEvent={formik.handleChange}
          onBlur={''}
          disabled={edit}
        />
      </WrapperInput>

      <WrapperInput>
        <FieldLabel>Frete Embarcador</FieldLabel>

        <Currency
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          name="freight_value"
          error={
            formik.touched.freight_value && Boolean(formik.errors.freight_value)
          }
          value={formik.values.freight_value}
          onChangeEvent={(e) => {
            formik.handleChange(e);
            formik.setFieldError('freight_value', '');
          }}
          onBlur={''}
          disabled={edit}
        />
      </WrapperInput>

      <WrapperInput>
        <FieldLabel>Frete Motorista</FieldLabel>

        <Currency
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          name="value"
          error={formik.touched.value && Boolean(formik.errors.value)}
          value={formik.values.value}
          onChangeEvent={(e) => {
            formik.handleChange(e);
            formik.setFieldError('value', '');
          }}
          onBlur={''}
          disabled={edit}
        />
      </WrapperInput>

      <Row>
        <WrapperInput>
          <TextInput
            label="Observação"
            name="note"
            placeholder=""
            value={formik.values.note}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WrapperInput>

        <WrapperInput>
          <FieldLabel>Valor do pedágio</FieldLabel>
          <Currency
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            name="toll_value"
            error={
              formik.touched.toll_value && Boolean(formik.errors.toll_value)
            }
            value={formik.values.toll_value}
            onChangeEvent={formik.handleChange}
            onBlur={''}
            disabled={edit}
          />
        </WrapperInput>
      </Row>
    </Container>
  );
}

export default LoadInfo;
