import React from 'react';
import PropTypes from 'prop-types';

import { DriverImage, Container, ContentWrapper } from './styles';

import Images from 'assets/img';

export default function AuthLayout({ children, rate }) {
  return (
    <>
      <DriverImage src={Images.Driver} />
      <Container>
        <ContentWrapper rate={rate}>{children}</ContentWrapper>
      </Container>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
