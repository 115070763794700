import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  img {
    height: ${(props) => (props.heightIcon ? props.heightIcon : '12px')};
    margin-right: 5.75px;
  }

  p {
    color: ${(props) => (props.color ? props.color : '#C4C4C4')};
    font-weight: 400;
    font-size: 12px;

    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    line-clamp: 1;

    max-width: 200px;
    margin-right: 5px;
  }
`;
