import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import InfoCard from 'components/InfoCard';
import Selector from 'components/Selector';
import Table from 'components/Table';
import { LoadingPage as Loading } from 'components/Loading';
import PaperComp from 'components/Paper';
import Images from 'assets/img';
import DashboardActions from 'store/ducks/dashboard';

import GraphInfo from './components/GraphInfo';
import { ColumnChart } from './charts';

import {
  Container,
  WrapperSelect,
  Paper,
  PaperTitle,
  WrapperChart,
  IconMoney,
  IconPaper,
  EyeIcon,
} from './styles';

export default function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { undoneUser, isLoading, selectedYear, yearsOptions, dashboard } =
    useSelector((state) => state.dashboard);

  const tableData = undoneUser.map((item, index) => ({
    actions: item.id,
    id: index,
    name: item.name,
    phone: item.phone || '-',
  }));

  useEffect(() => {
    dispatch(DashboardActions.getYearsOptionsRequest());
    dispatch(DashboardActions.getUndoneUserRequest());
  }, [dispatch]);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <WrapperSelect>
        <Selector
          width="110px"
          value={String(selectedYear)}
          options={yearsOptions}
          onChange={(e) =>
            dispatch(DashboardActions.getDashboardRequest(e.target.value))
          }
        />
      </WrapperSelect>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={4} xs>
              <InfoCard
                title="Faturamento"
                content={Number(dashboard.totalTax || 0).toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )}
                icon={<IconMoney />}
                backgroundColor="#44DD30"
                loading={isLoading}
              />
            </Grid>
            <Grid item lg={4} xs>
              <InfoCard
                title="Ticket médio do Cte’s"
                content={Number(
                  (dashboard.totalTax +
                    dashboard.totalSendTax +
                    dashboard.totalTransportTotal) /
                    dashboard.totalEmmitedCtes || 0
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<IconPaper />}
                backgroundColor="#fff"
                titleColor="#122D58"
                contentColor="#AFAFAF"
                loading={isLoading}
              />
            </Grid>
            <Grid item lg={4} xs>
              <InfoCard
                title="Ticket médio do frete"
                content={Number(
                  dashboard.totalSendTax / dashboard.totalEmmitedCtes || 0
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={Images.IconTruckPaper}
                backgroundColor="#fff"
                titleColor="#122D58"
                contentColor="#AFAFAF"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <PaperTitle>Nº de CTE’s emitidos</PaperTitle>
                <Grid container spacing={4} style={{ minHeight: 400 }}>
                  <Grid item lg={9} xs={12}>
                    <WrapperChart>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ColumnChart(
                          dashboard.nonPaidHistory,
                          dashboard.emittedHistory
                        )}
                        containerProps={{
                          style: { height: '100%' },
                        }}
                      />
                    </WrapperChart>
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <GraphInfo
                          title="Total de Cte’s emitidas"
                          content={String(dashboard.totalEmmitedCtes)}
                          icon={Images.IconBlueExclamationPaper}
                        />
                      </Grid>
                      <Grid item xs>
                        <GraphInfo
                          title="Total de Cte’s não pagas"
                          content={String(dashboard.totalNonPaidCtes)}
                          color="#F27160"
                          icon={Images.IconRedMoneyPaper}
                        />
                      </Grid>
                      <Grid item xs>
                        <GraphInfo
                          title="Valor total de Cte’s emitidas"
                          content={Number(
                            dashboard.totalTax +
                              dashboard.totalSendTax +
                              dashboard.totalTransportTotal || 0
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                          box
                          icon={Images.IconBlueMoney}
                        />
                      </Grid>
                      <Grid item xs>
                        <GraphInfo
                          title="Valor total de Cte’s não pagas"
                          content={Number(
                            dashboard.nonPaidCtesValue || 0
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                          box
                          color="#F27160"
                          icon={Images.IconRedMoney}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InfoCard
                title="Não concluíram o cadastro"
                content={String(undoneUser.length)}
                icon={Images.IconBlueUsers}
                backgroundColor="#fff"
                titleColor="#122D58"
                contentColor="#AFAFAF"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <PaperComp noHeader minHeight="470px">
                <Table
                  columns={[
                    { label: '', id: 'actions' },
                    { label: 'ID', id: 'id' },
                    { label: 'Nome', id: 'name' },
                    { label: 'Telefone', id: 'phone' },
                  ]}
                  data={tableData}
                  customComponent={{
                    actions: (e) => (
                      <EyeIcon
                        onClick={() => history.push('/trucker_data', { id: e })}
                      />
                    ),
                  }}
                  maxheight="500px"
                  noNavigator
                />
              </PaperComp>
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                title="Nº de clientes não aprovados"
                content={String(dashboard.nonApprovedUsers)}
                icon={Images.IconWhiteUsers}
                backgroundColor="#F27160"
                titleColor="#fff"
                contentColor="#fff"
                type="secondary"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                title="Nº de clientes Cadastrados"
                content={String(dashboard.registeredUsers)}
                icon={Images.IconWhiteUsers}
                backgroundColor="#122D58"
                titleColor="#fff"
                contentColor="#fff"
                type="secondary"
                loading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
