import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MdClose } from 'react-icons/md';
import Scrollbars from 'react-custom-scrollbars';
import { GrShare } from 'react-icons/gr';
import { Tab } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff66;
  backdrop-filter: blur(5px);

  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  z-index: 99;

  position: absolute;
  top: 0;
  left: 0;
`;

export const BoxStops = styled(Scrollbars)`
  width: 100%;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
`;

export const Card = styled.div`
  width: 365px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  max-width: 90vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;

  padding: 16px;
`;

export const TabPanelMol = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 10px 0 !important;
  }
`;

export const TabListMol = styled.div`
  width: 100%;

  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  color: #122d58;

  .PrivateTabIndicator-root-1 {
    background-color: #122d58;
  }

  .PrivateTabIndicator-root-12 {
    background-color: #122d58;
  }

  .MuiTab-wrapper {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #7b7b7b;
  }

  .MuiButtonBase-root {
    border-bottom: 1px solid #7b7b7b;
  }

  .Mui-selected {
    border-bottom: 1px solid #122d58;

    .MuiTab-wrapper {
      color: #122d58 !important;
    }
  }
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MarkerMol = styled.img`
  margin-right: 16.5px;
  width: 15.5px;
`;

export const ShareIcon = styled(GrShare)`
  color: #122d58;
  font-size: 16px;
  cursor: pointer;
`;

export const PositionBox = styled.div`
  width: 100%;
  height: 52px;
  background-color: rgba(182, 182, 182, 0.1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 8px;
`;

export const ProfileImg = styled.div`
  width: 45px !important;
  height: 40px !important;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  font-size: 18px;
  background-color: #c4c4c4;

  img {
    width: 100%;

    object-fit: cover;
  }
`;

export const SubTitleGrey = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #7b7b7b;

  margin-top: 16px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align ?? 'center'};
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  margin: ${(props) => props.margin ?? '0'};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.align ?? 'center'};
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  margin: ${(props) => props.margin ?? '0'};
`;

export const Text = styled.p`
  color: ${(props) => props.color ?? '#A7B4CA'};
  font-size: ${(props) => props.size ?? '10px'};
  font-weight: ${(props) => props.weight ?? '400'};
  line-height: ${(props) => props.lineHeight ?? '12.19px'};
  margin: ${(props) => props.margin ?? '0'};
  width: ${(props) => props.width ?? '100%'};
  text-align: ${(props) => props.align ?? 'center'};
`;

export const Header = styled.div`
  height: 33px;
  width: 100%;
  background-color: #122d58;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Close = styled(MdClose).attrs({
  size: '20px',
})`
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
`;
