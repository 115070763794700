import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { StepConnector, stepConnectorClasses } from '@mui/material';

export const Content = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 425px) {
    padding: 0 5vw;
  }
`;

export const TitleBox = styled.h3`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #122d58;
`;

export const WrapCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GridInfoBoxs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  justify-content: center;

  margin-top: 32px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 32px;
  }
`;

export const InfoBox = styled.div`
  width: 652px;
  height: 330px;
  padding: 32px;

  border: 1px solid #cacaca;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    height: fit-content;
  }

  @media (max-width: 425px) {
    width: 95vw;
  }
`;

export const BoxMap = styled.div`
  width: 73vw;
  height: 600px;
  position: relative;
  margin: 20px 0 15px 0;

  @media (max-width: 768px) {
    width: 95vw;
    margin: 50px 0 15px 0;
  }

  @media (max-width: 375px) {
    margin: 70px 0 15px 0;
  }
`;

export const ImageWithLabelAndValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  .img-big {
    width: 48px;
    margin-right: 8px;
  }

  .img-small {
    width: 16px;
    margin-right: 15px;
  }

  .marker-small {
    width: 12px;
    height: 17.14px;
    margin-right: 8px;
  }

  .marker-big {
    width: 16.8px;
    height: 24px;
    margin-right: 8px;
  }

  &.pointer {
    cursor: pointer;
  }

  .label-and-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;

    .label {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.02em;
      color: #7b7b7b;

      margin-bottom: 4px;
    }

    .value {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #7b7b7b;
    }
  }
`;

export const Txt = styled.p`
  font-family: 'Montserrat';
  font-weight: ${(props) => props.fontWeight ?? '400'};
  font-size: ${(props) => props.fontSize ?? '16px'};
  line-height: ${(props) => props.lineHeight ?? '22px'};

  margin: ${(props) => props.margin ?? '0'};

  text-align: ${(props) => props.textAlign ?? 'center'};

  strong {
    font-weight: 600;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  }

  color: #000000;
`;

export const ColorlibStepIconRoot = muiStyled('div')(
  ({ theme, ownerState }) => ({
    backgroundColor: '#cacaca',
    zIndex: 1,
    color: '#fff',
    width: 21,
    height: 21,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    '& .TempStepIcon-completed': {
      fontSize: 14,
    },

    ...(ownerState.active && {
      background: '#2A5BA8',
    }),
    ...(ownerState.completed && {
      background: '#2A5BA8',
    }),
  })
);

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: 'calc(-50%)',
    right: 'calc(50%)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#2A5BA8',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#2A5BA8',
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#CACACA',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));
