import { arrayOf, bool, object, shape, string } from 'prop-types';
import React, { useState } from 'react';
import PageNavigator from 'components/PageNavigator';
import {
  Body,
  Cell,
  Container,
  EmptyText,
  HeadCell,
  Row,
  Table,
  TableWrapper,
  WrapperEmpty,
  WrapperNavigator,
} from './styles';

function TableComp({ columns, data, customComponent, noNavigator, maxheight }) {
  const [activePage, setActivePage] = useState(0);

  const itemsPerPage = 10;

  function dataSeparated() {
    const categories = data;
    const categoriesSeparated = [];
    const itemsNumber = itemsPerPage;
    let aux = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < categories.length; i++) {
      aux.push(categories[i]);
      if (i % itemsNumber === itemsNumber - 1 || i === categories.length - 1) {
        categoriesSeparated.push(aux);
        aux = [];
      }
    }

    return categoriesSeparated;
  }

  function totalPages() {
    return Math.ceil(data.length / itemsPerPage);
  }

  return (
    <Container empty={data.length === 0}>
      <TableWrapper maxheight={maxheight}>
        {data.length !== 0 ? (
          <Table>
            <Body>
              <Row>
                {columns?.map((item) => (
                  <HeadCell key={item.id}>{item.label}</HeadCell>
                ))}
              </Row>
              {dataSeparated()[activePage].map((row) => (
                <Row key={row.id}>
                  {columns.map((item) =>
                    customComponent[item.id] ? (
                      <Cell key={item.id}>
                        {customComponent[item.id](row[item.id], row)}
                      </Cell>
                    ) : (
                      <Cell key={item.id}>{row[item.id]}</Cell>
                    )
                  )}
                </Row>
              ))}
            </Body>
          </Table>
        ) : (
          <WrapperEmpty>
            <EmptyText>Não há dados</EmptyText>
          </WrapperEmpty>
        )}
      </TableWrapper>
      {!noNavigator && (
        <WrapperNavigator>
          <PageNavigator
            actual={activePage}
            total={totalPages()}
            onChange={(page) => setActivePage(page.selected)}
          />
        </WrapperNavigator>
      )}
    </Container>
  );
}

export default TableComp;

TableComp.propTypes = {
  columns: arrayOf(object).isRequired,
  data: arrayOf(object).isRequired,
  customComponent: shape(),
  noNavigator: bool,
  color: string,
  maxheight: string,
};

TableComp.defaultProps = {
  color: '#7b7b7b',
  customComponent: {},
  noNavigator: false,
  maxheight: null,
};

/*    Exemplo de Uso

      <Table
        columns={[
          { label: '', id: 'avatar' },
          { label: 'FINALIDADE', id: 'goal' },
          { label: 'GASTOS', id: 'spending' },
          { label: 'DATA', id: 'date' }
        ]}
        data={[
          {
            avatar: 'https://bit.ly/3fgFabb',
            goal: '(PAG) Dr José Costa',
            spending: 'R$ 1.201,00',
            date: '22/05/2020',
          },
          {
            avatar: 'https://bit.ly/3fgFabb',
            goal: '(PAG) Dr José Costa',
            spending: 'R$ 1.202,00',
            date: '22/05/2020',
          },
          {
            avatar: 'https://bit.ly/3fgFabb',
            goal: '(PAG) Dr José Costa',
            spending: 'R$ 1.203,00',
            date: '22/05/2020',
          },
          {
            avatar: 'https://bit.ly/3fgFabb',
            goal: '(PAG) Dr José Costa',
            spending: 'R$ 1.204,00',
            date: '22/05/2020',
          },
          {
            avatar: 'https://bit.ly/3fgFabb',
            goal: '(PAG) Dr José Costa',
            spending: 'R$ 1.205,00',
            date: '22/05/2020',
          },
        ]}
        customComponent={{
          avatar: res => <img src={res} />
        }}
      />

*/
