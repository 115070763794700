import React from 'react';
import { string, bool } from 'prop-types';
import { Column, Icon } from 'styles/components';

import { Container, Title, Box, Number } from './styles';

function GraphInfo({ title, content, color, box, icon }) {
  return (
    <Container>
      <Column>
        <Title>{title}</Title>
        <Box gray={box}>
          <Icon src={icon} height={box ? '11px' : '15px'} />
          <Number small={box} color={color}>
            {content}
          </Number>
        </Box>
      </Column>
    </Container>
  );
}

export default GraphInfo;

GraphInfo.propTypes = {
  title: string,
  content: string,
  color: string,
  box: bool,
  icon: string,
};

GraphInfo.defaultProps = {
  title: '',
  content: '',
  color: '#122D58',
  box: false,
  icon: '',
};
