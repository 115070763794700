import { all, takeLatest, fork } from 'redux-saga/effects';

import { loginStatusWatcher, signIn, signOut, forgotPassword } from './auth';
import { AuthTypes } from '../ducks/auth';

import {
  getTruckers,
  getOneTrucker,
  getCpfTrucker,
  reportPending,
  updateAditionalDocument,
  setDocumentData,
} from './trucker';
import { TruckerTypes } from '../ducks/trucker';

import {
  getCollabs,
  addCollab,
  updateCollab,
  reactivateCollab,
  removeCollab,
} from './collaborators';
import { CollabsTypes } from '../ducks/collaborators';

import { getCtes, confirmPayment, cancelPayment } from './trip';
import { TripTypes } from '../ducks/trip';

import { getNotifications, addNotification, getUsers } from './notification';
import { NotificationsTypes } from '../ducks/notifications';

import { getUndoneUser, getDashboard, getYearsOptions } from './dashboard';
import { DashboardTypes } from '../ducks/dashboard';

import {
  getConvocations,
  addConvocation,
  updateConvocation,
  removeConvocation,
  sendMail,
  updateLocation,
  getTemporaryLink,
  convocationsWatcher,
} from './convocations';
import { ConvocationsTypes } from '../ducks/convocations';

import { getInsurances } from './insurances';
import { InsurancesTypes } from '../ducks/insurances';

import { getCte, addCte, updateCte, removeCte, getIdCte } from './cte';
import { CteTypes } from '../ducks/cte';

import {
  getProviders,
  addProvider,
  updateProvider,
  removeProvider,
} from './providers';
import { ProvidersTypes } from '../ducks/providers';

import {
  getReports,
  removeReport,
  setReportOld,
  setReportOldAll,
} from './reports';
import { ReportsTypes } from '../ducks/reports';

import { getChat, getMessages, addMessage } from './chat';
import { ChatTypes } from '../ducks/chat';
import { IssuersTypes } from '../ducks/issuers';
import { addIssuers, getIssuers, updateIssuer } from './issuer';

import { AntecipationsTypes } from '../ducks/antecipations';
import {
  deleteFileAntecipations,
  fileAntecipations,
  getAntecipations,
  getIdAntecipations,
  removeAntecipations,
  updateAntecipations,
} from './antecipations';

export default function* rootSaga() {
  yield fork(loginStatusWatcher);
  yield fork(convocationsWatcher);
  yield all([
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    takeLatest(AuthTypes.FORGOT_REQUEST, forgotPassword),

    takeLatest(TruckerTypes.GET_TRUCKERS_REQUEST, getTruckers),
    takeLatest(TruckerTypes.GET_ONE_TRUCKER_REQUEST, getOneTrucker),
    takeLatest(TruckerTypes.GET_CPF_TRUCKER_REQUEST, getCpfTrucker),
    takeLatest(TruckerTypes.REPORT_PENDING_REQUEST, reportPending),
    takeLatest(
      TruckerTypes.UPDATE_ADITIONAL_DOCUMENT_REQUEST,
      updateAditionalDocument
    ),
    takeLatest(TruckerTypes.SET_DOCUMENT_DATA_REQUEST, setDocumentData),

    takeLatest(CollabsTypes.GET_COLLABS_REQUEST, getCollabs),
    takeLatest(CollabsTypes.ADD_COLLAB_REQUEST, addCollab),
    takeLatest(CollabsTypes.UPDATE_COLLAB_REQUEST, updateCollab),
    takeLatest(CollabsTypes.REACTIVATE_COLLAB_REQUEST, reactivateCollab),
    takeLatest(CollabsTypes.REMOVE_COLLAB_REQUEST, removeCollab),

    takeLatest(TripTypes.GET_CTES_REQUEST, getCtes),
    takeLatest(TripTypes.CONFIRM_PAYMENT_REQUEST, confirmPayment),
    takeLatest(TripTypes.CANCEL_PAYMENT_REQUEST, cancelPayment),

    takeLatest(NotificationsTypes.GET_NOTIFICATIONS_REQUEST, getNotifications),
    takeLatest(NotificationsTypes.ADD_NOTIFICATION_REQUEST, addNotification),
    takeLatest(NotificationsTypes.GET_USERS_REQUEST, getUsers),

    takeLatest(DashboardTypes.GET_UNDONE_USER_REQUEST, getUndoneUser),
    takeLatest(DashboardTypes.GET_DASHBOARD_REQUEST, getDashboard),
    takeLatest(DashboardTypes.GET_YEARS_OPTIONS_REQUEST, getYearsOptions),

    // takeLatest(ConvocationsTypes.GET_CONVOCATIONS_REQUEST, getConvocations),
    takeLatest(ConvocationsTypes.ADD_CONVOCATION_REQUEST, addConvocation),
    takeLatest(ConvocationsTypes.UPDATE_LOCATION_REQUEST, updateLocation),
    takeLatest(ConvocationsTypes.UPDATE_CONVOCATION_REQUEST, updateConvocation),
    takeLatest(ConvocationsTypes.REMOVE_CONVOCATION_REQUEST, removeConvocation),
    takeLatest(ConvocationsTypes.PUT_SEND_MAIL_REQUEST, sendMail),
    takeLatest(ConvocationsTypes.OPEN_SHARE_MODAL, getTemporaryLink),

    takeLatest(CteTypes.GET_CTE_REQUEST, getCte),
    takeLatest(CteTypes.ADD_CTE_REQUEST, addCte),
    takeLatest(CteTypes.UPDATE_CTE_REQUEST, updateCte),
    takeLatest(CteTypes.REMOVE_CTE_REQUEST, removeCte),
    takeLatest(CteTypes.GET_ID_CTE_REQUEST, getIdCte),

    takeLatest(ProvidersTypes.GET_PROVIDERS_REQUEST, getProviders),
    takeLatest(ProvidersTypes.ADD_PROVIDER_REQUEST, addProvider),
    takeLatest(ProvidersTypes.UPDATE_PROVIDER_REQUEST, updateProvider),
    takeLatest(ProvidersTypes.REMOVE_PROVIDER_REQUEST, removeProvider),

    takeLatest(ReportsTypes.GET_REPORTS_REQUEST, getReports),
    takeLatest(ReportsTypes.REMOVE_REPORT_REQUEST, removeReport),
    takeLatest(ReportsTypes.SET_REPORT_OLD_REQUEST, setReportOld),
    takeLatest(ReportsTypes.SET_REPORT_OLD_ALL_REQUEST, setReportOldAll),

    takeLatest(ChatTypes.GET_CHAT_REQUEST, getChat),
    takeLatest(ChatTypes.GET_MESSAGES_REQUEST, getMessages),
    takeLatest(ChatTypes.ADD_MESSAGE_REQUEST, addMessage),

    takeLatest(IssuersTypes.GET_ISSUERS_REQUEST, getIssuers),
    takeLatest(IssuersTypes.ADD_ISSUER_REQUEST, addIssuers),
    takeLatest(IssuersTypes.UPDATE_ISSUER_REQUEST, updateIssuer),

    takeLatest(AntecipationsTypes.GET_ANTECIPATIONS_REQUEST, getAntecipations),
    takeLatest(
      AntecipationsTypes.UPDATE_ANTECIPATIONS_REQUEST,
      updateAntecipations
    ),
    takeLatest(
      AntecipationsTypes.REMOVE_ANTECIPATIONS_REQUEST,
      removeAntecipations
    ),
    takeLatest(
      AntecipationsTypes.FILE_ANTECIPATIONS_REQUEST,
      fileAntecipations
    ),
    takeLatest(
      AntecipationsTypes.DELETE_FILE_ANTECIPATIONS_REQUEST,
      deleteFileAntecipations
    ),
    takeLatest(
      AntecipationsTypes.GET_ID_ANTECIPATIONS_REQUEST,
      getIdAntecipations
    ),

    takeLatest(InsurancesTypes.GET_INSURANCES_REQUEST, getInsurances),
  ]);
}
