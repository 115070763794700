import styled from 'styled-components';
import { MdAddCircle, MdDelete, MdRemoveRedEye, MdEdit } from 'react-icons/md';
import { IoIosDocument } from 'react-icons/io';
import ReactLoading from 'react-loading';

export const Container = styled.div`
  margin: -24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  overflow: hidden;
  padding: 24px;
`;

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;

  position: absolute;
  top: 18px;
  left: 18px;

  cursor: pointer;

  & .icon {
    font-size: 18px;
    color: #122d58;
  }
`;

export const ShareButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;

  position: absolute;
  bottom: 18px;
  left: 18px;

  cursor: pointer;

  & .icon {
    width: 18px;
    color: #122d58;
  }
`;

export const Card = styled.div`
  /* margin: -24px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  overflow: hidden;

  /* box-shadow: 0 0 6px #0000000f; */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 14px 0 14px 14px;

  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;

export const ButtonDrawer = styled.div`
  width: 42px;
  height: 42px;
  background-color: #fff;
  border: 2px solid #2a5ba8;
  border-radius: 4px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1vw;
  right: 1vw;

  cursor: pointer;

  z-index: 99;

  & .icon-1 {
    font-size: 32px;
    margin-right: -7.5px;
  }

  & .icon-2 {
    font-size: 24px;
    margin-left: -7.5px;
  }
`;

export const Action = styled.div`
  width: 100%;
  padding: 0 25px 0 25px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Actions = styled.div`
  width: 100%;
  padding-left: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const WrapperAdd = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`;

export const AddIcon = styled(MdAddCircle).attrs({ size: '44px' })`
  color: #122d58;
  cursor: pointer;
`;

export const DeleteIcon = styled(MdDelete).attrs({ size: '16px' })`
  color: #f27160;
  cursor: pointer;
  margin-right: 24px;
`;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const EditIcon = styled(MdEdit).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const BoxMap = styled.div`
  width: 73vw;
  height: 84.2vh;
  position: relative;
  margin: 0 15px;

  @media (max-width: 425px) {
    margin-bottom: 30px;
  }
`;

export const BoxDrawerRight = styled.div`
  margin-top: 5%;
  height: 95%;
  width: 100%;
`;

export const WrapperPopover = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const PopoverText = styled.p`
  color: #888;
  margin-right: 10px;
`;

export const PopoverButton = styled.div`
  padding: 3px 5px;
  background-color: #f27160;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
`;

export const IconPaper = styled(IoIosDocument).attrs({ size: '18px' })`
  color: #122d58;
`;
