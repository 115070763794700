import * as yup from 'yup';

export const schema = yup.object().shape({
  cnh: yup.string().required(),
  driverCategory: yup.string().required(),
  licenseDue: yup.string().required(),
  plate: yup.string().required(),
  chassi: yup.string().required(),
  renavam: yup.string().required(),
  cpfCpnj: yup.string().required(),
  rntc: yup.string().required(),
  rg: yup.string().required(),
  firstlicense: yup.string().required(),
  security_number: yup.string().required(),
  birth_date: yup.string().required(),
  mother_name: yup.string().required(),
  sex_trucker: yup.string().required(),
  zipcode: yup.string().required(),
  point: yup.string().required(),
  neighborhood: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  transport_type: yup.string().required(),
});
