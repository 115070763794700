import cep from 'cep-promise';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import makeAnimated from 'react-select/animated';
import Images from 'assets/img';
import Map from 'components/Map';
import TextInput from 'components/TextInput';
import { Input } from 'components/TextInput/styles';
import WrapperInput from 'components/WrapperInput';
import Section from 'pages/CreateConvocation/components/Section';
import { locationByAddress } from 'services/getLocations';
import { Column, Row } from 'styles/components';
import {
  BoxMap,
  BoxSearch,
  Center,
  Container,
  Content,
  FieldLabel,
  ImgFlag,
  Loading,
} from './styles';

function DeliveryPoint({ formik, invoices, setInvoices, edit }) {
  const key = process.env.REACT_APP_MAPS_KEY;

  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [loadType, setLoadType] = useState([]);
  const [fullAddress, setFullAddress] = useState({
    cep: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const getPosition = (address) => {
    locationByAddress(address).then((pos) => {
      if (pos.lat !== 0) {
        formik.setFieldValue('delivery_lat', pos.lat);
        formik.setFieldValue('delivery_lng', pos.lng);

        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
      }
    });
  };
  const getFormattedAddress = (address, number) => {
    let result = address.street;
    if (number && number.length) {
      result += `, ${number}`;
    }
    if (address.neighborhood.length) {
      result += `, ${address.neighborhood}`;
    }
    if (address.city.length) {
      result += `, ${address.city}`;
    }
    if (address.city.length) {
      result += ` - ${address.state}`;
    }
    return `${result}, Brasil`;
  };

  const animatedComponents = makeAnimated();

  const handleCepChange = (cepRequested) => {
    cep(cepRequested)
      .then((cepResponse) => {
        const address = {
          cep: cepRequested,
          street: cepResponse.street,
          neighborhood: cepResponse.neighborhood,
          city: cepResponse.city,
          state: cepResponse.state,
        };
        setFullAddress(address);

        formik.setFieldValue(`delivery_point`, cepResponse.street);
        formik.setFieldValue(`delivery_neighborhood`, cepResponse.neighborhood);
        formik.setFieldValue(`delivery_city`, cepResponse.city);
        formik.setFieldValue(`delivery_state`, cepResponse.state);

        getPosition(
          getFormattedAddress(address, formik.values.delivery_number)
        );

        formik.setFieldValue(`delivery_point`, cepResponse.street);
        formik.setFieldValue(`delivery_neighborhood`, cepResponse.neighborhood);
        formik.setFieldValue(`delivery_city`, cepResponse.city);
        formik.setFieldValue(`delivery_state`, cepResponse.state);

        getPosition(
          getFormattedAddress(address, formik.values.delivery_number)
        );
      })
      .catch(() => {
        setFullAddress({
          cep: cepRequested,
          street: formik.values.address,
          neighborhood: formik.values.neighborhood,
          city: '',
          state: '',
        });
        formik.setFieldError(`zipcode`, 'CEP não encontrado');
      });
  };

  return (
    <Container>
      <Section title="DADOS DESTINO">
        <Content>
          <Column>
            <Row>
              <WrapperInput>
                <TextInput
                  id="delivery_zipcode"
                  label="CEP"
                  type="number"
                  name="delivery_zipcode"
                  error={
                    formik.touched.delivery_zipcode &&
                    Boolean(formik.errors.delivery_zipcode)
                  }
                  value={formik.values.delivery_zipcode}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_zipcode', '');
                    formik.setFieldError('delivery_point', '');
                    formik.setFieldError('delivery_neighborhood', '');
                    formik.setFieldError('delivery_city', '');
                    formik.setFieldError('delivery_state', '');
                  }}
                  onBlur={(e) => handleCepChange(e.target.value)}
                  disabled={edit}
                />
              </WrapperInput>
              <WrapperInput>
                <TextInput
                  id="delivery_point"
                  label="Endereço"
                  type="text"
                  name="delivery_point"
                  placeholder=""
                  value={formik.values.delivery_point}
                  error={
                    formik.touched.delivery_point &&
                    Boolean(formik.errors.delivery_point)
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_point', '');
                  }}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
              <WrapperInput>
                <TextInput
                  id="delivery_complement"
                  label="Complemento"
                  name="delivery_complement"
                  placeholder=""
                  value={formik.values.delivery_complement}
                  onChange={formik.handleChange}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
            </Row>
            <Row>
              <WrapperInput>
                <TextInput
                  id="delivery_neighborhood"
                  label="Bairro"
                  name="delivery_neighborhood"
                  placeholder=""
                  value={formik.values.delivery_neighborhood}
                  error={
                    formik.touched.delivery_neighborhood &&
                    Boolean(formik.errors.delivery_neighborhood)
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_neighborhood', '');
                  }}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
              <WrapperInput>
                <TextInput
                  id="delivery_city"
                  label="Cidade"
                  name="delivery_city"
                  placeholder=""
                  error={
                    formik.touched.delivery_city &&
                    Boolean(formik.errors.delivery_city)
                  }
                  value={formik.values.delivery_city}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_city', '');
                  }}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
              <WrapperInput>
                <TextInput
                  label="Estado"
                  name="delivery_state"
                  placeholder=""
                  value={formik.values.delivery_state}
                  error={
                    formik.touched.delivery_state &&
                    Boolean(formik.errors.delivery_state)
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_state', '');
                  }}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
            </Row>
            <Row>
              <WrapperInput>
                <TextInput
                  label="Nome do correspondente"
                  name="delivery_corresponding_name"
                  placeholder=""
                  value={formik.values.delivery_corresponding_name}
                  error={
                    formik.touched.delivery_corresponding_name &&
                    Boolean(formik.errors.delivery_corresponding_name)
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_corresponding_name', '');
                  }}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
              <WrapperInput>
                <FieldLabel>Telefone do correspondente</FieldLabel>
                <Row>
                  <BoxSearch>
                    <ImgFlag src={Images.IconFlagBrasil} />
                  </BoxSearch>
                  <InputMask
                    mask="(99) 99999-9999"
                    name="delivery_corresponding_phone"
                    placeholder=""
                    style={{ maxWidth: 150 }}
                    disabled={edit}
                    value={formik.values.delivery_corresponding_phone}
                    error={
                      formik.touched.delivery_corresponding_phone &&
                      Boolean(formik.errors.delivery_corresponding_phone)
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldError('delivery_corresponding_phone', '');
                    }}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Row>
              </WrapperInput>
              <WrapperInput>
                <TextInput
                  label="E-mail do correspondente"
                  name="delivery_corresponding_email"
                  placeholder=""
                  value={formik.values.delivery_corresponding_email}
                  error={
                    formik.touched.delivery_corresponding_email &&
                    Boolean(formik.errors.delivery_corresponding_email)
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('delivery_corresponding_email', '');
                  }}
                  onBlur={''}
                  disabled={edit}
                />
              </WrapperInput>
            </Row>
          </Column>

          <BoxMap>
            {position.latitude !== 0 ? (
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={
                  <Center>
                    <Loading />
                  </Center>
                }
                containerElement={
                  <div
                    style={{
                      height: `250px`,
                      // borderRadius: '11px',
                      overflow: 'hidden',
                      // boxShadow:
                      //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
                position={position}
              />
            ) : (
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={
                  <Center>
                    <Loading />
                  </Center>
                }
                containerElement={
                  <div
                    style={{
                      height: `250px`,
                      // borderRadius: '11px',
                      overflow: 'hidden',
                      // boxShadow:
                      //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
                position={position}
              />
            )}
          </BoxMap>
        </Content>
      </Section>
    </Container>
  );
}

export default DeliveryPoint;
