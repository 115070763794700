/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

export const Container = styled.div`
  margin: ${(props) => props.margin || '0 0 15px 0'};
  width: 100%;
`;

export const TextInput = withStyles({
  root: (props) => ({
    width: '100%',
    '&:hover': {
      borderColor: '#636F88',
    },
    '& .MuiFormLabel-root': {
      color: props.error
        ? '#F27160'
        : props.color === 'primary'
        ? '#fff'
        : props.color === 'secondary'
        ? '#112850'
        : '#000',
    },
    '& .MuiInputBase-input': {
      color: props.error
        ? '#F27160'
        : props.color === 'primary'
        ? '#fff'
        : props.color === 'secondary'
        ? '#112850'
        : '#000',
    },
    '& .MuiInput-underline:before': {
      borderColor: props.error
        ? '#F27160'
        : props.color === 'primary'
        ? '#fff'
        : props.color === 'secondary'
        ? '#112850'
        : '#000',
    },
    '& .MuiInput-underline:after': {
      borderColor: props.error
        ? '#F27160'
        : props.color === 'primary'
        ? '#fff'
        : props.color === 'secondary'
        ? '#112850'
        : '#000',
    },
  }),
})(TextField);
