import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MdClose } from 'react-icons/md';
import StepConnector from '@material-ui/core/StepConnector';
import Scrollbars from 'react-custom-scrollbars';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
`;

export const BoxFiles = styled(Scrollbars)`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
`;

export const GridFiles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  min-height: 100px;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff66;
  backdrop-filter: blur(5px);

  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  z-index: 99;

  position: absolute;
  top: 0;
  left: 0;
`;

export const SubTitleGrey = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #7b7b7b;
`;

export const ImageWithLabelAndValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  .img-big {
    width: 48px;
    margin-right: 8px;
  }

  .img-small {
    width: 16px;
    margin-right: 15px;
  }

  .marker-small {
    width: 12px;
    height: 17.14px;
    margin-right: 8px;
  }

  .marker-big {
    width: 16.8px;
    height: 24px;
    margin-right: 8px;
  }

  &.pointer {
    cursor: pointer;
  }

  .label-and-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;

    .label {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.02em;
      color: #7b7b7b;

      margin-bottom: 4px;
    }

    .value {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #7b7b7b;
    }
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const BoxPhoto = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 1px solid #c4c4c4;
  border-radius: 7px;
  overflow: hidden;

  position: relative;

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  transition: 400ms;

  & .download-box {
    opacity: 0;

    position: absolute;
    top: 0;
    right: 0;

    z-index: 1;
    color: #fff;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    & .download-box {
      opacity: 1;
    }
  }
`;

export const FileCard = styled.a`
  width: 93px;
  height: 75px;
  margin-right: 18px;
  border: 1px solid #efefef;
  padding: 4px;

  cursor: pointer;

  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .row {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #122d58;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 18px;
    }

    p {
      margin-left: 8px;
    }
  }

  img {
    width: 100%;
    height: 47px;

    border: 1px solid #f1f2f5;
    border-radius: 4px;
  }
`;

export const BoxStops = styled(PerfectScrollbar)`
  width: 100%;
  height: 180px;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
`;

export const Card = styled.div`
  width: 362px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  max-width: 90vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;

  padding: 16px;

  height: 370px;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;

  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    margin-right: 5px;
  }
`;

export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  height: 33px;
  width: 100%;
  background-color: #122d58;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

export const Close = styled(MdClose).attrs({
  size: '20px',
})`
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
`;
