import React, { useState } from 'react';
import { string, func } from 'prop-types';

import { Container, Input, SearchIcon, ClearIcon } from './styles';

function AnimatedSearchInput({ onChange, value, onClear, placeholder }) {
  const [open, setOpen] = useState(null);
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    if (open != null) {
      setOpen(!open);
    } else {
      setOpen(true);
    }

    if (visible) {
      setVisible(!visible);
    } else {
      setTimeout(() => setVisible(!visible), 700);
    }
  };

  return (
    <Container className={open != null ? (open ? 'active' : 'closed') : ''}>
      <SearchIcon onClick={handleClick} />
      <Input
        className={visible ? 'visible' : ''}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <ClearIcon
        className={visible ? 'visible' : ''}
        onClick={() => {
          if (value === '') {
            handleClick();
          } else {
            onClear();
          }
        }}
      />
    </Container>
  );
}

export default AnimatedSearchInput;

AnimatedSearchInput.propTypes = {
  onChange: func,
  value: string,
  onClear: func,
  placeholder: string,
};

AnimatedSearchInput.defaultProps = {
  onChange: () => {},
  value: '',
  onClear: () => {},
  placeholder: 'Buscar...',
};
