import Images from 'assets/img';

export function getWeight(weight) {
  if (typeof weight === 'string' || weight instanceof String) {
    const formattedWeight = parseFloat(weight);

    if (formattedWeight > 1000) {
      return `${(formattedWeight / 1000).toFixed(2)} Ton`;
    }
    if (formattedWeight < 1) {
      return `${(formattedWeight * 1000).toFixed(2)} g`;
    }
    return `${formattedWeight} kg`;
  }
  if (weight > 1000) {
    return `${(weight / 1000).toFixed(2)} Ton`;
  }
  if (weight < 1) {
    return `${(weight * 1000).toFixed(2)} g`;
  }
  return `${weight} kg`;
}

export function getValue(value) {
  if (typeof value === 'string' || value instanceof String) {
    const formattedValue = parseFloat(
      value.replace('R$', '').replaceAll('.', '').replaceAll(',', '.').trim()
    );

    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(formattedValue);
  }
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function formatDate(date) {
  const formattedDate = new Date(date);

  return formattedDate.toLocaleDateString('pt-BR');
}

export function capitalize(string) {
  if (string !== null && string !== undefined) {
    const strings = string.toString().toLowerCase().split(' ');
    let formattedString = '';
    strings.forEach((item) => {
      item = item.trim();
      if (item !== strings[strings.length - 1]) {
        formattedString += `${item.charAt(0).toUpperCase() + item.slice(1)} `;
      } else {
        formattedString += item.charAt(0).toUpperCase() + item.slice(1);
      }
    });

    return formattedString;
  }
  return '';
}

export function zipcode(string) {
  return `${string
    .toString()
    .replace('.', '')
    .replace('-', '')
    .substring(0, 5)}-${string.toString().substring(5)}`;
}

export function getImgType(type) {
  switch (type) {
    case 'Carga Granel':
      return Images.AgriculturalFlag;
    case 'Carga Perigosa':
      return Images.DangerousFlag;
    case 'Carga Frigorificada':
      return Images.ColdFlag;
    case 'Carga Viva':
      return Images.AnimalFlag;
    case 'Carga Solta':
      return Images.WoodFlag;
    case 'Carga Unitizada':
      return Images.BoxFlag;
    case 'Carga Animal':
      return Images.PetFlag;
    case 'Carga Inflamável':
      return Images.FlammableFlag;
    case 'Carga Química':
      return Images.ChemicalFlag;
    default:
      return Images.GeneralFlag;
  }
}
