/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import InputCurrency from 'react-currency-input';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MdClose } from 'react-icons/md';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff66;
  backdrop-filter: blur(5px);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 99;

  position: absolute;
  top: 0;
  left: 0;
`;

export const WrapperBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  max-width: 90vw;
`;

export const Header = styled.div`
  height: 33px;
  background-color: #b81212;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Close = styled(MdClose).attrs({
  size: '20px',
})`
  color: #fff;
  cursor: pointer;
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17.07px;
  text-align: center;
  color: #7b7b7b;
  width: 200px;

  strong {
    font-weight: 700;
  }
`;

export const BottomText = styled.a`
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  color: #122d58;
  cursor: pointer;
  padding: 12px 20px;

  .icon {
    margin-right: 5px;
  }
`;

export const Currency = styled(InputCurrency)`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;
