import React from 'react';
import Images from 'assets/img';
import { Box } from './styles';

export default function MarkerLetter({
  width,
  height,
  fontSize,
  number,
  markerBlue,
  margin,
}) {
  function getLetter() {
    const alfabeto = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return alfabeto[number];
  }

  return (
    <Box width={width} height={height} fontSize={fontSize} margin={margin}>
      <p>{getLetter()}</p>
      {markerBlue ? (
        <img src={Images.IconMarkerBlue} alt="Marcador" />
      ) : (
        <img src={Images.IconMarker} alt="Marcador" />
      )}
    </Box>
  );
}
