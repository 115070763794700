import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  margin-bottom: 7px;
`;

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  color: red;
  font-weight: 600;
  align-self: center;
`;
