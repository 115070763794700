import React from 'react';
import { Container } from './styles';

export default function BadgeStatus({ icon, text, color }) {
  return (
    <Container color={color}>
      <img src={icon} alt="Icone" />
      <p>{text}</p>
    </Container>
  );
}
