import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px 0;
  width: 100%;
  max-width: 255px;

  display: flex;
  flex-direction: column;
  margin: 0 25px 20px 0;
  align-items: flex-start;
`;
