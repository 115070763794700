/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Paper from 'components/Paper';
import Images from 'assets/img';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import TruckerActions from 'store/ducks/trucker';
import { Row } from 'styles/components';
import api from 'services/api';

import { Container, WrapperTable, EyeIcon, Icon } from './styles';

function Trucker() {
  const dispatch = useDispatch();
  const { truckers } = useSelector((state) => state.trucker);
  const history = useHistory();
  const [search, setSearch] = useState('');

  const data = truckers.map((item) => ({
    ...item,
    actions: item.id,
    id: item.id,
    name: item.name,
    phone: item.phone,
    email: item.email,
    cpf: item.cpf || '-',
    date: item.created_at ? item.created_at?.seconds * 1000 : new Date(),
    time: new Date(item.created_at.seconds * 1000).toString().substring(16, 24),
    updated: item?.status === 1 ? item.updated_at?.seconds * 1000 : '',
    updated_time:
      item?.status === 1
        ? new Date(item.updated_at.seconds * 1000).toString().substring(16, 24)
        : '',
  }));

  const filteredData = data.filter(
    (item) =>
      item.name.toUpperCase().includes(search.toUpperCase()) ||
      item.email.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    dispatch(TruckerActions.getTruckersRequest());
  }, [dispatch]);

  return (
    <Container>
      <Paper
        title="Caminhoneiros"
        icon={Images.IconGrayUsers}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              { label: 'Ações', id: 'actions' },
              { label: 'ID', id: 'id' },
              { label: 'Nome', id: 'name' },
              { label: 'Telefone', id: 'phone' },
              { label: 'E-mail', id: 'email' },
              { label: 'CPF', id: 'cpf' },
              { label: 'Data de cadastro', id: 'date' },
              { label: 'Hora de cadastro', id: 'time' },
              { label: 'Data Liberação', id: 'updated' },
              { label: 'Hora Liberação', id: 'updated_time' },
              { label: 'Status', id: 'status' },
            ]}
            activePage={0}
            totalPages={2}
            data={filteredData}
            customComponent={{
              actions: (e) => (
                <Row>
                  <EyeIcon
                    onClick={() => history.push('/trucker_data', { id: e })}
                  />
                </Row>
              ),
              date: (e) => format(new Date(e), 'dd/MM/yyyy'),
              updated: (e) => (e ? format(new Date(e), 'dd/MM/yyyy') : ''),
              status: (e) =>
                e === 0 ? (
                  <Icon src={Images.Block} />
                ) : (
                  <Icon src={Images.Check} />
                ),
            }}
          />
        </WrapperTable>
      </Paper>
    </Container>
  );
}

export default Trucker;
