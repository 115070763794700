import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
export const StyledMask = styled(InputMask)`
  width: 300px;
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px 20px 0;
  align-items: flex-start;
`;

export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  margin-bottom: 7px;
`;

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  color: red;
  font-weight: 600;
  align-self: center;
`;

export const BoxSearch = styled.div`
  height: 32px;
  padding: 0 8px;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const providerSelectStyles = {
  control: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: 'white',
    display: 'flex',
    width: 420,
    borderColor: '#ebebeb',
    minHeight: 32,
    height: 32,

    ':active': {
      ...styles[':active'],
      borderColor: !isDisabled && (isSelected ? '#122d58' : '#cecece'),
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      width: 310,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#122d58'
        : isFocused
        ? '#cecece'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : 'gray',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#122d58' : 'gray'),
      },
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: '#122d58', // Custom colour
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const ImgFlag = styled.img`
  height: 18px;
  object-fit: contain;
`;
