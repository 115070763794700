import { makeStyles, createStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontSize: 14,
      textAlign: 'end',
      marginTop: 30,
    },
    value: {
      fontSize: 14,
      textAlign: 'end',
      marginTop: 30,
    },
  })
);

export const Container = styled.div`
  width: 95vw;
  max-width: 518px;
`;

export const WrapperBody = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 15px 30px 0px 30px;
`;
export const WrapperBottom = styled.div`
  height: 66px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomText = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
  padding: 5px 20px;
`;

export const FieldText = styled.text`
  flex: flex;
  flex: 3;
  font-size: 14px;
  color: #122d58;
  font-weight: bold;
  text-align: start;
`;

export const Text = styled.text`
  flex: flex;
  flex: 0.2;
  font-size: 14px;
  color: #122d58;
  font-weight: bold;
  text-align: start;
`;

export const FieldValue = styled.text`
  flex: flex;
  flex: 1;
  font-size: 14px;
  color: ${(props) => (props?.negative ? 'red' : '#122d58')};
  font-weight: bold;
  text-align: end;
`;
