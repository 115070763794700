import styled from 'styled-components';
import InputCurrency from 'react-currency-input';

export const Container = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 80%;

  @media (min-width: 1920px) {
    width: 50%;
  }
`;
export const InnerSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  margin-bottom: 7px;
`;

export const Currency = styled(InputCurrency)`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  text-align: center;
  color: red;
  font-weight: 600;
  width: 100%;
`;
