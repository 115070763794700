import React from 'react';
import { Formik } from 'formik';
import { bool, func, arrayOf, object } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import TruckerActions from 'store/ducks/trucker';
import Selector from 'components/Selector';

import { schema } from './schemaValidation';
import SeeImage from '../SeeImage';

import { Container, Body, WrapperBottom, BackButton } from './styles';

function ModalComp({ open, onClose, data }) {
  const dispatch = useDispatch();

  function handleSubmitted(values) {
    dispatch(
      TruckerActions.updateAditionalDocumentRequest(
        values,
        data.document,
        data.id,
        onClose
      )
    );
  }
  return (
    <Modal open={open} onClose={onClose} title="DOCUMENTO ADICIONAL">
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            plate: data ? data.plate : '',
            chassi: data ? data.chassi : '',
            renavam: data ? data.renavam : '',
            cpfCnpj: data ? data.cpfCnpj : '',
            rntc: data ? data.rntc : '',
            uf_plate: data ? data.uf_plate : '',
            capacity_kg: data?.capacity_kg ? data.capacity_kg : '',
            capacity_m3: data?.capacity_m3 ? data.capacity_m3 : '',
            tara_kg: data ? data.tara_kg : '',
            type_rotated: data?.tara_kg ? data.type_rotated : '',
            type_bodywork: data ? data.type_bodywork : '',
            qtd_axes: data ? data.qtd_axes : '',
          }}
          onSubmit={(values) => handleSubmitted(values)}
          validationSchema={schema}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <Body>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xs>
                        <SeeImage
                          image={data?.document}
                          text="Foto do Renavan do veículo"
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <SeeImage
                          image={data?.holding_document}
                          text="Foto do motorista com o Renavan"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Placa do veículo"
                          name="plate"
                          helperText={touched.plate ? errors.plate : ''}
                          error={Boolean(errors.plate)}
                          value={values.plate}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Chassi"
                          name="chassi"
                          helperText={touched.chassi ? errors.chassi : ''}
                          error={Boolean(errors.chassi)}
                          value={values.chassi}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Renavam"
                          name="renavam"
                          helperText={touched.renavam ? errors.renavam : ''}
                          error={Boolean(errors.renavam)}
                          value={values.renavam}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="CNPJ/CPF do proprietário"
                          name="cpfCnpj"
                          helperText={touched.cpfCnpj ? errors.cpfCnpj : ''}
                          error={Boolean(errors.cpfCnpj)}
                          value={values.cpfCnpj}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="RNTC"
                          name="rntc"
                          helperText={touched.rntc ? errors.rntc : ''}
                          error={Boolean(errors.rntc)}
                          value={values.rntc}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="UF da Placa"
                          name="uf_plate"
                          helperText={touched.uf_plate ? errors.uf_plate : ''}
                          error={Boolean(errors.uf_plate)}
                          value={values.uf_plate}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Capacidade (KG)"
                          name="capacity_kg"
                          helperText={
                            touched.capacity_kg ? errors.capacity_kg : ''
                          }
                          error={Boolean(errors.capacity_kg)}
                          value={values.capacity_kg}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Capacidade (M3)"
                          name="capacity_m3"
                          helperText={
                            touched.capacity_m3 ? errors.capacity_m3 : ''
                          }
                          error={Boolean(errors.capacity_m3)}
                          value={values.capacity_m3}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Tara (KG)"
                          name="tara_kg"
                          helperText={touched.tara_kg ? errors.tara_kg : ''}
                          error={Boolean(errors.tara_kg)}
                          value={values.tara_kg}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <Selector
                          label="Tipo de Rodado"
                          defaulOption="Selecione o rodado"
                          options={[
                            { text: 'Truck', value: 'Truck' },
                            { text: 'Toco', value: 'Toco' },
                            {
                              text: 'Cavalo Mecânico',
                              value: 'Cavalo Mecânico',
                            },
                            { text: 'VAN', value: 'VAN' },
                            { text: 'Utilitário', value: 'Utilitário' },
                            { text: 'Outros', value: 'Outros' },
                          ]}
                          type="secondary"
                          name="type_rotated"
                          error={
                            touched.type_rotated && Boolean(errors.type_rotated)
                          }
                          value={values.type_rotated}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <Selector
                          label="Tipo de Carroceria"
                          defaulOption="Selecione a carroceria"
                          options={[
                            { text: 'Não aplicável', value: 'Não aplicável' },
                            { text: 'Aberta', value: 'Aberta' },
                            { text: 'Fechada/Baú', value: 'Fechada/Baú' },
                            { text: 'Granelera', value: 'Granelera' },
                            {
                              text: 'Porta Container',
                              value: 'Porta Container',
                            },
                            { text: 'Sider', value: 'Sider' },
                          ]}
                          type="secondary"
                          name="type_bodywork"
                          error={
                            touched.type_bodywork &&
                            Boolean(errors.type_bodywork)
                          }
                          value={values.type_bodywork}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs>
                        <TextInput
                          label="Quantidade de Eixos"
                          name="qtd_axes"
                          helperText={touched.qtd_axes ? errors.qtd_axes : ''}
                          error={Boolean(errors.qtd_axes)}
                          value={values.qtd_axes}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Body>
              <WrapperBottom>
                <BackButton onClick={onClose}>Voltar</BackButton>
                <BackButton onClick={handleSubmit}>Salvar</BackButton>
              </WrapperBottom>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  data: arrayOf(object).isRequired,
};
