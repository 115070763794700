/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Images from 'assets/img';
import Button from 'components/Button';
import { MapWithAMarker } from 'components/MapConvocationDetails';
import Paper from 'components/Paper';
import Map from 'components/Map';
import useSnapshot from 'hooks/useGetUserLocation';
import QRCode from 'pages/Convocations/components/QRCode';
import ConvocationsActions from 'store/ducks/convocations';
import TruckerActions from 'store/ducks/trucker';
import Box from './components/Box';
import Status from './components/Status';
import {
  BoxDescriptions,
  BoxMap,
  BoxStatus,
  Center,
  Container,
  Line,
  Loading,
  SubtitleDescription,
  TitleDescription,
  WrapperButton,
} from './styles';

function ConvocationsDetails() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const key = process.env.REACT_APP_MAPS_KEY;
  const [positionCollect, setPositionCollect] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [positionDelivery, setPositionDelivery] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [provider, setProvider] = React.useState({});
  const [convocation, setConvocation] = React.useState({});
  const { oneTrucker } = useSelector((state) => state.trucker);
  const { openModalQrCode } = useSelector((state) => state.convocations);
  const [convocationId, setConvocationId] = useState(null);

  const truckerLocation = useSnapshot(convocationId);

  const { latitude, longitude } = truckerLocation;

  useEffect(() => {
    state.convocations.map((item) => {
      if (item.id === state.selectedId) {
        state.providers.map((prov) => {
          if (item.provider_id === prov.id) {
            setProvider(prov);
          }

          if (item.collect_lat) {
            console.log(item.collect_lat);
            setPositionCollect({
              latitude: item.collect_lat,
              longitude: item.collect_lng,
            });
          }
          if (item.delivery_lat) {
            setPositionDelivery({
              latitude: item.delivery_lat,
              longitude: item.delivery_lng,
            });
          }

          return null;
        });

        if (item.user_id) {
          dispatch(TruckerActions.getOneTruckerRequest(item.user_id));
          // setConvocationId(item.user_id)
        }
        setConvocation(item);
        dispatch(ConvocationsActions.setConvocationRequest(item));
      }
      return null;
    });
  }, [state.selectedId]);

  function goToQrcode() {
    dispatch(ConvocationsActions.openModalQrcode());
  }

  return (
    <Container>
      <Paper
        title={`CARGA - # ${state.selectedId}`}
        icon={Images.IconGrayTruck}
      >
        {latitude !== undefined ? (
          <BoxMap>
            <MapWithAMarker
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
              containerElement={
                <div
                  style={{
                    height: `285px`,

                    overflow: 'hidden',
                    // boxShadow:
                    //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              loadingElement={
                <Center>
                  <Loading />
                </Center>
              }
              progresso={{ latitude, longitude }}
              posCollect={positionCollect}
              truckerLocation={truckerLocation}
              positionDest={positionDelivery}
            />
          </BoxMap>
        ) : (
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={
              <Center>
                <Loading />
              </Center>
            }
            containerElement={
              <div
                style={{
                  height: `285px`,
                  // borderRadius: '11px',
                  overflow: 'hidden',
                  // boxShadow:
                  //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
            position={positionCollect}
            positionDest={positionDelivery}
          />
        )}

        <Center column>
          <BoxStatus>
            <Status
              image={Images.StatusZero}
              description="Não iniciada"
              status={convocation?.status === 'initial'}
            />
            <Line />
            <Status
              image={Images.StatusOne}
              description="Motorista encontrado"
              status={convocation?.status === 'in_progress_req'}
            />
            <Line />
            <Status
              image={Images.StatusTwo}
              description="Carga coletada"
              status={
                convocation?.status === 'arrived_requester' &&
                convocation?.qrcode_requester
              }
            />
            <Line />
            <Status
              image={Images.StatusThree}
              description="A caminho"
              status={convocation?.status === 'in_progress_res'}
            />
            <Line />
            <Status
              image={Images.StatusFour}
              description="Carga Entregue"
              status={
                convocation?.status === 'arrived_responser' &&
                convocation?.qrcode_responser
              }
            />
            <Line />
            <Status
              image={Images.StatusFive}
              description="Carga finalizada"
              status={convocation?.status === 'completed'}
            />
          </BoxStatus>
          <BoxDescriptions>
            <Box title="SOLICITANTE">
              <TitleDescription>SOLICITANTE</TitleDescription>
              <SubtitleDescription>
                {provider?.social_reason}
              </SubtitleDescription>
              <SubtitleDescription>{provider?.cnpj}</SubtitleDescription>

              <TitleDescription>RESPONSÁVEL</TitleDescription>
              <SubtitleDescription>{provider?.owner}</SubtitleDescription>
              <SubtitleDescription>{provider?.phone}</SubtitleDescription>
              <SubtitleDescription>{provider?.email}</SubtitleDescription>
            </Box>
            <Box title="CARGA">
              <TitleDescription>PESO TOTAL DA CARGA</TitleDescription>
              <SubtitleDescription>
                {convocation?.weight} kg
              </SubtitleDescription>

              <TitleDescription>TIPO DE CARGA</TitleDescription>
              <SubtitleDescription>{convocation?.type}</SubtitleDescription>

              <TitleDescription>VALOR DO FRETE</TitleDescription>
              <SubtitleDescription>{convocation?.value}</SubtitleDescription>
            </Box>
            <Box title="CONDUTOR">
              <TitleDescription>CONDUTOR</TitleDescription>
              <SubtitleDescription>
                {convocation.user_id && oneTrucker?.name
                  ? oneTrucker?.name
                  : 'Não definido'}
              </SubtitleDescription>

              <TitleDescription>CONTATO</TitleDescription>
              <SubtitleDescription>
                {convocation.user_id && oneTrucker?.phone
                  ? oneTrucker?.phone
                  : ''}
              </SubtitleDescription>
              <SubtitleDescription>
                {convocation.user_id && oneTrucker?.email
                  ? oneTrucker?.email
                  : ''}
              </SubtitleDescription>
            </Box>
            <Box title="CORRESPONDENTE">
              <TitleDescription>CORRESPONDENTE DA COLETA</TitleDescription>
              <SubtitleDescription>
                {convocation?.collect_corresponding}
              </SubtitleDescription>
              <SubtitleDescription>
                {convocation?.collect_corresponding_phone}
              </SubtitleDescription>
              <SubtitleDescription>
                {convocation?.collect_corresponding_email}
              </SubtitleDescription>

              <TitleDescription>CORRESPONDENTE DA ENTREGA</TitleDescription>
              <SubtitleDescription>
                {convocation?.delivery_corresponding}
              </SubtitleDescription>
              <SubtitleDescription>
                {convocation?.delivery_corresponding_phone}
              </SubtitleDescription>
              <SubtitleDescription>
                {convocation?.delivery_corresponding_email}
              </SubtitleDescription>

              <WrapperButton>
                <Button
                  icon={Images.Qrcode}
                  label="ENVIAR QR CODE"
                  onClick={goToQrcode}
                />
              </WrapperButton>
            </Box>
          </BoxDescriptions>
        </Center>
      </Paper>
      {openModalQrCode && (
        <QRCode
          convocationKey={state.selectedId}
          open={openModalQrCode}
          name={provider?.owner ? provider?.owner : 'Não definido'}
          phone={provider?.phone ? provider?.phone : ''}
        />
      )}
    </Container>
  );
}

export default ConvocationsDetails;
