import { Popover } from '@material-ui/core';
import React from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Backdrop } from './styles';

export default function Layout({ open, popoverPosition, children }) {
  const { height, width } = useWindowDimensions();

  return width > 768 ? (
    <Popover
      anchorEl={popoverPosition}
      open={open}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          padding: '0 !important',
          borderRadius: '10px',
        },
      }}
    >
      {children}
    </Popover>
  ) : (
    <Backdrop open={open}>{children}</Backdrop>
  );
}
