import styled from 'styled-components';

export const Container = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.status ? 1 : 0.6)};
`;

export const Box = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 5px;
  /* border: 1px solid #122d58; */
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const Description = styled.span`
  color: #122c58;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-top: 22px;
`;
