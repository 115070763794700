import styled from 'styled-components';

export const Container = styled.div`
  width: 279px;
  height: 301px;
  background: #f7f7f7;
  border-radius: 6px;
  margin: 0 24px;
`;

export const Header = styled.div`
  width: 100%;
  height: 47px;
  background: #566d91;
  padding: 14px 28px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const Title = styled.span`
  font-size: 16px;
  color: white;
  font-weight: bold;
  font-family: 'Montserrat';
`;

export const Description = styled.div`
  padding: 0px 28px;
  display: flex;
  flex-direction: column;
`;
