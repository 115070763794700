import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #afafaf;
  margin-bottom: 4px;
  margin-left: 11px;
  white-space: nowrap;
  margin-right: 10px;
`;

export const Box = styled.div`
  display: flex;
  padding: 7px 11px;
  align-items: center;
  height: 29px;
  min-width: 180px;
  background-color: ${(props) => (props.gray ? '#F7F7F7' : '#fff')};
`;

export const Number = styled.div`
  font-size: ${(props) => (props.small ? '16px' : '25px')};
  font-weight: ${(props) => (props.small ? '500' : '900')};
  color: ${(props) => props.color};
  margin-left: 11px;
  white-space: nowrap;
`;
