export const customSelectStyles = {
  control: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: 'white',
    display: 'flex',
    width: 250,
    borderColor: '#ebebeb',
    minHeight: 32,
    height: 32,

    ':active': {
      ...styles[':active'],
      borderColor: !isDisabled && (isSelected ? '#122d58' : '#cecece'),
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      width: 250,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#122d58'
        : isFocused
        ? '#cecece'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : 'gray',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#122d58' : 'gray'),
      },
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: '#122d58', // Custom colour
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
