import styled from 'styled-components';

export const WrapperImage = styled.div`
  overflow: hidden;
  width: 95px;
  height: 95px;
  margin-right: 16px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const SeeImageText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
  margin-bottom: 9px;
`;
