import React from 'react';
import Images from 'assets/img';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Loading } from 'pages/Chat/styles';
import MarkerLetter from 'pages/Convocations/components/MarkerLetter';
import {
  capitalize,
  getImgType,
  getWeight,
  zipcode,
} from 'pages/Convocations/helpers';
import { Row } from 'styles/components';
import { InfoBox } from '../../styles';
import { ImageWithLabelAndValue, TitleBox } from './styles';

export default function ChargeInfoThree({ selectedConvocation, cte, user }) {
  const { height, width } = useWindowDimensions();

  return user != null ? (
    <InfoBox>
      <TitleBox>Informações do Motorista</TitleBox>
      {width > 425 ? (
        <Row>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Motorista</p>
              <p className="value">
                {user.name ? capitalize(user.name) : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">CNH</p>
              <p className="value">{user.cnh ?? 'Não informado'}</p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Categoria</p>
              <p className="value">{user.driverCategory ?? 'Não informado'}</p>
            </div>
          </ImageWithLabelAndValue>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Tipo de transportador</p>
              <p className="value">
                {user.transport_type
                  ? user.transport_type.toString().toUpperCase()
                  : 'Não informado'}
              </p>
            </div>
          </ImageWithLabelAndValue>
        </Row>
      ) : (
        <>
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Motorista</p>
                <p className="value">
                  {user.name ? capitalize(user.name) : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">CNH</p>
                <p className="value">{user.cnh ?? 'Não informado'}</p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Categoria</p>
                <p className="value">
                  {user.driverCategory ?? 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Tipo de transportador</p>
                <p className="value">
                  {user.transport_type
                    ? user.transport_type.toString().toUpperCase()
                    : 'Não informado'}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        </>
      )}
    </InfoBox>
  ) : (
    <InfoBox>
      <Loading />
    </InfoBox>
  );
}
