import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { MdExitToApp } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';

const drawerWidth = 240;

export const Wrapper = styled.div`
  height: 100%;
  background: #f0f0f0;
`;

export const Logo = styled.img`
  width: 84px;
  height: 34px;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#122D58',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const ContainerProfile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperProfile = styled.div`
  height: 35px;
  width: 35px;
  border: 2px solid #fff;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 48px;
`;

export const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PopoverRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  cursor: pointer;
`;

export const ExitIcon = styled(MdExitToApp).attrs({ size: '18px' })`
  color: #122d58;
  margin-right: 13px;
`;

export const ProfileIcon = styled(FaUser).attrs({ size: '18px' })`
  color: #122d58;
  margin-right: 13px;
`;

export const PopoverText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #7b7b7b;
`;
