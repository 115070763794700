import React, { useState, useEffect, useCallback } from 'react';
import BooleanWrapper from 'components/BooleanWrapper';
import Button from 'components/Button';
import Selector from 'components/Selector';
import TextInput from 'components/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import TripActions from 'store/ducks/trip';
import { useDropzone } from 'react-dropzone';
import InputMask from 'react-input-mask';
import { Input } from 'components/TextInput/styles';
import cep from 'cep-promise';

import CustomTable from '../../components/CustomTable';
import Section from '../../components/Section';
import {
  Container,
  WrapperSection,
  DeleteIcon,
  FieldLabel,
  WrapperInput,
  WrapperTable,
  ItemTable,
  Column,
  Currency,
  DragDrop,
  Row,
  Content,
  Error,
} from './styles';
import parser from 'fast-xml-parser';
import he from 'he';
import getInsurance from 'services/getInsurance';
import InsurancesActions from 'store/ducks/insurances';

function Cte({ formik, edit }) {
  const dispatch = useDispatch();
  const { insurances } = useSelector((state) => state.insurances);

  const [formErrors, setFormErrors] = useState({});
  const [fullAddress, setFullAddress] = useState({
    cep: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const sumVolPesNfe = (item, param) => {
    let value = 0;
    for (let index = 0; index < item.length; index++) {
      if (param === 'qVol') {
        value = item[index].qVol;
      } else {
        value = item[index].pesoB;
      }
    }

    return value;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFormErrors({ xml: '' });
      let errorNfe = [];

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const binaryStr = reader.result;

          //Converte Xml para Json
          const jsonCte = addXmltoJson(binaryStr);
          const defaultCpf = '0'.repeat(11);
          const defaultCnpj = '0'.repeat(14);

          //Se retornou json adiciona a nota fiscal
          if (jsonCte) {
            const { NFe } = jsonCte?.NFe ? jsonCte : jsonCte.nfeProc;

            if (binaryStr.indexOf('Id=') < 0 || !NFe) {
              setFormErrors({ xml: 'O arquivo selecionado é inválido!' });
            } else {
              const chave = binaryStr.substr(binaryStr.indexOf('Id=') + 7, 44);

              if (!validateDuplicityCte(chave)) {
                const data_cte = formik.values.invoices
                  ? [...formik.values.invoices]
                  : [];
                data_cte.push({
                  cnpj: maskField(
                    NFe.infNFe.dest.CPF
                      ? (defaultCpf + NFe.infNFe.dest.CPF).slice(-11)
                      : (defaultCnpj + NFe.infNFe.dest.CNPJ).slice(-14)
                  ),
                  barcode: chave,
                  nfeValue:
                    NFe.infNFe.pag.detPag.vPag || NFe.infNFe.total.ICMSTot.vNF,
                  volume:
                    NFe.infNFe.transp.vol?.qVol ||
                    sumVolPesNfe(NFe.infNFe.transp.vol, 'qVol'),
                  weight:
                    NFe.infNFe.transp.vol?.pesoB ||
                    sumVolPesNfe(NFe.infNFe.transp.vol, 'pesoB'),
                  payment_responser:
                    NFe.infNFe.transp.modFrete === 0 ? '1' : '2',
                  data: {
                    city: NFe.infNFe.dest.enderDest.xMun.trim(),
                    complement: NFe.infNFe.dest.enderDest.xCpl?.trim() || '',
                    country: 'Brasil',
                    district: NFe.infNFe.dest.enderDest.xBairro.trim(),
                    name: NFe.infNFe.dest.xNome.trim(),
                    number: NFe.infNFe.dest.enderDest.nro?.toString() || 'SN',
                    state: NFe.infNFe.dest.enderDest.UF.trim(),
                    street: NFe.infNFe.dest.enderDest.xLgr.trim(),
                    zipcode: NFe.infNFe.dest.enderDest.CEP?.toString(),
                  },
                });

                if (NFe.infNFe.dest.CPF) {
                  data_cte.cpf = maskField(
                    (defaultCpf + NFe.infNFe.dest.CPF).slice(-11)
                  );

                  data_cte[data_cte.length - 1].receiver = {
                    name: NFe.infNFe.dest.xNome,
                    city: NFe.infNFe.dest.enderDest.xMun,
                    complement: NFe.infNFe.dest.enderDest.xCpl || '',
                    neighborhood: NFe.infNFe.dest.enderDest.xBairro,
                    number: NFe.infNFe.dest.enderDest.nro || 'SN',
                    state: NFe.infNFe.dest.enderDest.UF,
                    street: NFe.infNFe.dest.enderDest.xLgr,
                    zipcode: NFe.infNFe.dest.enderDest.CEP,
                    phone:
                      NFe.infNFe.dest.enderDest.fone.toString().length >= 11
                        ? NFe.infNFe.dest.enderDest.fone
                            .toString()
                            .replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
                        : NFe.infNFe.dest.enderDest.fone
                            .toString()
                            .replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'),
                    email: NFe.infNFe.dest.email,
                  };
                }

                formik.setFieldValue('invoices', data_cte);
                formik.values.invoices = data_cte;

                //atualiza valor do seguro se tiver
                if (formik.values.type_insurance !== '') {
                  getInsurance(
                    formik.values.type_insurance,
                    formik,
                    insurances
                  );
                }
              } else {
                errorNfe.push('A chave ' + chave + ' já foi incluida!');
                setFormErrors({ xml: errorNfe });
              }
            }
          } else {
            setFormErrors({ xml: 'O arquivo selecionado é inválido!' });
          }
        };
        reader.readAsBinaryString(file);
      });
    },
    [formik]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  useEffect(() => {
    dispatch(TripActions.getCtesRequest());
    dispatch(InsurancesActions.getInsurancesRequest());
  }, [dispatch]);

  const handleCteInput = () => {
    const data_cte = formik.values.invoices ? [...formik.values.invoices] : [];
    const errors = validateRequester(formik);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      data_cte.push({
        barcode: formik.values.cte_key,
        cnpj: formik.values.delivery_cnpj,
        nfeValue: parseFloat(
          formik.values.romaneio.delivery_value
            .substr(3, formik.values.romaneio.delivery_value.length)
            .replace('.', '')
            .replace(',', '.')
        ),
        transportValue: parseFloat(
          formik.values.freight_value
            .substr(3, formik.values.freight_value.length)
            .replace('.', '')
            .replace(',', '.')
        ),
        volume: formik.values.romaneio.delivery_size,
        weight: formik.values.romaneio.weight,
        payment_responser:
          formik.values.payment_responser === 'Remetente' ? '1' : '2',
      });

      if (formik.values.delivery_cnpj.length <= 14) {
        data_cte.cpf = formik.values.delivery_cnpj;

        data_cte[data_cte.length - 1].receiver = {
          city: formik.values.romaneio.receiver.city,
          complement: formik.values.romaneio.receiver.complement,
          email: formik.values.romaneio.receiver.email,
          name: formik.values.romaneio.receiver.name,
          neighborhood: formik.values.romaneio.receiver.neighborhood,
          number: formik.values.romaneio.receiver.number,
          phone: formik.values.romaneio.receiver.phone,
          state: formik.values.romaneio.receiver.state,
          street: formik.values.romaneio.receiver.street,
          zipcode: formik.values.romaneio.receiver.zipcode,
        };
      }

      formik.setFieldValue('invoices', data_cte);
      formik.values.invoices = data_cte;

      //atualiza valor do seguro se tiver
      if (formik.values.type_insurance !== '') {
        getInsurance(formik.values.type_insurance, formik, insurances);
      }

      clearFields();
    }
  };

  const clearFields = () => {
    formik.setFieldValue('delivery_cnpj', '');
    formik.setFieldValue('cte_key', '');
    formik.setFieldValue('delivery_value', '');
    formik.setFieldValue('delivery_size', '');
    formik.setFieldValue('weight', '');
    formik.setFieldValue('romaneio.receiver.phone', '');
    formik.setFieldValue('romaneio.receiver.name', '');
    formik.setFieldValue('romaneio.receiver.zipcode', '');
    formik.setFieldValue('romaneio.receiver.street', '');
    formik.setFieldValue('romaneio.receiver.complement', '');
    formik.setFieldValue('romaneio.receiver.neighborhood', '');
    formik.setFieldValue('romaneio.receiver.city', '');
    formik.setFieldValue('romaneio.receiver.state', '');
    formik.setFieldValue('romaneio.receiver.number', '');
    formik.setFieldValue('romaneio.receiver.email', '');
    formik.setFieldValue('unload_value', '');
    formik.setFieldValue('payment_responser', 'Remetente');
  };

  const validateCte = (value) => {
    // Valida se tem a quantidade correta de caracter
    if (value.replace(/\D/g, '').length === 44) {
      // return true
      const key = value.replace(/\D/g, '').substring(0, 43);

      let multiplier = 2; // [2, 3, 4, 5, 6, 7, 8, 9]
      let totalizer = 0;
      const checker = value.replace(/\D/g, '')[43];
      let i = key.length - 1;

      while (i >= 0) {
        if (multiplier > 9) multiplier = 2;

        totalizer += key[i] * multiplier;
        multiplier++;

        i--;
      }

      const rest = totalizer % 11;
      let digit = 0;
      if (rest === 0 || rest === 1) {
        digit = 0;
      } else {
        digit = 11 - rest;
      }

      if (checker == digit) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const validateDuplicityCte = (value) => {
    const data_cte = formik.values.invoices ? [...formik.values.invoices] : [];
    let exist = false;

    data_cte.forEach((item, index) => {
      if (item.barcode === value) {
        exist = true;
        return;
      }
    });

    return exist;
  };

  const validateRequester = () => {
    const errors = {};

    if (formik.values.delivery_cnpj.length === 0) {
      errors.delivery_cnpj = 'O CNPJ é obrigatório';
    }

    if (formik.values.cte_key.length === 0) {
      errors.cte_key = 'A chave é obrigatório';
    }

    if (
      formik.values.cte_key.length > 0 &&
      !validateCte(formik.values.cte_key)
    ) {
      errors.cte_key = 'A chave informada é inválida!';
    }

    if (
      formik.values.cte_key.length > 0 &&
      validateDuplicityCte(formik.values.cte_key)
    ) {
      errors.cte_key = 'A chave informada já foi adicionada!';
    }

    if (formik.values.romaneio.delivery_value.length === 0) {
      errors.delivery_value = 'O valor da nota é obrigatório';
    }

    if (formik.values.romaneio.delivery_size.length === 0) {
      errors.delivery_size = 'O Volume é obrigatório';
    }

    if (formik.values.romaneio.weight.length === 0) {
      errors.romaneio_weight = 'O Peso é obrigatório';
    }

    if (formik.values.delivery_cnpj.length <= 14) {
      if (formik.values.romaneio.receiver.phone.length === 0) {
        errors.receiver_phone = 'O Telefone é obrigatório';
      }

      if (formik.values.romaneio.receiver.name.length === 0) {
        errors.receiver_name = 'O Nome é obrigatório';
      }

      if (formik.values.romaneio.receiver.zipcode.length === 0) {
        errors.receiver_zipcode = 'O Cep é obrigatório';
      }

      if (formik.values.romaneio.receiver.street.length === 0) {
        errors.receiver_street = 'O Endereço é obrigatório';
      }

      if (formik.values.romaneio.receiver.neighborhood.length === 0) {
        errors.receiver_neighborhood = 'O Bairro é obrigatório';
      }

      if (formik.values.romaneio.receiver.city.length === 0) {
        errors.receiver_city = 'A Cidade é Obrigatória';
      }

      if (formik.values.romaneio.receiver.state.length === 0) {
        errors.receiver_state = 'O Estado é obrigatório';
      }

      if (formik.values.romaneio.receiver.email.length === 0) {
        errors.receiver_email = 'O E-mail é obrigatório';
      }
    }

    return errors;
  };

  const addXmltoJson = (Xmlcte) => {
    const options = {
      attributeNamePrefix: '@_',
      attrNodeName: 'attr', //default is 'false'
      textNodeName: '#text',
      ignoreAttributes: true,
      ignoreNameSpace: false,
      allowBooleanAttributes: false,
      parseNodeValue: true,
      parseAttributeValue: false,
      trimValues: true,
      cdataTagName: '__cdata', //default is 'false'
      cdataPositionChar: '\\c',
      parseTrueNumberOnly: false,
      numParseOptions: {
        hex: true,
        leadingZeros: true,
        //skipLike: /\+[0-9]{10}/
      },
      arrayMode: false, //"strict"
      attrValueProcessor: (val, attrName) =>
        he.decode(val, { isAttributeValue: true }), //default is a=>a
      tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
      stopNodes: ['parse-me-as-string'],
      alwaysCreateTextNode: false,
    };

    if (parser.validate(Xmlcte) === true) {
      //optional (it'll return an object in case it's not valid)
      let jsonObj = parser.parse(Xmlcte, options);
    }

    const tObj = parser.getTraversalObj(Xmlcte, options);
    return parser.convertToJson(tObj, options);
  };

  const handleCteDelete = (cte) => {
    const data_cte = formik.values.invoices ? [...formik.values.invoices] : [];

    data_cte.forEach((item, index) => {
      if (item.barcode === cte.barcode) {
        data_cte.splice(index, 1);
      }
    });

    formik.setFieldValue('invoices', data_cte);
    formik.values.invoices = data_cte;

    //atualiza valor do seguro se tiver
    if (formik.values.type_insurance !== '') {
      getInsurance(formik.values.type_insurance, formik, insurances);
    }
  };

  const maskField = (value) => {
    let fieldValue = value.replace(/\D/g, '').substr(0, 14);

    if (fieldValue.length <= 11) {
      fieldValue = fieldValue.replace(/(\d{3})(\d)/, '$1.$2');
      fieldValue = fieldValue.replace(/(\d{3})(\d)/, '$1.$2');
      fieldValue = fieldValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      fieldValue = fieldValue.replace(/^(\d{2})(\d)/, '$1.$2');
      fieldValue = fieldValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      fieldValue = fieldValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      fieldValue = fieldValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return fieldValue;
  };

  const handleCepChange = (cepRequested) => {
    cep(cepRequested)
      .then((cepResponse) => {
        const address = {
          cep: cepRequested,
          street: cepResponse.street,
          neighborhood: cepResponse.neighborhood,
          city: cepResponse.city,
          state: cepResponse.state,
        };
        setFullAddress(address);

        formik.setFieldValue(`romaneio.receiver.street`, cepResponse.street);
        formik.setFieldValue(
          `romaneio.receiver.neighborhood`,
          cepResponse.neighborhood
        );
        formik.setFieldValue(`romaneio.receiver.city`, cepResponse.city);
        formik.setFieldValue(`romaneio.receiver.state`, cepResponse.state);
      })
      .catch(() => {
        setFullAddress({
          cep: cepRequested,
          street: formik.values.address,
          neighborhood: formik.values.neighborhood,
          city: '',
          state: '',
        });
        formik.setFieldError(`zipcode`, 'CEP não encontrado');
      });
  };

  const tableRaw =
    formik.values.invoices.length > 0
      ? formik.values.invoices.map((invoice) => ({
          ...invoice,
          cnpj: invoice.cnpj,
          barcode: invoice.barcode,
          invoiceNumber: invoice.barcode?.match(/\d{44}/)[0].substr(25, 9),
          name: invoice.data?.name || 'Não informado',
        }))
      : null;

  return (
    <Section title="Notas Fiscais">
      <Container>
        <Content>
          <Column>
            <Row>
              <WrapperInput>
                <FieldLabel>Responsável pelos dados: </FieldLabel>
                <Selector
                  name="cte_type"
                  type="secondary"
                  placeholder=""
                  value={formik.values.cte_type}
                  minWidth="20vw"
                  options={[
                    { text: 'Selecione', value: '' },
                    { text: 'Motorista', value: 'motorista' },
                    { text: 'Embarcador', value: 'embarcador' },
                  ]}
                  onChange={formik.handleChange}
                />
              </WrapperInput>
            </Row>
            {formik.values.cte_type === 'embarcador' ? (
              // <Section title="Dados da Nota" fontSize={'10px'}>
              <Row>
                <Column>
                  <Row>
                    <WrapperInput>
                      <TextInput
                        id="cte_key"
                        label="Chave Nf-e"
                        type="text"
                        name="cte_key"
                        width="100%"
                        minWidth="15.5vw"
                        error={!!formErrors.cte_key}
                        value={formik.values.cte_key}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            'cte_key',
                            e.target.value.replace(/\D/g, '').substr(0, 44)
                          );
                          formErrors.cte_key = null;
                          formErrors.xml = null;
                        }}
                        onBlur={''}
                      />
                      {formErrors.cte_key ? (
                        <Error>{formErrors.cte_key}</Error>
                      ) : null}
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        id="delivery_cnpj"
                        label="CNPJ/CPF Destinatário"
                        type="text"
                        name="delivery_cnpj"
                        minWidth="15.5vw"
                        error={!!formErrors.delivery_cnpj}
                        value={formik.values.delivery_cnpj}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            'delivery_cnpj',
                            maskField(e.target.value)
                          );
                          formErrors.delivery_cnpj = null;
                        }}
                        onBlur={''}
                      />
                      {formErrors.delivery_cnpj ? (
                        <Error>{formErrors.delivery_cnpj}</Error>
                      ) : null}
                    </WrapperInput>
                  </Row>

                  {formik.values.delivery_cnpj.length > 0 &&
                  formik.values.delivery_cnpj.length <= 14 ? (
                    <Column>
                      <Row>
                        <WrapperInput>
                          <TextInput
                            label="Nome"
                            name="romaneio.receiver.name"
                            placeholder=""
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_name}
                            value={formik.values.romaneio.receiver.name}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_name = null;
                            }}
                            onBlur={''}
                          />
                          {formErrors.receiver_name ? (
                            <Error>{formErrors.receiver_name}</Error>
                          ) : null}
                        </WrapperInput>

                        <WrapperInput>
                          <FieldLabel>Telefone</FieldLabel>
                          <InputMask
                            mask="(99) 99999-9999"
                            name="romaneio.receiver.phone"
                            placeholder=""
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_phone}
                            value={formik.values.romaneio.receiver.phone}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_phone = null;
                            }}
                            onBlur={formik.handleBlur}
                          >
                            {(inputProps) => <Input {...inputProps} />}
                          </InputMask>

                          {formErrors.receiver_phone ? (
                            <Error>{formErrors.receiver_phone}</Error>
                          ) : null}
                        </WrapperInput>
                      </Row>
                      <Row>
                        <WrapperInput>
                          <TextInput
                            label="CEP"
                            type="number"
                            name="romaneio.receiver.zipcode"
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_zipcode}
                            value={formik.values.romaneio.receiver.zipcode}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_zipcode = null;
                              formErrors.receiver_street = null;
                              formErrors.receiver_neighborhood = null;
                              formErrors.receiver_city = null;
                              formErrors.receiver_state = null;
                            }}
                            onBlur={(e) => handleCepChange(e.target.value)}
                          />

                          {formErrors.receiver_zipcode ? (
                            <Error>{formErrors.receiver_zipcode}</Error>
                          ) : null}
                        </WrapperInput>

                        <WrapperInput>
                          <TextInput
                            label="Endereço"
                            name="romaneio.receiver.street"
                            placeholder=""
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_street}
                            value={formik.values.romaneio.receiver.street}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_street = null;
                            }}
                            onBlur={''}
                          />

                          {formErrors.receiver_street ? (
                            <Error>{formErrors.receiver_street}</Error>
                          ) : null}
                        </WrapperInput>
                      </Row>

                      <Row>
                        <WrapperInput>
                          <TextInput
                            label="Complemento"
                            name="romaneio.receiver.complement"
                            placeholder=""
                            minWidth="15.5vw"
                            value={formik.values.romaneio.receiver.complement}
                            onChange={formik.handleChange}
                            onBlur={''}
                          />
                        </WrapperInput>

                        <WrapperInput>
                          <TextInput
                            id="romaneio.receiver.number"
                            label="Numero"
                            type="number"
                            width="100%"
                            minWidth="15.5vw"
                            name="romaneio.receiver.number"
                            value={formik.values.romaneio.receiver.number}
                            onChange={formik.handleChange}
                            onBlur={''}
                          />
                        </WrapperInput>
                      </Row>

                      <Row>
                        <WrapperInput>
                          <TextInput
                            label="Bairro"
                            name="romaneio.receiver.neighborhood"
                            placeholder=""
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_neighborhood}
                            value={formik.values.romaneio.receiver.neighborhood}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_neighborhood = null;
                            }}
                            onBlur={''}
                          />

                          {formErrors.receiver_neighborhood ? (
                            <Error>{formErrors.receiver_neighborhood}</Error>
                          ) : null}
                        </WrapperInput>

                        <WrapperInput>
                          <TextInput
                            label="Cidade"
                            name="romaneio.receiver.city"
                            placeholder=""
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_city}
                            value={formik.values.romaneio.receiver.city}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_city = null;
                            }}
                            onBlur={''}
                          />

                          {formErrors.receiver_city ? (
                            <Error>{formErrors.receiver_city}</Error>
                          ) : null}
                        </WrapperInput>
                      </Row>

                      <Row>
                        <WrapperInput>
                          <TextInput
                            label="Estado"
                            name="romaneio.receiver.state"
                            placeholder=""
                            minWidth="15.5vw"
                            error={!!formErrors.receiver_state}
                            value={formik.values.romaneio.receiver.state}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_state = null;
                            }}
                            onBlur={''}
                          />
                          {formErrors.receiver_state ? (
                            <Error>{formErrors.receiver_state}</Error>
                          ) : null}
                        </WrapperInput>

                        <WrapperInput>
                          <TextInput
                            label="E-mail"
                            name="romaneio.receiver.email"
                            placeholder=""
                            minWidth="15.5vw"
                            value={formik.values.romaneio.receiver.email}
                            error={!!formErrors.receiver_email}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formErrors.receiver_email = null;
                            }}
                            onBlur={''}
                          />

                          {formErrors.receiver_email ? (
                            <Error>{formErrors.receiver_email}</Error>
                          ) : null}
                        </WrapperInput>
                      </Row>
                    </Column>
                  ) : null}
                  <Row></Row>
                  <Row>
                    <WrapperInput>
                      <FieldLabel>Responsável Pagamento: </FieldLabel>
                      <Selector
                        name="payment_responser"
                        type="secondary"
                        placeholder=""
                        maxWidth="290px"
                        value={formik.values.payment_responser}
                        options={[
                          { text: 'Remetente', value: 'Remetente' },
                          { text: 'Destinatário', value: 'Destinatario' },
                        ]}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <FieldLabel>Valor da Nota</FieldLabel>
                      <Currency
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        name="romaneio.delivery_value"
                        width="280px"
                        error={!!formErrors.delivery_value}
                        value={formik.values.romaneio.delivery_value}
                        onChangeEvent={(e) => {
                          formik.handleChange(e);
                          formErrors.delivery_value = null;
                        }}
                        onBlur={''}
                      />
                      {formErrors.delivery_value ? (
                        <Error>{formErrors.delivery_value}</Error>
                      ) : null}
                    </WrapperInput>
                  </Row>

                  <Row>
                    <WrapperInput>
                      <TextInput
                        id="romaneio.delivery_size"
                        label="Volume"
                        type="number"
                        width="100%"
                        minWidth="15.5vw"
                        name="romaneio.delivery_size"
                        error={!!formErrors.delivery_size}
                        value={formik.values.romaneio.delivery_size}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formErrors.delivery_size = null;
                        }}
                        onBlur={''}
                      />

                      {formErrors.delivery_size ? (
                        <Error>{formErrors.delivery_size}</Error>
                      ) : null}
                    </WrapperInput>

                    <WrapperInput>
                      <FieldLabel>Peso (Kg)</FieldLabel>
                      <Currency
                        prefix=""
                        decimalSeparator=","
                        thousandSeparator="."
                        name="romaneio.weight"
                        error={!!formErrors.romaneio_weight}
                        value={formik.values.romaneio.weight}
                        onChangeEvent={(e) => {
                          formik.handleChange(e);
                          formErrors.romaneio_weight = null;
                        }}
                        onBlur={''}
                      />
                      {formErrors.romaneio_weight ? (
                        <Error>{formErrors.romaneio_weight}</Error>
                      ) : null}
                    </WrapperInput>
                  </Row>

                  <Row>
                    <WrapperInput>
                      <FieldLabel>Valor da descarga</FieldLabel>
                      <Currency
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        name="unload_value"
                        value={formik.values.unload_value}
                        onChangeEvent={formik.handleChange}
                        onBlur={''}
                      />
                    </WrapperInput>
                  </Row>

                  <BooleanWrapper
                    isShown={formik.values.cte_type === 'embarcador'}
                  >
                    <Button
                      margin="-15px -15px -15px 30px"
                      width="110px"
                      height="35px"
                      label="Adicionar"
                      onClick={!edit ? handleCteInput : null}
                    />
                  </BooleanWrapper>
                </Column>
                <Row>
                  <WrapperSection>
                    <DragDrop
                      {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      })}
                    >
                      <input {...getInputProps()} />
                      {
                        <p style={{ height: '100%', textAlign: 'center' }}>
                          Arraste e solte os arquivos aqui...
                        </p>
                      }
                    </DragDrop>
                    {formErrors.xml ? <Error>{formErrors.xml}</Error> : null}
                  </WrapperSection>
                </Row>
              </Row>
            ) : // </Section>
            null}
          </Column>

          <Row>
            {formik.values.cte_type === 'embarcador' ? (
              <Section title="Nf-e Adicionadas">
                <WrapperTable>
                  <CustomTable
                    columns={[
                      { label: 'CNPJ', id: 'cnpj' },
                      { label: 'Chave da NF-e', id: 'barcode' },
                      { label: 'Número da NF-e', id: 'invoiceNumber' },
                      { label: 'Nome', id: 'name' },
                      { label: 'Ações', id: 'actions' },
                    ]}
                    activePage={0}
                    totalPages={2}
                    data={tableRaw || []}
                    styleCell={{ flex: 1, alignContent: 'space-between' }}
                    customComponent={{
                      actions: (e, item) => (
                        <ItemTable>
                          <DeleteIcon onClick={(e) => handleCteDelete(item)} />
                        </ItemTable>
                      ),
                    }}
                  />
                </WrapperTable>
                {formik.errors.invoices ? (
                  <Error>{formik.errors.invoices}</Error>
                ) : null}
              </Section>
            ) : null}
          </Row>
        </Content>
      </Container>
    </Section>
  );
}

export default Cte;
