import React from 'react';
import { string, arrayOf, object, func, bool } from 'prop-types';

import { Container, Label, Content } from './styles';
import Select from 'react-select';

export default function Selector({
  name,
  options,
  onChange,
  value,
  type,
  label,
  width,
  maxWidth,
  minWidth,
  status,
  margin,
  defaulOption,
  error,
  disabled,
}) {
  const statusLabel = (stats) => {
    switch (stats) {
      case 'in_progress_req':
        return 'A caminho da coleta';
      case 'tracking':
        return 'Monitorando';
      case 'in_progress_res':
        return 'A caminho da entrega';
      case 'arrived_requester':
        return 'Carga coletada';
      case 'completed':
        return 'Finalizada';
      default:
        return 'Inicial';
    }
  };

  return (
    <Container width={width} margin={margin} maxWidth={maxWidth}>
      {label && <Label>{label}</Label>}
      <Select
        defaultValue={defaulOption}
        isMulti
        isClearable
        isDisabled={disabled}
        onChange={onChange}
        options={options.map(
          (opt) =>
            (opt = { label: status ? statusLabel(opt) : opt, value: opt })
        )}
        styles={{
          control: (styles) => ({
            ...styles,
            minHeight: '48px',
            backgroundColor: '#fafafa',
            border: `1px solid ${error ? '#F27160' : '#ebebeb'}`,
            borderRadius: '5px',
          }),
        }}
      />
    </Container>
  );
}

Selector.propTypes = {
  name: string,
  options: arrayOf(object).isRequired,
  onChange: func.isRequired,
  value: string,
  type: string,
  label: string,
  width: string,
  maxWidth: string,
  minWidth: string,
  margin: string,
  defaulOption: string,
  error: bool,
  disabled: bool,
};

Selector.defaultProps = {
  name: null,
  value: null,
  type: 'primary',
  label: null,
  width: null,
  maxWidth: null,
  minWidth: null,
  margin: null,
  defaulOption: null,
  error: false,
};
