/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { bool, func, arrayOf, object, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { isBefore } from 'date-fns';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FaSearch } from 'react-icons/fa';
import cep from 'cep-promise';
import InputMask from 'react-input-mask';

import Map from 'components/Map';

import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import Selector from 'components/Selector';
import DatePicker from 'components/DatePicker';
// import Button from 'components/Button';
import { Row } from 'styles/components';
import Images from 'assets/img';
import { Input, Label } from 'components/TextInput/styles';
import ConvocationsActions from 'store/ducks/convocations';

import { locationByIP, locationByAddress } from 'services/getLocations';

import { schema } from './schemaValidation';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  Line,
  Error,
  WrapperInput,
  Loading,
  // useStyles,
  StepperMUI,
  BoxMap,
  Center,
  BoxSearch,
  selectStyles,
  ImgFlag,
  Currency,
} from './styles';

const key = process.env.REACT_APP_MAPS_KEY;

function getSteps() {
  return [
    'DADOS DO SOLICITANTE',
    'INFORMAÇÕES DA CARGA',
    'ENDEREÇO DE COLETA',
    'ENDEREÇO DE ENTREGA',
  ];
}

const animatedComponents = makeAnimated();

export default function ModalConvocations({
  data,
  open,
  onClose,
  edit,
  selectedId,
  providers,
}) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [formErrors, setFormErrors] = useState({});
  const [fullAddress, setFullAddress] = useState({
    cep: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const [providerId, setProviderId] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      owner: '',
      owner_phone: '',
      owner_email: '',

      weight: '',
      type: '',
      validity: new Date(),
      value: '',

      collect_zipcode: '',
      collect_point: '',
      collect_number: '',
      collect_complement: '',
      collect_neighborhood: '',
      collect_city: '',
      collect_state: '',
      collect_lat: '',
      collect_lng: '',
      collect_corresponding: '',
      collect_corresponding_phone: '',
      collect_corresponding_email: '',

      delivery_zipcode: '',
      delivery_point: '',
      delivery_number: '',
      delivery_complement: '',
      delivery_neighborhood: '',
      delivery_city: '',
      delivery_state: '',
      delivery_lat: '',
      delivery_lng: '',
      delivery_corresponding: '',
      delivery_corresponding_phone: '',
      delivery_corresponding_email: '',
    },
    onSubmit: (values) => handleSubmitted(values),
    validationSchema: schema(),
    validateOnChange: true,
    isValidating: true,
  });

  const validateRequester = (value) => {
    const errors = {};
    if (!value || value.length === 0) {
      errors.providerId = 'Obrigatório, escolha um solicitante.';
    }

    return errors;
  };

  const validateCharge = (values) => {
    const errors = {};
    if (!values.weight) {
      errors.weight = 'Obrigatório';
    } else if (values.weight <= 0) {
      errors.weight = 'Peso não informado.';
    }

    if (!values.type) {
      errors.type = 'Obrigatório';
    }

    if (!values.validity) {
      errors.validity = 'Obrigatório';
    } else if (isBefore(values.validity, new Date())) {
      errors.validity = 'Data inválida.';
    }

    if (!values.value) {
      errors.value = 'Obrigatório';
    } else if (values.value <= 0) {
      errors.value = 'Valor inválido.';
    }

    return errors;
  };

  const validateCollectPoint = (values) => {
    const errors = {};
    if (!values.collect_zipcode) {
      errors.collect_zipcode = 'Obrigatório';
    } else if (values.weight.length <= 0) {
      errors.collect_zipcode = 'CEP não informado.';
    }

    if (!values.collect_point) {
      errors.collect_point = 'Obrigatório';
    } else if (values.collect_point.length <= 0) {
      errors.collect_point = 'Endereço não informado.';
    }

    if (!values.collect_neighborhood) {
      errors.collect_neighborhood = 'Obrigatório';
    } else if (values.collect_neighborhood.length <= 0) {
      errors.collect_neighborhood = 'Bairro não informado.';
    }

    if (!values.collect_city) {
      errors.collect_city = 'Obrigatório';
    } else if (values.collect_city.length <= 0) {
      errors.collect_city = 'Cidade não informada.';
    }

    if (!values.collect_state) {
      errors.collect_state = 'Obrigatório';
    } else if (values.collect_state.length <= 0) {
      errors.collect_state = 'Estado não informado.';
    }

    if (!values.collect_corresponding) {
      errors.collect_corresponding = 'Obrigatório';
    } else if (values.collect_corresponding.length <= 0) {
      errors.collect_corresponding = 'Correspondente não informado.';
    }

    if (!values.collect_corresponding_phone) {
      errors.collect_corresponding_phone = 'Obrigatório';
    } else if (
      values.collect_corresponding_phone.replace(/([^\d])+/gim, '').length < 11
    ) {
      errors.collect_corresponding_phone = 'Número de telefone incompleto.';
    }

    if (!values.collect_corresponding_email) {
      errors.collect_corresponding_email = 'Obrigatório';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        values.collect_corresponding_email
      )
    ) {
      errors.collect_corresponding_email = 'E-mail inválido.';
    }

    return errors;
  };

  const handleClose = () => {
    setActiveStep(0);
    formik.resetForm();
    onClose();
  };

  function handleSubmitted(values) {
    if (selectedId) {
      dispatch(
        ConvocationsActions.updateConvocationRequest(
          { ...values, id: selectedId },
          onClose
        )
      );
    } else {
      dispatch(
        ConvocationsActions.addConvocationRequest(
          {
            ...values,
            provider_id: providerId.value,
          },
          onClose
        )
      );
    }
    formik.resetForm();
  }

  const handleNext1 = () => {
    const errors = validateRequester(providerId);
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setFormErrors(errors);
    }
  };

  const handleNext2 = () => {
    const errors = validateCharge(formik.values);
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setFormErrors(errors);
    }
  };

  const handleNext3 = () => {
    const errors = validateCollectPoint(formik.values);
    if (Object.keys(errors).length === 0) {
      if (formik.values.delivery_lat) {
        setPosition({
          latitude: formik.values.delivery_lat,
          longitude: formik.values.delivery_lng,
        });
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setFormErrors(errors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getPosition = (address) => {
    locationByAddress(address).then((pos) => {
      if (pos.lat !== 0) {
        if (activeStep === 2) {
          formik.setFieldValue('collect_lat', pos.lat);
          formik.setFieldValue('collect_lng', pos.lng);
        } else {
          formik.setFieldValue('delivery_lat', pos.lat);
          formik.setFieldValue('delivery_lng', pos.lng);
        }
        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
      }
    });
  };

  const getInitialPosition = (address) => {
    locationByIP()
      .then((pos) => {
        if (pos.lat !== 0) {
          setPosition({
            latitude: pos.lat,
            longitude: pos.lng,
          });
        }
      })
      .catch(() => {
        locationByAddress(address).then((pos) => {
          if (pos.lat !== 0) {
            setPosition({
              latitude: pos.lat,
              longitude: pos.lng,
            });
          }
        });
      });
  };

  useEffect(() => {
    if (!edit) {
      getInitialPosition('Brasil');
    }
  }, [edit]);

  // useEffect(() => {
  //   if (edit && activeStep === 2) {
  //     setPosition({
  //       latitude: Number(formik.values.collect_lat),
  //       longitude: Number(formik.values.collect_lng),
  //     });
  //   } else if (edit && activeStep === 3) {
  //     setPosition({
  //       latitude: Number(formik.values.delivery_lat),
  //       longitude: Number(formik.values.delivery_lng),
  //     });
  //   }
  // }, [activeStep]);

  useEffect(() => {
    if (edit) {
      data.map((item) => {
        if (item.id === selectedId) {
          providers.map((prov) => {
            if (item.provider_id === prov.id) {
              setProviderId({
                value: item.provider_id,
                label: prov.social_reason,
              });
            }
            if (item.collect_lat) {
              setPosition({
                latitude: item.collect_lat,
                longitude: item.collect_lng,
              });
            }
            return null;
          });

          formik.setValues({ ...item, validity: item.validity.seconds * 1000 });
        }
        return null;
      });
    }
  }, [data, edit, providers, selectedId]);

  useEffect(() => {
    let provider = {};
    providers.map((prov) => {
      if (prov.id === providerId.value) {
        provider = {
          ...prov,
        };
      }
      return null;
    });
    formik.setFieldValue(`cnpj`, provider?.cnpj);
    formik.setFieldValue(`owner`, provider?.owner);
    formik.setFieldValue(`owner_phone`, provider?.phone);
    formik.setFieldValue(`owner_email`, provider?.email);
  }, [providerId.value, providers]);

  const getFormattedAddress = (address, number) => {
    let result = address.street;
    if (number && number.length) {
      result += `, ${number}`;
    }
    if (address.neighborhood.length) {
      result += `, ${address.neighborhood}`;
    }
    if (address.city.length) {
      result += `, ${address.city}`;
    }
    if (address.city.length) {
      result += ` - ${address.state}`;
    }
    return `${result}, Brasil`;
  };

  const handleCepChange = (cepRequested) => {
    cep(cepRequested)
      .then((cepResponse) => {
        const address = {
          cep: cepRequested,
          street: cepResponse.street,
          neighborhood: cepResponse.neighborhood,
          city: cepResponse.city,
          state: cepResponse.state,
        };
        setFullAddress(address);
        if (activeStep === 2) {
          formik.setFieldValue(`collect_point`, cepResponse.street);
          formik.setFieldValue(
            `collect_neighborhood`,
            cepResponse.neighborhood
          );
          formik.setFieldValue(`collect_city`, cepResponse.city);
          formik.setFieldValue(`collect_state`, cepResponse.state);

          getPosition(
            getFormattedAddress(address, formik.values.collect_number)
          );
        } else {
          formik.setFieldValue(`delivery_point`, cepResponse.street);
          formik.setFieldValue(
            `delivery_neighborhood`,
            cepResponse.neighborhood
          );
          formik.setFieldValue(`delivery_city`, cepResponse.city);
          formik.setFieldValue(`delivery_state`, cepResponse.state);

          getPosition(
            getFormattedAddress(address, formik.values.delivery_number)
          );
        }
      })
      .catch(() => {
        setFullAddress({
          cep: cepRequested,
          street: formik.values.address,
          neighborhood: formik.values.neighborhood,
          city: '',
          state: '',
        });
        formik.setFieldError(`zipcode`, 'CEP não encontrado');
      });
  };

  const handleAddressNumberChange = (addressNumber) => {
    if (addressNumber) {
      console.error(getFormattedAddress(fullAddress, addressNumber));
      getPosition(getFormattedAddress(fullAddress, addressNumber));
    }
  };

  const providersOptions = [];
  providers.map((item) =>
    providersOptions.push({
      value: item.id,
      label: item.social_reason,
    })
  );

  const onChange = (selectedOptions) => setProviderId(selectedOptions);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={selectedId ? 'ATUALIZAR UMA CARGA' : 'CRIAR NOVA CARGA'}
    >
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <>
            <WrapperBody>
              <WrapperContent>
                <StepperMUI activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {
                      color: '#FFF28D',
                    };
                    const labelProps = {};

                    return activeStep !== index ? (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps} />
                      </Step>
                    ) : (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </StepperMUI>

                <Line />

                {activeStep === 0 && (
                  <>
                    <WrapperInput>
                      <Label>Solicitante</Label>
                      <Row>
                        <BoxSearch>
                          <FaSearch size={18} />
                        </BoxSearch>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Informe o solicitante"
                          components={animatedComponents}
                          isSearchable
                          name="provider_id"
                          options={providersOptions}
                          styles={selectStyles}
                          value={providerId}
                          onChange={onChange}
                        />
                      </Row>

                      <Error>
                        {formErrors.providerId && providerId.length <= 0
                          ? formErrors.providerId
                          : null}
                      </Error>
                    </WrapperInput>

                    <WrapperInput>
                      <Label>CNPJ</Label>
                      <InputMask
                        id="cnpj"
                        mask="99.999.999/9999-99"
                        label="CNPJ"
                        placeholder="Solicitante ainda não definido..."
                        value={formik.values.cnpj}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                      >
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Responsável"
                        name="owner"
                        placeholder="Solicitante ainda não definido..."
                        value={formik.values.owner}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <Label>Telefone</Label>
                      <Row width="100%">
                        <BoxSearch>
                          <ImgFlag src={Images.IconFlagBrasil} />
                        </BoxSearch>
                        <InputMask
                          id="owner_phone"
                          mask="(99) 99999-9999"
                          placeholder="Solicitante ainda não definido..."
                          value={formik.values.owner_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </Row>
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        type="email"
                        label="E-mail"
                        placeholder="Solicitante ainda não definido..."
                        name="owner_email"
                        value={formik.values.owner_email}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </WrapperInput>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <WrapperInput>
                      <TextInput
                        type="number"
                        label="Peso total da carga (em toneladas)"
                        name="weight"
                        error={!!formErrors.weight}
                        value={formik.values.weight}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <Selector
                        label="Tipo de carga"
                        options={[
                          { text: 'Selecione', value: '' },
                          { text: 'Carga Geral', value: 'Carga Geral' },
                          { text: 'Solta', value: 'Solta' },
                          { text: 'Unitizada', value: 'Unitizada' },
                          { text: 'Carga a Granel', value: 'Carga a Granel' },
                          { text: 'Carga Neogranel', value: 'Carga Neogranel' },
                          {
                            text: 'Carga Frigorificada',
                            value: 'Carga Frigorificada',
                          },
                          { text: 'Carga Perigosa', value: 'Carga Perigosa' },
                        ]}
                        type="secondary"
                        name="type"
                        error={!!formErrors.type}
                        value={formik.values.type}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <DatePicker
                        name="validity"
                        error={!!formErrors.validity}
                        label="Validade da carga"
                        selectedDate={formik.values.validity}
                        handleDateChange={(e) => {
                          formik.setFieldValue('validity', e);
                        }}
                      />
                    </WrapperInput>
                    <WrapperInput>
                      {/* <TextInput
                        label="Valor do frete"
                        name="value"
                        error={!!formErrors.value}
                        value={formik.values.value}
                        onChange={formik.handleChange}
                      /> */}
                      <Label>Valor do frete</Label>
                      <Currency
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        name="value"
                        error={!!formErrors.value}
                        value={formik.values.value}
                        onChangeEvent={formik.handleChange}
                      />
                    </WrapperInput>
                  </>
                )}

                {activeStep === 2 && (
                  <>
                    <WrapperInput>
                      <TextInput
                        label="CEP"
                        type="number"
                        name="collect_zipcode"
                        error={!!formErrors.collect_zipcode}
                        value={formik.values.collect_zipcode}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleCepChange(e.target.value)}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Endereço"
                        name="collect_point"
                        error={!!formErrors.collect_point}
                        value={formik.values.collect_point}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Número"
                        type="number"
                        name="collect_number"
                        error={!!formErrors.collect_number}
                        value={formik.values.collect_number}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          handleAddressNumberChange(e.target.value);
                        }}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Complemento"
                        name="collect_complement"
                        error={!!formErrors.collect_complement}
                        value={formik.values.collect_complement}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>
                    <WrapperInput>
                      <TextInput
                        label="Bairro"
                        name="collect_neighborhood"
                        error={!!formErrors.collect_neighborhood}
                        value={formik.values.collect_neighborhood}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Cidade"
                        name="collect_city"
                        error={!!formErrors.collect_city}
                        value={formik.values.collect_city}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Estado"
                        name="collect_state"
                        error={!!formErrors.collect_state}
                        value={formik.values.collect_state}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </WrapperInput>

                    <BoxMap>
                      {position.latitude !== 0 && (
                        <Map
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                          loadingElement={
                            <Center>
                              <Loading />
                            </Center>
                          }
                          containerElement={
                            <div
                              style={{
                                height: `250px`,
                                overflow: 'hidden',
                              }}
                            />
                          }
                          mapElement={<div style={{ height: `100%` }} />}
                          position={position}
                        />
                      )}
                    </BoxMap>

                    <WrapperInput>
                      <TextInput
                        label="Nome do correspondente"
                        name="collect_corresponding"
                        error={!!formErrors.collect_corresponding}
                        value={formik.values.collect_corresponding}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>
                    <WrapperInput>
                      <Label>Telefone</Label>
                      <Row width="100%">
                        <BoxSearch>
                          <ImgFlag src={Images.IconFlagBrasil} />
                        </BoxSearch>
                        <InputMask
                          name="collect_corresponding_phone"
                          mask="(99) 99999-9999"
                          placeholder="Digite aqui..."
                          error={!!formErrors.collect_corresponding_phone}
                          value={formik.values.collect_corresponding_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </Row>
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        name="collect_corresponding_email"
                        type="email"
                        label="E-mail"
                        placeholder="Digite aqui..."
                        error={!!formErrors.collect_corresponding_email}
                        value={formik.values.collect_corresponding_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <WrapperInput>
                      <TextInput
                        label="CEP"
                        type="number"
                        name="delivery_zipcode"
                        error={
                          formik.touched.delivery_zipcode &&
                          Boolean(formik.errors.delivery_zipcode)
                        }
                        value={formik.values.delivery_zipcode}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleCepChange(e.target.value)}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Endereço"
                        name="delivery_point"
                        error={
                          formik.touched.delivery_point &&
                          Boolean(formik.errors.delivery_point)
                        }
                        value={formik.values.delivery_point}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Número"
                        type="number"
                        name="delivery_number"
                        error={
                          !!formik.touched.delivery_number &&
                          !!formik.errors.delivery_number
                        }
                        value={formik.values.delivery_number}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          handleAddressNumberChange(e.target.value);
                        }}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Complemento"
                        name="delivery_complement"
                        error={
                          formik.touched.delivery_complement &&
                          Boolean(formik.errors.delivery_complement)
                        }
                        value={formik.values.delivery_complement}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>
                    <WrapperInput>
                      <TextInput
                        label="Bairro"
                        name="delivery_neighborhood"
                        error={
                          formik.touched.delivery_neighborhood &&
                          Boolean(formik.errors.delivery_neighborhood)
                        }
                        value={formik.values.delivery_neighborhood}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Cidade"
                        name="delivery_city"
                        error={
                          formik.touched.delivery_city &&
                          Boolean(formik.errors.delivery_city)
                        }
                        value={formik.values.delivery_city}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        label="Estado"
                        name="delivery_state"
                        error={
                          formik.touched.delivery_state &&
                          Boolean(formik.errors.delivery_state)
                        }
                        value={formik.values.delivery_state}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </WrapperInput>

                    <BoxMap>
                      {position.latitude !== 0 && (
                        <Map
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                          loadingElement={
                            <Center>
                              <Loading />
                            </Center>
                          }
                          containerElement={
                            <div
                              style={{
                                height: `250px`,
                                overflow: 'hidden',
                              }}
                            />
                          }
                          mapElement={<div style={{ height: `100%` }} />}
                          position={position}
                        />
                      )}
                    </BoxMap>

                    <WrapperInput>
                      <TextInput
                        label="Nome do correspondente"
                        name="delivery_corresponding"
                        error={
                          formik.touched.delivery_corresponding &&
                          Boolean(formik.errors.delivery_corresponding)
                        }
                        value={formik.values.delivery_corresponding}
                        onChange={formik.handleChange}
                      />
                    </WrapperInput>
                    <WrapperInput>
                      <Label>Telefone</Label>
                      <Row width="100%">
                        <BoxSearch>
                          <ImgFlag src={Images.IconFlagBrasil} />
                        </BoxSearch>
                        <InputMask
                          name="delivery_corresponding_phone"
                          mask="(99) 99999-9999"
                          placeholder="Digite aqui..."
                          error={!!formErrors.delivery_corresponding_phone}
                          value={formik.values.delivery_corresponding_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </Row>
                    </WrapperInput>

                    <WrapperInput>
                      <TextInput
                        name="delivery_corresponding_email"
                        type="email"
                        label="E-mail"
                        placeholder="Digite aqui..."
                        error={!!formErrors.delivery_corresponding_email}
                        value={formik.values.delivery_corresponding_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WrapperInput>
                  </>
                )}
              </WrapperContent>
            </WrapperBody>
            <WrapperBottom>
              {activeStep !== 0 && (
                <BottomText onClick={handleBack}>Voltar</BottomText>
              )}
              {activeStep === 3 ? (
                <BottomText onClick={formik.handleSubmit}>Concluir</BottomText>
              ) : (
                <BottomText
                  onClick={
                    activeStep === 0
                      ? handleNext1
                      : activeStep === 1
                      ? handleNext2
                      : handleNext3
                  }
                >
                  Avançar
                </BottomText>
              )}
            </WrapperBottom>
          </>
        </form>
      </Container>
    </Modal>
  );
}

ModalConvocations.propTypes = {
  open: bool.isRequired,
  edit: bool.isRequired,
  onClose: func.isRequired,
  selectedId: string,
  data: arrayOf(object).isRequired,
};

ModalConvocations.defaultProps = {
  selectedId: null,
};
