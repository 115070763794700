import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.isShown ? 'flex' : 'none')};
  padding: 15px 0;
  width: 100%;
  width: 255px;

  flex-direction: column;
  margin-right: 20px;
  align-items: flex-start;
`;
