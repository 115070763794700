import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 88px;
  background-color: #ffffff;
  padding: 23px 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  color: #7b7b7b;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  transition: 400ms;

  .box-icon {
    width: 55px;
    height: 40px;
    background-color: #122d58;
    border-radius: 5px;

    margin-right: 18px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      color: white;
    }
  }

  & + & {
    border-top: 1px solid #dddddd;
  }

  &:hover {
    background-color: #f4f4f4;
  }
`;
