import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import api, { apiLogin } from 'services/api';

import CteActions from '../ducks/cte';

export function* getCte({ convocation_id }) {
  try {
    const data = yield api
      .firestore()
      .collection('ctes')
      .where('convocation_id', '==', convocation_id)
      .get();

    const [cte] = data.docs.map((item) => item.data());

    yield put(CteActions.getCteSuccess(cte));
  } catch (err) {
    yield put(CteActions.getCteFailure(err));
  }
}

export function* addCte({ cte, closeModal }) {
  try {
    const data = {
      ...cte,
      status: 1,
      status_cte: 1,
      created_at: new Date(),
      updated_at: new Date(),
    };

    yield apiLogin
      .firestore()
      .collection('ctes')
      .add(data)
      .then((ref) => {
        data.id = ref.id;
        ref.update({ id: ref.id });
      });

    data.created_at = {
      seconds: Math.round(data.created_at.getTime() / 1000),
    };

    yield put(CteActions.addCteSuccess(cte));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'CTE',
        message: 'Registrado com sucesso!',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar Cte',
        message: 'Tente novamente',
      })
    );

    yield put(CteActions.addCteFailure(err));
  }
}
export function* updateCte({ cte, closeModal }) {
  try {
    const data = {
      ...cte,
      updated_at: new Date(),
    };

    yield api.firestore().collection('ctes').doc(cte.id).update(data);

    yield put(CteActions.updateCteSuccess(cte));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'CTE',
        message: 'Atualizado com sucesso!',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar o Ct-e',
        message: 'Tente novamente',
      })
    );

    console.log('err', err);

    yield put(CteActions.updateCteFailure(err));
  }
}

export function* removeCte({ id }) {
  try {
    yield api.firestore().collection('ctes').doc(id).delete();

    yield put(CteActions.removeCteSuccess());
    yield put(CteActions.getCteRequest());
  } catch (err) {
    yield put(CteActions.removeCteFailure(err));
  }
}

export function* getIdCte({ id }) {
  try {
    const data = yield api.firestore().collection('ctes').doc(id).get();

    const cte = data.data();

    yield put(CteActions.getIdCteSuccess(cte));
  } catch (err) {
    yield put(CteActions.getIdCteFailure(err));
  }
}
