/* eslint-disable no-nested-ternary */
import { Tab, Tabs } from '@material-ui/core';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled.main`
  padding: 20px 0 40px 45px;
  width: 89vw;
  background-color: #fff;
`;

export const Content = styled.div``;
export const Header = styled.div`
  width: 100%;
  height: 50px;
  background-color: #122d58;
  display: flex;
  align-items: center;
  padding-left: 28px;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
`;
export const BoxSearch = styled.div`
  height: 32px;
  padding: 0 12px;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WrapperSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: space-around;
  flex-wrap: wrap;
`;

// export const WrapperInput = styled.div`
//   flex: 1;
//   padding: 15px 0;
//   width: 100%;
//   max-width: 355px;
//   display: flex;
//   flex-direction: column;
//   margin: 0 25px 20px 0;
//   align-content: space-around;
// `

export const Loading = styled(ReactLoading).attrs({
  textColor: '#122d58',
})``;

export const TabNav = withStyles({
  root: {
    flex: 1,
    color: '#122d58',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    width: '97%',
    justifyContent: 'space-around',
  },
  indicator: {
    backgroundColor: '#122d58',
    fontWeight: 'bold',
  },
})(Tabs);

export const TabItem = withStyles({
  root: {
    flex: 1,
    fontSize: '0.8rem',
    fontWeight: 800,
    fontFamily: 'Montserrat',
    alignSelf: 'Center',
  },
})(Tab);

export const WrapperNav = styled.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  justify-content: space-around;
`;
export const HeaderIndicator = styled.div`
  width: 100%;
  background-color: #122d58;
  height: 3px;
  position: absolute;
  bottom: -3px;
  transform: ${(props) => (props.right ? 'translateX(100%)' : 'translateX(0)')};
  transition: 200ms;
`;

export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
`;

export const LeftComp = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
`;
export const BoxMap = styled.div`
  width: 100%;
  max-width: 500px;
  height: 260px;
`;
export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  color: red;
  font-weight: 600;
  align-self: center;
`;

export const WrapperButtons = styled.div`
  display: flex;
  width: 96%;
  height: 55px;
  padding: 10px 35px 0px 0px;
  justify-content: flex-end;
  background-color: #ebebeb;
  border-radius: 5px;
`;

export const bigSelect = {
  control: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'white',
    display: 'flex',
    width: 300,
    borderColor: '#ebebeb',
    minHeight: 32,
    height: 32,

    ':active': {
      ...styles[':active'],
      borderColor: !isDisabled && (isSelected ? '#122d58' : '#cecece'),
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      width: 300,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#122d58'
        : isFocused
        ? '#cecece'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : 'gray',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#122d58' : 'gray'),
      },
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: '#122d58', // Custom colour
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
