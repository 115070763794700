import styled from 'styled-components';
import { MdRemoveRedEye, MdBlock } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const EyeIcon = styled(MdRemoveRedEye).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const WhatsappIcon = styled(FaWhatsapp).attrs({ size: '16px' })`
  color: #6abf62;
  cursor: pointer;
  margin-right: 26px;
`;

export const BlockIcon = styled(MdBlock).attrs({ size: '16px' })`
  color: #f27160;
  cursor: pointer;
`;
