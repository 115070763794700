import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getConvocationsRequest: null,
  getConvocationsSuccess: ['convocations'],
  getConvocationsFailure: ['error'],
  addConvocationRequest: ['convocation', 'closeModal'],
  addConvocationSuccess: ['convocation'],
  addConvocationFailure: ['error'],
  updateLocationRequest: [
    'fixedLocations',
    'locations',
    'selectedConvocation',
    'closeModal',
  ],
  updateConvocationRequest: ['convocation', 'closeModal'],
  updateConvocationSuccess: ['convocation'],
  updateConvocationFailure: ['error'],
  removeConvocationRequest: ['id'],
  removeConvocationSuccess: null,
  removeConvocationFailure: ['error'],
  putSendMailRequest: ['file'],
  putSendMailSuccess: null,
  putSendMailFailure: ['error'],
  openModalEdit: null,
  closeModalEdit: null,
  openModalQrcode: null,
  closeModalQrcode: null,
  setConvocationRequest: ['convocation'],
  openDeleteModal: null,
  closeDeleteModal: null,
  setTemporaryLink: ['tempLink'],
  openShareModal: ['convocationId', 'invoiceId'],
  closeShareModal: null,
  setAcceptedDelete: null,
  setErrorDelete: null,
  openMessageModal: null,
  closeMessageModal: null,
  setCenterMap: ['centerMap'],
});

export const ConvocationsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  convocations: [],
  isLoading: false,
  error: null,
  openModal: false,
  modalDelete: false,
  modalShare: false,
  messageModal: false,
  successDelete: false,
  errorDelete: false,
  openModalEdit: false,
  openModalQrCode: false,
  convocationActive: null,
  temporaryLink: null,
  collect_email: null,
  delivery_email: null,
  centerMap: null,
});

/* Reducers */

const getConvocationsRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getConvocationsSuccess = (state, { convocations }) =>
  state.merge({
    convocations,
    isLoading: false,
  });

const getConvocationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addConvocationRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addConvocationSuccess = (state, { convocation }) =>
  state.merge({
    convocations: [...state.convocations, convocation],
    isLoading: false,
    openModal: false,
    openModalQrCode: true,
    convocationActive: convocation,
  });

const addConvocationFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const updateLocationRequest = (state) => state.merge({});

const updateConvocationRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const updateConvocationSuccess = (state, { convocation }) =>
  state.merge({
    convocations: state.convocations.map((item) => {
      if (item.id === convocation.id) {
        return convocation;
      }
      return item;
    }),
    convocationActive: convocation,
    isLoading: false,
    openModal: false,
  });

const updateConvocationFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const removeConvocationRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const removeConvocationSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const removeConvocationFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const sendMailRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const sendMailSuccess = (state) =>
  state.merge({
    isLoading: false,
    openModalQrCode: false,
  });

const sendMailFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const openQrcode = (state) =>
  state.merge({
    openModalQrCode: true,
  });

const closeQrcode = (state, { error }) =>
  state.merge({
    error,
    openModalQrCode: false,
  });

const setConvocation = (state, { convocation }) =>
  state.merge({
    convocationActive: convocation,
  });

const setAcceptedDelete = (state) =>
  state.merge({
    successDelete: true,
  });

const setErrorDelete = (state) =>
  state.merge({
    errorDelete: true,
  });

const openDeleteModal = (state) =>
  state.merge({
    successDelete: false,
    modalDelete: true,
  });

const closeDeleteModal = (state) =>
  state.merge({
    modalDelete: false,
  });

const setTemporaryLink = (state, { tempLink }) =>
  state.merge({
    temporaryLink: tempLink,
  });

const openShareModal = (state, { convocationId, invoiceId }) =>
  state.merge({
    modalShare: true,
  });

const closeShareModal = (state) =>
  state.merge({
    modalShare: false,
  });

const openMessageModal = (state) =>
  state.merge({
    messageModal: true,
  });

const closeMessageModal = (state) =>
  state.merge({
    messageModal: false,
  });

const openModalEdit = (state) =>
  state.merge({
    openModalEdit: true,
  });

const closeModalEdit = (state) =>
  state.merge({
    openModalEdit: false,
  });

const setCenterMap = (state, { centerMap }) =>
  state.merge({
    centerMap,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CONVOCATIONS_REQUEST]: getConvocationsRequest,
  [Types.GET_CONVOCATIONS_SUCCESS]: getConvocationsSuccess,
  [Types.GET_CONVOCATIONS_FAILURE]: getConvocationsFailure,
  [Types.ADD_CONVOCATION_REQUEST]: addConvocationRequest,
  [Types.ADD_CONVOCATION_SUCCESS]: addConvocationSuccess,
  [Types.ADD_CONVOCATION_FAILURE]: addConvocationFailure,
  [Types.UPDATE_LOCATION_REQUEST]: updateLocationRequest,
  [Types.UPDATE_CONVOCATION_REQUEST]: updateConvocationRequest,
  [Types.UPDATE_CONVOCATION_SUCCESS]: updateConvocationSuccess,
  [Types.UPDATE_CONVOCATION_FAILURE]: updateConvocationFailure,
  [Types.REMOVE_CONVOCATION_REQUEST]: removeConvocationRequest,
  [Types.REMOVE_CONVOCATION_SUCCESS]: removeConvocationSuccess,
  [Types.REMOVE_CONVOCATION_FAILURE]: removeConvocationFailure,
  [Types.PUT_SEND_MAIL_REQUEST]: sendMailRequest,
  [Types.PUT_SEND_MAIL_SUCCESS]: sendMailSuccess,
  [Types.PUT_SEND_MAIL_FAILURE]: sendMailFailure,
  [Types.OPEN_MODAL_EDIT]: openModalEdit,
  [Types.CLOSE_MODAL_EDIT]: closeModalEdit,
  [Types.OPEN_MODAL_QRCODE]: openQrcode,
  [Types.CLOSE_MODAL_QRCODE]: closeQrcode,
  [Types.SET_CONVOCATION_REQUEST]: setConvocation,
  [Types.OPEN_DELETE_MODAL]: openDeleteModal,
  [Types.CLOSE_DELETE_MODAL]: closeDeleteModal,
  [Types.SET_TEMPORARY_LINK]: setTemporaryLink,
  [Types.OPEN_SHARE_MODAL]: openShareModal,
  [Types.CLOSE_SHARE_MODAL]: closeShareModal,
  [Types.SET_ACCEPTED_DELETE]: setAcceptedDelete,
  [Types.SET_ERROR_DELETE]: setErrorDelete,
  [Types.OPEN_MESSAGE_MODAL]: openMessageModal,
  [Types.CLOSE_MESSAGE_MODAL]: closeMessageModal,
  [Types.SET_CENTER_MAP]: setCenterMap,
});
