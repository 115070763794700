import React from 'react';
import { string, element, bool, oneOfType } from 'prop-types';

// import { isNull } from 'lodash';
import { Icon, Column } from 'styles/components';

import { Container, Header, WrapperLeft, Title, Subtitle } from './styles';

function StaticDrawer({
  title,
  subtitle,
  action,
  icon,
  children,
  noHeader,
  minHeight,
  width,
  color,
  mapActived,
}) {
  return (
    <Container mapActived={mapActived} minHeight={minHeight} width={width}>
      {!noHeader && (
        <Header>
          <WrapperLeft subtitle={subtitle}>
            {typeof icon === 'string' ? (
              <Icon src={icon} height="24px" margin="0 25px 0 0" />
            ) : (
              icon
            )}
            <Column>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              <Title color={color}>{title.toUpperCase()}</Title>
            </Column>
          </WrapperLeft>
          {action ?? action}
        </Header>
      )}
      {children}
    </Container>
  );
}

export default StaticDrawer;

StaticDrawer.propTypes = {
  title: string,
  subtitle: string,
  icon: oneOfType({
    string,
    element,
  }),
  children: element,
  noHeader: bool,
  minHeight: string,
};

StaticDrawer.defaultProps = {
  title: '',
  subtitle: '',
  icon: null,
  children: null,
  noHeader: false,
  minHeight: null,
};
