export const ColumnChart = (data1, data2) => ({
  chart: {
    type: 'column',
  },
  title: null,
  xAxis: {
    categories: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abri',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
  },
  yAxis: {
    min: 0,
    title: null,
    stackLabels: { enabled: true, style: { color: '#7B7B7B' } },
  },
  plotOptions: {
    series: {
      stacking: 'normal',
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: 'CTE’s não pagas',
      color: '#F27160',
      data: data1,
    },
    {
      name: 'CTE’s Emitidas',
      color: '#435B82',
      data: data2,
    },
  ],
});
