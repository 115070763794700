const getInsurance = (type, formik, insurances) => {
  let valueTransport = 0;
  let valueInsurance = 0;

  if (!type) {
    formik.setFieldValue('type_insurance', '');
    formik.setFieldValue('insurance_value', 0);
  } else if (!formik.values.collect_state && type !== '') {
    formik.setFieldValue('type_insurance', '');
    formik.setFieldError(
      'insurance_value',
      'Informe o endereço de coleta para calcular o seguro!'
    );
  } else if (!formik.values.delivery_state && type !== '') {
    formik.setFieldValue('type_insurance', '');
    formik.setFieldError(
      'insurance_value',
      'Informe o endereço de entrega para calcular o seguro!'
    );
  } else {
    if (formik.values.invoices.length > 0) {
      formik.values.invoices.forEach((item) => {
        valueTransport += item.nfeValue;
      });
    }
    const [state_collect] = insurances.filter(
      (item) => item.collect === formik.values.collect_state
    );

    if (state_collect) {
      const [state_delivery] = state_collect.deliverys.filter(
        (item) => item.state === formik.values.delivery_state
      );

      if (state_delivery) {
        valueInsurance = valueTransport * (state_delivery.value / 100);

        // se tiver seguro de roubo
        if (type === 'cargaroubo') {
          valueInsurance *= 1.003;
        }

        // Acrescenta iof ao valor
        valueInsurance *= 1.076;
      }

      formik.setFieldValue(
        'insurance_value',
        Math.round(valueInsurance * 100) / 100
      );
      formik.setFieldError('insurance_value', '');
    }
  }
};

export default getInsurance;
