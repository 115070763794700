import { call, take, put } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { push } from 'connected-react-router';
import { actions as toastrActions } from 'react-redux-toastr';
import api from 'services/api';

import AuthActions from '../ducks/auth';
import ConvocationsActions from '../ducks/convocations';

// const uuidv1 = require('uuid/v1');

function getAuthChannel() {
  return eventChannel((emit) => {
    const unsubscribe = api.auth().onAuthStateChanged((user) => emit({ user }));
    return unsubscribe;
  });
}

export function* loginStatusWatcher() {
  const channel = yield call(getAuthChannel);

  while (true) {
    const { user } = yield take(channel);

    if (user) {
      if (user.emailVerified) {
        const docRef = yield api.firestore().collection('users').doc(user.uid);

        let userActive;
        yield docRef.get().then((doc) => {
          userActive = doc.data();
        });
        if (userActive.role === 'admin') {
          yield put(AuthActions.signInSuccess(userActive));
          yield put(ConvocationsActions.getConvocationsRequest());
        } else {
          yield put(
            toastrActions.add({
              type: 'info',
              title: 'CONTA NÃO ADMINISTRATIVA',
              message: 'Entre em contato conosco para verificar sua conta',
            })
          );
          yield put(AuthActions.signOut());
          yield put(push('/signin'));
        }
      } else {
        yield user.sendEmailVerification();
        yield put(
          toastrActions.add({
            type: 'info',
            title: 'Verifique seu e-mail',
            message:
              'Acesse seu e-mail para confirmar sua identidade. Evite bloqueio de conta.',
          })
        );
      }
    } else {
      // yield put(AuthActions.signOut());
      console.log('User not logged');
      // yield put(push('/signin'));
    }
    yield put(AuthActions.authCheckedSuccess());
  }
}

export function* signIn({ email, password }) {
  try {
    const querySnapshot = yield api
      .firestore()
      .collection('users')
      .where("email", '==', email)
      .limit(1)
      .get();

    const user = querySnapshot.empty ? null : querySnapshot.docs[0].data()

    if (user && user.profile === 'administrative') {
      if (user.status === 1) {
        yield api.auth().signInWithEmailAndPassword(email, password);
        yield put(AuthActions.signInSuccess(user));
        yield put(push('/dashboard'));
      } else {
        yield put(
          toastrActions.add({
            type: 'info',
            title: 'ATIVAÇÃO DE CONTA',
            message: 'Entre em contato conosco para ativar sua conta.',
          })
        );
        yield put(AuthActions.signInFailure(Error('Inativo')));
      }
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no login',
          message: 'Você é um admin? Entre em contato conosco.',
        })
      );
      yield put(AuthActions.signInFailure(Error('Not Admin')));
    }
  } catch (error) {
    console.error(error)
    yield put(AuthActions.signInFailure(error));
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no login',
        message: 'Verifique seu e-mail/senha!',
      })
    );
  }
}

export function* signOut() {
  try {
    yield api.auth().signOut();
    yield put(push('/signin'));
  } catch (error) {
    // yield put(SignlogoutFailure(error));
    console.log(error);
  }
}

// export function* syncFileUrl(storageRef, file, user) {
//   try {
//     const url = yield storageRef.getDownloadURL();

//     const userRef = yield api.firestore().collection('users').doc(user.id);

//     yield userRef.update({
//       avatarUrl: url,
//     });

//     yield put(AuthActions.closeFileModal());

//     yield put(
//       toastrActions.add({
//         type: 'success',
//         title: 'Avatar atualizado',
//         message: 'Seu avatar foi atualizado.',
//       })
//     );

//     yield put(AuthActions.uploadAvatarSuccess(url));
//   } catch (error) {
//     yield put(AuthActions.uploadAvatarFailure(error));
//     yield put(
//       toastrActions.add({
//         type: 'error',
//         title: 'Falha no upload',
//         message: 'Ops...Algo de errado aconteceu. Tente mais tarde.',
//       })
//     );
//   }
// }

// export function* uploadFile({ file }) {
//   const user = yield select((state) => state.auth.userActive);

//   const storageRef = yield api.storage().ref().child(`avatars/${uuidv1()}`);

//   const taskAvatar = storageRef.put(file);

//   taskAvatar.on('state_changed', (snapshot) => {
//     const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
//     console.log(`${pct}%`);
//   });

//   // Wait for upload to complete
//   yield taskAvatar;

//   yield syncFileUrl(storageRef, file, user);
// }

export function* forgotPassword({ email }) {
  try {
    yield api.auth().sendPasswordResetEmail(email);

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'E-mail enviado!',
        message: 'Verifique seu e-mail para recuperar sua senha.',
      })
    );

    yield put(push('/signin'));

    yield put(AuthActions.forgotSuccess());
  } catch (error) {
    yield put(
      AuthActions.forgotFailure({ message: 'Error sent email password reset' })
    );

    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no reset de senha',
        message: 'Ops...Algo de errado aconteceu. Tente mais tarde.',
      })
    );
  }
}

// export function* profileUpdate({ user }) {
//   try {
//     const userRef = yield api.firestore().collection('users').doc(user.id);

//     yield userRef.update({
//       name: user.fullName,
//       phone: user.phone,
//       state: user.state,
//       country: user.country,
//       updated_at: new Date(),
//     });

//     let userActive;
//     yield userRef.get().then((doc) => {
//       userActive = doc.data();
//     });

//     yield put(AuthActions.profileSuccess(userActive));
//   } catch (e) {
//     yield put(
//       toastrActions.add({
//         type: 'error',
//         title: 'Falha na atualização',
//         message: 'Ops...Algo de errado aconteceu. Tente mais tarde.',
//       })
//     );
//     yield put(
//       AuthActions.profileFailure({
//         message: 'Error update profile user',
//         e,
//       })
//     );
//   }
// }
