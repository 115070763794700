import styled from 'styled-components';
import ReactLoading from 'react-loading';

export const Container = styled.div`
  width: 88vw;
`;

export const BoxMap = styled.div`
  width: 88vw;
  height: 50px;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const Center = styled.div`
  height: 88vw;
  width: 50vw;
  margin-left: 8vw;
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  justify-content: center;
  position: relative;
`;

export const BoxStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 445px 34px;
`;

export const Line = styled.div`
  width: 88vw;
  height: 1px;
  background-color: #122d58;
  margin: 0px 2px 42px;
`;

export const BoxDescriptions = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 70px 34px;
`;

export const TitleDescription = styled.strong`
  font-size: 12px;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #122d58;
  margin-top: 18px;
`;

export const SubtitleDescription = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #777777;
  margin-top: 2px;
`;

export const WrapperButton = styled.div`
  margin: 10px 0;
`;
