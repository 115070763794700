import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-bottom: 20px;
`;

export const Header = styled.div`
  width: 100%;
  height: 50px;
  background-color: #122d58;
  display: flex;
  align-items: center;
  padding-left: 28px;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
`;

export const Body = styled.div`
  padding: 31px 30px 0;
  width: 100%;
  max-width: 1400px;
`;
