/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import InputCurrency from 'react-currency-input';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MdClose } from 'react-icons/md';
import { Send } from '@material-ui/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff66;
  backdrop-filter: blur(5px);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;

  position: absolute;
  top: 0;
  left: 0;
`;

export const WrapperBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 21px;
  }

  .close-icon {
    font-size: 12px;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #7b7b7b;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #7b7b7b;
  }
`;

export const Card = styled.div`
  width: 406px;
  height: 266px;
  padding: 32px 24px 24px 24px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7b7b7b;
`;

export const SendImg = styled.img`
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  margin-bottom: 0 !important;
`;

export const CirclePhoto = styled.div`
  background-color: #efefef;
  width: 54px;
  height: 54px;
  padding: 2.84px;

  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7b7b7b;

  margin-right: 8px;

  .img {
    width: 100%;
    height: 100%;
    font-size: 21px;
    border-radius: 50%;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .status-trucker {
    width: 30px;
    height: 30px;
    background-color: #6abf62;
    border-radius: 50%;
    position: absolute;
    right: 2.7px;
    bottom: 2.7px;
    z-index: 99;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #122d58;
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 700;

  .close-icon {
    cursor: pointer;
    font-size: 18px;
  }
`;

export const SendIcon = styled(Send)`
  color: #122d58;
  width: 17.5px;
  cursor: pointer;
  margin-right: 8.83px;
`;

export const Close = styled(MdClose).attrs({
  size: '20px',
})`
  color: #fff;
  cursor: pointer;
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17.07px;
  text-align: center;
  color: #7b7b7b;

  strong {
    font-weight: 700;
  }
`;

export const BottomText = styled.a`
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  color: #122d58;
  cursor: pointer;
  padding: 12px 20px;

  .icon {
    margin-right: 5px;
  }
`;

export const Currency = styled(InputCurrency)`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;
