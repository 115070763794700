import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import Paper from 'components/Paper';
import Images from 'assets/img';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Table from 'components/Table';
import Section from './Section';
import AntecipationsActions from 'store/ducks/antecipations';
import CteActions from 'store/ducks/cte';
import { EyeIcon, DeleteIcon } from '../styles';
import Zoom from 'react-medium-image-zoom';

import {
  Container,
  Body,
  Row,
  WrapperText,
  WrapperSection,
  FieldLabel,
  FieldValue,
  SmallImage,
  WrapperSmallImage,
  WrapperTable,
  FlexCell,
  WhiteRow,
  GrayRow,
} from './styles';
import { useHistory } from 'react-router-dom';
import { color } from 'highcharts';

function InfoAntecipations({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { antecipation } = useSelector((state) => state.antecipations);
  const { cte } = useSelector((state) => state.cte);

  const trucker = location.state.trucker;
  const value = location.state.value;
  const antecipations_id = location.state.antecipations_id;
  const cte_id = location.state.cte_id;
  const [refresh, setRefresh] = useState(true);
  const [opened, setOpened] = useState(false);

  const getFileName = (file) => {
    const text = file.substr(file.indexOf('%2', 10) + 3);
    const fileName = text
      .substr(text.indexOf('%2', 10) + 3)
      .substr(
        text.substr(text.indexOf('%2', 10)).indexOf('%2'),
        text.indexOf('?alt', 10) - 3 - text.indexOf('%2', 10)
      );

    return fileName;
  };

  const dataTable = antecipation?.images
    ? antecipation.images.map((item, index) => ({
        actions: '',
        document: item,
        item: index,
        id: item
          .substr(item.indexOf('%2', 10) + 3)
          .substr(0, item.substr(item.indexOf('%2', 10) + 3).indexOf('%2')),
        name: getFileName(item),
      }))
    : [];

  const closeModal = () => {
    history.goBack();
  };

  useEffect(() => {
    if (refresh) {
      dispatch(
        AntecipationsActions.getIdAntecipationsRequest(antecipations_id)
      );
      dispatch(CteActions.getIdCteRequest(cte_id));
      setRefresh(false);
    }
  }, [dispatch, refresh]);

  return (
    <Container>
      <Paper
        title="DADOS DO PAGAMENTO"
        subtitle="PAGAMENTOS/"
        icon={Images.IconHandeHolding}
        rightContent={
          <Grid container spacing={1} style={{ maxWidth: 360 }}>
            <Grid item sm={6} xs={12}>
              <Button
                label={
                  antecipation?.payment === 'completed'
                    ? 'CANCELAR PAGAMENTO'
                    : 'CONFIRMAR PAGAMENTO'
                }
                onClick={() => {
                  dispatch(
                    AntecipationsActions.updateAntecipationsRequest(
                      antecipation.id,
                      antecipation?.payment,
                      closeModal
                    )
                  );
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                label="VOLTAR"
                type="secondary"
                onClick={() => {
                  closeModal();
                }}
              />
            </Grid>
          </Grid>
        }
      >
        <Body>
          <Row>
            <WrapperSection>
              <Section title="MOTORISTA">
                <Grid container spacing={3}>
                  <Grid item lg={5} xs>
                    <TextInput label="Nome" value={trucker?.name} disabled />
                  </Grid>

                  <Grid item lg={5} xs>
                    <TextInput label="CPF" value={trucker?.cpf} disabled />
                  </Grid>
                </Grid>
              </Section>
            </WrapperSection>

            <WrapperText>
              <FieldLabel>
                Valor a pagar:
                <FieldValue>
                  {value?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </FieldValue>
              </FieldLabel>
            </WrapperText>
          </Row>
        </Body>

        <Section title="DADOS BANCARIOS">
          <Body>
            <Grid container spacing={3}>
              <Grid item lg={3} xs>
                <TextInput
                  label="Banco"
                  value={trucker?.bank?.bank?.name}
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput
                  label="Tipo"
                  value={trucker?.bank?.typeAccount}
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput
                  label="Agencia"
                  value={trucker?.bank?.agency}
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput
                  label="Conta"
                  value={trucker?.bank?.account}
                  disabled
                />
              </Grid>
            </Grid>
          </Body>
        </Section>

        <Section title="PIX">
          <Body>
            <Grid container spacing={3}>
              <Grid item lg={3} xs>
                <TextInput
                  label="Tipo"
                  value={trucker?.bank?.typePix}
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput label="Chave" value={trucker?.bank?.pix} disabled />
              </Grid>
            </Grid>
          </Body>
        </Section>

        <Section title="DADOS TITULO">
          <Body>
            <Grid container spacing={3}>
              <Grid item lg={3} xs>
                <TextInput
                  label="Id Cte"
                  value={antecipation?.cte_id}
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput
                  label="Emissao"
                  value={format(
                    new Date(antecipation?.created_at?.seconds * 1000 || null),
                    'dd/MM/yyyy'
                  )}
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput
                  label="Data da baixa"
                  value={
                    antecipation?.date_write_off_at
                      ? format(
                          new Date(
                            antecipation?.date_write_off_at?.seconds * 1000
                          ),
                          'dd/MM/yyyy'
                        )
                      : null
                  }
                  disabled
                />
              </Grid>

              <Grid item lg={3} xs>
                <TextInput
                  label="Tipo"
                  value={
                    antecipation?.type === 'anticipation' || !antecipation?.type
                      ? 'Antecipação'
                      : 'Saldo'
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Body>
        </Section>

        <Section title="COMPROVANTES ANEXADOS">
          <Body>
            <>
              <Table
                maxHeight="200px"
                noNavigator
                columns={[
                  { label: 'Ações', id: 'actions' },
                  { label: 'Item', id: 'item' },
                  { label: 'Documento', id: 'document' },
                  { label: 'Id', id: 'id' },
                  { label: 'Name Arquivo', id: 'name' },
                ]}
                activePage={0}
                totalPages={2}
                data={dataTable}
                customComponent={{
                  actions: (e, item) => (
                    <Row>
                      <EyeIcon
                        onClick={() => {
                          window.open(item.document);
                        }}
                      />
                      <DeleteIcon
                        onClick={() => {
                          dispatch(
                            AntecipationsActions.deleteFileAntecipationsRequest(
                              item.document,
                              item.id,
                              setRefresh
                            )
                          );
                        }}
                      />
                    </Row>
                  ),
                  document: (e) => (
                    <WrapperSmallImage>
                      <Zoom>
                        <SmallImage src={e} />
                      </Zoom>
                    </WrapperSmallImage>
                  ),
                }}
              />
            </>
          </Body>
        </Section>

        <Section title="COMPROVANTES ENTREGA">
          <WrapperTable>
            <WhiteRow>
              <FlexCell flex={0.05} />
              <FlexCell flex={0.2} bold>
                Item
              </FlexCell>
              <FlexCell flex={0.5} bold>
                Nota Fiscal
              </FlexCell>
              <FlexCell flex={1} bold>
                Chave Ct-e
              </FlexCell>
              <FlexCell flex={1} bold />
            </WhiteRow>
            <GrayRow>
              {cte?.invoices?.map((e, index) => (
                <>
                  <FlexCell flex={0.3} />
                  <FlexCell flex={0.85}>{index.toString()}</FlexCell>
                  <FlexCell flex={2.4}>{e?.barcode.substr(25, 9)}</FlexCell>
                  <FlexCell flex={2}>{e?.chave_cte}</FlexCell>
                  <FlexCell flex={1} />
                  {e?.images?.map((item) => (
                    <FlexCell flex={0.5} center>
                      <WrapperSmallImage>
                        <Zoom>
                          <SmallImage src={item} />
                        </Zoom>
                      </WrapperSmallImage>
                    </FlexCell>
                  ))}
                </>
              ))}

              <FlexCell></FlexCell>
              <FlexCell></FlexCell>
            </GrayRow>
          </WrapperTable>
        </Section>
      </Paper>
    </Container>
  );
}

export default InfoAntecipations;

InfoAntecipations.propTypes = {
  trucker: object.isRequired,
};
