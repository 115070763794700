/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TextInput from 'components/TextInput';
import SearchInput from 'components/SearchInput';
import Images from 'assets/img';
import { Row, Column } from 'styles/components';
import ChatActions from 'store/ducks/chat';
import TruckerActions from 'store/ducks/trucker';

import {
  Container,
  BoxCards,
  Card,
  HeaderDate,
  HeaderCard,
  DriverName,
  DateCard,
  DescriptionCard,
  BoxMessages,
  Message,
  TextMessage,
  HourMessage,
  WrapperInput,
  ButtonSend,
  SendImg,
  BoxInfo,
  BoxImg,
  Description,
  useStyles,
  Center,
  Loading,
} from './styles';

const defaultProps = {
  border: 1,
};

function Chat() {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const history = useHistory();
  const chat = useSelector((state) => state.chat);
  const { oneTrucker } = useSelector((state) => state.trucker);
  const [searchUser, setSearchUser] = useState('');
  const [searchMessage, setSearchMessage] = useState('');
  const [textMessage, setTextMessage] = useState('');
  const [activeCard, setActiveCard] = useState('');
  const [userId, setUserId] = useState('');
  const [dateIteration, setDateIteration] = useState(null);

  useEffect(() => {
    dispatch(ChatActions.getChatRequest());
  }, [dispatch]);

  useEffect(() => {
    const interval = setTimeout(() => {
      const container = document.querySelector('#container-scroll');
      container.scrollTop = Number.MAX_SAFE_INTEGER;
    }, 3000);

    return () => clearTimeout(interval);
  }, [activeCard]);

  useEffect(() => {
    const date = chat?.messages[chat?.messages?.length - 1]?.created_at;
    setDateIteration(date);
  }, [chat.messages]);

  function handleCard(id, truckerId) {
    dispatch(ChatActions.getMessagesRequest(id));
    dispatch(TruckerActions.getOneTruckerRequest(truckerId));
    setActiveCard(id);
    setUserId(truckerId);
  }

  function handleSend() {
    dispatch(ChatActions.addMessageRequest(activeCard, userId, textMessage));
    const containerScroll = document.querySelector('#container-scroll');
    containerScroll.scrollTop = Number.MAX_SAFE_INTEGER;
    setTextMessage('');
  }

  const filteredData = chat.data?.filter((item) =>
    item.user.name.toUpperCase().includes(searchUser.toUpperCase())
  );

  const filteredMessages = chat.messages?.filter((item) =>
    item.textMessage.toUpperCase().includes(searchMessage.toUpperCase())
  );

  return (
    <Container>
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={0}>
            <Grid item xs={3}>
              <Paper className={classes.paper} elevation={0}>
                <SearchInput
                  onChange={(e) => setSearchUser(e.target.value)}
                  onClear={() => setSearchUser('')}
                  value={searchUser}
                  placeholder="Pesquise um usuário..."
                />

                <BoxCards>
                  {chat.loading ? (
                    <Center>
                      <Loading />
                    </Center>
                  ) : (
                    filteredData?.map((item) => (
                      <Card
                        key={item.id}
                        active={activeCard === item.id}
                        onClick={() => handleCard(item.id, item.user.id)}
                      >
                        <HeaderCard>
                          <DriverName>{item.user.name}</DriverName>
                          <DateCard>
                            {format(
                              new Date(item.latestMessage.created_at),
                              'dd/MM/yyyy'
                            )}
                          </DateCard>
                        </HeaderCard>
                        <DescriptionCard>
                          {item.latestMessage.textMessage}
                        </DescriptionCard>
                      </Card>
                    ))
                  )}
                </BoxCards>
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Box
                borderColor="#DDDDDD"
                {...defaultProps}
                borderTop={0}
                borderBottom={0}
              >
                <Paper className={classes.paper} elevation={0}>
                  <SearchInput
                    onChange={(e) => setSearchMessage(e.target.value)}
                    onClear={() => setSearchMessage('')}
                    value={searchMessage}
                    placeholder="Pesquisar na conversa..."
                  />
                  {chat.loading ? (
                    <Center>
                      <Loading />
                    </Center>
                  ) : (
                    <BoxMessages id="container-scroll">
                      {filteredMessages?.map((message, index) => {
                        if (message.textMessage.length > 0) {
                          let dateTemp = true;
                          if (
                            new Date(message.created_at).getDate() !==
                            new Date(
                              filteredMessages[index - 1]?.created_at
                            ).getDate()
                          )
                            dateTemp = true;
                          else dateTemp = false;
                          return (
                            <>
                              {dateTemp && (
                                <HeaderDate>
                                  {format(
                                    new Date(message.created_at),
                                    'dd/MM/yyyy'
                                  )}
                                </HeaderDate>
                              )}
                              <Message key={message.id} type={message.type}>
                                <TextMessage type={message.type}>
                                  {message.textMessage}
                                </TextMessage>
                                <HourMessage type={message.type}>
                                  {format(
                                    new Date(message.created_at),
                                    'HH:mm'
                                  )}
                                </HourMessage>
                              </Message>
                            </>
                          );
                        }
                      })}
                    </BoxMessages>
                  )}

                  {activeCard !== '' && (
                    <Row align="center">
                      <WrapperInput>
                        <TextInput
                          height="40px"
                          placeholder="Enviar mensagem..."
                          onChange={(e) => setTextMessage(e.target.value)}
                          value={textMessage}
                        />
                      </WrapperInput>
                      <ButtonSend onClick={handleSend}>
                        <SendImg src={Images.IconSend} />
                      </ButtonSend>
                    </Row>
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper} elevation={0}>
                <BoxInfo>
                  {activeCard !== '' && (
                    <>
                      <Description color="#939393" size="16px" weight="bold">
                        Documentos do condutor
                      </Description>
                      <Row>
                        <BoxImg>
                          <SendImg src={oneTrucker.url_cnh} />
                        </BoxImg>
                        <BoxImg>
                          <SendImg src={oneTrucker.url_holding_cnh} />
                        </BoxImg>
                      </Row>
                      <Description
                        color="#939393"
                        size="16px"
                        weight="bold"
                        top="15px"
                      >
                        Documentos do veículo
                      </Description>
                      <Row>
                        <BoxImg>
                          <SendImg src={oneTrucker.url_document} />
                        </BoxImg>
                        <BoxImg>
                          <SendImg src={oneTrucker.url_holding_document} />
                        </BoxImg>
                      </Row>

                      <Description
                        color="#122D58"
                        size="22px"
                        weight="600"
                        top="15px"
                      >
                        Última interação
                      </Description>

                      <Description color="#939393" size="16px" weight="400">
                        {dateIteration &&
                          format(new Date(dateIteration), 'dd/MM/yyy HH:mm')}
                      </Description>

                      <Description
                        color="#122D58"
                        size="22px"
                        weight="600"
                        top="15px"
                      >
                        Dados para contato
                      </Description>

                      <Column>
                        <Description color="#939393" size="16px" weight="400">
                          {oneTrucker.email}
                        </Description>
                        <Description color="#939393" size="16px" weight="400">
                          {oneTrucker.phone}
                        </Description>
                        {/* <Description color="#939393" size="16px" weight="400">
                      (21) 98104-0061
                    </Description> */}
                      </Column>
                    </>
                  )}
                </BoxInfo>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Chat;
