import styled from 'styled-components';

export const Box = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-right: 5px;

  p {
    margin: ${(props) => props.margin};
    font-size: ${(props) => props.fontSize};
    text-transform: uppercase;
    z-index: 1;
    font-weight: 700;
    color: #fff;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;
