import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MdKeyboardArrowUp } from 'react-icons/md';

export const Container = styled.div`
  max-width: 90vw;
`;

export const WrapperTable = styled.div`
  border-bottom: 1px solid #f3f3f3;
`;

export const WrapperMiddle = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding: 47px 36px 11px;
`;

export const Row = styled.div`
  display: flex;
  padding-bottom: 21px;
  justify-content: space-between;
`;

export const LeftText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #122d58;
`;

export const RightText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #7b7b7b;
`;

export const WrapperBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;
`;

export const BottomText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #122d58;
  cursor: pointer;
`;

export const WrapperCollapse = styled(PerfectScrollbar)`
  padding: ${(props) => (props.open ? '15px' : '0')};
  width: 100%;
  max-height: ${(props) => (props.open ? '500px' : '0')};
  transition: 300ms;
`;

export const WhiteRow = styled.div`
  height: 30px;
  display: flex;
  margin-top: 30px;
`;

export const GrayRow = styled.div`
  height: 50px;
  background-color: #f3f3f3;
  display: flex;
`;

export const FlexCell = styled.div`
  flex: ${(props) => props.flex || 2};
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: center;
  color: #7b7b7b;
  font-size: 11px;
  font-weight: ${(props) => props.bold && '700'};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;

export const Arrow = styled(MdKeyboardArrowUp).attrs({
  size: '20px',
})`
  color: #7b7b7b;
  transform: ${(props) => (props.open ? 'rotate(180deg)' : '')};
  transition: 300ms;
`;
