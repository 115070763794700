import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getAntecipationsRequest: null,
  getAntecipationsSuccess: ['antecipations'],
  getAntecipationsFailure: ['error'],
  updateAntecipationsRequest: ['id', 'status', 'closeModal'],
  updateAntecipationsSuccess: ['antecipations'],
  updateAntecipationsFailure: ['error'],
  removeAntecipationsRequest: ['id'],
  removeAntecipationsSuccess: null,
  removeAntecipationsFailure: ['error'],
  fileAntecipationsRequest: ['file', 'id'],
  fileAntecipationsSuccess: null,
  fileAntecipationsFailure: ['error'],
  deleteFileAntecipationsRequest: ['file', 'id', 'setRefresh'],
  deleteFileAntecipationsSuccess: ['antecipations'],
  deleteFileAntecipationsFailure: ['error'],
  getIdAntecipationsRequest: ['id'],
  getIdAntecipationsSuccess: ['antecipation'],
  getIdAntecipationsFailure: ['error'],
});

export const AntecipationsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  antecipations: [],
  antecipation: [],
  isLoading: false,
  error: null,
  openModal: false,
});

const getAntecipationsRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getAntecipationsSuccess = (state, { antecipations }) =>
  state.merge({
    antecipations,
    isLoading: false,
  });

const getAntecipationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const updateAntecipationsRequest = (state, { id, status, closeModal }) =>
  state.merge({
    id,
    status,
    closeModal,
    isLoading: true,
  });

const updateAntecipationsSuccess = (state, { antecipations }) =>
  state.merge({
    antecipations,
    isLoading: false,
  });

const updateAntecipationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const removeAntecipationsRequest = (state, { id }) =>
  state.merge({
    id,
    isLoading: true,
  });

const removeAntecipationsSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const removeAntecipationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const fileAntecipationsRequest = (state, { file, id }) =>
  state.merge({
    file,
    id,
    isLoading: true,
  });

const fileAntecipationsSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const fileAntecipationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const deleteFileAntecipationsRequest = (state, { file, id, setRefresh }) =>
  state.merge({
    file,
    id,
    setRefresh,
    isLoading: true,
  });

const deleteFileAntecipationsSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const deleteFileAntecipationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getIdAntecipationsRequest = (state, { id }) =>
  state.merge({
    id,
    isLoading: true,
  });

const getIdAntecipationsSuccess = (state, { antecipation }) =>
  state.merge({
    antecipation,
    isLoading: false,
  });

const getIdAntecipationsFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ANTECIPATIONS_REQUEST]: getAntecipationsRequest,
  [Types.GET_ANTECIPATIONS_SUCCESS]: getAntecipationsSuccess,
  [Types.GET_ANTECIPATIONS_FAILURE]: getAntecipationsFailure,
  [Types.UPDATE_ANTECIPATIONS_REQUEST]: updateAntecipationsRequest,
  [Types.UPDATE_ANTECIPATIONS_SUCCESS]: updateAntecipationsSuccess,
  [Types.UPDATE_ANTECIPATIONS_FAILURE]: updateAntecipationsFailure,
  [Types.REMOVE_ANTECIPATIONS_REQUEST]: removeAntecipationsRequest,
  [Types.REMOVE_ANTECIPATIONS_SUCCESS]: removeAntecipationsSuccess,
  [Types.REMOVE_ANTECIPATIONS_FAILURE]: removeAntecipationsFailure,
  [Types.FILE_ANTECIPATIONS_REQUEST]: fileAntecipationsRequest,
  [Types.FILE_ANTECIPATIONS_SUCCESS]: fileAntecipationsSuccess,
  [Types.FILE_ANTECIPATIONS_FAILURE]: fileAntecipationsFailure,
  [Types.DELETE_FILE_ANTECIPATIONS_REQUEST]: deleteFileAntecipationsRequest,
  [Types.DELETE_FILE_ANTECIPATIONS_SUCCESS]: deleteFileAntecipationsSuccess,
  [Types.DELETE_FILE_ANTECIPATIONS_FAILURE]: deleteFileAntecipationsFailure,
  [Types.GET_ID_ANTECIPATIONS_REQUEST]: getIdAntecipationsRequest,
  [Types.GET_ID_ANTECIPATIONS_SUCCESS]: getIdAntecipationsSuccess,
  [Types.GET_ID_ANTECIPATIONS_FAILURE]: getIdAntecipationsFailure,
});
