import React from 'react';
import { string, func, bool } from 'prop-types';

import { Container, Label, Textarea } from './styles';

function TextArea({
  name,
  value,
  onChange,
  label,
  width,
  maxWidth,
  error,
  disabled,
}) {
  return (
    <Container width={width} maxWidth={maxWidth}>
      {label && <Label>{label}</Label>}
      <Textarea
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        disabled={disabled}
      />
    </Container>
  );
}

export default TextArea;

TextArea.propTypes = {
  name: string,
  value: string,
  onChange: func,
  label: string,
  width: string,
  maxWidth: string,
  error: bool,
  disabled: bool,
};

TextArea.defaultProps = {
  name: null,
  value: null,
  onChange: () => {},
  label: null,
  width: null,
  maxWidth: null,
  error: false,
  disabled: false,
};
