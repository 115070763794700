import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Images from 'assets/img';
import ReportsActions from 'store/ducks/reports';
import api from 'services/api';

import { Content, Footer, Header, Logo, Main } from './styles';

export default function TempLayout({ children, rate }) {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [reportsEl, setReportsEl] = React.useState(null);
  const { reports } = useSelector((state) => state.reports);
  const [temporarySelected, setTemporarySelected] = React.useState(null);
  const [convocationSelected, setConvocationSelected] = React.useState(null);
  const [logoProvider, setLogoProvider] = React.useState(null);

  function getProvider() {
    api
      .firestore()
      .collection('providers')
      .where('cnpj', '==', convocationSelected.cnpj)
      .get()
      .then((response) => {
        if (response.size > 0) {
          setLogoProvider(response.docs[0].data().avatarUrl);
        } else {
          console.log('No such document!');
        }
      });
  }

  function getConvocation() {
    api
      .firestore()
      .collection('convocations')
      .doc(temporarySelected.convocation_id)
      .get()
      .then((response) => {
        if (response.exists) {
          setConvocationSelected(response.data());
        } else {
          console.log('No such document!');
        }
      });
  }

  function getTemporary(idTemp) {
    api
      .firestore()
      .collection('temporary')
      .doc(idTemp)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTemporarySelected(doc.data());
        } else {
          console.log('No such document!');
        }
      });
  }

  useEffect(() => {
    if (convocationSelected) {
      getProvider();
    }
  }, [convocationSelected]);

  useEffect(() => {
    if (temporarySelected) {
      getConvocation();
    }
  }, [temporarySelected]);

  useEffect(() => {
    if (id && pathname.split('/')[1] === 'temp') {
      getTemporary(id);
    }
  }, [id]);

  useEffect(() => {
    dispatch(ReportsActions.getReportsRequest());
  }, [dispatch]);

  return (
    <Main>
      <Header>
        <Logo src={rate ? Images.Logo : logoProvider} />
        <h2>{rate ? 'Avaliação de Entrega' : 'Acompanhamento de Carga'}</h2>
        <div className="space" />
      </Header>
      <Content>{children}</Content>
      <Footer>
        <div className="description">
          {rate
            ? 'A nossa entrega é mais do que uma simples entrega de carga, é a entrega da confiança e comprometimento com o seu sucesso.'
            : 'O link de acompanhamento de carga estará disponível por 30 dias, a contar da data de solicitação de transporte.'}
          <br />
          <br />
        </div>
        <Logo src={Images.Logo} />
      </Footer>
    </Main>
  );
}
