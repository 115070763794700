import { useEffect, useRef, useState } from 'react';

import api from 'services/api';

const useMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
};

const useSnapshot = (convocation) => {
  const [data, updateData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const [location, setLocation] = useState({});

  const mounted = useMounted();

  useEffect(() => {
    if (convocation != null) {
      const snapshot = api
        .firestore()
        .collection('location')
        .doc(`${convocation.id}`)
        .onSnapshot((snapshot) => {
          console.log(snapshot);
          if (snapshot.data()) {
            const loc = snapshot.data().user_location;
            if (loc.length > 0) {
              const { latitude, longitude } = loc[loc.length - 1];
              setLocation({ latitude, longitude });
              if (setLoading && mounted.current) setLoading(false);
            }
          }
        });

      return () => snapshot();
    }
  }, [convocation]);

  return location;
};

export default useSnapshot;
