import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getCtesRequest: null,
  getCtesSuccess: ['ctes'],
  getCtesFailure: ['error'],
  confirmPaymentRequest: ['id', 'closeModal'],
  confirmPaymentSuccess: null,
  confirmPaymentFailure: ['error'],
  cancelPaymentRequest: ['id'],
  cancelPaymentSuccess: null,
  cancelPaymentFailure: ['error'],
});

export const TripTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  ctes: [],
  isLoading: false,
  error: null,
});

/* Reducers */

const getCtesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getCtesSuccess = (state, { ctes }) =>
  state.merge({
    ctes,
    isLoading: false,
  });

const getCtesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const confirmPaymentRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const confirmPaymentSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const confirmPaymentFailure = (state) =>
  state.merge({
    isLoading: false,
  });

const cancelPaymentRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const cancelPaymentSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const cancelPaymentFailure = (state) =>
  state.merge({
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CTES_REQUEST]: getCtesRequest,
  [Types.GET_CTES_SUCCESS]: getCtesSuccess,
  [Types.GET_CTES_FAILURE]: getCtesFailure,
  [Types.CONFIRM_PAYMENT_REQUEST]: confirmPaymentRequest,
  [Types.CONFIRM_PAYMENT_SUCCESS]: confirmPaymentSuccess,
  [Types.CONFIRM_PAYMENT_FAILURE]: confirmPaymentFailure,
  [Types.CANCEL_PAYMENT_REQUEST]: cancelPaymentRequest,
  [Types.CANCEL_PAYMENT_SUCCESS]: cancelPaymentSuccess,
  [Types.CANCEL_PAYMENT_FAILURE]: cancelPaymentFailure,
});
