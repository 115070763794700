import * as yup from 'yup';

export const schema = () => {
  return yup.object().shape({
    owner: yup.string().required('Informe o solicitante'),
    weight: yup.string().required('Peso da carga é obrigatório'),
    value: yup.string().required('Informe o valor do frete'),
    cargo_type: yup.string().required('O tipo de carga é obrigatório'),
    freight_value: yup.string().required('Informe o valor pago ao Embarcador'),
    collect_zipcode: yup.number().min(8).required('CEP é obrigatório'),
    collect_city: yup.string().required('Cidade é obrigatória'),
    collect_state: yup.string().max(2).required('Estado é obrigatório'),

    collect_point: yup.string().required('Endereço é obrigatório'),
    collect_neighborhood: yup.string().required('Bairro é obrigatório'),
    collect_corresponding_name: yup
      .string()
      .required('Nome do solicitante é obrigatório'),
    collect_corresponding_phone: yup
      .string()
      .required('Telefone do solicitante é obrigatório'),
    collect_corresponding_email: yup
      .string()
      .email('E-mail inválido')
      .required('Email do solicitante é obrigatório'),

    private_trucker_cpf: yup.string().when('type_convocation', {
      is: (value) => value === 'private',
      then: yup
        .string()
        .required('Informar o motorista caso a carga seja privada'),
    }),

    delivery_zipcode: yup.number().min(8).required('CEP é obrigatório'),
    delivery_point: yup.string().required('Endereço é obrigatório'),
    delivery_neighborhood: yup.string().required('Bairro é obrigatório'),
    delivery_city: yup.string().required('Cidade é obrigatório'),
    delivery_state: yup.string().required('Estado é obrigatório'),
    delivery_corresponding_name: yup
      .string()
      .required('Correspondente é obrigatorio'),
    delivery_corresponding_phone: yup
      .string()
      .required('Telefone do correspondente é obrigatorio'),
    delivery_corresponding_email: yup
      .string()
      .email('E-mail inválido')
      .required('Email do correspondente é obrigatorio'),

    insurance_value: yup.string().when('type_insurance', {
      is: (value) => value === 'carga' || value === 'cargaroubo',
      then: yup
        .string()
        .required(
          'Verifique se o endereço de coleta e entrega foram informados!'
        ),
    }),
  });
};
