import { put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from 'services/api';

import TripActions from '../ducks/trip';

export function* getCtes() {
  try {
    const data = yield api
      .firestore()
      .collection('ctes')
      .orderBy('created_at', 'desc')
      .get();

    const ctes = data.docs.map((item) => item.data());

    yield put(TripActions.getCtesSuccess(ctes));
  } catch (err) {
    yield put(TripActions.getCtesFailure(err));
  }
}

export function* confirmPayment({ id, closeModal }) {
  try {
    yield api.firestore().collection('ctes').doc(id).update({ status: 1 });
    yield put(TripActions.getCtesRequest());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Pagamento confirmado',
      })
    );

    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao confirmar pagamento',
      })
    );
    yield put(TripActions.confirmPaymentFailure(err));
  }
}

export function* cancelPayment({ id }) {
  try {
    yield api.firestore().collection('ctes').doc(id).update({ status: 3 });
    yield put(TripActions.getCtesRequest());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Pagamento cancelado',
      })
    );
  } catch (err) {
    console.log(err);
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cancelar pagamento',
      })
    );
    yield put(TripActions.confirmPaymentFailure(err));
  }
}
