import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/styles';

export const Container = styled.div`
  /* height: 700px; */
  background-color: #f7f7f7;
`;
export const BoxCards = styled(PerfectScrollbar)`
  padding-top: 47px;
  height: 700px;
`;

export const Card = styled.div`
  width: 100%;
  height: 111px;
  padding: 12px 12px 25px;
  cursor: pointer;

  background-color: ${(props) => (props.active ? '#e5e5e5' : 'transparent')};
  border: ${(props) => (props.active ? '6px solid #122d58' : 'none')};
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
`;

export const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const DriverName = styled.strong`
  color: #122d58;
  font-size: 22px;
`;
export const DateCard = styled.span`
  color: #707070;
  font-size: 12px;
`;
export const DescriptionCard = styled.p`
  color: #939393;
  font-size: 16px;
`;

export const BoxMessages = styled(PerfectScrollbar)`
  margin-top: 15px;
  padding-top: 47px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  height: 700px;
`;

export const HeaderDate = styled.strong`
  color: #707070;
  font-size: 16px;
  align-self: center;
  margin-bottom: 45px;
`;

export const Message = styled.div`
  background-color: ${(props) =>
    props.type === 'driver' ? '#F2F2F2' : ' #546c93'};
  display: flex;
  flex-direction: column;
  max-width: 317px;
  border-radius: 5px;
  align-self: ${(props) =>
    props.type === 'driver' ? 'flex-start' : 'flex-end'};
  margin-bottom: 10px;
  padding: 10px;
`;
export const TextMessage = styled.p`
  color: ${(props) => (props.type === 'driver' ? '#707070' : ' #FFF')};
  font-size: 16px;
`;
export const HourMessage = styled.span`
  color: ${(props) => (props.type === 'driver' ? '#707070' : ' #FFF')};
  font-size: 11px;
  align-self: flex-end;
`;

export const WrapperInput = styled.div`
  padding: 15px 0;
  width: 100%;
  /* max-width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ButtonSend = styled.button`
  background-color: #122d58;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 10px;
`;

export const SendImg = styled.img`
  width: 90%;
  height: 90%;
`;

export const BoxInfo = styled.div`
  padding-top: 47px;
  display: flex;
  flex-direction: column;
  width: 350px;
`;

export const Description = styled.p`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.weight};
  margin-top: ${(props) => props.top};
`;

export const BoxImg = styled.div`
  width: 48px;
  height: 48px;
  margin-top: 15px;
  margin-right: 15px;
`;

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // height: '100%',
    // position: 'absolute',
    // bottom: 35,
  },
  paper: {
    // height: window.innerHeight,
    padding: '46px 0px 10px 37px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7',
  },
}));

export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled(ReactLoading).attrs({
  color: '#122d58',
})``;

export const Mark = styled.div`
  border: 1px solid red;
`;
