import { element } from 'prop-types';
import React from 'react';
import { Container } from './styles';

export default function WrapperInput({ children }) {
  return <Container>{children}</Container>;
}

WrapperInput.propTypes = {
  children: element,
};

WrapperInput.defaultProps = {
  children: null,
};
