import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container, useStyles } from './styles';

export const LoadingPage = () => {
  const classes = useStyles();
  return (
    <Container>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Container>
  );
};

export const LoadingComponent = ({ width = '100px' }) => {
  // const setWith = width || '100px';
  return (
    <div className="text-center">
      <CircularProgress />
    </div>
  );
};
