import styled from 'styled-components';
import { MdEdit } from 'react-icons/md';

export const Container = styled.div``;

export const WrapperTable = styled.div`
  padding: 20px 0;
`;

export const EyeIcon = styled(MdEdit).attrs({ size: '19px' })`
  color: #122d58;
  cursor: pointer;
  margin-right: 26px;
`;

export const Icon = styled.img`
  width: 19px;
  height: 19px;
  /* margin-right: 26px; */
`;
