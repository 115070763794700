/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'components/Paper';
import Images from 'assets/img';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import { Row } from 'styles/components';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Popover } from '@material-ui/core';
import LongMenu from 'components/Menu';

import TruckersActions from 'store/ducks/trucker';
import AntecipationsActions from 'store/ducks/antecipations';

import {
  Container,
  WrapperTable,
  EyeIcon,
  WrapperPopover,
  PopoverButton,
  PopoverText,
  DeleteIcon,
  UploadFile,
  InputFile,
} from './styles';

function Antecipations() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [search, setSearch] = useState('');
  const { truckers } = useSelector((state) => state.trucker);
  const { antecipations } = useSelector((state) => state.antecipations);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);

  const getDriverName = (id) => {
    const [trucker] = truckers?.filter((item) => item.id === id);

    return trucker.name;
  };

  const data = antecipations
    ? antecipations.map((item) => ({
        actions: item.id,
        id: '#' + item.cte_id.substr(0, 4),
        payment: item?.payment || 'pending',
        user_id: item.user_id,
        user_name: getDriverName(item.user_id),
        created_at: item.created_at?.seconds * 1000,
        value_adt:
          item?.type === 'anticipation'
            ? Number(item.value_adt)
            : item?.value_over || 0,
        date_write_off_at: item?.date_write_off_at?.seconds * 1000 || null,
        cte_id: item?.cte_id,
        antecipations_id: item.id,
        images: item.images,
        type: item?.type === 'anticipation' ? 'Antecipação' : 'Saldo',
      }))
    : [];

  const filteredData = data.filter(
    (item) =>
      String(item.user_name).toUpperCase().includes(search.toUpperCase()) ||
      String(
        item?.payment === 'pending' || !item.hasOwnProperty('payment')
          ? 'Pendente'
          : 'Pago'
      )
        .toUpperCase()
        .includes(search.toUpperCase()) ||
      String(item.type).toUpperCase().includes(search.toUpperCase())
  );

  const handleClick = (event, item) => {
    setSelectedId(item);
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
    setSelectedId(null);
  };

  useEffect(() => {
    if (file) {
      dispatch(
        AntecipationsActions.fileAntecipationsRequest(
          file,
          selectedId.antecipations_id
        )
      );
      handleClose();
    }
  }, [file]);

  useEffect(() => {
    dispatch(TruckersActions.getTruckersRequest());
    dispatch(AntecipationsActions.getAntecipationsRequest());
  }, [dispatch]);

  return (
    <Container>
      <Paper
        title="PAGAMENTOS MOTORISTA"
        icon={Images.IconHandeHolding}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              { label: 'Ações', id: 'actions' },
              { label: 'ID', id: 'id' },
              { label: 'Status', id: 'payment' },
              { label: 'Tipo', id: 'type' },
              { label: 'Id Motorista', id: 'user_id' },
              { label: 'Nome Motorista', id: 'user_name' },
              { label: 'Emissão', id: 'created_at' },
              { label: 'Valor', id: 'value_adt' },
            ]}
            data={filteredData}
            customComponent={{
              actions: (e, item) => (
                <Row>
                  <LongMenu
                    handleClick={(e) => handleClick(e, item)}
                    handleClose={handleClose}
                    anchorMenu={anchorMenu}
                    menuOptions={[
                      {
                        icon: <EyeIcon />,
                        textButton: 'Visualizar',
                        onClick: () => {
                          const [trucker] = truckers.filter(
                            (data) => data.id === selectedId.user_id
                          );
                          history.push('/InfoAntecipations', {
                            trucker,
                            antecipations_id: selectedId.antecipations_id,
                            value: selectedId.value_adt,
                            cte_id: selectedId.cte_id,
                          });
                        },
                      },
                      {
                        icon: <DeleteIcon />,
                        textButton: 'Excluir',
                        onClick: () => {
                          setAnchorEl(e.currentTarget);
                          setOpen(true);
                        },
                      },
                      {
                        icon: <UploadFile />,
                        textButton: 'Anexar Comprovante',
                        onClick: () => {
                          setAnchorEl(e.currentTarget);
                          inputFile.current.click();
                        },
                      },
                    ]}
                  />
                </Row>
              ),

              created_at: (e) => (e ? format(new Date(e), 'dd/MM/yyyy') : '-'),

              value_adt: (e) =>
                e.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),

              payment: (e) => (e === 'pending' ? 'Pendente' : 'Pago'),
            }}
          />
        </WrapperTable>
      </Paper>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
        }}
        placement="center"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <WrapperPopover>
          <PopoverText>Tem certeza que deseja excluir ?</PopoverText>
          <PopoverButton
            onClick={() => {
              dispatch(
                AntecipationsActions.removeAntecipationsRequest(
                  selectedId.antecipations_id
                )
              );
              setOpen(false);
            }}
          >
            Sim
          </PopoverButton>
        </WrapperPopover>
      </Popover>

      <InputFile
        ref={inputFile}
        onChange={(res) => setFile(res.target.files[0])}
        accept="image/*,.pdf"
      />
    </Container>
  );
}

export default Antecipations;
