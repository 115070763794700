import React, { useState, useCallback } from 'react';
import { string } from 'prop-types';
import { Controlled } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { Row, Column } from 'styles/components';
import Button from 'components/Button';

import { WrapperImage, Image, SeeImageText } from './styles';

function SeeImage({ image, text }) {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomUp = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <Row align="flex-end">
      <WrapperImage>
        <Controlled isZoomed={isZoomed} onZoomChange={handleZoomChange}>
          <Image src={image} />
        </Controlled>
      </WrapperImage>
      <Column justify="flex-end" width="50%">
        <SeeImageText>{text}</SeeImageText>
        <Button label="VER" onClick={handleZoomUp} />
      </Column>
    </Row>
  );
}

export default SeeImage;

SeeImage.propTypes = {
  image: string.isRequired,
  text: string.isRequired,
};
