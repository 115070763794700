import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getChatRequest: null,
  getChatSuccess: ['data'],
  getChatFailure: ['error'],
  addMessageRequest: ['id', 'userId', 'message'],
  addMessageSuccess: null,
  addMessageFailure: ['error'],
  getMessagesRequest: ['id'],
  getMessagesSuccess: ['messages'],
  getMessagesFailure: ['error'],
});

export const ChatTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  messages: [],
  isLoading: false,
  error: null,
});

/* Reducers */

const getChatRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getChatSuccess = (state, { data }) =>
  state.merge({
    data,
    isLoading: false,
  });

const getChatFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addMessageRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addMessageSuccess = (state) =>
  state.merge({
    isLoading: false,
  });

const addMessageFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const getMessagesRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getMessagesSuccess = (state, { messages }) =>
  state.merge({
    messages,
    isLoading: false,
  });

const getMessagesFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CHAT_REQUEST]: getChatRequest,
  [Types.GET_CHAT_SUCCESS]: getChatSuccess,
  [Types.GET_CHAT_FAILURE]: getChatFailure,
  [Types.ADD_MESSAGE_REQUEST]: addMessageRequest,
  [Types.ADD_MESSAGE_SUCCESS]: addMessageSuccess,
  [Types.ADD_MESSAGE_FAILURE]: addMessageFailure,
  [Types.GET_MESSAGES_REQUEST]: getMessagesRequest,
  [Types.GET_MESSAGES_SUCCESS]: getMessagesSuccess,
  [Types.GET_MESSAGES_FAILURE]: getMessagesFailure,
});
