import styled from 'styled-components';
import { MdExitToApp } from 'react-icons/md';

export const Header = styled.header`
  width: 100vw;
  height: 80px;
  background: linear-gradient(179.76deg, #22467d 0.25%, #0a1d3c 99.82%);
  padding: 36px 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 19px;
    line-height: 27px;

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .space {
    width: 100px;
  }

  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 425px) {
    flex-direction: column;
    height: fit-content;

    h2 {
      margin-top: 12px;
    }
  }
`;

export const Main = styled.main`
  background: #ffffff;
  height: max-content;
`;

export const Content = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  padding: 80px 0;
  overflow: hidden;
  margin-top: 20px;

  @media (max-width: 425px) {
    padding: 110px 0 80px 0;
  }
`;

export const Footer = styled.footer`
  width: 100vw;
  /* height: 80px; */
  background: linear-gradient(179.76deg, #22467d 0.25%, #0a1d3c 99.82%);
  padding: 18.5px 32px;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .description {
    width: 1110px;

    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;

    color: #ffffff;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 425px) {
    flex-direction: column;

    .description {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`;

export const Logo = styled.img`
  width: 80px;
`;

export const ExitIcon = styled(MdExitToApp).attrs({ size: '18px' })`
  color: #122d58;
  margin-right: 13px;
`;
