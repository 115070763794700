import cep from 'cep-promise';
import React, { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import makeAnimated from 'react-select/animated';
import Images from 'assets/img';
import Map from 'components/Map';
import TextInput from 'components/TextInput';
import { Input } from 'components/TextInput/styles';
import WrapperInput from 'components/WrapperInput';
import { locationByAddress } from 'services/getLocations';
import { Column, Row } from 'styles/components';
import {
  BoxMap,
  BoxSearch,
  Center,
  Container,
  Content,
  CustomText,
  FieldLabel,
  FixedAdressDiv,
  ImgFlag,
  Loading,
} from './styles';

function CollectPoints({ formik, formErrors, edit }) {
  const key = process.env.REACT_APP_MAPS_KEY;
  const animatedComponents = makeAnimated();

  const [isShown, setIsShown] = useState(true);
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [fullAddress, setFullAddress] = useState({
    cep: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const getFormattedAddress = (address, number) => {
    let result = address.street;
    if (number && number.length) {
      result += `, ${number}`;
    }
    if (address.neighborhood.length) {
      result += `, ${address.neighborhood}`;
    }
    if (address.city.length) {
      result += `, ${address.city}`;
    }
    if (address.city.length) {
      result += ` - ${address.state}`;
    }
    return `${result}, Brasil`;
  };

  const getPosition = (address) => {
    locationByAddress(address).then((pos) => {
      if (pos.lat !== 0) {
        formik.setFieldValue('collect_lat', pos.lat);
        formik.setFieldValue('collect_lng', pos.lng);

        setPosition({
          latitude: pos.lat,
          longitude: pos.lng,
        });
      }
    });
  };

  const handleCepChange = (cepRequested) => {
    cep(cepRequested)
      .then((cepResponse) => {
        const address = {
          cep: cepRequested,
          street: cepResponse.street,
          neighborhood: cepResponse.neighborhood,
          city: cepResponse.city,
          state: cepResponse.state,
        };
        setFullAddress(address);

        formik.setFieldValue(`collect_point`, cepResponse.street);
        formik.setFieldValue(`collect_neighborhood`, cepResponse.neighborhood);
        formik.setFieldValue(`collect_city`, cepResponse.city);
        formik.setFieldValue(`collect_state`, cepResponse.state);

        getPosition(getFormattedAddress(address, formik.values.collect_number));
      })
      .catch(() => {
        setFullAddress({
          cep: cepRequested,
          street: formik.values.address,
          neighborhood: formik.values.neighborhood,
          city: '',
          state: '',
        });
        formik.setFieldError(`zipcode`, 'CEP não encontrado');
      });
  };

  const onChange = (option) => {
    formik.setFieldValue('delivery_type', option.value);
  };
  const getValue = (options) => {
    if (options) {
      return options.find(
        (option) => option.value === formik.values.delivery_type
      );
    } else {
      return '';
    }
  };

  return (
    <Container>
      <Content>
        <Column>
          <FixedAdressDiv>
            <FaInfoCircle color="#7B7B7B" />
            <CustomText>Ponto de coleta fixo</CustomText>
          </FixedAdressDiv>
          <Row>
            <WrapperInput>
              <TextInput
                label="CEP"
                type="number"
                name="collect_zipcode"
                error={
                  formik.touched.collect_zipcode &&
                  Boolean(formik.errors.collect_zipcode)
                }
                value={formik.values.collect_zipcode}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_zipcode', '');
                  formik.setFieldError('collect_point', '');
                  formik.setFieldError('collect_neighborhood', '');
                  formik.setFieldError('collect_city', '');
                  formik.setFieldError('collect_state', '');
                }}
                onBlur={(e) => handleCepChange(e.target.value)}
                disabled={edit}
              />
            </WrapperInput>
            <WrapperInput>
              <TextInput
                label="Endereço"
                name="collect_point"
                placeholder=""
                error={
                  formik.touched.collect_point &&
                  Boolean(formik.errors.collect_point)
                }
                value={formik.values.collect_point}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_point', '');
                }}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>
            <WrapperInput>
              <TextInput
                label="Complemento"
                name="collect_complement"
                placeholder=""
                value={formik.values.collect_complement}
                onChange={formik.handleChange}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>
          </Row>
          <Row>
            <WrapperInput>
              <TextInput
                label="Bairro"
                name="collect_neighborhood"
                minWidth="15.5vw"
                placeholder=""
                error={
                  formik.touched.collect_neighborhood &&
                  Boolean(formik.errors.collect_neighborhood)
                }
                value={formik.values.collect_neighborhood}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_neighborhood', '');
                }}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>

            <WrapperInput>
              <TextInput
                label="Cidade"
                name="collect_city"
                placeholder=""
                error={
                  formik.touched.collect_city &&
                  Boolean(formik.errors.collect_city)
                }
                value={formik.values.collect_city}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_city', '');
                }}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>
            <WrapperInput>
              <TextInput
                label="Estado"
                name="collect_state"
                placeholder=""
                error={
                  formik.touched.collect_state &&
                  Boolean(formik.errors.collect_state)
                }
                value={formik.values.collect_state}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_state', '');
                }}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>
          </Row>
          <Row>
            <WrapperInput>
              <TextInput
                label="Nome do correspondente"
                name="collect_corresponding_name"
                placeholder=""
                value={formik.values.collect_corresponding_name}
                error={
                  formik.touched.collect_corresponding_name &&
                  Boolean(formik.errors.collect_corresponding_name)
                }
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_corresponding_name', '');
                }}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>

            <WrapperInput>
              <FieldLabel>Telefone do correspondente</FieldLabel>
              <Row>
                <BoxSearch>
                  <ImgFlag src={Images.IconFlagBrasil} />
                </BoxSearch>
                <InputMask
                  mask="(99) 99999-9999"
                  name="collect_corresponding_phone"
                  style={{ maxWidth: 150 }}
                  placeholder=""
                  disabled={edit}
                  error={
                    formik.touched.collect_corresponding_phone &&
                    Boolean(formik.errors.collect_corresponding_phone)
                  }
                  value={formik.values.collect_corresponding_phone}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldError('collect_corresponding_phone', '');
                  }}
                  onBlur={formik.handleBlur}
                >
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
              </Row>
            </WrapperInput>
            <WrapperInput>
              <TextInput
                label="E-mail do correspondente"
                name="collect_corresponding_email"
                placeholder=""
                error={
                  formik.touched.collect_corresponding_email &&
                  Boolean(formik.errors.collect_corresponding_email)
                }
                value={formik.values.collect_corresponding_email}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldError('collect_corresponding_email', '');
                }}
                onBlur={''}
                disabled={edit}
              />
            </WrapperInput>
          </Row>
        </Column>

        <BoxMap>
          {position.latitude !== 0 ? (
            <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={
                <Center>
                  <Loading />
                </Center>
              }
              containerElement={
                <div
                  style={{
                    height: `250px`,
                    // borderRadius: '11px',
                    overflow: 'hidden',
                    // boxShadow:
                    //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              position={position}
            />
          ) : (
            <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={
                <Center>
                  <Loading />
                </Center>
              }
              containerElement={
                <div
                  style={{
                    height: `250px`,
                    // borderRadius: '11px',
                    overflow: 'hidden',
                    // boxShadow:
                    //   '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              position={position}
            />
          )}
        </BoxMap>
      </Content>
      {/* <button type="button" onClick={()=> changeValue('value from c1')}>CLICK</button> */}
    </Container>
  );
}

export default CollectPoints;
