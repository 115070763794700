/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import Images from 'assets/img';
import { Loading } from 'pages/Chat/styles';
import MarkerLetter from 'pages/Convocations/components/MarkerLetter';
import { saveAs } from 'file-saver';
import {
  capitalize,
  getImgType,
  getWeight,
  zipcode,
} from 'pages/Convocations/helpers';
import { Column, Row } from 'styles/components';
import {
  BoxPhoto,
  Documento,
  GridFiles,
  ImageWithLabelAndValue,
  Label,
  Pagina,
  TitleBox,
} from './styles';
import { GetApp, WarningRounded } from '@material-ui/icons';
import { InfoBox } from '../../styles';
import { Document, Page, pdfjs } from 'react-pdf';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Scrollbars from 'react-custom-scrollbars';

export default function ChargeInfoTwo({
  selectedInvoice,
  selectedConvocation,
  cte,
}) {
  const { height, width } = useWindowDimensions();
  const download = (image) => {
    saveAs(image, image.split('?')[0].split('%').pop());
  };
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return cte != null ? (
    <InfoBox>
      <TitleBox>Informações da Carga</TitleBox>
      {width > 425 ? (
        <Row>
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Número do manifesto</p>
              <p className="value">
                {selectedInvoice
                  ? selectedInvoice.client_id_invoice
                    ? selectedInvoice.client_id_invoice
                    : 'Não informado'
                  : cte.id}
              </p>
            </div>
          </ImageWithLabelAndValue>
          {!selectedInvoice && (
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Notas Fiscais</p>
                <p className="value">{cte.invoices.length}</p>
              </div>
            </ImageWithLabelAndValue>
          )}
          <ImageWithLabelAndValue>
            <div className="label-and-value">
              <p className="label">Impostos</p>
              <p className="value">
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(cte.tax)}
              </p>
            </div>
          </ImageWithLabelAndValue>
        </Row>
      ) : (
        <>
          <Row>
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Número do manifesto</p>
                <p className="value">
                  {selectedInvoice
                    ? selectedInvoice.client_id_invoice
                      ? selectedInvoice.client_id_invoice
                      : 'Não informado'
                    : cte.id}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
          <Row>
            {!selectedInvoice && (
              <ImageWithLabelAndValue>
                <div className="label-and-value">
                  <p className="label">Notas Fiscais</p>
                  <p className="value">{cte.invoices.length}</p>
                </div>
              </ImageWithLabelAndValue>
            )}
            <ImageWithLabelAndValue>
              <div className="label-and-value">
                <p className="label">Impostos</p>
                <p className="value">
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(cte.tax)}
                </p>
              </div>
            </ImageWithLabelAndValue>
          </Row>
        </>
      )}
      <Row>
        <ImageWithLabelAndValue>
          <div className="label-and-value">
            <p className="label">Frete</p>
            <p className="value">
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(cte.transportTotal)}
            </p>
          </div>
        </ImageWithLabelAndValue>
        <ImageWithLabelAndValue>
          <div className="label-and-value">
            <p className="label">Total</p>
            <p className="value">
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(cte.totalInvoices)}
            </p>
          </div>
        </ImageWithLabelAndValue>
      </Row>
      <Row width="100%">
        <Column width="100%">
          <Label>Arquivos anexados</Label>
          <Scrollbars style={{ maxWidth: '100%', height: '110px' }}>
            {!selectedInvoice ? (
              <GridFiles>
                <BoxPhoto
                  key={cte.url_mdfe}
                  onClick={() => {
                    if (cte.url_mdfe) {
                      window.open(cte.url_mdfe);
                    }
                  }}
                >
                  {cte.url_mdfe ? (
                    <Documento file={cte.url_mdfe}>
                      <Pagina width="100" pageNumber={1} />
                    </Documento>
                  ) : (
                    <>
                      <div className="not-found">
                        <WarningRounded />
                        <p>Não encontrado</p>
                      </div>
                    </>
                  )}
                  <div className="download-box">
                    <GetApp className="icon" />
                  </div>
                </BoxPhoto>
                {cte.invoices.map((invoice) => {
                  if (invoice.dacte) {
                    return (
                      <BoxPhoto
                        key={invoice.dacte}
                        onClick={() => {
                          window.open(invoice.dacte);
                        }}
                      >
                        <Documento file={invoice.dacte}>
                          <Pagina width="100" pageNumber={1} />
                        </Documento>
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    );
                  }
                })}
                {cte.invoices.map((invoice) => {
                  if (invoice.images != null && invoice.images.length > 0) {
                    return invoice.images.map((image) => (
                      <BoxPhoto key={image} onClick={() => download(image)}>
                        <img src={image} alt="Imagem" />
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    ));
                  }
                  return null;
                })}
              </GridFiles>
            ) : (
              <GridFiles>
                {selectedInvoice.documents &&
                  selectedInvoice.documents.map((doc) => {
                    return doc.split('.').pop().toLowerCase() !== '.pdf' ? (
                      <BoxPhoto key={doc} onClick={() => download(doc)}>
                        <img src={doc} alt="Imagem" />
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    ) : (
                      <BoxPhoto
                        key={doc}
                        onClick={() => {
                          window.open(doc);
                        }}
                      >
                        <Documento file={doc}>
                          <Pagina width="100" pageNumber={1} />
                        </Documento>
                        <div className="download-box">
                          <GetApp className="icon" />
                        </div>
                      </BoxPhoto>
                    );
                  })}
              </GridFiles>
            )}
          </Scrollbars>
        </Column>
      </Row>
    </InfoBox>
  ) : (
    <InfoBox>
      <Loading />
    </InfoBox>
  );
}
