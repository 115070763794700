import styled from 'styled-components';

export const Button = styled.div`
  width: ${(props) => props.width || 'fit-content'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.type === 'primary' || props.type === 'submit'
      ? '#122D58'
      : props.type === 'text-button'
      ? 'transparent'
      : 'transparent'};
  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.type === 'primary' || props.type === 'submit'
      ? '#FFFFFF'
      : props.type === 'text-button'
      ? '#122D58'
      : '#122D58'};
  border: ${(props) =>
    props.type === 'primary' ||
    props.type === 'submit' ||
    props.type === 'text-button'
      ? 'none'
      : '1px solid #122D58'};
  border-radius: 5px;
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || '0'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13.5px 15px;
  align-self: ${(props) => props.align || 'stretch'};
  font-size: 14px;
  font-weight: 800;
  transition: 400ms;

  &:hover {
    background-color: ${(props) =>
      props.type === 'text-button' ? 'rgba(0, 0, 0, 0.1)' : ''};
    background-color: ${(props) =>
      // eslint-disable-next-line no-nested-ternary
      props.type === 'primary' || props.type === 'submit'
        ? 'transparent'
        : props.type === 'text-button'
        ? 'rgba(0, 0, 0, 0.1)'
        : '#122D58'};
    color: ${(props) =>
      // eslint-disable-next-line no-nested-ternary
      props.type === 'primary' || props.type === 'submit'
        ? '#122D58'
        : props.type === 'text-button'
        ? '#FFFFFF'
        : '#FFFFFF'};
    border: ${(props) =>
      props.type === 'primary' ||
      props.type === 'submit' ||
      props.type === 'text-button'
        ? '1px solid #122D58'
        : '1px solid #122D58'};
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  filter: ${(props) => (props.iconLight ? 'brightness(100)' : null)};
`;
