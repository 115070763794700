/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func, string, arrayOf, object } from 'prop-types';

// import InputMask from 'react-input-mask';

import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
// import Selector from 'components/Selector';
// import Button from 'components/Button';
// import { Row, Column, Icon } from 'styles/components';
// import Images from 'assets/img';
// import { Input, Label } from 'components/TextInput/styles';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  Line,
  WrapperInput,
  // Loading,
  // Center,
  // ImgFlag,
} from './styles';

function ModalProviders({ data, open, onClose }) {
  return (
    <Modal open={open} onClose={onClose} title="REPORTE">
      <Container>
        <>
          <WrapperBody>
            <WrapperContent>
              <Line />

              <WrapperInput>
                <TextInput
                  label="Condutor"
                  name="driver"
                  value={data?.driver}
                  disabled
                />
              </WrapperInput>

              <WrapperInput>
                <TextArea
                  label="Reporte"
                  name="problem"
                  value={data?.problem}
                  disabled
                />
              </WrapperInput>

              <WrapperInput>
                <TextInput
                  label="Status"
                  name="status"
                  value={data?.status}
                  disabled
                />
              </WrapperInput>

              {/* <Selector
                margin="20px 0 0 0"
                options={users}
                name="status"
                value={data.status}
                onChange={handleChange}
              /> */}
            </WrapperContent>
          </WrapperBody>
          <WrapperBottom>
            <BottomText onClick={onClose}>Fechar</BottomText>
          </WrapperBottom>
        </>
      </Container>
    </Modal>
  );
}

export default ModalProviders;

ModalProviders.propTypes = {
  open: bool.isRequired,
  edit: bool.isRequired,
  onClose: func.isRequired,
  selectedId: string,
  data: arrayOf(object).isRequired,
};

ModalProviders.defaultProps = {
  selectedId: null,
};
