import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Container, Label, WrapperDatePicker, DateInput } from './styles';

function DatePicker({
  label,
  selectedDate,
  handleDateChange,
  name,
  error,
  helperText,
  disabled,
}) {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <WrapperDatePicker error={error}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateInput
            name={name}
            helperText={helperText}
            margin="normal"
            id={`date-picker-dialog${label}`}
            format="dd/MM/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </WrapperDatePicker>
    </Container>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  handleDateChange: () => {},
  name: null,
  error: false,
};

export default DatePicker;
