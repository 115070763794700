import Logo from './logo.png';
import Driver from './driver-banner.png';
import IconExclamationPaper from './icon-exclamation-paper.png';
import IconBlueExclamationPaper from './icon-blue-exc-paper.png';
import IconMoneyPaper from './icon-money-paper.png';
import IconRedMoneyPaper from './icon-red-money-paper.png';
import IconBlueUsers from './icon-blue-users.png';
import IconGrayUsers from './icon-gray-users.png';
import IconWhiteUsers from './icon-white-users.png';
import IconPaper from './icon-paper.png';
import IconGrayUser from './icon-gray-user.png';
import IconUserGear from './icon-user-gear.png';
import IconCamera from './icon-camera.png';
import IconTruckPaper from './icon-truck-paper.png';
import IconBlueMoney from './icon-blue-money.png';
import IconRedMoney from './icon-red-money.png';
import IconProfileImg from './icon-profile-img.png';
import IconGrayTruck from './icon-gray-truck.png';
import IconFlagBrasil from './icon-flag-brasil.png';
import IconProviders from './icon-providers.png';
import IconChat from './icon-chat.png';
import IconReports from './icon-reports.png';
import IconSend from './icon-send.png';
import Empty from './empty.png';
import StatusZero from './status0.png';
import StatusOne from './status1.png';
import StatusTwo from './status2.png';
import StatusThree from './status3.png';
import StatusFour from './status4.png';
import StatusFive from './status5.png';
import Qrcode from './icon_qrcode.png';
import Check from './icon-check.png';
import Block from './icon-block.png';
import Issuer from './icon-gray-issuer.png';
import Add from './icon-add.png';
import Filter from './icon-filter.png';
import DangerousFlag from './dangerous-flag.png';
import AnimalFlag from './animal-flag.png';
import ChemicalFlag from './chemical-flag.png';
import FlammableFlag from './flammable-flag.png';
import ColdFlag from './cold-flag.png';
import AgriculturalFlag from './agricultural-flag.png';
import GeneralFlag from './general-flag.png';
import PetFlag from './pet-flag.png';
import GasFlag from './gas-flag.png';
import WoodFlag from './wood-flag.png';
import ChargeStatusZero from './charge-status0.svg';
import ChargeStatusOne from './charge-status1.svg';
import ChargeStatusTwo from './charge-status2.svg';
import ChargeStatusThree from './charge-status3.svg';
import ChargeStatusFour from './charge-status4.svg';
import ChargeStatusFive from './charge-status5.svg';
import MarkerCharge from './marker-charge.svg';
import IconDate from './icon-date.svg';
import IconDriver from './icon-driver.svg';
import Gift from './icon-gift.svg';
import Kg from './icon-kg.png';
import Monetization from './icon-monetization.png';
import IconBlueGreyUsers from './icon-blue-grey-users.png';
import BoxFlag from './box-flag.png';
import BoxCalendar from './box-calendar.png';
import IconMarkerBlue from './marker-blue.svg';
import IconMarker from './icon-marker.svg';
import IconBlueEye from './icon-blue-eye.svg';
import IconDownload from './icon-download.svg';
import IconSendGreen from './icon-send-green.svg';
import IconPath from './icon-path.svg';
import IconPathOrange from './icon-path-orange.svg';
import IconTrash from './icon-trash.svg';
import LogoMap from './logo-map.svg';
import DoneIcon from './done-icon.svg';
import IconDateBlue from './icon-date-blue.svg';
import IconBoxBlue from './icon-box-blue.svg';
import IconTruckerBlue from './icon-trucker-blue.svg';
import IconKgBlue from './icon-kg-blue.svg';
import MarkerChargeOne from './marker-charge1.svg';
import MarkerChargeTwo from './marker-charge2.svg';
import MarkerChargeThree from './marker-charge3.svg';
import MarkerChargeFour from './marker-charge4.svg';
import MarkerMol from './marker-mol.png';
import IconHandHolding from './icon-gray-hand-holding.png';
import IconShare from './icon-share.svg';

const Images = {
  Logo,
  Driver,
  IconExclamationPaper,
  IconBlueExclamationPaper,
  IconMoneyPaper,
  IconRedMoneyPaper,
  IconBlueUsers,
  IconBlueGreyUsers,
  IconGrayUsers,
  IconWhiteUsers,
  IconPaper,
  IconGrayUser,
  IconUserGear,
  IconCamera,
  IconTruckPaper,
  IconBlueMoney,
  IconRedMoney,
  IconProfileImg,
  IconGrayTruck,
  IconFlagBrasil,
  IconProviders,
  IconChat,
  IconReports,
  IconSend,
  Empty,
  StatusZero,
  StatusOne,
  StatusTwo,
  StatusThree,
  StatusFour,
  StatusFive,
  Qrcode,
  Check,
  Block,
  Issuer,
  Add,
  Filter,
  DangerousFlag,
  AnimalFlag,
  ChemicalFlag,
  FlammableFlag,
  AgriculturalFlag,
  GeneralFlag,
  PetFlag,
  GasFlag,
  WoodFlag,
  ColdFlag,
  ChargeStatusZero,
  ChargeStatusOne,
  ChargeStatusTwo,
  ChargeStatusThree,
  ChargeStatusFour,
  ChargeStatusFive,
  Gift,
  Kg,
  Monetization,
  BoxFlag,
  BoxCalendar,
  MarkerCharge,
  IconDate,
  IconDriver,
  IconMarker,
  IconPath,
  IconPathOrange,
  IconSendGreen,
  IconBlueEye,
  IconDownload,
  IconTrash,
  LogoMap,
  DoneIcon,
  IconMarkerBlue,
  IconDateBlue,
  IconBoxBlue,
  IconTruckerBlue,
  IconKgBlue,
  MarkerChargeOne,
  MarkerChargeTwo,
  MarkerChargeThree,
  MarkerChargeFour,
  MarkerMol,
  IconHandHolding,
  IconShare,
};

export default Images;
