import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Lottie from 'react-lottie';
import { schema } from './schemaValidation';
import AuthActions from 'store/ducks/auth';
import Images from 'assets/img';
import TextField from 'components/TextField';
import animationData from 'assets/animation.json';

import {
  Logo,
  Content,
  WrapperButtons,
  EnterButton,
  ButtonText,
  ForgotLink,
  Forgot,
  GridLoading,
} from './styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function SignIn() {
  const dispatch = useDispatch();
  const authChecked = useSelector((state) => state.auth.authChecked);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const signedIn = useSelector((state) => state.auth.signedIn);

  function handleSubmitted(values) {
    dispatch(AuthActions.signInRequest(values.email, values.password));
  }

  if (!authChecked)
    return (
      <GridLoading>
        <Lottie options={defaultOptions} height={400} width={400} />
      </GridLoading>
    );

  if (isLoading)
    return (
      <GridLoading>
        <Lottie options={defaultOptions} height={400} width={400} />
      </GridLoading>
    );

  return !signedIn ? (
    <Content>
      <Logo src={Images.Logo} />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values) => handleSubmitted(values)}
        validationSchema={schema}
      >
        {({ values, handleChange, errors, touched, handleSubmit }) => (
          <>
            <TextField
              label="Login"
              name="email"
              helperText={touched.email ? errors.email : ''}
              error={Boolean(errors.email)}
              value={values.email}
              onChange={handleChange}
              fullWidth
              color="primary"
            />
            <TextField
              label="Senha"
              name="password"
              type="password"
              helperText={touched.password ? errors.password : ''}
              error={Boolean(errors.password)}
              value={values.password}
              onChange={handleChange}
              fullWidth
              color="primary"
            />
            <Forgot>
              <ForgotLink href="/forgot" variant="body2" color="#000">
                Recuperar senha
              </ForgotLink>
            </Forgot>
            <WrapperButtons>
              <EnterButton onClick={handleSubmit} color="primary">
                <ButtonText>ENTRAR</ButtonText>
              </EnterButton>
            </WrapperButtons>
          </>
        )}
      </Formik>
    </Content>
  ) : (
    <Redirect to="/" />
  );
}
