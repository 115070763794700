import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MdClose } from 'react-icons/md';

export const Container = styled(PerfectScrollbar)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background-color: #0a1d3b66;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  max-width: 90vw;
`;

export const Header = styled.div`
  height: 71px;
  background-color: #122d58;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Close = styled(MdClose).attrs({
  size: '20px',
})`
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
`;
