import { put } from 'redux-saga/effects';
import api from 'services/api';

import DashboardActions from '../ducks/dashboard';

export function* getUndoneUser() {
  try {
    const res = yield api
      .firestore()
      .collection('users')
      .where('completed', '==', false)
      .get();
    const users = res.docs.map((item) => item.data());

    yield put(DashboardActions.getUndoneUserSuccess(users));
  } catch (err) {
    yield put(DashboardActions.getUndoneUserFailure(err));
  }
}

export function* getDashboard({ year }) {
  try {
    const res = yield api.firestore().collection('dashboard').doc(year).get();

    const data = res.data();
    yield put(DashboardActions.getDashboardSuccess(data));
  } catch (err) {
    yield put(DashboardActions.getDashboardFailure(err));
  }
}

export function* getYearsOptions() {
  try {
    const res = yield api.firestore().collection('dashboard').get();
    const years = res.docs
      .map((item) => ({ text: item.id, value: item.id }))
      .reverse();
    yield put(DashboardActions.getYearsOptionsSuccess(years));
    yield put(DashboardActions.getDashboardRequest(years[0].value));
  } catch (err) {
    yield put(DashboardActions.getYearsOptionsFailure(err));
  }
}
