/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { bool, func, arrayOf, object, string } from 'prop-types';
import { FiRotateCw, FiUser } from 'react-icons/fi';
import ConvocationActions from 'store/ducks/convocations';

import Modal from 'components/Modal';

import {
  Container,
  ContainerModal,
  Card,
  WrapperCenter,
  SendIcon,
  Header,
  CirclePhoto,
  Name,
  SendImg,
} from './styles';
import ButtonDrawer from 'components/ButtonDrawer';
import { useDispatch, useSelector } from 'react-redux';
import Images from 'assets/img';
import { Close } from '@material-ui/icons';
import api from 'services/api';
import ButtonModal from 'components/ButtonModal';
import { Row } from 'styles/components';
import { ButtonSend, WrapperInput } from 'pages/Chat/styles';
import TextInput from 'components/TextInput';
import ConvocationsActions from 'store/ducks/convocations';

export default function MessageTruckerModal({ open, reload, onClose }) {
  const dispatch = useDispatch();
  const { convocationActive } = useSelector((state) => state.convocations);
  const [textMessage, setTextMessage] = useState('');
  const { selectedTrucker } = useSelector((state) => state.trucker);

  const handleSend = (convocation) => {};

  return (
    <Container>
      <Card>
        <ContainerModal>
          <Header>
            <SendIcon />
            Enviar mensagem ao motorista
            <Close
              onClick={() => dispatch(ConvocationsActions.closeMessageModal())}
              className="close-icon"
            />
          </Header>
          <Row align="center" justify="flex-start">
            <CirclePhoto>
              <div className="img">
                <FiUser />
              </div>
            </CirclePhoto>
            <Name>{selectedTrucker.name}</Name>
          </Row>
          <Row align="center" justify="center">
            <WrapperInput>
              <TextInput
                height="40px"
                placeholder="Enviar mensagem..."
                onChange={(e) => setTextMessage(e.target.value)}
                value={textMessage}
              />
            </WrapperInput>
            <ButtonSend onClick={handleSend}>
              <SendImg src={Images.IconSend} />
            </ButtonSend>
          </Row>
        </ContainerModal>
      </Card>
    </Container>
  );
}

MessageTruckerModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
