import { MdDelete } from 'react-icons/md';
import styled from 'styled-components';
import InputCurrency from 'react-currency-input';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#122d58';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const Container = styled.form`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`;

export const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0 30px 0;
`;
export const Divider = styled.div`
  border: 1px solid #dddddd;
`;
export const InfoTitle = styled.text`
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  font-weight: bold;
  color: #8091ab;
`;
export const TableValue = styled.text`
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  font-weight: bold;
  color: #8091ab;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const ItemTable = styled.div`
  width: 100%;
`;

export const WrapperSection = styled.div`
  width: 100%;
  max-height: 350px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 30px;
`;

export const InnerSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: left; */
  /* justify-content: space-between; */
`;
export const WrapperInput = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 30px;
  align-content: space-between;
`;

export const DeleteIcon = styled(MdDelete).attrs({ size: '16px' })`
  color: #f27160;
  cursor: pointer;
  margin-right: 24px;
`;

export const cteSelect = {
  control: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: 'white',
    display: 'flex',
    width: 250,
    borderColor: '#ebebeb',
    minHeight: 32,
    height: 32,

    // ':hover': {
    //   ...styles[':hover'],
    //   borderColor: !isDisabled && (isSelected ? '#122d58' : '#ebebeb'),
    // },
    ':active': {
      ...styles[':active'],
      borderColor: !isDisabled && (isSelected ? '#122d58' : '#cecece'),
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      width: 250,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#122d58'
        : isFocused
        ? '#cecece'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : 'gray',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#122d58' : 'gray'),
      },
    };
  },
  dropdownIndicator: (base) => ({
    ...base,
    color: '#122d58', // Custom colour
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
export const FieldLabel = styled.text`
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  margin-bottom: 7px;
`;
export const WrapperTable = styled.div``;

export const Column = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
`;

export const DragDrop = styled.div`
  flex: 1;
  align-items: center;
  width: 100%;
  max-width: 480px;
  height: 260px;
  margin: 80px 30px 0 20px;
  padding: 120px 80px 120px 80px;
  border-width: 2px;
  border-radius: 2px;
  background-color: #cecece;
  border-style: solid;
  font-size: 11px;
  color: #7b7b7b;
  font-weight: bold;
  outline: none;
  border-color: ${(props) => getColor(props)};
  transition: border 0.24s ease-in-out;
`;
export const Currency = styled(InputCurrency)`
  -webkit-appearance: none;
  height: ${(props) => props.height || '32px'};
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid ${(props) => (props.error ? '#F27160' : '#ebebeb')};
  border-radius: 3px;
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  max-width: ${(props) => props.maxWidth || '100%'};

  ${(props) => props.error && 'box-shadow: 0 3px 6px #f2716044;'}

  ::placeholder {
    font-weight: 400;
  }

  transition: 200ms;
`;

export const Error = styled.span`
  padding: 5px;
  font-size: 12px;
  text-align: center;
  color: red;
  font-weight: 600;
  width: 100%;
`;
