/* eslint-disable no-console */
/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import {
  Content,
  InfoBox,
  TitleBox,
  WrapperBottom,
  BottomText,
  Txt,
  Logo,
  WrapperLogo,
  Loading,
} from './styles';
import TextArea from 'components/TextArea';
import Images from 'assets/img';
import api from 'services/api';

export default function EvaluationForm() {
  const { id } = useParams();
  const [valueDriver, setValueDriver] = React.useState(1);
  const [valueDelivery, setValueDelivery] = React.useState(1);
  const [valueObs, setValueObs] = React.useState('');
  const [invoiceName, setInvoiceName] = React.useState('');
  const [selectedRating, setSelectedRating] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  function getCte(cteId) {
    api
      .firestore()
      .collection('ctes')
      .doc(cteId)
      .get()
      .then((doc) => {
        if (doc) {
          const cteInvoices = doc.data().invoices;
          if (
            selectedRating &&
            cteInvoices.length > 0 &&
            selectedRating.barcode
          ) {
            cteInvoices.forEach((invoice) => {
              if (invoice.barcode === selectedRating.barcode) {
                setInvoiceName(
                  invoice.cnpj ? invoice.data.name : invoice.receiver.name
                );
              }
            });
          }
        }
      });
  }

  function getRating(rateId) {
    if (rateId) {
      api
        .firestore()
        .collection('rating')
        .where('id', '==', rateId)
        .onSnapshot((snapshot) => {
          if (snapshot.docs.length > 0) {
            const selectedRate = snapshot.docs[0].data();
            setSelectedRating(selectedRate);
            if (selectedRate) {
              setLoading(false);
            }
          } else {
            console.log('No such document!');
            setSelectedRating(null);
          }
        });
    }
  }

  useEffect(() => {
    if (selectedRating && selectedRating.cte_id) {
      getCte(selectedRating.cte_id);
    }
  }, [selectedRating]);

  useEffect(() => {
    if (id.length >= 6) {
      setLoading(true);
      getRating(id);
    }
  }, [id]);

  function handleSubmitRate() {
    const dataUpdated = {
      ...selectedRating,
      valueDriver,
      valueDelivery,
      valueObs,
      updated_at: new Date(),
    };

    if (selectedRating.id) {
      api
        .firestore()
        .collection('rating')
        .doc(selectedRating.id)
        .update(dataUpdated)
        .then((resp) => {
          console.log('Updated rate', resp);
        })
        .catch((err) => console.log('Error updated rate', err));
    }
  }

  return (
    <Content>
      <InfoBox>
        {loading && (
          <WrapperLogo>
            <Logo src={Images.MarkerMol} />
          </WrapperLogo>
        )}
        {loading ? (
          <Loading />
        ) : (
          <Box
            sx={{
              '& > legend': { mt: 2 },
            }}
          >
            {selectedRating === null && (
              <TitleBox>Avaliação de entrega não encontrada!</TitleBox>
            )}
            {selectedRating && selectedRating.valueDriver ? (
              <TitleBox>A entrega já foi avaliada. Obrigado!</TitleBox>
            ) : (
              selectedRating && (
                <>
                  <TitleBox>Avalie a entrega da sua carga</TitleBox>
                  <Txt textAlign="left" fontSize="14px">
                    Olá, <strong>{invoiceName}</strong>
                    <strong>{selectedRating && selectedRating.name}</strong>,
                    nos importamos com sua avaliação e opinião. Por favor,
                    avalie sua entrega e deixe seus comentários, caso
                    necessário.
                  </Txt>
                  <Typography component="legend">Motorista</Typography>
                  <Rating
                    name="simple-controlled"
                    value={valueDriver}
                    onChange={(event, newValue) => {
                      setValueDriver(newValue);
                    }}
                  />
                  <Typography component="legend">Entrega</Typography>
                  <Rating
                    name="simple-controlled"
                    value={valueDelivery}
                    onChange={(event, newValue) => {
                      setValueDelivery(newValue);
                    }}
                  />
                  <TextArea
                    label="Observações"
                    width="100%"
                    name="body"
                    value={valueObs}
                    onChange={(event) => {
                      setValueObs(event.target.value);
                    }}
                  />
                  <WrapperBottom>
                    <BottomText onClick={handleSubmitRate}>Enviar</BottomText>
                  </WrapperBottom>
                </>
              )
            )}
          </Box>
        )}
      </InfoBox>
    </Content>
  );
}
