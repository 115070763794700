import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// import {
//   // ArrowForwardIos as ArrowForwardIosIcon,
//   Payment as PaymentIcon,
//   PeopleOutlined as PeopleIcon,
//   Code as CodeIcon,
//   Store as StoreIcon,
//   Schedule as ScheduleIcon,
// } from '@material-ui/icons';

import ptBr from 'date-fns/locale/pt-BR';
import Images from 'assets/img';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '350px',
    maxWidth: '100%',
  },
  header: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'url("/images/connected_world.svg")',
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    paddingBottom: '34px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '34px',
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  content: {},
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  listItemIcon: {
    width: 54,
    height: 54,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: '10px',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItemTextSecondary: {
    marignTop: '4px',
    color: theme.palette.text.secondary,
  },
  arrowForward: {
    color: theme.palette.text.secondary,
    height: '16px',
    width: '16px',
  },
  footer: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  empty: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  emptyImageWrapper: {
    marginBottom: theme.spacing(3),
  },
  emptyImage: {
    width: '240px',
    maxWidth: '100%',
    height: 'auto',
  },
}));

// const icons = {
//   order: {
//     icon: <PaymentIcon />,
//     color: 'blue',
//   },
//   schedule: {
//     icon: <ScheduleIcon />,
//     color: 'purple',
//   },
//   user: {
//     icon: <PeopleIcon />,
//     color: 'red',
//   },
//   product: {
//     icon: <StoreIcon />,
//     color: 'green',
//   },
//   feature: {
//     icon: <CodeIcon />,
//     color: 'yellow',
//   },
// };

export default function ReportsList({ reports, onSelect }) {
  const classes = useStyles();

  return (
    <div className={classes.rootClassName}>
      {reports.length > 0 ? (
        <>
          <div className={classes.header}>
            <Typography variant="h6">Reportes</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {reports.length} novo(s) reportes
            </Typography>
          </div>
          <div className={classes.content}>
            <List component="div">
              {reports.map((report) => (
                <div key={report.id}>
                  <ListItem
                    className={classes.listItem}
                    component="div"
                    onClick={() => onSelect(report.id)}
                  >
                    {/* <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ color: icons[report.type].color }}
                    >
                      {icons[report.type].icon}
                    </ListItemIcon> */}
                    <ListItemText
                      classes={{ secondary: classes.listItemTextSecondary }}
                      primary={`${report.problem} - Carga: #${report.convocation_id}`}
                      secondary={formatDistanceToNow(
                        report?.created_at?.seconds * 1000,
                        { locale: ptBr }
                      )}
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
            <div className={classes.footer}>
              {/* <Button
                  color="primary"
                  component={Link}
                  size="small"
                  to="#"
                  variant="contained"
                >
                  Ver todas
                </Button> */}
            </div>
          </div>
        </>
      ) : (
        <div className={classes.empty}>
          <div className={classes.emptyImageWrapper}>
            <img
              alt="Empty list"
              className={classes.emptyImage}
              src={Images.Empty}
            />
          </div>
          <Typography variant="h4">Nada por aqui...</Typography>
        </div>
      )}
    </div>
  );
}

ReportsList.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func,
};

ReportsList.defaultProps = {
  onSelect: () => {},
};
