import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import TextField from 'components/TextField';
import Selector from 'components/Selector';
import NotificationActions from 'store/ducks/notifications';

import { schema } from './schemaValidation';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
} from './styles';

function ModalComp({ open, onClose }) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.notifications);

  function createNotification(values) {
    dispatch(NotificationActions.addNotificationRequest(values, onClose));
  }

  useEffect(() => {
    dispatch(NotificationActions.getUsersRequest());
  }, [dispatch]);

  return (
    <Modal open={open} onClose={onClose} title="CADASTRAR NOTIFICAÇÃO">
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            title: '',
            body: '',
            direction: undefined,
          }}
          onSubmit={(values) => {
            createNotification(values);
          }}
          validationSchema={schema}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <WrapperBody>
                <WrapperContent>
                  <TextField
                    color="secondary"
                    placeholder="Título"
                    name="title"
                    error={touched.title && Boolean(errors.title)}
                    value={values.title}
                    onChange={handleChange}
                  />
                  <TextArea
                    label="Observações"
                    width="100%"
                    name="body"
                    error={touched.body && Boolean(errors.body)}
                    value={values.body}
                    onChange={handleChange}
                  />
                  <Selector
                    margin="20px 0 0 0"
                    options={users}
                    name="direction"
                    error={touched.direction && Boolean(errors.direction)}
                    value={values.direction}
                    onChange={handleChange}
                  />
                </WrapperContent>
              </WrapperBody>
              <WrapperBottom>
                <BottomText onClick={onClose}>Cancelar</BottomText>
                <BottomText onClick={handleSubmit}>Enviar</BottomText>
              </WrapperBottom>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
