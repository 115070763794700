import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';

import { Container, Label, Input, DatePicker } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

function DateInput({
  ref,
  id,
  name,
  label,
  selected,
  onChange,
  startDate,
  endDate,
  placeholder,
  disabled,
  error,
  height,
  width,
  minWidth,
}) {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <DatePicker
        ref={ref}
        name={name}
        id={id}
        dateFormat="dd/MM/yyyy"
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selected={selected}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        height={height}
        width={width}
        minWidth={minWidth}
        selectsRange
      />
    </Container>
  );
}

export default DateInput;

DateInput.propTypes = {
  ref: string,
  id: string,
  name: string,
  label: string,
  value: string,
  onChange: func,
  onBlur: func,
  placeholder: string,
  disabled: bool,
  error: bool,
  height: string,
  width: string,
  minWidth: string,
};

DateInput.defaultProps = {
  ref: null,
  id: null,
  name: null,
  label: '',
  value: null,
  onChange: () => {},
  onBlur: () => {},
  placeholder: 'Digite aqui...',
  disabled: false,
  error: false,
  height: '32px',
  width: '100%',
  minWidth: null,
};
