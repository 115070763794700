import { IconButton, Menu } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EyeIcon from '@material-ui/icons/Visibility';
import NotEyeIcon from '@material-ui/icons/VisibilityOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, Done } from '@material-ui/icons';
import { FaQrcode } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { MenuButtons } from '../../styles';
import { PopupButton } from './styles';
import CteActions from 'store/ducks/cte';
import ConvocationActions from 'store/ducks/convocations';
import Images from 'assets/img';
import api from 'services/api';

const ITEM_HEIGHT = 48;

export default function PopupMenu({
  viewConvocation,
  tooglePercurseLogs,
  selected,
  data,
  anchorEl,
  open,
  handleClose,
  handleClick,
}) {
  const dispatch = useDispatch();
  const [download, setDownload] = useState(false);
  const { cte } = useSelector((state) => state.cte);
  const { convocations } = useSelector((state) => state.convocations);

  useEffect(() => {
    if (cte.convocation_id === data.id && download) {
      console.log(cte);
      if (cte.url_mdfe) {
        window.open(cte.url_mdfe);
      }

      for (let i = 0; i < cte.invoices.length; i++) {
        if (cte.invoices[i].dacte) {
          window.open(cte.invoices[i].dacte);
        }
      }

      setDownload(false);
      handleClose();
    }
  }, [cte]);

  const [dataConvocation] = convocations.filter(
    (convocation) => convocation.id === data.actions
  );

  const handleUpdateConvocation = async () => {
    try {
      await api
        .firestore()
        .collection('convocations')
        .doc(dataConvocation.id)
        .update({
          ...dataConvocation,
          status: 'completed',
        });

      await toast.promise(Promise.resolve(), {
        success: 'Carga encerrada com sucesso!',
      });
    } catch (error) {
      console.error(error);
      await toast.promise(Promise.reject(), {
        error: (err) => `Erro ao encerrar carga: ${err.message}`,
      });
    }

    handleClose();
  };

  return (
    <>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: '185px',
            borderRadius: '0 5px 5px 5px',
            border: '1px solid #C4C4C4',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
            padding: '5px',
          },
        }}
        transformOrigin={{
          vertical: -40,
          horizontal: -20,
        }}
      >
        <MenuButtons>
          <PopupButton
            onClick={() => {
              viewConvocation(data);
              handleClose();
            }}
          >
            {selected ? (
              <>
                <NotEyeIcon className="icon" />
                <p>Tirar visualização</p>
              </>
            ) : (
              <>
                <EyeIcon className="icon" />
                <p>Visualizar</p>
              </>
            )}
          </PopupButton>
          <PopupButton
            onClick={() => {
              dispatch(ConvocationActions.setConvocationRequest(data));
              dispatch(ConvocationActions.openModalEdit());
              handleClose();
            }}
          >
            <Edit className="icon" />
            <p>Editar</p>
          </PopupButton>
          <PopupButton
            onClick={() => {
              dispatch(ConvocationActions.setConvocationRequest(data));
              dispatch(ConvocationActions.openModalQrcode());
              handleClose();
            }}
          >
            <FaQrcode className="icon" />
            <p>QR Code</p>
          </PopupButton>
          <PopupButton
            onClick={() => {
              dispatch(ConvocationActions.openShareModal(data.id));
              handleClose();
            }}
          >
            <img
              className="icon"
              src={Images.IconShare}
              alt="Ícone de Download"
            />
            <p>Compartilhar</p>
          </PopupButton>
          <PopupButton
            onClick={() => {
              dispatch(CteActions.getCteRequest(data.id));
              setDownload(true);
            }}
          >
            <img
              className="icon"
              src={Images.IconDownload}
              alt="Ícone de Download"
            />
            <p>Baixar documentos</p>
          </PopupButton>
          <PopupButton
            onClick={() => {
              tooglePercurseLogs(data);
              handleClose();
            }}
          >
            <img
              className="icon"
              src={Images.IconPathOrange}
              alt="Ícone de Caminho"
            />
            <p>Log de percurso</p>
          </PopupButton>
          <PopupButton
            onClick={() => {
              // viewConvocation(data);
              dispatch(ConvocationActions.setConvocationRequest(data));
              dispatch(ConvocationActions.openMessageModal());
              handleClose();
            }}
          >
            <img
              className="icon"
              src={Images.IconSendGreen}
              alt="Ícone de Envio"
            />
            <p>
              Mensagem ao
              <br />
              motorista
            </p>
          </PopupButton>
          <PopupButton
            onClick={() => {
              // viewConvocation(data);
              dispatch(ConvocationActions.setConvocationRequest(data));
              dispatch(ConvocationActions.openDeleteModal());
              handleClose();
            }}
          >
            <img className="icon" src={Images.IconTrash} alt="Ícone de Lixo" />
            <p>Excluir</p>
          </PopupButton>
          <PopupButton onClick={handleUpdateConvocation}>
            <Done className="icon" />
            <p>Encerrar</p>
          </PopupButton>
        </MenuButtons>
      </Menu>
    </>
  );
}
