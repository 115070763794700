import React from 'react';
import { Container } from './styles';

export default function TextIcon({ icon, text, color, heightIcon }) {
  return (
    <Container color={color} heightIcon={heightIcon}>
      <img src={icon} alt="Icone" />
      <p>{text}</p>
    </Container>
  );
}
