/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@material-ui/core';

import Paper from 'components/Paper';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import Modal from './components/Modal';
import Images from 'assets/img';
import { Row } from 'styles/components';
import CollabsActions from 'store/ducks/collaborators';

import {
  Container,
  WrapperTable,
  WrapperAdd,
  AddIcon,
  DeleteIcon,
  WrapperPopover,
  PopoverText,
  PopoverButton,
} from './styles';

function Collaborators() {
  const dispatch = useDispatch();
  const collabs = useSelector((state) => state.collabs);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState('');

  const data = collabs.collabs
    .map((item) => ({
      ...item,
      actions: item.id,
      id: item.id,
      name: item.name,
      email: item.email,
      date: item.created_at.seconds * 1000,
    }))
    .filter((item) => item.status === 1);

  const filteredData = data.filter(
    (item) =>
      item.name.toUpperCase().includes(search.toUpperCase()) ||
      item.email.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    dispatch(CollabsActions.getCollabsRequest());
  }, [dispatch]);

  return (
    <Container>
      <Paper
        title="Colaboradores"
        icon={Images.IconUserGear}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              { label: 'Ações', id: 'actions' },
              { label: 'ID', id: 'id' },
              { label: 'Nome', id: 'name' },
              { label: 'E-mail', id: 'email' },
              { label: 'Data de cadastro', id: 'date' },
            ]}
            activePage={0}
            totalPages={2}
            data={filteredData}
            customComponent={{
              actions: (id) => (
                <Row>
                  <DeleteIcon
                    onClick={(e) => {
                      setSelectedId(id);
                      setAnchorEl(e.currentTarget);
                      setOpen(true);
                    }}
                  />
                </Row>
              ),
              date: (e) => format(new Date(e), 'dd/MM/yyyy'),
            }}
          />
        </WrapperTable>
      </Paper>
      <WrapperAdd>
        <AddIcon onClick={() => setOpenModal(true)} />
      </WrapperAdd>
      <Modal
        data={collabs.collabs}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          setSelectedId(null);
        }}
        placement="top"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <WrapperPopover>
          <PopoverText>Tem certeza?</PopoverText>
          <PopoverButton
            onClick={() => {
              dispatch(CollabsActions.removeCollabRequest(selectedId));
              setSelectedId(null);
              setOpen(false);
            }}
          >
            Sim
          </PopoverButton>
        </WrapperPopover>
      </Popover>
    </Container>
  );
}

export default Collaborators;
