import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  getUndoneUserRequest: null,
  getUndoneUserSuccess: ['undoneUser'],
  getUndoneUserFailure: ['error'],
  getDashboardRequest: ['year'],
  getDashboardSuccess: ['dashboard'],
  getDashboardFailure: ['error'],
  getYearsOptionsRequest: null,
  getYearsOptionsSuccess: ['yearsOptions'],
  getYearsOptionsFailure: ['error'],
});

export const DashboardTypes = Types;
export default Creators;

/* Initial State */

const INITIAL_STATE = Immutable({
  undoneUser: [],
  dashboard: {},
  selectedYear: new Date().getFullYear(),
  yearsOptions: [],
  isLoading: false,
  error: null,
});

/* Reducers */

const getUndoneUserRequest = (state) => state.merge({ isLoading: true });
const getUndoneUserSuccess = (state, { undoneUser }) =>
  state.merge({ isLoading: false, undoneUser });

const getUndoneUserFailure = (state, { error }) =>
  state.merge({
    isLoading: false,
    error,
  });

const getDashboardRequest = (state, { year }) =>
  state.merge({ year, isLoading: true });

const getDashboardSuccess = (state, { dashboard }) =>
  state.merge({
    isLoading: false,
    dashboard: {
      nonApprovedUsers: 0,
      nonPaidCtesValue: 0,
      registeredUsers: 0,
      totalEmmitedCtes: 0,
      totalNonPaidCtes: 0,
      totalSendTax: 0,
      totalTax: 0,
      totalTransportTotal: 0,
      nonPaidHistory: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      emittedHistory: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ...dashboard,
    },
  });

const getDashboardFailure = (state, { error }) =>
  state.merge({
    isLoading: false,
    error,
  });

const getYearsOptionsRequest = (state) => state.merge({ isLoading: true });
const getYearsOptionsSuccess = (state, { yearsOptions }) =>
  state.merge({ isLoading: false, yearsOptions });

const getYearsOptionsFailure = (state, { error }) =>
  state.merge({
    isLoading: false,
    error,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_UNDONE_USER_REQUEST]: getUndoneUserRequest,
  [Types.GET_UNDONE_USER_SUCCESS]: getUndoneUserSuccess,
  [Types.GET_UNDONE_USER_FAILURE]: getUndoneUserFailure,
  [Types.GET_DASHBOARD_REQUEST]: getDashboardRequest,
  [Types.GET_DASHBOARD_SUCCESS]: getDashboardSuccess,
  [Types.GET_DASHBOARD_FAILURE]: getDashboardFailure,
  [Types.GET_YEARS_OPTIONS_REQUEST]: getYearsOptionsRequest,
  [Types.GET_YEARS_OPTIONS_SUCCESS]: getYearsOptionsSuccess,
  [Types.GET_YEARS_OPTIONS_FAILURE]: getYearsOptionsFailure,
});
