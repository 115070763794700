import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { FiArrowLeft, FiUser } from 'react-icons/fi';
import Images from 'assets/img';
import { Column } from 'styles/components';
import { capitalize, formatDate, getValue } from '../../helpers';
import BadgeStatus from '../ChargeItem/components/BadgeStatus';
import PercurseItem from './components/PercurseItem';

import {
  Container,
  Content,
  Divider,
  LabelAndValue,
  ProfileImgDefault,
  Text,
} from './styles';

export default function ChargeInfos({ convocation, cte, onClose }) {
  const [selectedCte, setSelectedCte] = useState(cte);

  function getStatus(status) {
    switch (status) {
      case 'in_progress_req':
        return (
          <Tooltip title="Motorista encontrado">
            <BadgeStatus
              icon={Images.ChargeStatusOne}
              color="rgba(137, 70, 160, 0.4)"
            />
          </Tooltip>
        );
      case 'arrived_requester':
        return (
          <Tooltip title="Carga encontrada">
            <BadgeStatus
              icon={Images.ChargeStatusTwo}
              color="rgba(240, 151, 2, 0.4)"
            />
          </Tooltip>
        );
      case 'in_progress_res':
        return (
          <Tooltip title="A caminho">
            <BadgeStatus
              icon={Images.ChargeStatusThree}
              color="rgba(112, 180, 242, 0.4)"
            />
          </Tooltip>
        );
      case 'completed':
        return (
          <Tooltip title="Carga finalizada">
            <BadgeStatus icon={Images.ChargeStatusFive} color="#2A5BA8" ended />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title={status}>
            <BadgeStatus icon={Images.ChargeStatusZero} text="Não iniciada" />
          </Tooltip>
        );
    }
  }

  useEffect(() => {
    setSelectedCte(cte);
  }, [cte]);

  return (
    <Container>
      <Scrollbars
        style={{ width: '336px', height: 'calc(100vh - 128px - 28px)' }}
      >
        <Content>
          <div className="status">{getStatus(convocation.status)}</div>
          <FiArrowLeft className="exit-icon" onClick={onClose} />
          <div className="profile-img">
            <ProfileImgDefault>
              <FiUser />
            </ProfileImgDefault>
          </div>
          <Text
            margin="8px 0 0 0"
            weight="600"
            color="#122D58"
            size="12px"
            lineHeight="14.63px"
          >
            {capitalize(convocation.driver_name)}
          </Text>
          <Text margin="0 0 14px 0">Motorista</Text>
          <Text color="#7B7B7B" size="12px" lineHeight="14.63px">
            {capitalize(convocation.requester)}
          </Text>
          <Text>Solicitante</Text>
          <Divider margin="24px 0" />
          <LabelAndValue>
            <div className="label">Carga</div>
            <div className="value">
              {convocation.type.replace('Carga ', '')}
            </div>
          </LabelAndValue>
          <LabelAndValue>
            <div className="label">Coleta</div>
            <div className="value">{formatDate(convocation.created_at)}</div>
          </LabelAndValue>
          <LabelAndValue>
            <div className="label">Volumes</div>
            <div className="value">Geral</div>
          </LabelAndValue>
          <LabelAndValue>
            <div className="label">
              {convocation.base_unit ? convocation.base_unit : 'Peso'}
            </div>
            <div className="value">{convocation.weight}</div>
          </LabelAndValue>
          <LabelAndValue>
            <div className="label">Valor</div>
            <div className="value">{getValue(convocation.value)}</div>
          </LabelAndValue>
          <LabelAndValue>
            <div className="label">ID</div>
            <div className="value">{convocation.id}</div>
          </LabelAndValue>
          <Divider margin="24px 0" />
          <Text
            margin="0 0 24px 0"
            weight="600"
            color="#7B7B7B"
            size="14px"
            lineHeight="17.07px"
            align="start"
          >
            Percurso
          </Text>
          <Column height="100%">
            {selectedCte.invoices.map((invoice) => (
              <PercurseItem
                invoice={invoice}
                invoiceTimestamp={cte.created_at}
              />
            ))}
          </Column>
        </Content>
      </Scrollbars>
    </Container>
  );
}

ChargeInfos.propTypes = {
  convocation: PropTypes.objectOf(PropTypes.string).isRequired,
  cte: PropTypes.objectOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};
