import React from 'react';
import { string, func } from 'prop-types';

import { Button, Icon } from './styles';

function CircleButton({
  icon,
  iconLight,
  disabled,
  width,
  label,
  type,
  onClick,
  align,
  height,
  padding,
  margin,
}) {
  return (
    <Button
      width={width}
      padding={padding}
      disabled={disabled}
      type={type}
      onClick={onClick}
      align={align}
      height={height}
      margin={margin}
    >
      {icon !== null && <Icon src={icon} iconLight={iconLight} />}
      {disabled && <div className="disabled-modal" />}
    </Button>
  );
}

export default CircleButton;

CircleButton.propTypes = {
  width: string,
  height: string,
  label: string.isRequired,
  type: string,
  margin: string,
  onClick: func,
  icon: string,
  align: string,
};

CircleButton.defaultProps = {
  width: null,
  icon: null,
  height: null,
  margin: 0,
  align: null,
  type: 'primary',
  onClick: () => {},
};
