import { put } from 'redux-saga/effects';
import api from 'services/api';

import InsurancesActions from '../ducks/insurances';

export function* getInsurances() {
  try {
    const data = yield api.firestore().collection('insurances').get();

    const insurances = data.docs.map((item) => item.data());

    yield put(InsurancesActions.getInsurancesSuccess(insurances));
  } catch (err) {
    yield put(InsurancesActions.getInsurancesFailure(err));
  }
}
