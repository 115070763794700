import { Tooltip } from '@material-ui/core';
import React from 'react';
import { Container } from './styles';

export default function BadgeStatus({ icon, color, title, ended }) {
  return (
    <Tooltip title={title}>
      <Container color={color} ended={ended}>
        <img src={icon} alt="Icone" />
      </Container>
    </Tooltip>
  );
}
