import { put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from 'services/api';

import TruckersActions from '../ducks/trucker';

export function* getTruckers() {
  try {
    const data = yield api
      .firestore()
      .collection('users')
      .where('role', '==', 'trucker')
      .orderBy('status')
      .get();

    const users = data.docs.map((item) => item.data());

    yield put(TruckersActions.getTruckersSuccess(users));
  } catch (err) {
    yield put(TruckersActions.getTruckersFailure(err));
  }
}

export function* getOneTrucker({ id }) {
  try {
    const data = yield api.firestore().collection('users').doc(id).get();

    const user = data.data();
    yield put(TruckersActions.getOneTruckerSuccess(user));
  } catch (err) {
    yield put(TruckersActions.getOneTruckerFailure(err));
  }
}

export function* getCpfTrucker({ cpf }) {
  try {
    const data = yield api
      .firestore()
      .collection('users')
      .where('cpf', '==', cpf)
      .get();

    const [user] = data.docs.map((item) => item.data());

    yield put(TruckersActions.getOneTruckerSuccess(user));
  } catch (err) {
    yield put(TruckersActions.getOneTruckerFailure(err));
  }
}

export function* reportPending({ id, body, closeModal }) {
  try {
    yield api
      .firestore()
      .collection('reports')
      .add({
        body,
        created_at: new Date(),
        direction: id,
        title: 'Pendência no cadastro',
      })
      .then((ref) => ref.update({ id: ref.id }));

    yield put(TruckersActions.reportPendingSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Pendência reportada',
      })
    );
    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao reportar pendência',
      })
    );
    yield put(TruckersActions.reportPendingFailure(err));
  }
}

export function* updateAditionalDocument({
  values,
  url_document,
  id,
  modalClose,
}) {
  try {
    const user = yield api.firestore().collection('users').doc(id).get();

    const { documents } = user.data();
    const index = documents.findIndex(
      (item) => item.url_document === url_document
    );

    documents[index] = Object.assign(documents[index], values);

    yield api.firestore().collection('users').doc(id).update({ documents });

    yield put(TruckersActions.updateAditionalDocumentSuccess());
    yield put(TruckersActions.getOneTruckerRequest(id));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Documento atualizado.',
      })
    );
    modalClose();
  } catch (err) {
    yield put(TruckersActions.updateAditionalDocumentFailure(err));
  }
}

export function* setDocumentData({ values, closeModal }) {
  try {
    const { oneTrucker } = yield select((state) => state.trucker);

    yield api
      .firestore()
      .collection('users')
      .doc(oneTrucker.id)
      .update({
        ...values,
        birth_date: new Date(values.birth_date),
        licenseDue: new Date(values.licenseDue),
        firstlicense: new Date(values.birth_date),
        date_risky: new Date(values.date_risky),
        updated_at: new Date(),
      });

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Aprovação realizada.',
      })
    );

    closeModal();

    yield put(TruckersActions.setDocumentDataSuccess());
  } catch (err) {
    yield put(TruckersActions.setDocumentDataFailure(err));
  }
}
