import React from 'react';
import { useDispatch } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import QRCode from 'qrcode.react';
import Modal from 'components/Modal';
import ConvocationsActions from 'store/ducks/convocations';
import Images from 'assets/img';

import {
  Container,
  WrapperBody,
  WrapperContent,
  WrapperBottom,
  BottomText,
  BoxQrCode,
  ImgLogo,
  BoxInfos,
  Label,
  Strong,
  Line,
} from './styles';

export default function ModalQRCode({ open, convocationKey, name, phone }) {
  const dispatch = useDispatch();
  const componentRef = React.useRef();
  const [image, setImage] = React.useState();

  const handleSendEmail = () => {
    htmlToImage
      .toJpeg(componentRef.current, {
        quality: 0.95,
        backgroundColor: '#122d58',
      })
      .then(function (dataUrl) {
        setImage(dataUrl);
      });
  };

  React.useEffect(() => {
    if (image !== null && image !== undefined)
      dispatch(ConvocationsActions.putSendMailRequest(image));
  }, [image]);

  const handleClose = () => {
    dispatch(ConvocationsActions.closeModalQrcode());
  };

  function handleDownload() {
    htmlToImage
      .toJpeg(componentRef.current, {
        quality: 0.95,
        backgroundColor: '#122d58',
      })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = 'qrcode.jpeg';
        link.href = dataUrl;
        link.click();
      });
  }

  return (
    <Modal open={open} onClose={handleClose} title="ENVIAR QRCODE?">
      <Container>
        <WrapperBody>
          <WrapperContent>
            {convocationKey !== undefined && convocationKey !== null && (
              <BoxQrCode ref={componentRef}>
                <ImgLogo src={Images.Logo} />
                <QRCode
                  id="qrcode"
                  value={convocationKey}
                  size={192}
                  level="H"
                  includeMargin
                />
                <BoxInfos>
                  <Label>Nome</Label>
                  <Strong>{name}</Strong>

                  <Label>Telefone</Label>
                  <Strong>{phone}</Strong>
                </BoxInfos>
              </BoxQrCode>
            )}
          </WrapperContent>
        </WrapperBody>
        <Line />
        <WrapperBottom>
          <BottomText onClick={handleDownload}>Baixar</BottomText>

          <BottomText onClick={handleSendEmail}>Enviar</BottomText>
        </WrapperBottom>
      </Container>
    </Modal>
  );
}
