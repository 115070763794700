import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  min-height: ${(props) => props.minHeight};
  height: calc(91.4vh - 64px);
  width: ${(props) => props.width};
  transition: 200ms;

  border-right: ${(props) => (props.mapActived ? '1px solid #F1F2F5' : 'none')};
  padding-right: 14px;

  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 768px) {
    display: ${(props) => (props.mapActived ? 'none' : 'flex')};
  }
`;

export const Header = styled.div`
  padding: 24px 28px 0 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & .icon {
    cursor: pointer;
  }

  @media (max-width: 510px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const WrapperLeft = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 510px) {
    padding-bottom: 20px;
  }
`;

export const Title = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : '#7b7b7b')};
`;

export const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
  position: absolute;
  top: -15px;
`;
