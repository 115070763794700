import styled from 'styled-components';
import { MdKeyboardArrowUp } from 'react-icons/md';

export const Container = styled.div``;

export const Body = styled.div`
  margin: 20px;
`;

export const Padding = styled.div`
  padding: 0 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const WrapperSection = styled.div`
  display: flex;
  flex: 2;
  width: 70%;
`;

export const WrapperText = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex: 1;
  width: 30%;
`;

export const FieldLabel = styled.text`
  font-size: 14px;
  color: #122d58;
  font-weight: 700;
  height: 30px;
`;

export const WrapperSmallImage = styled.div`
  overflow: hidden;
  height: 30px;
  width: 30px;
  border-radius: 5px;
`;

export const FieldValue = styled.h1`
  color: red;
`;
export const SmallImage = styled.img`
  width: 100%;
`;

export const WrapperTable = styled.div`
  border-bottom: 1px solid #f3f3f3;
`;

export const FlexCell = styled.div`
  flex: ${(props) => props.flex || 2};
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: center;
  color: #7b7b7b;
  font-size: 13px;
  font-weight: ${(props) => props.bold && '700'};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;

export const WhiteRow = styled.div`
  height: 30px;
  display: flex;
  margin-top: 30px;
`;

export const GrayRow = styled.div`
  height: 50px;
  background-color: #f3f3f3;
  display: flex;
`;
