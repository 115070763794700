import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getIssuersRequest: null,
  getIssuersSuccess: ['issuers'],
  getIssuersFailure: ['error'],
  addIssuerRequest: ['issuer', 'closeModal'],
  addIssuerSuccess: ['issuer'],
  addIssuerFailure: ['error'],
  updateIssuerRequest: ['issuer', 'closeModal'],
  updateIssuerSuccess: ['issuer'],
  updateIssuerFailure: ['error'],
});

export const IssuersTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  Issuers: [],
  isLoading: false,
});

/* Reducers */

const getIssuersRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getIssuersSuccess = (state, { issuers }) =>
  state.merge({
    issuers,
    isLoading: false,
  });

const getIssuersFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const addIssuerRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const addIssuerSuccess = (state, { issuer }) =>
  state.merge({
    issuer,
    isLoading: false,
  });

const addIssuerFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

const updateIssuerRequest = (state, { issuer }) =>
  state.merge({
    issuer,
    isLoading: true,
  });

const updateIssuerSuccess = (state, { issuer }) =>
  state.merge({
    issuer,
    isLoading: false,
  });

const updateIssuerFailure = (state, { error }) =>
  state.merge({
    error,
    isLoading: false,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ISSUERS_REQUEST]: getIssuersRequest,
  [Types.GET_ISSUERS_SUCCESS]: getIssuersSuccess,
  [Types.GET_ISSUERS_FAILURE]: getIssuersFailure,
  [Types.ADD_ISSUER_REQUEST]: addIssuerRequest,
  [Types.ADD_ISSUER_SUCCESS]: addIssuerSuccess,
  [Types.ADD_ISSUER_FAILURE]: addIssuerFailure,
  [Types.UPDATE_ISSUER_REQUEST]: updateIssuerRequest,
  [Types.UPDATE_ISSUER_SUCCESS]: updateIssuerSuccess,
  [Types.UPDATE_ISSUER_FAILURE]: updateIssuerFailure,
});
