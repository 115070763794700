import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as toastr } from 'react-redux-toastr';
import { reducer as auth } from './auth';
import { reducer as trucker } from './trucker';
import { reducer as collabs } from './collaborators';
import { reducer as trip } from './trip';
import { reducer as notifications } from './notifications';
import { reducer as dashboard } from './dashboard';
import { reducer as convocations } from './convocations';
import { reducer as providers } from './providers';
import { reducer as reports } from './reports';
import { reducer as chat } from './chat';
import { reducer as cte } from './cte';
import { reducer as insurances } from './insurances';
import { reducer as issuers } from './issuers';
import { reducer as antecipations } from './antecipations';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    toastr,
    auth,
    trucker,
    collabs,
    trip,
    notifications,
    dashboard,
    convocations,
    providers,
    reports,
    chat,
    cte,
    insurances,
    issuers,
    antecipations,
  });
