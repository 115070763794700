import { put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import api, { apiLogin } from 'services/api';

import ProvidersActions from '../ducks/providers';
// import AuthActions from '../ducks/auth';

export function* getProviders() {
  try {
    const data = yield api.firestore().collection('providers').get();

    const providers = data.docs.map((item) => item.data());

    yield put(ProvidersActions.getProvidersSuccess(providers));
  } catch (err) {
    yield put(ProvidersActions.getProvidersFailure(err));
  }
}

export function* addProvider({ provider, closeModal }) {
  try {
    const data = {
      ...provider,
      created_at: new Date(),
      updated_at: new Date(),
    };

    yield apiLogin
      .firestore()
      .collection('providers')
      .add(data)
      .then((ref) => {
        data.id = ref.id;
        ref.update({ id: ref.id });
      });

    data.created_at = {
      seconds: Math.round(data.created_at.getTime() / 1000),
    };

    yield put(ProvidersActions.addProviderSuccess(data));
    closeModal();
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'EMPRESA',
        message: 'Cadastro realizado com sucesso!',
      })
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar empresa',
        message: 'Tente novamente',
      })
    );

    yield put(ProvidersActions.addConvocationFailure(err));
  }
}
export function* updateProvider({ provider, closeModal }) {
  try {
    const uploadData = {
      ...provider,
      updated_at: new Date(),
    };

    yield api
      .firestore()
      .collection('providers')
      .doc(provider.id)
      .set(uploadData);

    yield put(ProvidersActions.updateProviderSuccess(uploadData));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'EMPRESA',
        message: 'Atualizada com sucesso!',
      })
    );
    closeModal();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar a empresa',
        message: 'Tente novamente',
      })
    );

    yield put(ProvidersActions.updateProviderFailure(err));
  }
}

export function* removeProvider({ id }) {
  try {
    yield api.firestore().collection('providers').doc(id).delete();

    yield put(ProvidersActions.removeProviderSuccess());
    yield put(ProvidersActions.getProvidersRequest());
  } catch (err) {
    yield put(ProvidersActions.removeProviderFailure(err));
  }
}
