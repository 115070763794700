import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Images from 'assets/img';
import { capitalize, zipcode } from 'pages/Convocations/helpers';
import { formatDateTime } from 'utils/formatDateTime';
import BadgeStatus from '../BadgeStatus';
import { Card, Container, Line, Text } from './styles';

export default function PercurseItem({ invoice, invoiceTimestamp }) {
  const [infoCharge, setInfoCharge] = useState(null);

  const getStatus = () => {
    if (invoice) {
      if (invoice.signature && invoice.status === 'completed') {
        return (
          <BadgeStatus
            icon={Images.ChargeStatusTwo}
            text="Carga Entregue"
            color="#6ABF62"
          />
        );
      }

      if (invoice.images && invoice.images.length > 0) {
        return (
          <BadgeStatus
            icon={Images.ChargeStatusTwo}
            text="Carga Coletada"
            color="#F09702"
          />
        );
      }

      return (
        <BadgeStatus
          icon={Images.ChargeStatusOne}
          text="Motorista Encontrado"
          color="#8946A0"
        />
      );
    }

    return null;
  };

  useEffect(() => {
    const getInfoCharge = (id) => {
      const charge = {
        completed: {
          marker: Images.MarkerChargeFour,
          color: 'rgba(106, 191, 98, 0.1)',
        },
        hasImages: {
          marker: Images.MarkerChargeTwo,
          color: 'rgba(240, 151, 2, 0.1)',
        },
        default: {
          marker: Images.MarkerChargeOne,
          color: 'rgba(137, 70, 160, 0.2)',
        },
      };

      if (id.signature && id.status === 'completed') {
        return charge.completed;
      }

      if (id.images != null && id.images.length > 0) {
        return charge.hasImages;
      }

      return charge.default;
    };

    const handleSetInfoCharge = () => {
      const data = getInfoCharge(invoice);
      setInfoCharge(data);
    };

    handleSetInfoCharge();
  }, [invoice]);

  const invoiceNumber = invoice.barcode.match(/\d{44}/)[0].substr(25, 9);

  const invoiceCreatedAt = new Date(
    invoiceTimestamp?.seconds * 1000 + invoiceTimestamp?.nanoseconds / 1000000
  );

  return (
    <Container>
      <div className="marker-line">
        <img src={infoCharge ? infoCharge.marker : null} alt="" />
        <Line className="line" />
      </div>
      <Card color={infoCharge ? infoCharge.color : null}>
        {invoice?.data?.name ? (
          <Text
            margin="8px 0 0 0"
            weight="600"
            color="#122D58"
            size="12px"
            lineHeight="14.63px"
            align="left"
          >
            {capitalize(invoice.data.name)}
          </Text>
        ) : (
          <Text
            margin="8px 0 0 0"
            weight="600"
            color="#122D58"
            size="12px"
            lineHeight="14.63px"
            align="left"
          >
            {capitalize(invoice.receiver.name)}
          </Text>
        )}
        <p>
          {invoice?.data
            ? `${capitalize(invoice.data.street)} ${
                invoice.data.number
              } - ${capitalize(
                invoice.data.city
              )}/${invoice.data.state.toUpperCase()} ${capitalize(
                invoice.data.country
              )} - ${zipcode(invoice.data.zipcode)}${
                invoice.data.complement !== ''
                  ? ` (${capitalize(invoice.data.complement)})`
                  : ''
              }`
            : invoice?.receiver
            ? `${
                invoice.receiver.street
                  ? `${capitalize(invoice.receiver.street)}`
                  : ''
              } ${
                invoice.receiver.number ? `${invoice.receiver.number}` : ''
              } - ${capitalize(
                invoice.receiver.city
                  ? `${capitalize(invoice.receiver.city)}`
                  : ''
              )}/${
                invoice.receiver.state
                  ? `${invoice.receiver.state.toUpperCase()}`
                  : ''
              } ${
                invoice.receiver.country
                  ? `${capitalize(invoice.receiver.country)}`
                  : ''
              } - ${
                invoice.receiver.zipcode
                  ? `${zipcode(invoice.receiver.zipcode)}`
                  : ''
              }${
                invoice.receiver.complement !== ''
                  ? ` (${capitalize(invoice.receiver.complement)})`
                  : ''
              }`
            : 'Não informado'}
        </p>
        {invoice?.client_id_invoice && <p>ID: {invoice.client_id_invoice}</p>}
        <p>Nº da Nota: {invoiceNumber}</p>
        {getStatus}
        <p className="date">{formatDateTime(invoiceCreatedAt)}</p>
      </Card>
    </Container>
  );
}

PercurseItem.propTypes = {
  invoice: PropTypes.shape({
    signature: PropTypes.bool,
    status: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.objectOf(PropTypes.string),
    receiver: PropTypes.arrayOf(PropTypes.string),
    client_id_invoice: PropTypes.string,
    barcode: PropTypes.string,
  }).isRequired,
  invoiceTimeStamp: PropTypes.string,
};
