import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  margin-top: 5px;
  padding: 0 70px 50px 0;
`;

export const Title = styled.h4`
  color: #122d58;
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '14px'};
  margin-left: 26px;
  text-transform: uppercase;
`;

export const FootWrapper = styled.div`
  height: 6px;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  flex: 1;
  align-content: flex-start;
  margin: 15px 0 35px 0;
`;
export const FootThick = styled.div`
  width: 25%;
  height: 6px;
  background: #122d58;
`;
export const FootLight = styled.div`
  height: 3px;
  width: 83%;
  background: #f4f4f4;
`;

export const WrapperContent = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1;
`;
