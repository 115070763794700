import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/ButtonDrawer';
import Selector from './components/Selector';
import TextInput from './components/TextInput';
import { Backdrop, Container } from './styles';
import Images from 'assets/img';
import DateInput from './components/DateInput';

export default function ModalFilter({
  form,
  setForm,
  handleFilter,
  options,
  open,
  handleClose,
  setOptions,
  resetFilter,
  data,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (form.delivery_state !== '') {
      const filtered = data.filter(
        (item) => item.delivery_state === form.delivery_state
      );
      const delivery_cities = [];

      filtered.forEach((item) => {
        if (!alreadyExists(capitalize(item.delivery_city), delivery_cities)) {
          delivery_cities.push(capitalize(item.delivery_city));
        }
      });

      setOptions({ ...options, deliveryCities: delivery_cities });
    }
  }, [form]);

  const alreadyExists = (value, list) => {
    const index = list.indexOf(value);

    return index !== -1;
  };

  function capitalize(string) {
    if (string !== null && string !== undefined) {
      const strings = string.toString().toLowerCase().split(' ');
      let formattedString = '';
      strings.forEach((item) => {
        item = item.trim();
        if (item === 'sao') {
          item = 'são';
        }
        if (item !== strings[strings.length - 1]) {
          formattedString += `${item.charAt(0).toUpperCase() + item.slice(1)} `;
        } else {
          formattedString += item.charAt(0).toUpperCase() + item.slice(1);
        }
      });

      return formattedString;
    }
    return '';
  }

  return (
    <Container className={open ? 'visible' : ''}>
      <div className="header">
        <img src={Images.Filter} alt="" />
        <h3>Filtrar Carga</h3>
        <CloseIcon className="icon-close" onClick={handleClose} />
      </div>
      <div className="grid">
        <TextInput
          name="id"
          label="Identificação"
          value={form.id}
          height="48px"
          placeholder="Informe a identificação"
          onChange={(e) => setForm({ ...form, id: e.target.value })}
        />
        <DateInput
          name="created_at"
          label="Data de criação"
          height="48px"
          placeholder="00/00/0000 - 00/00/0000"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            setForm({
              ...form,
              startDate: Date.parse(start),
              endDate: Date.parse(end),
            });
          }}
        />
        <Selector
          name="status"
          label="Status"
          value={form.status !== '' ? form.status : null}
          defaulOption="Selecione"
          status
          disabled={options.status.length === 0}
          options={options.status}
          onChange={(e) => setForm({ ...form, status: e })}
        />
        <TextInput
          label="Veículo"
          height="48px"
          placeholder="Informe o veículo"
          value={form.vehicle}
          onChange={(e) => setForm({ ...form, vehicle: e.target.value })}
        />
        <Selector
          name="driver_name"
          value={form.driver_name ? form.driver_name : null}
          label="Motorista"
          defaulOption="Selecione o motorista"
          disabled={options.drivers.length === 0}
          options={options.drivers}
          onChange={(e) => setForm({ ...form, driver_name: e })}
        />
        <Selector
          name="type"
          value={form.type ? form.type : null}
          label="Tipo de Carga"
          defaulOption="Selecione o tipo de carga"
          disabled={options.types.length === 0}
          options={options.types}
          onChange={(e) => setForm({ ...form, type: e })}
        />
        <TextInput
          name="nfe"
          value={form.nfe}
          label="Nota Fiscal"
          height="48px"
          placeholder="Informe a nota fiscal"
          onChange={(e) => setForm({ ...form, nfe: e.target.value })}
        />
        <Selector
          name="delivery_state"
          value={form.delivery_state ? form.delivery_state : null}
          label="Estado de Entrega"
          defaulOption="Selecione o estado de entrega"
          disabled={options.deliveryStates.length === 0}
          options={options.deliveryStates}
          onChange={(e) => setForm({ ...form, delivery_state: e })}
        />
        <Selector
          name="delivery_city"
          value={form.delivery_city ? form.delivery_city : null}
          label="Cidade de Entrega"
          defaulOption="Selecione a cidade de entrega"
          disabled={
            form.delivery_state === '' || options.deliveryCities.length === 0
          }
          options={options.deliveryCities}
          onChange={(e) => setForm({ ...form, delivery_city: e })}
        />
      </div>
      <div className="actions">
        <Button type="text-button" label="Limpar" onClick={resetFilter} />
        <Button
          type="primary"
          iconLight
          onClick={handleFilter}
          icon={Images.Filter}
          label="Filtrar"
        />
      </div>
    </Container>
  );
}
