/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';

import Paper from 'components/Paper';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import Modal from './components/Modal';
import Images from 'assets/img';
import { Row } from 'styles/components';
import ProvidersActions from 'store/ducks/providers';

import {
  Container,
  WrapperTable,
  WrapperAdd,
  AddIcon,
  DeleteIcon,
  EyeIcon,
  WrapperPopover,
  PopoverText,
  PopoverButton,
} from './styles';

function Providers() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const providers = useSelector((state) => state.providers);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState('');

  const data = providers.providers.map((item) => ({
    ...item,
    actions: item.id,
    id: item.id,
    socialReason: item.social_reason,
    cnpj: item.cnpj,
    owner: item.owner,
    phone: item.phone,
    email: item.email,
    address: `${item?.address}, ${item?.complement} - ${item?.neighborhood}, ${item?.city} - ${item?.state}`,
    created_at: item?.created_at.seconds * 1000,
  }));

  const filteredData = data.filter(
    (item) =>
      item.owner.toUpperCase().includes(search.toUpperCase()) ||
      item.socialReason.toUpperCase().includes(search.toUpperCase()) ||
      item.email.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    dispatch(ProvidersActions.getProvidersRequest());
  }, [dispatch]);

  return (
    <Container>
      <Paper
        title="EMPRESAS"
        icon={Images.IconProviders}
        rightContent={
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        }
      >
        <WrapperTable>
          <Table
            columns={[
              { label: 'Ações', id: 'actions' },
              { label: 'Razão Social', id: 'social_reason' },
              { label: 'CNPJ', id: 'cnpj' },
              { label: 'Responsável', id: 'owner' },
              { label: 'Telefone', id: 'phone' },
              { label: 'E-mail', id: 'email' },
              { label: 'Endereço', id: 'address' },
              { label: 'Data de cadastro', id: 'created_at' },
            ]}
            activePage={0}
            totalPages={2}
            data={filteredData}
            customComponent={{
              actions: (id) => (
                <Row>
                  <EyeIcon
                    onClick={() => {
                      setSelectedId(id);
                      setEdit(true);
                      setOpenModal(true);
                    }}
                  />
                  <DeleteIcon
                    onClick={(e) => {
                      setSelectedId(id);
                      setAnchorEl(e.currentTarget);
                      setOpen(true);
                    }}
                  />
                </Row>
              ),
              created_at: (e) => format(new Date(e), 'dd/MM/yyyy'),
            }}
          />
        </WrapperTable>
      </Paper>
      <WrapperAdd>
        <AddIcon
          onClick={() => {
            setSelectedId(null);
            setOpenModal(true);
          }}
        />
      </WrapperAdd>
      {openModal && (
        <Modal
          data={providers.providers}
          open={openModal}
          onClose={() => setOpenModal(false)}
          selectedId={selectedId}
          edit={edit}
        />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          setSelectedId(null);
        }}
        placement="top"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <WrapperPopover>
          <PopoverText>Tem certeza?</PopoverText>
          <PopoverButton
            onClick={() => {
              dispatch(ProvidersActions.removeProviderRequest(selectedId));
              setSelectedId(null);
              setOpen(false);
            }}
          >
            Sim
          </PopoverButton>
        </WrapperPopover>
      </Popover>
    </Container>
  );
}

export default Providers;
