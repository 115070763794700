import React from 'react';
import { bool, func, string } from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import { schema } from './schemaValidation';
import TruckerActions from 'store/ducks/trucker';

import { Container, WrapperBody, WrapperBottom, BottomText } from './styles';

function ReportModal({ open, onClose, id }) {
  const dispatch = useDispatch();

  function reportPending(body) {
    dispatch(TruckerActions.reportPendingRequest(id, body, onClose));
  }
  return (
    <Modal open={open} onClose={onClose} title="REPORTAR PENDÊNCIA">
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            body: '',
          }}
          onSubmit={(values) => {
            reportPending(values.body);
          }}
          validationSchema={schema}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <WrapperBody>
                <TextArea
                  label="Observações"
                  width="90%"
                  maxWidth="400px"
                  name="body"
                  error={touched.body && Boolean(errors.body)}
                  value={values.body}
                  onChange={handleChange}
                />
              </WrapperBody>
              <WrapperBottom>
                <BottomText onClick={onClose}>Voltar</BottomText>
                <BottomText onClick={handleSubmit}>Reportar</BottomText>
              </WrapperBottom>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}

export default ReportModal;

ReportModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  id: string.isRequired,
};
